import { graphql } from '../types/orange'

export const getVaultListQuery = graphql(/* GraphQL */ `
  query getVaultList($account: ID!) {
    vaults(orderBy: tvl, orderDirection: desc) {
      id
      totalAssets
      totalSupply
      feeAPR
      totalFeeUSD
      tvl
      decimals
      yieldStart
      isTokenPairReversed
      depositCap
      pool {
        id
        token0 {
          id
          name
          symbol
          decimals
          derivedETH
        }
        token1 {
          id
          name
          symbol
          decimals
          derivedETH
        }
      }
    }
    user(id: $account) {
      id
      positions(where: { share_gt: 0 }) {
        id
        vault
        share
      }
    }
    globalState(id: "current") {
      ethPriceUSD
    }
  }
`)

export const getVaultQuery = graphql(/* GraphQL */ `
  query getVault($account: ID!, $vaultAddress: ID!) {
    vault(id: $vaultAddress) {
      id
      totalAssets
      totalSupply
      feeAPR
      totalFeeUSD
      tvl
      decimals
      yieldStart
      isTokenPairReversed
      depositCap
      parameter
      pool {
        id
        tick
        sqrtPrice
        token0 {
          id
          symbol
          decimals
          derivedETH
        }
        token1 {
          id
          symbol
          decimals
          derivedETH
        }
      }
      tokenComposition {
        vaultToken1
        vaultToken0
        poolToken1
        poolToken0
      }
    }
    user(id: $account) {
      id
      positions(where: { share_gt: 0 }) {
        id
        vault
        share
      }
    }
    globalState(id: "current") {
      ethPriceUSD
    }
  }
`)

export const getDopexVaultListQuery = graphql(/* GraphQL */ `
  query getDopexVaultList($account: ID!) {
    dopexVaults(orderBy: totalSupply, orderDirection: desc) {
      id
      totalAssets
      totalSupply
      decimals
      yieldStart
      isTokenPairReversed
      depositCap
      pool
    }
    user(id: $account) {
      id
      positions(where: { share_gt: 0 }) {
        id
        vault
        share
      }
    }
  }
`)

export const getDopexVaultQuery = graphql(/* GraphQL */ `
  query getDopexVault($account: ID!, $vaultAddress: ID!) {
    dopexVault(id: $vaultAddress) {
      id
      totalAssets
      totalSupply
      decimals
      yieldStart
      isTokenPairReversed
      depositCap
      pool
    }
    user(id: $account) {
      id
      positions(where: { share_gt: 0 }) {
        id
        vault
        share
      }
    }
  }
`)

// TODO: remove $account not used
export const getSpaceshipVaultListQuery = graphql(/* GraphQL */ `
  query getSpaceshipVaultList($account: ID!) {
    dopexVaults(orderBy: totalSupply, orderDirection: desc) {
      id
      totalAssets
      totalSupply
      decimals
      yieldStart
      isTokenPairReversed
      depositCap
      pool
    }
    vaults(orderBy: totalSupply, orderDirection: desc) {
      id
      totalAssets
      totalSupply
      decimals
      yieldStart
      isTokenPairReversed
      depositCap
      pool {
        id
      }
    }
  }
`)

export const getSpaceshipVaultListWithBlockNumberQuery = graphql(/* GraphQL */ `
  query getSpaceshipVaultListWithBlockNumber($blockNumber: Int) {
    dopexVaults(block: { number: $blockNumber }, orderBy: totalSupply, orderDirection: desc) {
      id
      totalAssets
      totalSupply
      decimals
      yieldStart
      isTokenPairReversed
      depositCap
      pool
    }
    vaults(block: { number: $blockNumber }, orderBy: totalSupply, orderDirection: desc) {
      id
      totalAssets
      totalSupply
      decimals
      yieldStart
      isTokenPairReversed
      depositCap
      pool {
        id
      }
    }
  }
`)

export const getRebalanceEventsListQuery = graphql(/* GraphQL */ `
  query getDopexRebalanceEventsList($vaultAddress: String!, $yieldStart: BigInt!) {
    rebalances(
      where: { vault: $vaultAddress, blockTimestamp_gte: $yieldStart }
      orderBy: blockTimestamp
      orderDirection: asc
    ) {
      id
      vault
      totalAssets
      totalSupply
      rate
      blockNumber
      blockTimestamp
    }
  }
`)

export const getMultiPoolReservedLiquidityListQuery = graphql(/* GraphQL */ `
  query getMultiPoolReservedLiquidityList(
    $account: String!
    $poolIds: [String!]!
    $handlerIds: [String!]!
    $skip: Int
  ) {
    reservedLiquidities(
      where: { user: $account, handler_in: $handlerIds, pool_in: $poolIds, liquidity_gt: 0 }
      first: 1000
      skip: $skip
    ) {
      id
      tokenId
      handler
      helper
      user
      pool
      tickLower
      tickUpper
      liquidity
      blockNumber
      blockTimestamp
    }
  }
`)
