'use client'

import { createConfig, Connector, http, CreateConnectorFn, createStorage } from 'wagmi'
import { arbitrum, polygon } from 'wagmi/chains'
import { coinbaseWallet, walletConnect, injected, metaMask } from 'wagmi/connectors'

// import { BybitConnector } from './connectors/BybitConnector'
// import { RabbyConnector } from './connectors/RabbyConnector'

export type Config = ReturnType<typeof createConfig>

const arbitrumAlchemyId = process.env.NEXT_PUBLIC_ALCHEMY_ID as string | undefined

if (!arbitrumAlchemyId) {
  throw Error('NO ALCHEMY ID SET')
}

const alchemyBaseUrlArbitrum = 'https://arb-mainnet.g.alchemy.com/v2'

const walletconnectProjectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID

export const getConfig = () => {
  const connectors: CreateConnectorFn[] = [
    // injected({ target: 'metaMask' }),
    // injected({ target: 'rabby' }),
    // new RabbyConnector({
    //   chains,
    //   options: {
    //     installUrl: 'https://rabby.io',
    //   },
    // }),
    // new BybitConnector({
    //   chains,
    //   options: {
    //     installUrl:
    //       'https://chromewebstore.google.com/detail/bybit-wallet/pdliaogehgdbhbnmkklieghmmjkpigpa',
    //   },
    // }),
    coinbaseWallet({
      appName: 'Orange Finance',
    }),
  ]
  if (walletconnectProjectId) {
    connectors.push(
      walletConnect({
        projectId: walletconnectProjectId,
      })
    )
  }

  return createConfig({
    chains: [arbitrum],
    connectors,
    multiInjectedProviderDiscovery: true,
    transports: {
      [arbitrum.id]: http(`${alchemyBaseUrlArbitrum}/${arbitrumAlchemyId}`),
    },
  })
}
