import { BN } from '@apps-orangefi/lib'
import { OrangeStakingABI } from '@apps-orangefi/wagmi/abis'
import {
  useSimulateContractWithErrorHandling,
  useWriteContractWithErrorHandling,
  useWaitForTransactionReceiptWithErrorHandling,
} from '@apps-orangefi/wagmi/hooks/common'
import dayjs from 'dayjs'
import { useMemo, useEffect, useState } from 'react'
import { BaseError } from 'wagmi'

const MIN_LOCK_DURATION = dayjs.duration(1, 'days').asSeconds()
const MAX_LOCK_DURATION = dayjs.duration(183, 'days').asSeconds()

export const useCreateLock = (
  tokenAddress: AddressType | undefined,
  stakingAddress: AddressType | undefined,
  amount: BN | undefined,
  lockDuration: number,
  allowance: BN,
  decimals: number | undefined,
  callback?: { success?: () => void; fail?: (cause: BaseError | string) => void }
) => {
  const [isReady, setIsReady] = useState(false)

  const args = useMemo(() => {
    return [
      tokenAddress!,
      amount?.pow10(decimals ?? 0)?.convertBigint() ?? BigInt(0),
      BigInt(lockDuration),
    ] as const
  }, [tokenAddress, amount, lockDuration, decimals])

  const enabled = useMemo(() => {
    return (
      !!tokenAddress &&
      !!stakingAddress &&
      !!amount &&
      amount.gt(0) &&
      allowance.gte(amount) &&
      lockDuration >= MIN_LOCK_DURATION &&
      lockDuration <= MAX_LOCK_DURATION &&
      !!decimals
    )
  }, [tokenAddress, stakingAddress, amount, lockDuration, allowance, decimals])

  const { data } = useSimulateContractWithErrorHandling({
    address: stakingAddress!,
    abi: OrangeStakingABI,
    functionName: 'createNewPosition',
    args,
    query: {
      enabled,
    },
  })

  const { data: hash, writeContract } = useWriteContractWithErrorHandling({
    mutation: {
      onError(error) {
        if (callback && callback.fail) {
          callback.fail(error.cause as BaseError)
        }
      },
    },
  })

  const {
    isLoading: waitLoading,
    isSuccess,
    isError,
    error,
  } = useWaitForTransactionReceiptWithErrorHandling({ hash }, callback)

  useEffect(() => {
    setIsReady(!!writeContract && !!data?.request)
  }, [writeContract, data?.request])

  return {
    isReady,
    hash,
    isSuccess,
    onCreateLock: () => {
      if (!isReady) {
        return
      }
      writeContract(data!.request)
    },
  }
}
