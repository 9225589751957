import { MyPosition } from '@apps-orangefi/lib/types'
import { useConvertToAssets, useTokenBalance } from '@apps-orangefi/wagmi/hooks'
import { useState, useEffect, useMemo, useCallback } from 'react'

import '@apps-orangefi/lib/extensions'

// TODO: ABI should be replaced by vault version
const useMyPosition = (
  vaultAddress: AddressType | undefined,
  account: AddressType | undefined,
  vaultDecimals: number | null,
  watch: boolean = false
) => {
  const [myPosition, setMyPosition] = useState<MyPosition | undefined>(undefined)
  const {
    data: myData,
    isFetching: myDataIsFetching,
    isError: myDataIsError,
    refresh: refreshTokenBalance,
  } = useTokenBalance({
    account,
    tokenAddress: vaultAddress,
    watch,
  })

  const {
    assets,
    isFetching,
    refresh: refreshConvertToAssets,
  } = useConvertToAssets(vaultAddress, myData?.balance, vaultDecimals)

  useEffect(() => {
    if (!!assets && !!myData && !!vaultDecimals) {
      const _myPosition: MyPosition = {
        lpBalance: myData.balance,
        position: assets,
      }
      setMyPosition(_myPosition)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(assets), myData, vaultDecimals])

  const refresh = useCallback(() => {
    refreshTokenBalance()
    refreshConvertToAssets()
  }, [refreshTokenBalance, refreshConvertToAssets])

  return { myPosition, isFetching, refresh }
}

export default useMyPosition
