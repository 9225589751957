import { BN } from '@apps-orangefi/lib'
import { StrykeUniV3HandlerV2ABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractsWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import '@apps-orangefi/lib/extensions'

type ReservedLiquidity = {
  tokenId: string
  liquidity: BN
  lastReserve: number
}

export const useGetReservedLiquidities = (
  handlerAddress: AddressType | undefined,
  reserveHelperAddress: AddressType | undefined,
  tokenIds: string[]
) => {
  const [reservedLiquidities, setReservedLiquidities] = useState<ReservedLiquidity[]>([])
  const enabled = !!handlerAddress && !!reserveHelperAddress && tokenIds.length > 0

  const contractBase = {
    address: handlerAddress!,
    abi: StrykeUniV3HandlerV2ABI,
    functionName: 'reservedLiquidityPerUser',
  }

  const handlerContracts = tokenIds.map(tokenId => {
    return {
      ...contractBase,
      args: [tokenId, reserveHelperAddress!],
    }
  })

  const { data, isFetching, isFetched, queryKey } = useReadContractsWithErrorHandling({
    contracts: handlerContracts,
    query: {
      enabled,
    },
  })

  useEffect(() => {
    if (data) {
      const result = data.map((item: any) => {
        const typedItem = item.result as [bigint, bigint]
        return {
          liquidity: new BN(typedItem[0].toString()),
          lastReserve: Number(typedItem[1]),
        }
      })
      const rsvdLiquidities = tokenIds.map((tokenId, index) => {
        return {
          tokenId,
          ...result[index],
        }
      })
      if (!isEqual(reservedLiquidities, rsvdLiquidities)) {
        setReservedLiquidities(rsvdLiquidities)
      }
    }
  }, [data])

  return {
    reservedLiquidities,
    isFetching,
    isFetched,
  }
}
