import { BN } from '@apps-orangefi/lib'
import { OctagonContainer } from '@apps-orangefi/ui/atoms/point'
import { IconInfo } from '@apps-orangefi/ui/molecules/icons'
import dayjs, { Dayjs } from 'dayjs'
import Image from 'next/image'
import Link from 'next/link'

const tailwindConfig = require('@apps-orangefi/tailwindcss')

type Props = {
  protocolName: string
  protocolImage: string
  description: string
  rewardPoints: number
  linkUrl: string | undefined
  theme: string
  isJoined: boolean
  snapshotDate: Dayjs
}
const regexp = /\n/g

export const Task = ({
  protocolName,
  protocolImage,
  description,
  rewardPoints,
  linkUrl,
  theme,
  isJoined,
  snapshotDate,
}: Props) => {
  const borderColor = `linear-gradient(to right, ${tailwindConfig.theme.extend.colors.point[theme]} 5%, rgba(95, 99, 111, 0.1) 60%)`

  const hoverText =
    description.replace(regexp, '<br/>') +
    `<br/><br/>You'll earn ${new BN(rewardPoints).toFormat()} Chapter1 points`

  const now = dayjs().utc()

  return (
    <OctagonContainer size={4} borderColor={borderColor} className="px-4 py-3 sm:px-8 sm:py-6">
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <div className="flex flex-col">
          <div className="flex flex-col sm:flex-row justify-between w-full gap-1 sm:gap-0">
            <div className="flex flex-row items-center">
              <Image src={protocolImage} alt="orange" width={28} height={28} />
              <span className="ml-3 type-xl-semibold dark:text-white">{protocolName}</span>
              <IconInfo tooltipText={hoverText} size={16} className="ml-3" />
            </div>
          </div>
          <div className="mt-1 ml-10 type-sm-caption">
            Snapshot: {snapshotDate.format('MMMM D, YYYY, ha (UTC)')}
          </div>
        </div>

        <div className="flex flex-row items-center mt-2 sm:mt-0 w-full sm:w-auto justify-between">
          <div className="type-base-semibold sm:type-xl-semibold ml-10 sm:ml-0">
            <span className="dark:text-orange-500">
              {new BN(rewardPoints).toFormat()} Chapter1 Points
            </span>
          </div>
          <OctagonContainer
            size={3}
            borderColor={tailwindConfig.theme.extend.colors.gray[600]}
            className="ml-10 w-fit h-fit"
          >
            {now.isBefore(snapshotDate) && linkUrl ? (
              <div
                className="flex flex-row justify-center type-sm-bold dark:text-white w-[94px] sm:w-[110px] h-full px-3 sm:px-5 py-2 link-shadow rounded-[9px]"
                // className="flex flex-row type-sm-bold dark:text-white sm:w-full h-full px-3 sm:px-5 py-2 link-shadow rounded-xl"
              >
                {isJoined ? (
                  <a href={linkUrl} target="_blank">
                    DEPOSIT
                  </a>
                ) : (
                  <Link href="/">JOIN FIRST</Link>
                )}
              </div>
            ) : (
              <button
                disabled
                className="flex flex-row justify-center type-sm-bold dark:text-white w-[94px] sm:w-[110px] h-full px-3 sm:px-5 py-2 link-shadow rounded-[9px]"
              >
                CLOSED
              </button>
            )}
          </OctagonContainer>
        </div>
      </div>
    </OctagonContainer>
  )
}
