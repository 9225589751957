import { Position } from '@apps-orangefi/lib/types/point'
import { Platform } from '@apps-orangefi/ui/atoms'
import { TokenPairBySymbol } from '@apps-orangefi/ui/molecules'
import { twMerge } from 'tailwind-merge'

type TokenPair = Position['tokenPair']
type Platform = Position['platform']

type Props = {
  tokenPair: TokenPair
  platform: Platform
  className?: string
}

export const VaultAttributes = ({ tokenPair, platform, className }: Props) => {
  const pairName = tokenPair.baseToken.symbol + '-' + tokenPair.quoteToken.symbol
  return (
    <div className={twMerge('rounded-xl dark:bg-gray-850 px-3 py-2', className)}>
      <div className="flex flex-row items-center">
        <TokenPairBySymbol
          baseTokenSymbol={tokenPair.baseToken.symbol}
          quoteTokenSymbol={tokenPair.quoteToken.symbol}
          size={24}
        />
        <span className="type-base-semibold dark:text-white ml-7">{pairName}</span>
      </div>
      <div className="w-full h-1 border-t border-gray-800 my-2"></div>
      <Platform amm={platform?.amm} derivative={platform?.derivative} size={24} />
    </div>
  )
}
