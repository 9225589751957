import { OctagonContainer } from '@apps-orangefi/ui/atoms/point/OctagonContainer'
import { forwardRef, useState } from 'react'

const tailwindConfig = require('@apps-orangefi/tailwindcss')

type Index = 0 | 1 | 2 | 3 | 4 | 5

type Props = {
  code: string
  index: Index
  setCode: (code: string, index: Index) => void
  setAllCodes?: (inviteCode: string) => void
  disabled: boolean
}

export const InviteCodeInput = forwardRef(function InviteCode(
  { code, index, setCode, setAllCodes, disabled }: Props,
  ref
) {
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = ref as React.MutableRefObject<HTMLInputElement>

  return (
    <OctagonContainer
      size={4}
      borderColor={tailwindConfig.theme.extend.colors.gray[800]}
      bgColor={tailwindConfig.theme.extend.colors.gray[850]}
      className="flex flex-col items-center justify-center h-fit w-fit type-base-bold"
    >
      <div className="flex flex-col relative items-center">
        <input
          type="text"
          value={code}
          ref={inputRef}
          maxLength={1}
          onFocus={e => {
            setIsFocused(true)
          }}
          onBlur={e => {
            setIsFocused(false)
          }}
          onChange={e => {
            setCode(e.currentTarget.value.toUpperCase(), index as Index)
          }}
          onKeyDown={e => {
            if (e.code === 'Backspace' && code === '') {
              setCode('', index as Index)
            }
          }}
          onPaste={e => {
            if (setAllCodes) {
              const inviteCode = e.clipboardData.getData('text/plain').toUpperCase()
              setAllCodes(inviteCode)
            }
          }}
          disabled={disabled}
          className="max-w-[36px] max-h-[40px] md:max-w-[60px] md:max-h-[54px] bg-transparent focus:outline-0 focus:outline-none focus:ring-0 border-none caret-transparent text-center type-4xl-bold dark:text-white"
        />
        <div
          className={`absolute w-6 border-b border-1 bottom-2 ${
            isFocused ? 'border-orange-500' : 'border-gray-800'
          }`}
        ></div>
      </div>
    </OctagonContainer>
  )
})
