import { BN } from '@apps-orangefi/lib'
import { bigintToBN } from '@apps-orangefi/lib/utils'
import { StrykeGaugeControllerABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractsWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { useEffect, useMemo, useState } from 'react'

import '@apps-orangefi/lib/extensions'

type RewardTarget = {
  vaultAddress: AddressType
  strykeGaugeId: AddressType
  epoch: number
}

export const useEpochRewards = (
  controllerAddress: AddressType | undefined,
  rewardTargets: RewardTarget[]
) => {
  const [rewards, setRewards] = useState<(RewardTarget & { reward: BN })[] | null>(null)

  const enabled = useMemo(
    () => !!controllerAddress && rewardTargets.length > 0,
    [controllerAddress, rewardTargets]
  )

  const contracts = useMemo(() => {
    const controllerContract = {
      address: controllerAddress!,
      abi: StrykeGaugeControllerABI,
    }

    return rewardTargets.map(({ strykeGaugeId, epoch }) => ({
      ...controllerContract,
      functionName: 'computeRewards',
      args: [strykeGaugeId, epoch],
    }))
  }, [rewardTargets, controllerAddress])

  const { data, isFetching, queryKey } = useReadContractsWithErrorHandling({
    contracts,
    query: {
      enabled,
    },
  })

  useEffect(() => {
    if (data) {
      setRewards(
        rewardTargets.map((target, index) => ({
          ...target,
          // TODO: decimal
          reward: bigintToBN(data[index].result as bigint | undefined).pow10ofMinus(18),
        }))
      )
    }
  }, [data])

  return {
    rewards,
    isFetching,
  }
}
