import { BaseModal } from '@apps-orangefi/ui/organisms/modals'
import { Dialog } from '@headlessui/react'
import useTranslation from 'next-translate/useTranslation'

export type SwitchChainModalProps = {
  handleClose: () => void
  handleSwitchChain: () => void
  chainInfo: {
    name: string | undefined
    id: number | undefined
  }
}

export const SwitchChainModal = ({
  handleClose,
  handleSwitchChain,
  chainInfo,
}: SwitchChainModalProps) => {
  const { t } = useTranslation()

  return (
    <BaseModal onClose={() => handleClose()}>
      <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl dark:bg-gray-900 p-6 pb-8 text-left align-middle shadow-xl transition-all">
        <Dialog.Title as="h3" className="type-xl-semibold dark:text-gray-400 text-start">
          {t('MODAL.SWITCH_NETWORK.TITLE')}
        </Dialog.Title>
        <div className="mt-10 type-base-medium dark:text-gray-200">
          <div>{t('MODAL.SWITCH_NETWORK.DESC')}</div>
          {chainInfo.id && chainInfo.name && (
            <button
              key={chainInfo.id}
              onClick={() => handleSwitchChain()}
              className="flex w-full items-center rounded-full py-4 px-8 bg-brightGray dark:text-white hover:opacity-90 mt-8 disabled:cursor-not-allowed disabled:opacity-70"
            >
              {chainInfo.name}
            </button>
          )}
        </div>
      </Dialog.Panel>
    </BaseModal>
  )
}
