import { useTx, useModal } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { Tx, txListAtom, txStatus, isAllowedCloseModalAtom } from '@apps-orangefi/lib/store'
import { MODAL_TYPES, TxModalTypes } from '@apps-orangefi/ui/organisms/modals'
import {
  WithdrawHook,
  useReserveHelpers,
  useCreateReserveHelper,
  useIsApporvedStrykeLP,
  useApproveStrykeLP,
} from '@apps-orangefi/wagmi/hooks'
import { atom, useAtom, useAtomValue } from 'jotai'
import { chain as _chain, isEmpty, every, some, set } from 'lodash'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useAccount } from 'wagmi'

const txCreateReserveHelperDefault: Tx = {
  title: 'Create Reserve Helper',
  hash: undefined,
  status: txStatus.Wait,
}

const txApproveLPDefault: Tx = {
  title: 'Approve Stryke LP',
  hash: undefined,
  status: txStatus.Wait,
}

const txCreateReserveHelperAtom = atom<Tx>(txCreateReserveHelperDefault)
const txApproveLPAtom = atom<Tx>(txApproveLPDefault)

export const useApproveLPForm = (
  account: AddressType | undefined,
  modalType: TxModalTypes,
  reserveProxyAddress?: AddressType,
  handlerAddress?: AddressType
) => {
  const [txList, setTxList] = useAtom(txListAtom)
  const [isAllowedCloseModal, setIsAllowedCloseModal] = useAtom(isAllowedCloseModalAtom)

  const { chain } = useAccount()

  const {
    tx: txCreateReserveHelper,
    setTx: setTxCreateReserveHelper,
    moveToPending: txCreateReserveHelperPending,
    moveToError: txCreateReserveHelperError,
    moveToSuccess: txCreateReserveHelperSuccess,
  } = useTx(txCreateReserveHelperAtom)

  const {
    tx: txApproveLP,
    setTx: setTxApproveLP,
    moveToPending: txApproveLPPending,
    moveToError: txApproveLPError,
    moveToSuccess: txApproveLPSuccess,
  } = useTx(txApproveLPAtom)

  const resetTx = () => {
    if (txList.length !== 0) return
    setTxCreateReserveHelper(txCreateReserveHelperDefault)
    setTxApproveLP(txApproveLPDefault)
  }

  const initTxList = () => {
    if (txList.length === 0) {
      setTxList([txCreateReserveHelperAtom, txApproveLPAtom])
    }
  }

  const {
    reserveHelperAddress,
    refetch: refetchReserveHelper,
    isFetched: isFetchedReserveHelper,
  } = useReserveHelpers(reserveProxyAddress, account)

  const createReserveHelper = useCreateReserveHelper(reserveProxyAddress, {
    success: () => {
      txCreateReserveHelperSuccess()
      refetchReserveHelper()
    },
  })

  const {
    isApproved,
    isFetched: isFetchedIsApproved,
    refresh: refreshIsApproved,
  } = useIsApporvedStrykeLP(handlerAddress, account, reserveHelperAddress)

  const approveStrykeLP = useApproveStrykeLP(
    handlerAddress,
    reserveHelperAddress,
    isFetchedIsApproved && !isApproved,
    {
      success: () => {
        txApproveLPSuccess()
        refreshIsApproved()
      },
    }
  )

  useEffect(() => {
    if (!createReserveHelper.hash || !!txCreateReserveHelper.hash) return
    setTxCreateReserveHelper(prev => {
      return { ...prev, hash: createReserveHelper.hash }
    })
  }, [createReserveHelper.hash])

  useEffect(() => {
    if (!approveStrykeLP.hash || !!txApproveLP.hash) return
    setTxApproveLP(prev => {
      return { ...prev, hash: approveStrykeLP.hash }
    })
  }, [approveStrykeLP.hash])

  useEffect(() => {
    if (txList.length === 0) return
    if (txCreateReserveHelper.status !== txStatus.Success) return
    if (txApproveLP.status === txStatus.Wait || txApproveLP.status === txStatus.Pending) {
      if (isApproved) {
        txApproveLPSuccess()
      }
    }
    if (txApproveLP.status === txStatus.Wait && approveStrykeLP.isWriteReady && !isApproved) {
      txApproveLPPending()
      approveStrykeLP.onApprove()
    }
  }, [txList, txCreateReserveHelper, txApproveLP, isApproved, approveStrykeLP])

  useEffect(() => {
    const isTransactionEnd =
      every([txCreateReserveHelper, txApproveLP], ['status', txStatus.Success]) ||
      some([txCreateReserveHelper, txApproveLP], ['status', txStatus.Error])
    if (isTransactionEnd !== isAllowedCloseModal) {
      setIsAllowedCloseModal(isTransactionEnd)
    }
  }, [txCreateReserveHelper, txApproveLP, setIsAllowedCloseModal])

  const { showModal, hideModal } = useModal()

  const onApproveLP = useCallback(() => {
    resetTx()
    initTxList()
    showModal({
      modalType,
      modalProps: {
        title: 'Approve LP transaction',
        chain,
        handleClose: () => {
          hideModal()
        },
        isDefaultClosable: true,
      },
    })
    txCreateReserveHelperPending()
    if (!reserveHelperAddress) {
      createReserveHelper.onCreateReserveHelper()
    } else {
      txCreateReserveHelperSuccess()
    }
  }, [
    txCreateReserveHelperPending,
    reserveHelperAddress,
    createReserveHelper.onCreateReserveHelper,
  ])

  const onApproveLPWithoutModal = useCallback(() => {
    resetTx()
    initTxList()

    txCreateReserveHelperPending()
    if (!reserveHelperAddress) {
      createReserveHelper.onCreateReserveHelper()
    } else {
      txCreateReserveHelperSuccess()
    }
  }, [
    txCreateReserveHelperPending,
    reserveHelperAddress,
    createReserveHelper.onCreateReserveHelper,
  ])

  return {
    resetTx,
    initTxList,
    hasReserveHelper: !!reserveHelperAddress,
    isApproved,
    isFetched: isFetchedReserveHelper && (!!reserveHelperAddress ? isFetchedIsApproved : true),
    isReadyCreateReserveHelper: createReserveHelper.isWriteReady,
    onApproveLP,
    onApproveLPWithoutModal,
  }
}
