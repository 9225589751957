import { useTx, useModal, useSimulateRedeem } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { MIN_FACTOR } from '@apps-orangefi/lib/constants'
import {
  Tx,
  migrationTxListAtom,
  txStatus,
  vaultDecimalsAtom,
  tokenAtom,
} from '@apps-orangefi/lib/store'
import { vaultVersion } from '@apps-orangefi/lib/types'
import { getDepositProof } from '@apps-orangefi/lib/utils'
import { MODAL_TYPES } from '@apps-orangefi/ui/organisms/modals'
import {
  useAllowance,
  useApprove,
  useConvertToShares,
  useWithdrawLPDfi,
  useDepositLPDfi,
} from '@apps-orangefi/wagmi/hooks'
import { atom, useAtom, useAtomValue } from 'jotai'
import { isEmpty } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useAccount } from 'wagmi'

import type { DistributorList } from '@apps-orangefi/lib/utils'

const txRedeemDefault: Tx = {
  title: 'Redeem Position',
  hash: undefined,
  status: txStatus.Wait,
}

const txApproveDefault: Tx = {
  title: 'Approve WETH',
  hash: undefined,
  status: txStatus.Wait,
}

const txDepositDefault: Tx = {
  title: 'Deposit',
  hash: undefined,
  status: txStatus.Wait,
}

const txRedeemAtom = atom<Tx>(txRedeemDefault)
const txApproveAtom = atom<Tx>(txApproveDefault)
const txDepositAtom = atom<Tx>(txDepositDefault)

export const useMigrateAutomatorForm = (
  tokenAddress: AddressType | undefined,
  fromWithdrawAddress: AddressType | undefined,
  toDepositAddress: AddressType | undefined,
  account: AddressType | undefined,
  lpBalance: BN | undefined,
  accountTokenBalance: BN | undefined,
  whiteList: DistributorList,
  handlerAddress: AddressType
) => {
  const { t } = useTranslation()
  const [txList, setTxList] = useAtom(migrationTxListAtom)
  const vaultDecimals = useAtomValue(vaultDecimalsAtom)
  const token = useAtomValue(tokenAtom)
  const { chain } = useAccount()
  const [amount, setAmount] = useState(new BN(0))
  const [merkleProof, setMerkleProof] = useState<AddressType[]>([])

  const {
    tx: txRedeem,
    setTx: setTxRedeem,
    moveToPending: txRedeemPending,
    moveToError: txRedeemError,
    moveToSuccess: txRedeemSuccess,
  } = useTx(txRedeemAtom)

  const {
    tx: txApprove,
    setTx: setTxApprove,
    moveToPending: txApprovePending,
    moveToError: txApproveError,
    moveToSuccess: txApproveSuccess,
  } = useTx(txApproveAtom)

  const {
    tx: txDeposit,
    setTx: setTxDeposit,
    moveToPending: txDepositPending,
    moveToError: txDepositError,
    moveToSuccess: txDepositSuccess,
  } = useTx(txDepositAtom)

  const resetTx = () => {
    if (txList.length !== 0) return
    setTxRedeem(txRedeemDefault)
    setTxApprove(txApproveDefault)
    setTxDeposit(txDepositDefault)
  }

  const initTxList = () => {
    if (txList.length === 0) {
      setTxList([txRedeemAtom, txApproveAtom, txDepositAtom])
    }
  }
  const { simulateWithdrawLPDfi, resultSimulation, isSimulating } = useSimulateRedeem(
    fromWithdrawAddress,
    account,
    lpBalance,
    vaultDecimals
  )

  const redeem = useWithdrawLPDfi({
    fromWithdrawAddress,
    account,
    handlerAddress,
    shares: lpBalance ?? new BN(0),
    minAssets: resultSimulation?.withdrawnAssets?.times(MIN_FACTOR),
    vaultDecimals,
    callback: {
      success: txRedeemSuccess,
      fail: txRedeemError,
    },
  })

  const allowance = useAllowance(tokenAddress, account, toDepositAddress, token?.decimals)
  const approve = useApprove(tokenAddress, account, toDepositAddress, amount, token?.decimals, {
    success: allowance.refetch,
    fail: txApproveError,
  })

  const { shares: depositShares } = useConvertToShares(toDepositAddress, amount, vaultDecimals)

  useEffect(() => {
    const getProof = async () => {
      const proof = await getDepositProof(whiteList, account)
      setMerkleProof(proof)
    }
    getProof()
  }, [account, JSON.stringify(whiteList)])

  const deposit = useDepositLPDfi(
    toDepositAddress,
    account,
    amount,
    depositShares,
    merkleProof,
    allowance.data,
    vaultDecimals,
    {
      success: txDepositSuccess,
      fail: txDepositError,
    }
  )

  useEffect(() => {
    if (!redeem.hash || !!txRedeem.hash) return
    setTxRedeem(prev => {
      return { ...prev, hash: redeem.hash }
    })
  }, [redeem.hash])

  useEffect(() => {
    if (!approve.hash || !!txApprove.hash) return
    setTxApprove(prev => {
      return { ...prev, hash: approve.hash }
    })
  }, [approve.hash])

  useEffect(() => {
    if (!deposit.hash || !!txDeposit.hash) return
    setTxDeposit(prev => {
      return { ...prev, hash: deposit.hash }
    })
  }, [deposit.hash])

  useEffect(() => {
    if (txList.length === 0) return
    if (!redeem.isWithdrawReady) return
    if (lpBalance?.lte(0)) return
    if (!resultSimulation.withdrawnAssets && !isSimulating) {
      simulateWithdrawLPDfi()
    }
    if (txRedeem.status === txStatus.Wait && !!resultSimulation.withdrawnAssets) {
      txRedeemPending()
      redeem.onWithdraw()
    }
  }, [
    txList,
    txRedeem,
    redeem.isWithdrawReady,
    redeem.onWithdraw,
    lpBalance?.toFixed(),
    resultSimulation.withdrawnAssets,
    simulateWithdrawLPDfi,
  ])

  useEffect(() => {
    if (txList.length === 0) return
    if (txRedeem.status !== txStatus.Success) return
    if (
      !!resultSimulation.withdrawnAssets &&
      accountTokenBalance?.gte(resultSimulation.withdrawnAssets)
    ) {
      setAmount(resultSimulation.withdrawnAssets)
    }
  }, [txList, txRedeem, accountTokenBalance, resultSimulation.withdrawnAssets])

  useEffect(() => {
    if (txList.length === 0) return
    if (txRedeem.status !== txStatus.Success) return
    if (txApprove.status === txStatus.Wait) {
      if (amount.lte(0)) return
      if (allowance.data.lt(amount) && !!approve.writeContract && !!approve.data?.request) {
        txApprovePending()
        approve.onApprove()
      }
    }

    if (txApprove.status === txStatus.Wait || txApprove.status === txStatus.Pending) {
      if (amount.gt(0) && allowance.data.gte(amount)) {
        txApproveSuccess()
      }
    }
  }, [
    txList,
    txRedeem,
    txApprove,
    amount.toFixed(),
    allowance.data.toFixed(),
    approve.isSuccess,
    approve.writeContract,
    approve.data?.request,
  ])

  useEffect(() => {
    if (!approve.isSuccess) return
    if (allowance.isRefetching || !allowance.isLoaded) return
    if (txApprove.status !== txStatus.Pending) return
    if (allowance.data.lt(amount)) {
      txApproveError(t('WIDGET.ERROR.ALLOWANCE_NOT_ENOUGH'))
    }
  }, [JSON.stringify(allowance.data), allowance.isLoaded])

  useEffect(() => {
    if (txList.length === 0) return
    if (!deposit.isWriteReady) return
    if (txApprove.status === txStatus.Success && txDeposit.status === txStatus.Wait) {
      txDepositPending()
      deposit.onDeposit()
    }
  }, [txList, txApprove, txDeposit, deposit.isWriteReady, deposit.onDeposit, amount.toFixed()])

  const { showModal, hideModal } = useModal()

  const isAllowedWallet = useMemo(() => {
    return isEmpty(whiteList) || !isEmpty(merkleProof)
  }, [whiteList, JSON.stringify(merkleProof)])

  const onMigrate = useCallback(() => {
    if (!isAllowedWallet) return
    resetTx()
    initTxList()
    showModal({
      modalType: MODAL_TYPES.TxModal,
      modalProps: {
        title: 'Migrate transaction',
        chain,
        handleClose: () => {
          hideModal()
        },
        // FIXME: view resposibility
        isMigration: true,
        isDefaultClosable: true,
      },
    })
  }, [isAllowedWallet])

  return {
    amount,
    // setAmount,
    allowance: allowance.data,
    initTxList,
    onMigrate,
    isAllowedWallet,
  }
}
