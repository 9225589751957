import Image from 'next/image'
import { twMerge } from 'tailwind-merge'

type Props = {
  token0IconUrl: string
  token1IconUrl: string
  size?: number
  className?: string
}

export const TokenPair = ({ token0IconUrl, token1IconUrl, size = 40, className = '' }: Props) => {
  const shift = size * 0.75

  return (
    <div className={twMerge('flex flex-row justify-start items-center relative', className)}>
      <>
        <Image
          src={token0IconUrl}
          alt="token0"
          width={size}
          height={size}
          className="rounded-full max-w-none"
        />
        <Image
          src={token1IconUrl}
          alt="token1"
          width={size}
          height={size}
          className="absolute z-10 border border-2 border-gray-850 rounded-full box-content max-w-none"
          style={{ left: shift }}
        />
      </>
    </div>
  )
}
