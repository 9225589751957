import { ModalType, modalState } from '@apps-orangefi/ui/state'
import { useAtom } from 'jotai'

export function useModal() {
  const [modal, setModal] = useAtom(modalState)

  const showModal = (modalType: ModalType) => {
    setModal(modalType)
  }

  const hideModal = () => {
    setModal(null)
  }

  return {
    modal,
    setModal,
    showModal,
    hideModal,
  }
}
