import { atom } from 'jotai'
import { Chain, arbitrum } from 'wagmi/chains'

type TargetChain = {
  supportedChainIds: number[]
  defaultChainId: number
  supportedChains: { [key: string]: Chain }
}
export const targetChainAtom = atom<TargetChain>({
  supportedChainIds: [],
  defaultChainId: arbitrum.id,
  supportedChains: {},
})

export const selectedChainAtom = atom<Chain | undefined>(undefined)
