import ExternalLink from '@apps-orangefi/assets/images/external-link.svg'
import { shortAddress } from '@apps-orangefi/lib/utils'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'

type Props = {
  rank: number
  walletAddress: AddressType
  poolShare: number
  score: number
  isMyRank: boolean
  className?: string
}

export const Rank = ({ rank, walletAddress, poolShare, score, isMyRank, className }: Props) => {
  const url = `https://arbiscan.io/address/${walletAddress}`
  const myRankStyle = isMyRank ? 'border-orange-500 border-[1px]' : ''

  return (
    <div
      className={twMerge(
        'flex flex-row rounded-lg dark:bg-gray-800 py-2 px-4 font-mono box-border',
        myRankStyle,
        className
      )}
    >
      <div className="w-1/12">{rank}</div>
      <div className="w-6/12 flex flex-row items-center">
        <span className="leading-5">{shortAddress(walletAddress)}</span>
        <a href={url} target="_blank">
          <Image src={ExternalLink} alt="wallet" width={16} height={16} className="ml-2" />
        </a>
      </div>
      <div className="w-2/12 text-right">{poolShare.toFixed(2)}%</div>
      <div className="w-4/12 text-right">{score}</div>
    </div>
  )
}

export const RankSm = ({ rank, walletAddress, poolShare, score, isMyRank, className }: Props) => {
  const url = `https://arbiscan.io/address/${walletAddress}`
  const myRankStyle = isMyRank ? 'border-orange-500 border-[1px]' : ''

  return (
    <div
      className={twMerge(
        'flex flex-col px-4 py-2 rounded-2xl dark:bg-gray-800 box-border',
        myRankStyle,
        className
      )}
    >
      <div className="flex flex-row">
        <div className="flex flex-col w-1/4">
          <div className="type-sm-medium dark:text-gray-400">Rank</div>
          <div className="type-base-semibold dark:text-white font-mono">{rank}</div>
        </div>
        <div className="flex flex-col w-3/4">
          <div className="type-sm-medium dark:text-gray-400">Wallet address</div>
          <div className="flex flex-row items-center type-base-semibold dark:text-white font-mono">
            {/* <span className="leading-5">{shortAddress(walletAddress)}</span> */}
            <span>{shortAddress(walletAddress)}</span>
            <a href={url} target="_blank" className="h-full">
              <Image
                src={ExternalLink}
                alt="wallet"
                width={16}
                height={16}
                className="ml-2 mt-0.5"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-row mt-3">
        <div className="flex flex-col w-1/2">
          <div className="type-sm-medium dark:text-gray-400">Pool share</div>
          <div className="type-base-semibold dark:text-white font-mono">
            {poolShare.toFixed(2)}%
          </div>
        </div>
        <div className="flex flex-col w-1/2">
          <div className="type-sm-medium dark:text-gray-400">Score</div>
          <div className="type-base-semibold dark:text-white font-mono">{score}</div>
        </div>
      </div>
    </div>
  )
}
