import { type PrimitiveAtom, atom } from 'jotai'
import { atomWithReset } from 'jotai/utils'

export const txStatus = {
  Wait: 'wait',
  Pending: 'pending',
  Success: 'success',
  Error: 'error',
} as const

export type TxStatus = typeof txStatus[keyof typeof txStatus]

export type Tx = {
  title: string
  hash: AddressType | undefined
  status: TxStatus
  error?: string
}

export const txListAtom = atomWithReset<PrimitiveAtom<Tx>[]>([])
export const migrationTxListAtom = atomWithReset<PrimitiveAtom<Tx>[]>([])
export const isAllowedCloseModalAtom = atomWithReset<boolean>(true)
