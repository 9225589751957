import { useModal } from '@apps-orangefi/hooks'
import { selectedChainAtom, targetChainAtom } from '@apps-orangefi/lib/store'
import { MODAL_TYPES } from '@apps-orangefi/ui/organisms/modals'
import { useAtomValue } from 'jotai'
import { useCallback } from 'react'
import { useConnect } from 'wagmi'

export const modalVersion = {
  StrategyVault: 'strategyVault',
} as const

export type ModalVersion = typeof modalVersion[keyof typeof modalVersion]

export const useConnectWalletModal = (version?: ModalVersion) => {
  const selectedChain = useAtomValue(selectedChainAtom)
  const targetChain = useAtomValue(targetChainAtom)

  const { connect } = useConnect()
  const { showModal, hideModal } = useModal()

  const modalType =
    version === modalVersion.StrategyVault
      ? MODAL_TYPES.ConnectModal
      : MODAL_TYPES.ConnectAlphaModal

  const openConnectWalletModal = useCallback(() => {
    showModal({
      modalType,
      modalProps: {
        handleClose: hideModal,
        handleConnect: connector => {
          connect({ connector, chainId: selectedChain?.id })
          setTimeout(() => hideModal(), 150)
        },
      },
    })
  }, [selectedChain])

  return {
    openConnectWalletModal,
  }
}
