import { useDebounce } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { MIN_FACTOR } from '@apps-orangefi/lib/constants'
import { V1VaultABI } from '@apps-orangefi/wagmi/abis'
import { ResultUseDeposit } from '@apps-orangefi/wagmi/hooks'
import {
  useSimulateContractWithErrorHandling,
  useWriteContractWithErrorHandling,
  useWaitForTransactionReceiptWithErrorHandling,
} from '@apps-orangefi/wagmi/hooks/common'
import { isEmpty } from 'lodash'
import { useMemo, useEffect, useState } from 'react'
import { BaseError } from 'wagmi'
import '@apps-orangefi/lib/extensions'

export const useDepositV1 = (
  contractAddress: AddressType | undefined,
  account: AddressType | undefined,
  assets: BN,
  shares: BN | undefined,
  merkleProof: AddressType[],
  allowance: BN,
  vaultDecimals: number | null,
  callback?: { success?: () => void; fail?: (cause: BaseError | string) => void }
): ResultUseDeposit => {
  const [isWriteReady, setIsWriteReady] = useState(false)

  const maxAssets = assets.pow10(vaultDecimals ?? 0).convertBigint()
  const _shares = shares
    ?.times(MIN_FACTOR)
    .pow10(vaultDecimals ?? 0)
    .convertBigint()

  const args = useMemo(() => {
    return [_shares!, maxAssets, merkleProof] as const
  }, [_shares, maxAssets, JSON.stringify(merkleProof)])

  const enabled = useDebounce(
    !!contractAddress && !!account && assets.gt(0) && allowance.gte(assets) && !!shares,
    300
  )

  const { data } = useSimulateContractWithErrorHandling({
    address: contractAddress!,
    abi: V1VaultABI, // NOTE: need to switch ABI by contract version
    functionName: 'deposit',
    args,
    query: {
      enabled,
    },
  })

  const { data: hash, writeContract } = useWriteContractWithErrorHandling({
    mutation: {
      onError(error) {
        if (callback && callback.fail) {
          callback.fail(error.cause as BaseError)
        }
      },
    },
  })

  const {
    isLoading: waitLoading,
    isSuccess,
    isError,
    error,
  } = useWaitForTransactionReceiptWithErrorHandling({ hash }, callback)

  useEffect(() => {
    setIsWriteReady(!!writeContract && !!data?.request)
  }, [writeContract, data?.request])

  return {
    isWriteReady,
    hash,
    isSuccess,
    onDeposit: () => {
      if (!isWriteReady) {
        return
      }
      writeContract(data!.request)
    },
  }
}
