import { Amm, Derivative } from '@apps-orangefi/lib/types'
import { getAmmIcon, getDerivativeIcon } from '@apps-orangefi/lib/utils'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'

type Props = {
  amm: Amm | undefined
  derivative?: Derivative | undefined
  size?: number
  noText?: boolean
  noDelimiter?: boolean
  className?: string
}

export const Platform = ({
  amm,
  derivative,
  size = 20,
  noText = false,
  noDelimiter = false,
  className = '',
}: Props) => {
  const ammIcon = getAmmIcon(amm)
  const derivativeIcon = getDerivativeIcon(derivative)
  const rightIconClass =
    noText && noDelimiter && derivative
      ? 'absolute z-10 border border-2 border-gray-900 box-content'
      : ''
  const rightIconStyle = noText && derivative ? { left: size * 0.75 } : {}

  return (
    <div className={twMerge('flex flex-row justify-start items-center relative', className)}>
      {derivative && derivativeIcon && (
        <>
          <Image
            src={derivativeIcon}
            alt="derivative"
            width={size}
            height={size}
            className="rounded-full max-w-none"
          />
          {!noText && <span className="ml-2">{derivative.toUpperCase()}</span>}
          {!noDelimiter && <span className="type-sm-caption dark:text-gray-650 mx-3">/</span>}
        </>
      )}
      {ammIcon && (
        <>
          <Image
            src={ammIcon}
            alt="AMM"
            width={size}
            height={size}
            className={twMerge('rounded-full max-w-none dark:bg-gray-900', rightIconClass)}
            style={rightIconStyle}
          />
          {!noText && <span className="ml-2">{amm?.toUpperCase()}</span>}
        </>
      )}
    </div>
  )
}
