import { BN } from '@apps-orangefi/lib'
import { depositTokenList } from '@apps-orangefi/lib/constants'
import {
  StrategyVaultInfo,
  VaultCapacity,
  VaultVersion,
  vaultVersion,
  merklRewardStatus,
} from '@apps-orangefi/lib/types'
import { numberWithShortScale } from '@apps-orangefi/lib/utils'
import { SimpleButton } from '@apps-orangefi/ui/atoms'
import { VaultInfoSkeleton } from '@apps-orangefi/ui/molecules/skeletons'
import { StrategyVaultCapacityBar, APRStats } from '@apps-orangefi/ui/molecules/strategy'
import { ChevronLeft } from 'lucide-react'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { memo, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

type Props = {
  vaultInfo: StrategyVaultInfo
  vaultCapacity: VaultCapacity
  fetching?: {
    subgraph1: boolean
    subgraph2: boolean
    subgraphAll: boolean
    reward: boolean
  }
  onClickBack: () => void
}

export const VaultSummary = memo(function VaultSummary({
  vaultInfo: {
    info: { productName, caption, imageUrls, version, merklRewardStatus: rewardStatus },
    feeApr,
    rewardsApr,
    baseToken,
    symbol,
  },
  vaultCapacity,
  fetching,
  onClickBack,
}: Props) {
  const { t } = useTranslation()

  const tokenLogoUrl = useMemo(() => {
    return depositTokenList.find(token => token.symbol === symbol)?.logoUrl
  }, [depositTokenList, symbol])

  const aprs = useMemo(() => {
    const aprReward = rewardsApr ?? new BN(0)
    const aprFee = feeApr ?? new BN(0)
    const _aprs: { label: string; value: string; tooltipText?: string }[] = [
      {
        label: 'TVL',
        value: `$${numberWithShortScale(
          vaultCapacity.totalDeposit.multipliedBy(vaultCapacity.baseTokenPriceUSD ?? new BN(0)),
          2
        )}`,
      },
      {
        label: 'Total APR',
        value: `${aprFee.plus(aprReward).toFixed(2)}%`,
        tooltipText: t('MODEL.APR_STATS.HINTS.TOTAL_APR'),
      },
      {
        label: 'Reward APR',
        value:
          rewardStatus === merklRewardStatus.TBD ? 'TBD' : `${rewardsApr?.toFixed(2) ?? '- '}%`,
        tooltipText: t('MODEL.APR_STATS.HINTS.REWARD_APR'),
      },
      {
        label: 'Fee APR',
        value: `${aprFee.toFixed(2)}%`,
        tooltipText: t('MODEL.APR_STATS.HINTS.FEE_APR'),
      },
    ]
    return _aprs
  }, [rewardsApr, feeApr, version, vaultCapacity])

  return (
    <div className={`bg-main  border-b border-gray-800`}>
      <div className="container flex flex-col md:flex-col items-center py-4 pb-8 md:py-6 md:pb-12">
        <SimpleButton
          text="Back to all vaults"
          icon={<ChevronLeft />}
          className="self-start"
          onSubmit={onClickBack}
        />

        {fetching?.subgraph1 ? (
          <VaultInfoSkeleton />
        ) : (
          <div className="flex flex-row w-full justify-between -mt-5">
            <div className="flex flex-col md:flex-row mt-6 md:mt-12 w-full md:items-end">
              <div className="flex flex-col">
                <h2 className="type-base-semibold dark:text-orange-500 md:text-2xl leading-8">
                  {caption}
                </h2>
                <div className="flex flex-row items-center mt-3 mb-2 md:mt-5 md:mb-0">
                  <div className="w-10 h-10 md:w-14 md:h-14">
                    {!!tokenLogoUrl ? (
                      <Image
                        src={tokenLogoUrl}
                        alt={productName}
                        className="w-10 h-10 md:w-14 md:h-14 rounded-full"
                      />
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <h1 className="dark:text-white text-3xl md:text-7xl font-bold leading-9 md:leading-[4.5rem] ml-5">
                    {productName}
                  </h1>
                </div>
              </div>
            </div>
            {imageUrls?.concept && (
              <Image
                src={imageUrls.concept}
                alt={productName}
                className="w-24 h-24 self-end md:w-48 md:h-48 md:self-auto"
              />
            )}
          </div>
        )}
        {/* {version === vaultVersion.V1 && (
          <StrategyVaultCapacityBar vaultCapacity={vaultCapacity} className="mt-12" />
        )} */}
        <APRStats
          aprs={aprs}
          fetching={!!fetching?.subgraphAll || !!fetching?.reward}
          className="mt-12 overflow-x-scroll md:overflow-x-hidden hidden-scrollbar"
        />
      </div>
    </div>
  )
})
