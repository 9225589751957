import { useDebounce } from '@apps-orangefi/hooks'
import { V1VaultABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { useState, useEffect } from 'react'

export const useV1Token0 = (vaultAddress: AddressType | undefined) => {
  const [tokenAddress, setTokenAddress] = useState<AddressType | undefined>(undefined)
  const enabled = useDebounce(!!vaultAddress, 300)

  const { data, isFetching } = useReadContractWithErrorHandling({
    address: vaultAddress!,
    abi: V1VaultABI,
    functionName: 'token0',
    query: {
      enabled,
    },
  })

  useEffect(() => {
    if (!!data) {
      setTokenAddress(data)
    }
  }, [JSON.stringify(data)])

  return {
    tokenAddress,
    isFetching,
  }
}
