import { ProductInfo, strategy } from '@apps-orangefi/lib/types'
import useTranslation from 'next-translate/useTranslation'
import { memo } from 'react'

type Props = {
  info: ProductInfo
  altOverview?: string
}

export const StrategyVaultDescription = memo(function StrategyVaultDescription({
  info,
  altOverview,
}: Props) {
  const { t } = useTranslation()
  const overview = altOverview || info.overview
  const title =
    info.strategy === strategy.PeggedPair
      ? t('DOCS.OVERVIEW.PEGGED.TITLE')
      : t('DOCS.OVERVIEW.TITLE')

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <h2 className="type-3xl-semibold dark:text-white">{title}</h2>
        {/* <div className="type-lg-normal mt-6">{info.overview}</div> */}
        <div className="type-lg-normal mt-6" dangerouslySetInnerHTML={{ __html: overview }}></div>
      </div>
    </div>
  )
})
