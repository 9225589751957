import { useDebounce } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { bigintToBN } from '@apps-orangefi/lib/utils'
import { StrykeLPAutomatorV2ABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { useMemo } from 'react'

import '@apps-orangefi/lib/extensions'

export const useMinDepositAssets = (
  vaultAddress: AddressType | undefined,
  decimals: number | null
) => {
  const enabled = useDebounce(!!vaultAddress && !!decimals, 300)

  const read = useReadContractWithErrorHandling({
    address: vaultAddress!,
    abi: StrykeLPAutomatorV2ABI,
    functionName: 'minDepositAssets',
    query: {
      enabled,
    },
  })

  return useMemo(
    () => ({
      minDepositAssets: bigintToBN(read.data).pow10ofMinus(decimals ?? 0) ?? new BN(0),
      isFetching: read.isFetching,
    }),
    [read.data, read.isFetching, decimals]
  )
}
