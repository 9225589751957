import { graphql } from '../types/uniswap'

export const getUniV3PoolQuery = graphql(`
  query getPoolQuery($poolId: ID!) {
    pool(id: $poolId) {
      id
      tick
      sqrtPrice
      token0 {
        id
        name
        symbol
        decimals
        derivedETH
      }
      token1 {
        id
        name
        symbol
        decimals
        derivedETH
      }
      token0Price
      token1Price
    }
    bundle(id: "1") {
      ethPriceUSD
      id
    }
  }
`)

export const uniV3PoolListQuery = graphql(`
  query getPoolListQuery($poolIds: [ID!]!) {
    pools(where: { id_in: $poolIds }) {
      id
      tick
      sqrtPrice
      token0 {
        id
        name
        symbol
        decimals
        derivedETH
      }
      token1 {
        id
        name
        symbol
        decimals
        derivedETH
      }
      token0Price
      token1Price
    }
    bundle(id: "1") {
      ethPriceUSD
      id
    }
  }
`)

export const uniV3PoolListWithBlockNumberQuery = graphql(`
  query getPoolListWithBlockNumberQuery($poolIds: [ID!]!, $blockNumber: Int) {
    pools(where: { id_in: $poolIds }, block: { number: $blockNumber }) {
      id
      tick
      sqrtPrice
      token0 {
        id
        name
        symbol
        decimals
        derivedETH
      }
      token1 {
        id
        name
        symbol
        decimals
        derivedETH
      }
      token0Price
      token1Price
    }
    bundle(id: "1", block: { number: $blockNumber }) {
      ethPriceUSD
      id
    }
  }
`)
