import { useDebounce } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { MerkleDistributorABI } from '@apps-orangefi/wagmi/abis'
import {
  useSimulateContractWithErrorHandling,
  useWriteContractWithErrorHandling,
  useWaitForTransactionReceiptWithErrorHandling,
} from '@apps-orangefi/wagmi/hooks/common'
import { isEmpty } from 'lodash'
import { useMemo, useEffect, useState } from 'react'
import { zeroAddress } from 'viem'
import { BaseError } from 'wagmi'

export const useClaim = (
  distributorAddress: AddressType | undefined,
  account: AddressType | undefined,
  index: BN | undefined,
  amount: BN | undefined,
  merklProof: AddressType[],
  isClaimable: boolean,
  callback?: { success?: () => void; fail?: (cause: BaseError | string) => void }
) => {
  const [isClaimReady, setIsClaimReady] = useState(false)

  const args = useMemo(() => {
    return [
      index?.convertBigint() ?? BigInt(0),
      account ?? zeroAddress,
      amount?.convertBigint() ?? BigInt(0),
      merklProof,
    ] as const
  }, [index, account, amount, merklProof])

  const enabled = useMemo(() => {
    return (
      isClaimable &&
      !!distributorAddress &&
      !!index &&
      !!account &&
      !!amount &&
      amount.gt(0) &&
      !isEmpty(merklProof)
    )
  }, [isClaimable, distributorAddress, index, account, amount, merklProof])

  const { data } = useSimulateContractWithErrorHandling({
    address: distributorAddress!,
    abi: MerkleDistributorABI,
    functionName: 'claim',
    args,
    query: {
      enabled,
    },
  })

  const { data: hash, writeContract } = useWriteContractWithErrorHandling({
    mutation: {
      onError(error) {
        if (callback && callback.fail) {
          callback.fail(error.cause as BaseError)
        }
      },
    },
  })

  const {
    isLoading: waitLoading,
    isSuccess,
    isError,
    error,
  } = useWaitForTransactionReceiptWithErrorHandling({ hash }, callback)

  useEffect(() => {
    setIsClaimReady(!!writeContract && !!data?.request)
  }, [writeContract, data?.request])

  return {
    isClaimReady,
    hash,
    isSuccess,
    onClaim: () => {
      if (!isClaimReady) {
        return
      }
      writeContract(data!.request)
    },
  }
}
