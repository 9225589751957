import { useModal } from '@apps-orangefi/hooks'
import { MODAL_TYPES } from '@apps-orangefi/ui/organisms/modals'
import { useCallback } from 'react'

export const useInvalidAddressModal = (isAllowedWallet: boolean) => {
  const { showModal, hideModal } = useModal()

  const onShowInvalidAddressModal = useCallback(
    (e?: Event) => {
      if (!isAllowedWallet) {
        e?.preventDefault()
        setTimeout(() => {
          showModal({
            modalType: MODAL_TYPES.InvalidAddressModal,
            modalProps: {
              handleClose: hideModal,
            },
          })
        }, 200) // open modal after connect wallet modal hide
      }
    },
    [isAllowedWallet]
  )

  return { onShowInvalidAddressModal }
}
