import { BaseModal, SwitchChainModalProps } from '@apps-orangefi/ui/organisms/modals'
import { Dialog } from '@headlessui/react'
import useTranslation from 'next-translate/useTranslation'

export const SwitchChainAlphaModal = ({
  handleClose,
  handleSwitchChain,
  chainInfo,
}: SwitchChainModalProps) => {
  const { t } = useTranslation()

  return (
    <BaseModal onClose={() => handleClose()}>
      <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-sub px-10 py-14 text-left align-middle shadow-xl transition-all">
        <Dialog.Title as="h3" className="text-style-body1-sb text-center">
          {t('MODAL.SWITCH_NETWORK.TITLE')}
        </Dialog.Title>
        <div className="mt-10 text-style-info">
          <div>{t('MODAL.SWITCH_NETWORK.DESC')}</div>
          {chainInfo.id && chainInfo.name && (
            <button
              key={chainInfo.id}
              onClick={() => handleSwitchChain()}
              className="flex w-full items-center rounded-full py-4 px-8 bg-brightGray dark:text-white hover:opacity-90 mt-8 disabled:cursor-not-allowed disabled:opacity-70"
            >
              {chainInfo.name}
            </button>
          )}
        </div>
      </Dialog.Panel>
    </BaseModal>
  )
}
