import axios from 'axios'

type FetchQuestsQueryKey = {
  queryKey: [string, {}]
}

type ResultFetchQuests = {
  questSlug: string
  protocolSlug: string
  description: string
  point: number
}[]

const url = process.env.NEXT_PUBLIC_ORCHARD_API_URL

export const fetchQuests = async ({ queryKey }: FetchQuestsQueryKey) => {
  const endpoint = `${url}/quests`
  try {
    const res = await axios.get<ResultFetchQuests>(endpoint)
    return res.data
  } catch (e) {
    return null
  }
}
