import {
  useOrangeQuery,
  useUniV3Query,
  useStrykeQuery,
  useMultiPoolReservedLPStatus,
} from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { usdceAddressAtom } from '@apps-orangefi/lib/store'
import {
  getDopexVaultListQuery,
  uniV3PoolListQuery,
  getDopexLpPositionsListQuery,
  getDopexDailyStrikeEarningsListQuery,
} from '@apps-orangefi/lib/subgraph/queries'
import {
  type GetDopexLpPositionsListQuery,
  type GetDopexDailyStrikeEarningsListQuery,
} from '@apps-orangefi/lib/subgraph/types/dopex/graphql'
import { type GetDopexVaultListQuery } from '@apps-orangefi/lib/subgraph/types/orange/graphql'
import { type GetPoolListQueryQuery } from '@apps-orangefi/lib/subgraph/types/uniswap/graphql'
import {
  VaultInfo,
  ProductCardProps,
  Vault,
  category,
  Pool,
  Token,
  ProductType,
  merklRewardStatus,
} from '@apps-orangefi/lib/types'
import { convertUSDCSymbol, calculateAPR, addHex } from '@apps-orangefi/lib/utils'
import { useHandlers } from '@apps-orangefi/wagmi/hooks'
import { useAtomValue } from 'jotai'
import { isEmpty, chain, isEqual, uniqBy } from 'lodash'
import { useEffect, useState, useMemo } from 'react'

import { ProductCardInfo, ALMAddressPair } from './type'

export const useDopexVaultList = (
  account: AddressType | undefined,
  vaultInfoList: VaultInfo[],
  chainId: number
) => {
  const usdceAddress = useAtomValue(usdceAddressAtom)
  const [productCardList, setProductCardList] = useState<ProductCardInfo[]>([])
  const [almAddressList, setAlmAddressList] = useState<ALMAddressPair[]>([])

  const [result, reexecuteQuery] = useOrangeQuery<GetDopexVaultListQuery>({
    query: getDopexVaultListQuery,
    variables: { account: account?.toLowerCase() ?? '' },
    requestPolicy: 'network-only',
  })
  const { data: dataOrange, fetching: fetchingOrange, error: error2 } = result
  const dopexVaults = useMemo(() => {
    return (
      dataOrange?.dopexVaults.filter(vault => {
        return vaultInfoList
          .map(vaultInfo => vaultInfo.VAULT_ADDRESS.toLowerCase())
          .includes(vault.id.toLowerCase())
      }) ?? []
    )
  }, [dataOrange?.dopexVaults, vaultInfoList])

  const [resultUniV3] = useUniV3Query<GetPoolListQueryQuery>({
    query: uniV3PoolListQuery,
    variables: {
      poolIds: chain(dopexVaults ?? [])
        .map(vault => vault.pool)
        .uniq()
        .value(),
    },
    pause: isEmpty(dopexVaults),
  })
  const { data: dataUniV3, fetching: fetchingUniV3, error: errorUniV3 } = resultUniV3

  const [resultDopex1st] = useStrykeQuery<GetDopexLpPositionsListQuery>({
    query: getDopexLpPositionsListQuery,
    variables: {
      userIds: dopexVaults.map(vault => vault.id) ?? [],
    },
    pause: isEmpty(dopexVaults),
  })
  const {
    data: dataDopexFirst,
    fetching: fetchingDopexFirst,
    error: errorDopexFirst,
  } = resultDopex1st

  const tokenIds = dataDopexFirst?.lppositions.map(lpPosition => lpPosition.strike.id) ?? []

  const startTime = useMemo(() => {
    return Math.floor(new Date().getTime() / 1000) - 60 * 60 * 24
  }, [])

  const [resultDopex2nd] = useStrykeQuery<GetDopexDailyStrikeEarningsListQuery>({
    query: getDopexDailyStrikeEarningsListQuery,
    variables: {
      tokenIds,
      tokenIdsCount: tokenIds.length,
      startTime: startTime.toString(),
    },
    pause: isEmpty(tokenIds),
  })
  const {
    data: dataDopexSecond,
    fetching: fetchingDopexSecond,
    error: errorDopexSecond,
  } = resultDopex2nd

  const dopexStrikeEarningsDict = useMemo(() => {
    const lpPositions = dataDopexFirst?.lppositions ?? []
    const dailyDonations = uniqBy(dataDopexSecond?.dailyDonations, 'strike.id')
    const dailyFeeCompounds = uniqBy(dataDopexSecond?.dailyFeeCompounds, 'strike.id')
    return chain(lpPositions)
      .map(lpPosition => {
        const donation = dailyDonations.find(d => d.strike.id === lpPosition.strike.id)
        const compound = dailyFeeCompounds.find(c => c.strike.id === lpPosition.strike.id)
        if (!donation && !compound) {
          return null
        }

        return {
          sqrtPriceX96: donation?.sqrtPriceX96 ?? compound?.sqrtPriceX96,
          donation: donation?.donation ?? '0',
          compound: compound?.compound ?? '0',
          strike: lpPosition?.strike,
          pool: lpPosition.pool,
          shares: lpPosition.shares,
          user: lpPosition.user,
          handler: lpPosition.handler,
        }
      })
      .compact()
      .groupBy('user')
      .value()
  }, [dataDopexFirst, dataDopexSecond])

  const statsList = useMemo(() => {
    if (!dopexVaults || !dataUniV3?.pools || !dataUniV3?.bundle) {
      return []
    }
    const ethPriceUSD = new BN(dataUniV3.bundle.ethPriceUSD)
    return chain(dopexVaults)
      .map(vault => {
        const dopexStrikeEarnings = dopexStrikeEarningsDict[vault.id]
        const pool = dataUniV3?.pools.find(pool => pool.id === vault?.pool) as Pool
        if (!vault || !pool) {
          return
        }
        return calculateAPR(dopexStrikeEarnings, vault, pool, ethPriceUSD)
      })
      .compact()
      .value()
  }, [dopexVaults, dataUniV3?.pools, dataUniV3?.bundle, dopexStrikeEarningsDict])

  // NOTE: can't summarize fee revenue from dopex vaults
  // const totalFeeRevenue = useMemo(() => {
  //   return dataOrange?.dopexVaults?.reduce((sum: BN, vault) => {
  //     sum = sum.plus(new BN(vault.totalFeeUSD))
  //     return sum
  //   }, new BN(0))
  //   return new BN(0)
  // }, [dataOrange?.dopexVaults])

  const allTvl = useMemo(() => {
    return statsList.reduce((sum: BN, stats) => {
      sum = sum.plus(new BN(stats.tvl))
      return sum
    }, new BN(0))
  }, [statsList])

  const { handlerAddressList } = useHandlers(
    dopexVaults.map(vault => vault.id as AddressType) ?? []
  )

  const { poolReservedLpValues } = useMultiPoolReservedLPStatus(
    account,
    dataUniV3?.pools.map(p => p.id as AddressType) ?? [],
    handlerAddressList,
    chainId
  )

  useEffect(() => {
    const ethPriceUSD = new BN(dataUniV3?.bundle?.ethPriceUSD ?? 0)
    const _productCardList = chain(dopexVaults)
      .map(vault => {
        const vaultInfo = vaultInfoList.find(
          (vaultInfo: VaultInfo) => vaultInfo.VAULT_ADDRESS.toLowerCase() === vault.id.toLowerCase()
        )
        const pool = dataUniV3?.pools.find(pool => pool.id === vault?.pool)

        if (!vaultInfo || !vault || !pool) {
          return null
        }
        const stats = statsList.find(stats => stats.vaultId === vault.id)
        const myPosition = dataOrange?.user?.positions.find(
          position => position.vault === vaultInfo?.VAULT_ADDRESS.toLowerCase()
        )

        // FIXME: exist similar variable name
        const [baseToken, quoteToken] = (
          vault.isTokenPairReversed ? [pool.token1, pool.token0] : [pool.token0, pool.token1]
        ) as [Token, Token]
        const symbol =
          convertUSDCSymbol(baseToken.id as AddressType, usdceAddress) ?? baseToken.symbol ?? ''

        const feeApr = new BN(stats?.dopexApr ?? 0).multipliedBy(100)
        const share = new BN(myPosition?.share ?? 0)
        const [totalAssets, totalSupply] = [new BN(vault.totalAssets), new BN(vault.totalSupply)]
        const amount = totalSupply.isZero()
          ? new BN(0)
          : share.times(totalAssets).div(totalSupply).pow10ofMinus(Number(baseToken.decimals))

        const amountUSD = amount.times(new BN(baseToken.derivedETH)).times(new BN(ethPriceUSD ?? 0))

        const vaultDecimals = Number(vault.decimals)
        const totalDeposit = new BN(vault.totalAssets).pow10ofMinus(vaultDecimals)
        const tvl = totalDeposit.times(new BN(baseToken.derivedETH)).times(new BN(ethPriceUSD ?? 0))

        const reservedLpValue = poolReservedLpValues[pool.id as AddressType]

        const productCard: ProductCardInfo = {
          vaultAddress: vaultInfo.VAULT_ADDRESS,
          poolAddress: pool.id.toLowerCase() as AddressType,
          category: vaultInfo.info.category,
          title: vaultInfo.info.productName,
          caption: vaultInfo.info.caption,
          description: vaultInfo.info.description,
          tags: vaultInfo.info.tags,
          totalDeposit,
          tvl,
          // maxCapacity: new BN(vault.depositCap ?? 0).pow10ofMinus(vaultDecimals),
          reservedLpAmountUSD: reservedLpValue?.totalAmountUSD ?? new BN(0),
          maxCapacity: null,
          apr: null,
          feeApr,
          myPosition: amount,
          myPositionUSD: amountUSD,
          symbol,
          baseToken,
          quoteToken,
          imageUrls: vaultInfo.info.imageUrls,
          theme: vaultInfo.info.theme,
          platform: vaultInfo.info.platform,
          productType: (vaultInfo.info.productType ?? []) as ProductType[],
          merklRewardStatus: vaultInfo.info.merklRewardStatus ?? merklRewardStatus.Inactive,
        }
        return productCard
      })
      .compact()
      .value()

    setProductCardList(_productCardList)

    if (!!dopexVaults && !!dataUniV3?.pools) {
      const vaultInfoAddresses = vaultInfoList.map(v => v.VAULT_ADDRESS.toLowerCase())
      const _almAddressList = chain(dopexVaults)
        .map(vault => {
          if (!vaultInfoAddresses.includes(vault.id)) {
            return
          }

          const pool = dataUniV3.pools.find(pool => pool.id === vault.pool)
          if (!pool) {
            return
          }
          const strykePoolId = addHex(pool.id as AddressType, 9)
          return { vaultAddress: vault.id as AddressType, poolAddress: strykePoolId as AddressType }
        })
        .compact()
        .value()

      if (!isEqual(almAddressList, _almAddressList)) {
        setAlmAddressList(_almAddressList)
      }
    }
  }, [
    statsList,
    usdceAddress,
    vaultInfoList,
    dopexVaults,
    dataUniV3?.pools,
    dataUniV3?.bundle,
    poolReservedLpValues,
  ])

  const myVaults = useMemo(() => {
    const reservedLpVaults = chain(productCardList)
      .filter(productCard =>
        productCard.reservedLpAmountUSD ? productCard.reservedLpAmountUSD.gt(0) : false
      )
      .map(productCard => productCard.vaultAddress.toLowerCase() as AddressType)
      .value()
    const positionedVaults =
      dataOrange?.user?.positions.map(position => position.vault as AddressType) ?? []
    return chain(positionedVaults).concat(reservedLpVaults).uniq().value()
  }, [dataOrange?.user?.positions, productCardList])

  return {
    productCardList,
    myVaults,
    allTvl,
    almAddressList,
    fetching: fetchingOrange || fetchingUniV3 || fetchingDopexFirst || fetchingDopexSecond,
    reexecuteQuery,
    poolList: (dataUniV3?.pools ?? []) as Pool[],
    ethPriceUSD: new BN(dataUniV3?.bundle?.ethPriceUSD ?? 0),
  }
}
