import { useDebounce } from '@apps-orangefi/hooks'
import { useInvalidateQueries } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { ClaimStatus } from '@apps-orangefi/lib/types'
import { MerkleDistributorABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractsWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { useState, useEffect, useMemo } from 'react'

const initialClaimStatus: ClaimStatus = {
  isClaimed: false,
  isExpired: false,
  endTime: null,
}
const BLOCK_INTERVAL = 150

export const useClaimStatus = (
  distributorAddress: AddressType | undefined,
  index: BN | undefined,
  watch: boolean = false
) => {
  const [claimStatus, setClaimStatus] = useState<ClaimStatus>(initialClaimStatus)

  const args = useMemo(() => {
    return [index?.convertBigint()!] as const
  }, [index])

  const contract = {
    address: distributorAddress!,
    abi: MerkleDistributorABI,
  }
  // const enabled = useDebounce(!!distributorAddress && !!index, 300)
  const enabled = !!distributorAddress && !!index

  const { data, isFetching, isError, queryKey } = useReadContractsWithErrorHandling({
    contracts: [
      {
        ...contract,
        functionName: 'endTime',
      },
      {
        ...contract,
        functionName: 'isClaimed',
        args,
      },
    ],
    query: {
      enabled,
    },
  })
  useInvalidateQueries({ queryKey, blockInterval: BLOCK_INTERVAL })

  useEffect(() => {
    if (!data) {
      setClaimStatus(initialClaimStatus)
      return
    }

    const endTime = Number(data[0].result as bigint) * 1000
    const isClaimed = data[1].result as unknown as boolean
    const isExpired = endTime < Date.now()

    setClaimStatus({
      isClaimed,
      isExpired,
      endTime,
    })
  }, [data, distributorAddress, index])

  return { claimStatus, isFetching, isError }
}
