import { txStatus } from '@apps-orangefi/lib/store'
import { LineLoader } from '@apps-orangefi/ui/atoms/loaders'
import { PauseOctagon, AlertOctagon, CheckCircle } from 'lucide-react'

import type { TxStatus } from '@apps-orangefi/lib/store'

type Props = {
  status: TxStatus | undefined
}

export default function StatusIcon({ status }: Props): JSX.Element {
  const Icon = {
    [txStatus.Wait]: <PauseOctagon />,
    [txStatus.Pending]: <LineLoader type="triangle" />,
    [txStatus.Success]: <CheckCircle />,
    [txStatus.Error]: <AlertOctagon />,
  }

  return (
    <div className="items-center shrink-0 flex">
      <div className="flex flex-col w-8 h-8 justify-center">
        {status ? Icon[status] : <PauseOctagon />}
      </div>
    </div>
  )
}
