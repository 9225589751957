import { useDebounce } from '@apps-orangefi/hooks'
import { useInvalidateQueries } from '@apps-orangefi/hooks'
import { AstronautProxyABI, AstronautABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractsWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { useState, useEffect } from 'react'

import '@apps-orangefi/lib/extensions'

const BLOCK_INTERVAL = 450

export type NFTStatus = {
  isClaimed: boolean
  mintedNumbers: number
}

export const useNFTStatus = (
  nftAddress: AddressType | undefined,
  astronautProxyAddress: AddressType | undefined,
  account: AddressType | undefined
) => {
  const [nftStatus, setNFTStatus] = useState<NFTStatus>({
    isClaimed: false,
    mintedNumbers: 0,
  })

  const enabled = useDebounce(!!astronautProxyAddress && !!nftAddress && !!account, 300)

  const proxyContract = {
    address: astronautProxyAddress!,
    abi: AstronautProxyABI,
  }

  const astronautContract = {
    address: nftAddress!,
    abi: AstronautABI,
  }

  const { data, isFetching, isError, queryKey } = useReadContractsWithErrorHandling({
    contracts: [
      {
        ...proxyContract,
        functionName: 'isClaimed',
        args: [account!],
      },
      {
        ...astronautContract,
        functionName: 'totalSupply',
      },
    ],
    query: {
      enabled,
    },
  })

  useInvalidateQueries({ queryKey, blockInterval: BLOCK_INTERVAL })

  useEffect(() => {
    if (data) {
      const totalSupply = Number(data[1].result ?? 0)

      setNFTStatus({
        isClaimed: data[0].result as boolean,
        mintedNumbers: totalSupply,
      })
    }
  }, [data])

  return { nftStatus, isFetching, isError }
}
