import ArbIcon from '@apps-orangefi/assets/images/tokens/arb.svg'
import BoopIcon from '@apps-orangefi/assets/images/tokens/boop.svg'
import DaiIcon from '@apps-orangefi/assets/images/tokens/dai.svg'
import EzEthIcon from '@apps-orangefi/assets/images/tokens/ezETH.svg'
import UsdcIcon from '@apps-orangefi/assets/images/tokens/usdc.svg'
import UsdtIcon from '@apps-orangefi/assets/images/tokens/usdt.svg'
import WbtcIcon from '@apps-orangefi/assets/images/tokens/wbtc.svg'
import WeEthIcon from '@apps-orangefi/assets/images/tokens/weETH.svg'
import WethIcon from '@apps-orangefi/assets/images/tokens/weth.svg'
import WstEthIcon from '@apps-orangefi/assets/images/tokens/wstETH.svg'

export const getTokenIcon = (symbol: string): string | null => {
  switch (symbol) {
    case 'ARB':
      return ArbIcon
    case 'USDC':
      return UsdcIcon
    case 'WBTC':
      return WbtcIcon
    case 'WETH':
      return WethIcon
    case 'DAI':
      return DaiIcon
    case 'USDT':
      return UsdtIcon
    case 'Boop':
      return BoopIcon
    case 'wstETH':
      return WstEthIcon
    case 'weETH':
      return WeEthIcon
    case 'ezETH':
      return EzEthIcon
    default:
      return null
  }
}
