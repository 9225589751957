import ChevronRight from '@apps-orangefi/assets/images/point/chevron-right-orange.svg'
import { OctagonContainer } from '@apps-orangefi/ui/atoms/point/OctagonContainer'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'

const tailwindConfig = require('@apps-orangefi/tailwindcss')

type Props = {
  label?: string | JSX.Element
  disabled?: boolean
  onSubmit?: (e?: Event) => void
  hideArrow?: boolean
  className?: string
}

export const Button = ({
  label,
  disabled = false,
  hideArrow = false,
  onSubmit,
  className,
}: Props) => {
  return (
    <OctagonContainer
      size={3}
      borderColor={tailwindConfig.theme.extend.colors.orange[500]}
      className={twMerge(
        'flex flex-col items-center justify-center h-fit w-fit type-base-bold hover:cursor-pointer',
        className
      )}
    >
      <button
        onClick={() => {
          onSubmit && onSubmit()
        }}
        disabled={disabled}
        className="flex flex-row w-full h-full px-5 py-4 justify-center items-center button-shadow"
      >
        {label}
        {!hideArrow && (
          <Image
            src={ChevronRight}
            alt="arrow-right"
            height={14}
            className={`${!!label ? 'ml-5' : ''}`}
          />
        )}
      </button>
    </OctagonContainer>
  )
}
