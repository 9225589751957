import { useDebounce } from '@apps-orangefi/hooks'
import { useInvalidateQueries } from '@apps-orangefi/hooks'
import { VaultCapacity } from '@apps-orangefi/lib/types'
import { bigintToBN } from '@apps-orangefi/lib/utils'
import { V1VaultABI, V1ParametersABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractsWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { useState, useEffect } from 'react'

import '@apps-orangefi/lib/extensions'

type Result = Omit<VaultCapacity, 'tokenSymbol'>

export const useV1VaultCapacity = (
  vaultAddress: AddressType | undefined,
  parametersAddress: AddressType | undefined,
  vaultDecimals: number | null,
  watch: boolean = false
) => {
  const [vaultCapacity, setVaultCapacity] = useState<Result | undefined>(undefined)
  const enabled = useDebounce(!!vaultAddress && !!parametersAddress, 300)

  const v1VaultContract = {
    address: vaultAddress!,
    abi: V1VaultABI,
  }

  const v1ParametersContract = {
    address: parametersAddress!,
    abi: V1ParametersABI,
  }

  const { data, isFetching, isError, queryKey } = useReadContractsWithErrorHandling({
    contracts: [
      {
        ...v1VaultContract,
        functionName: 'totalAssets',
      },
      {
        ...v1ParametersContract,
        functionName: 'depositCap',
      },
    ],
    query: {
      enabled,
    },
  })
  useInvalidateQueries({ queryKey })

  useEffect(() => {
    if (!!data && !!vaultDecimals) {
      const result = {
        totalDeposit: bigintToBN(data[0].result as bigint).pow10ofMinus(vaultDecimals),
        maxCapacity: bigintToBN(data[1].result as bigint).pow10ofMinus(vaultDecimals),
      }
      setVaultCapacity(result)
    }
  }, [JSON.stringify(data), vaultDecimals])

  return { vaultCapacity, isFetching, isError }
}
