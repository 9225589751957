import { BN } from '@apps-orangefi/lib'
import { MIN_FACTOR } from '@apps-orangefi/lib/constants'
import { StrykeLPAutomatorV2ABI } from '@apps-orangefi/wagmi/abis'
import { useFreeAssetsOfTotalShare } from '@apps-orangefi/wagmi/hooks'
import { useMemo, useState, useEffect, useCallback } from 'react'
import { createPublicClient, http } from 'viem'
import { arbitrum } from 'viem/chains'

const client = createPublicClient({
  chain: arbitrum,
  transport: http(),
})

// NOTE: similar to WithdrawLP
type DopexLpShare = {
  tokenId: string
  share: BN
}

type ResultRedeemSimulation = {
  withdrawnAssets: BN | undefined
  dopexLpShares: DopexLpShare[]
}

export const useSimulateRedeem = (
  vaultAddress: AddressType | undefined,
  account: AddressType | undefined,
  shares: BN | undefined,
  vaultDecimals: number | null
) => {
  const [resultSimulation, setResultSimulation] = useState<ResultRedeemSimulation>({
    withdrawnAssets: undefined,
    dopexLpShares: [],
  })
  const [hasUtilizedLP, setHasUtilizedLP] = useState(false)
  const [isSimulating, setIsSimulating] = useState(false)

  const args = useMemo(() => {
    if (!shares) return [BigInt(0), BigInt(0)] as const
    const _shares = shares
      .pow10(vaultDecimals ?? 0)
      .convertBigint()
      .valueOf()
    const minAssets = new BN(0).convertBigint().valueOf()
    return [_shares, minAssets!] as const
  }, [shares, vaultDecimals])

  const simulateWithdrawLPDfi = useCallback(() => {
    if (!vaultAddress || !account || !shares || shares.isZero() || !vaultDecimals) {
      return
    }

    const simulateWithdraw = async () => {
      setIsSimulating(true)
      try {
        const { result } = await client.simulateContract({
          address: vaultAddress!,
          abi: StrykeLPAutomatorV2ABI,
          functionName: 'redeem',
          account,
          args,
        })
        if (result) {
          const withdrawnAssets = result[0]
            ? new BN(result[0].toString()).pow10ofMinus(vaultDecimals)
            : new BN(0)
          const dopexLpShares = result[1].map(share => {
            return {
              tokenId: share.tokenId.toString(),
              share: new BN(share.shares.toString()),
            }
          })
          const totalShare = dopexLpShares.reduce((acc, cur) => {
            acc = acc.plus(cur.share)
            return acc
          }, new BN(0))

          setResultSimulation({
            withdrawnAssets,
            dopexLpShares,
          })
          setHasUtilizedLP(totalShare.gt(0))
          setIsSimulating(false)
        }
      } catch (error) {
        console.log('Withdraw simulation error occured:', error, `args: ${args}`)
      }
    }
    simulateWithdraw()
  }, [vaultAddress, account, shares, args, vaultDecimals])

  return {
    resultSimulation,
    simulateWithdrawLPDfi,
    hasUtilizedLP,
    isSimulating,
  }
}
