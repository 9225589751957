module.exports = {
  xs: ['12px', '16px'],
  sm: ['14px', '17px'],
  base: ['15px', '24px'],
  md: ['16px', '22px'],
  lg: ['18px', '120%'],
  xl: ['20px', '28px'],
  '2xl': ['24px', '120%'],
  '3xl': ['30px', '120%'],
  '4xl': ['32px', '120%'],
  '6xl': ['60px', '120%'],
  '7xl': ['64px', '120%'],
  '8xl': ['80px', '118%'],
}
