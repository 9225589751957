import { LockForm, type LockProps } from '@apps-orangefi/ui/molecules/point/forms'

import { LockModalWrapper } from './ModalWrapper'

export type LockModalProps = LockProps & {
  handleClose: () => void
}

export const LockModal = ({
  position,
  tokenBalance,
  lock,
  estimate,
  handleClose,
}: LockModalProps) => {
  return (
    <LockModalWrapper mode="lock" handleClose={handleClose}>
      <LockForm position={position} tokenBalance={tokenBalance} lock={lock} estimate={estimate} />
    </LockModalWrapper>
  )
}
