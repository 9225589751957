import { useDebounce } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import {
  useSimulateContractWithErrorHandling,
  useWriteContractWithErrorHandling,
  useWaitForTransactionReceiptWithErrorHandling,
} from '@apps-orangefi/wagmi/hooks/common'
import { useEffect, useMemo, useCallback } from 'react'
import { erc20Abi } from 'viem'
import { BaseError } from 'wagmi'

import '@apps-orangefi/lib/extensions'

const APPROVAL_LIMIT = BigInt(1e6)

const useApprove = (
  tokenAddress: AddressType | undefined,
  owner: AddressType | undefined,
  spender: AddressType | undefined,
  amount: BN | undefined,
  decimals: number | undefined,
  callback?: { success?: () => void; fail?: (cause: BaseError | string) => void }
) => {
  const args = useMemo(() => {
    const approveLimit = amount?.pow10(decimals ?? 0).convertBigint() ?? APPROVAL_LIMIT
    return [spender!, approveLimit] as const
  }, [spender, amount?.toFixed(), decimals])
  const enabled = useDebounce(
    !!tokenAddress && !!owner && !!spender && !!amount && amount.isPositive() && !!decimals,
    300
  )

  const { data } = useSimulateContractWithErrorHandling({
    address: tokenAddress!,
    abi: erc20Abi,
    functionName: 'approve',
    args,
    query: {
      enabled,
    },
  })

  const { data: hash, writeContract } = useWriteContractWithErrorHandling({
    mutation: {
      onError(error) {
        console.log('useApprove write error', error)
        if (callback && callback.fail) {
          callback.fail(error.cause as BaseError)
        }
      },
    },
  })

  const {
    isLoading: waitLoading,
    isSuccess,
    isError,
    error,
  } = useWaitForTransactionReceiptWithErrorHandling({ hash }, callback)

  return {
    writeContract,
    data,
    hash,
    isSuccess,
    onApprove: () => {
      if (!writeContract || !data?.request) {
        return
      }
      writeContract(data.request)
    },
    waitLoading,
  }
}

export default useApprove
