import { twMerge } from 'tailwind-merge'

type Props = {
  label: string | JSX.Element
  disabled?: boolean
  animate?: boolean
  onSubmit?: (e?: Event) => void
  className?: string
}

export const Button = ({
  label,
  disabled = false,
  animate = false,
  onSubmit,
  className,
}: Props) => {
  return (
    <button
      disabled={disabled}
      className={twMerge(
        `px-10 py-5 rounded-4xl dark:bg-orange-500 w-fit type-base-bold dark:text-white outline-0 hover:opacity-90`,
        disabled ? 'dark:bg-gray-800 dark:text-gray-600 cursor-not-allowed' : '',
        animate ? 'animate-highlight' : '',
        className
      )}
      onClick={() => {
        if (onSubmit) {
          onSubmit()
        }
      }}
    >
      {label}
    </button>
  )
}
