import { useDebounce } from '@apps-orangefi/hooks'
import { StrykeLPAutomatorV2ABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractsWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { isEmpty, chain } from 'lodash'
import { useState, useEffect } from 'react'

export const useHandlers = (vaultAddressList: (AddressType | undefined)[] | undefined) => {
  const [handlerAddressList, setHandlerAddressList] = useState<AddressType[]>([])
  const enabled = useDebounce(!isEmpty(vaultAddressList), 300)

  const contracts = chain(vaultAddressList ?? [])
    .uniq()
    .map(address => {
      return {
        address,
        abi: StrykeLPAutomatorV2ABI,
        functionName: 'handler',
      }
    })
    .value()

  const { data, isFetching, isError, queryKey, refetch } = useReadContractsWithErrorHandling({
    contracts,
    query: {
      enabled,
    },
  })

  useEffect(() => {
    if (!!data && !isEmpty(data)) {
      const handlerList = data.map(d => d.result as AddressType)
      setHandlerAddressList(handlerList)
    }
  }, [data])

  return { handlerAddressList, isFetching, isError }
}
