// import { QueryFunctionContext } from '@tanstack/react-query'
import { BN } from '@apps-orangefi/lib'
import axios from 'axios'
import { camelCase, map, mapKeys } from 'lodash'

type FetchDuneQueryKey = {
  queryKey: [string, { queryId: string; duneApiKey: string | undefined }]
}
type SpaceshipResult = {
  result: {
    rows: {
      rank: number
      user: AddressType
      TotalShare: number | null
      points: number
    }[]
  }
}

type AstronautQueryKey = {
  queryKey: [
    string,
    { queryId: string; duneApiKey: string | undefined; account: string | undefined }
  ]
}

type AstronautResult = {
  result: {
    rows: {
      user: AddressType
      max_days_in_sequence: number
      latest_cumulative_eth_amount: string
      latest_cumulative_usd_amount: string
    }[]
  }
}

type AstronautResultItem = {
  user: AddressType
  maxDaysInSequence: number
  latestCumulativeEthAmount: BN
  latestCumulativeUsdAmount: BN
}

export const fetchDune = async ({ queryKey }: FetchDuneQueryKey) => {
  const [_, { queryId, duneApiKey }] = queryKey
  if (queryId === undefined || duneApiKey === undefined) {
    return undefined
  }
  const config = {
    headers: { 'x-dune-api-key': duneApiKey },
  }
  const url = `https://api.dune.com/api/v1/query/${queryId}/results`
  const res = await axios.get<SpaceshipResult>(url, config)

  return res.data
}

export const fetchDuneAstronaut = async ({ queryKey }: AstronautQueryKey) => {
  const [_, { queryId, duneApiKey, account }] = queryKey
  if (queryId === undefined || duneApiKey === undefined || account === undefined) {
    return undefined
  }
  const config = {
    headers: { 'x-dune-api-key': duneApiKey },
    params: { filters: `user = ${account.toLowerCase()}` },
  }
  const url = `https://api.dune.com/api/v1/query/${queryId}/results`
  const res = await axios.get<AstronautResult>(url, config)

  return map(res.data.result.rows, row => {
    const convertedRow = mapKeys(row, (_, key) => camelCase(key))
    return {
      ...convertedRow,
      latestCumulativeEthAmount: new BN(convertedRow.latestCumulativeEthAmount),
      latestCumulativeUsdAmount: new BN(convertedRow.latestCumulativeUsdAmount),
    } as AstronautResultItem
  })
}
