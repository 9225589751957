import { BN } from '@apps-orangefi/lib'
import { TextField, TagButton } from '@apps-orangefi/ui/atoms'
import { FieldError } from 'react-hook-form'

type Props = {
  value: string
  editable: boolean
  balance: BN | undefined
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClickMax: () => void
  error?: FieldError
}

export const LockAmountField = ({
  value,
  editable,
  balance,
  onClickMax,
  onChange,
  error,
}: Props) => {
  return (
    <div className="flex flex-col relative">
      <div className="flex flex-row justify-between">
        <div className="type-sm-medium dark:text-gray-400">Lock amount</div>
        <div className="type-sm-medium dark:text-gray-400">{(balance ?? 0).toFixed(4)} LP</div>
      </div>
      <div className="flex flex-row items-center mt-1.5 relative w-full">
        <TextField
          value={value}
          onChange={onChange}
          disabled={!editable}
          className="w-full dark:bg-gray-800 border-gray-750 font-mono type-base-medium dark:text-white text-left px-4 py-3 md:pr-5 pl-5 rounded-xl md:rounded-xl focus:border-transparent"
          wrapperClassName="w-full"
        />
        <TagButton
          label="MAX"
          disabled={false}
          onClick={onClickMax}
          className="absolute right-0 mr-2 md:mr-5 py-1.5 px-3 rounded-lg"
        />
      </div>
      {error && <div className="dark:text-red-400 mt-1">{error.message}</div>}
    </div>
  )
}
