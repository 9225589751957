import { useOrangeQuery } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { usdceAddressAtom } from '@apps-orangefi/lib/store'
import { getVaultQuery } from '@apps-orangefi/lib/subgraph/queries'
import { GetVaultQuery } from '@apps-orangefi/lib/subgraph/types/orange/graphql'
import {
  VaultInfo,
  ContractProp,
  StrategyVaultInfo,
  Token,
  AMMAnalyticsData,
} from '@apps-orangefi/lib/types'
import { convertUSDCSymbol } from '@apps-orangefi/lib/utils'
import { useAtomValue } from 'jotai'
import { chain as _chain, isEqual } from 'lodash'
import { useEffect, useState, useMemo } from 'react'

export const useV1Vault = (account: AddressType | undefined, vaultInfo: VaultInfo) => {
  const usdceAddress = useAtomValue(usdceAddressAtom)
  const [analyticsData, setAnalyticsData] = useState<AMMAnalyticsData>()
  const [productContract, setProductContract] = useState<StrategyVaultInfo | undefined>(undefined)
  const [contractProps, setContractProps] = useState<ContractProp[]>([])

  const [result, reexecuteQuery] = useOrangeQuery<GetVaultQuery>({
    query: getVaultQuery,
    variables: {
      account: account?.toLowerCase() ?? '',
      vaultAddress: vaultInfo.VAULT_ADDRESS.toLowerCase(),
    },
  })
  const { data, fetching, error } = result

  const myVault = useMemo(() => {
    return data?.user?.positions.find(position => position.vault as AddressType) ?? []
  }, [data?.user?.positions])

  useEffect(() => {
    if (data) {
      if (!vaultInfo || !data.vault) {
        return
      }
      const vault = data.vault
      const currentEthPrice = new BN(data.globalState?.ethPriceUSD ?? 0)

      const _myPosition = data.user?.positions.find(
        position => position.vault === vaultInfo?.VAULT_ADDRESS
      )
      const [baseToken, quoteToken] = (
        vault.isTokenPairReversed
          ? [vault.pool.token1, vault.pool.token0]
          : [vault.pool.token0, vault.pool.token1]
      ) as [Token, Token]

      const symbol =
        convertUSDCSymbol(baseToken.id as AddressType, usdceAddress) ?? baseToken.symbol ?? ''

      const share = new BN(_myPosition?.share ?? 0)
      const [totalAssets, totalSupply] = [new BN(vault.totalAssets), new BN(vault.totalSupply)]
      const myPosition = totalSupply.isZero()
        ? new BN(0)
        : share.times(totalAssets).div(totalSupply).pow10ofMinus(Number(baseToken.decimals))

      const _productContract: StrategyVaultInfo = {
        ...vaultInfo,
        PARAMETERS_ADDRESS: vault.parameter.toLowerCase() as AddressType,
        YIELD_START: Number(vault.yieldStart) * 1000,
        poolAddress: vault.pool.id.toLowerCase() as AddressType,
        totalDeposit: new BN(vault.totalAssets).pow10ofMinus(Number(vault.decimals)),
        maxCapacity: new BN(vault.depositCap).pow10ofMinus(Number(vault.decimals)),
        apr: null,
        feeApr: new BN(vault.feeAPR).multipliedBy(100),
        myPosition,
        myPositionUSD: myPosition
          .multipliedBy(new BN(baseToken.derivedETH))
          .multipliedBy(currentEthPrice),
        baseTokenPriceUSD: new BN(baseToken.derivedETH ?? 0).multipliedBy(currentEthPrice),
        symbol,
        baseToken,
        quoteToken,
        allocation: {
          amm: vaultInfo.info.platform?.amm,
          derivative: vaultInfo.info.platform?.derivative,
        },
      }
      setAnalyticsData({
        composition: vault.tokenComposition,
        tick: vault.pool.tick,
        isTokenPairReversed: vault.isTokenPairReversed,
        pool: vault.pool,
      })
      if (!isEqual(productContract, _productContract)) {
        setProductContract(_productContract)
      }
    }
  }, [data, usdceAddress, vaultInfo])

  useEffect(() => {
    if (data) {
      if (!vaultInfo || !data.vault) {
        return
      }
      const vault = data.vault
      const [token0, token1] = vault.isTokenPairReversed
        ? [vault.pool.token1, vault.pool.token0]
        : [vault.pool.token0, vault.pool.token1]

      const _contractProps: ContractProp[] = [
        {
          symbol: convertUSDCSymbol(token0.id as AddressType, usdceAddress) ?? token0.symbol,
          address: token0.id.toLowerCase() as AddressType,
          url: `https://arbiscan.io/address/${token0.id}`,
        },
        {
          symbol: convertUSDCSymbol(token1.id as AddressType, usdceAddress) ?? token1.symbol,
          address: token1.id.toLowerCase() as AddressType,
          url: `https://arbiscan.io/address/${token1.id}`,
        },
        {
          symbol: 'Vault',
          address: vault.id.toLowerCase() as AddressType,
          url: `https://arbiscan.io/address/${vault.id}`,
        },
        {
          symbol: 'Pool',
          address: vault.pool.id.toLowerCase() as AddressType,
          url: `https://arbiscan.io/address/${vault.pool.id}`,
        },
      ]
      if (!isEqual(contractProps, _contractProps)) {
        setContractProps(_contractProps)
      }
    }
  }, [data, vaultInfo])

  return {
    productContract,
    analyticsData,
    myVault,
    contractProps,
    fetching,
    error,
    reexecuteQuery,
  }
}
