import { getDepositProof } from '@apps-orangefi/lib/utils'
import { isEmpty } from 'lodash'
import { useState, useEffect, useMemo } from 'react'

import type { DistributorList } from '@apps-orangefi/lib/utils'

export const useGetMerklProof = (whiteList: DistributorList, account: AddressType | undefined) => {
  const [merkleProof, setMerkleProof] = useState<AddressType[]>([])

  useEffect(() => {
    const getProof = async () => {
      const proof = await getDepositProof(whiteList, account)
      setMerkleProof(proof)
    }
    getProof()
  }, [account, JSON.stringify(whiteList)])

  const isAllowedWallet = useMemo(() => {
    return isEmpty(whiteList) || !isEmpty(merkleProof)
  }, [whiteList, JSON.stringify(merkleProof)])

  return { merkleProof, isAllowedWallet }
}
