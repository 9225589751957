import { useInvalidateQueries } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { bigintToBN, hashPosition } from '@apps-orangefi/lib/utils'
import { UniswapV3PoolABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractsWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { chain } from 'lodash'
import { useState, useEffect, useMemo } from 'react'

type PoolPosition = {
  tickLower: number
  tickUpper: number
  liquidity: BN
}

export const useGetPoolPositions = (
  poolAddress: AddressType | undefined,
  handlerAddress: AddressType | undefined,
  tickList: { tickLower: number; tickUpper: number }[]
) => {
  const [poolPositions, setPoolPositions] = useState<PoolPosition[]>([])
  const enabled = useMemo(() => {
    return !!poolAddress && !!handlerAddress && tickList.length > 0
  }, [poolAddress, handlerAddress, tickList])

  const poolContracts = chain(tickList)
    .map(tick => {
      if (!handlerAddress) return
      const hash = hashPosition(handlerAddress, tick.tickLower, tick.tickUpper)
      return {
        address: poolAddress!,
        abi: UniswapV3PoolABI,
        functionName: 'positions',
        args: [hash],
      }
    })
    .compact()
    .value()

  const { data, isFetching, isError, queryKey } = useReadContractsWithErrorHandling({
    contracts: poolContracts,
    query: {
      enabled,
    },
  })

  useEffect(() => {
    if (!!data) {
      const typedData = data.map((d: any) => d.result as [BigInt, BigInt, BigInt, BigInt, BigInt])

      const _positions = tickList.map((tick, index) => {
        return {
          ...tick,
          liquidity: bigintToBN(typedData[index][0] as bigint),
        }
      })
      setPoolPositions(_positions)
    }
  }, [JSON.stringify(data)])

  return { poolPositions, isFetching, isError }
}
