import BigNumber from 'bignumber.js'

declare module 'bignumber.js' {
  interface BigNumber {
    pow10: (decimals: number) => BigNumber
    pow10ofMinus: (decimals: number) => BigNumber
    convertBigint: () => bigint
  }
}

BigNumber.prototype.pow10 = function (decimals: number): BigNumber {
  return this.times(10 ** decimals)
}

BigNumber.prototype.pow10ofMinus = function (decimals: number): BigNumber {
  return this.div(10 ** decimals)
}

BigNumber.prototype.convertBigint = function (): bigint {
  return BigInt(this.toFixed(0))
}
