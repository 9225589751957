import { Transition, Dialog } from '@headlessui/react'
import { Fragment } from 'react'

type BaseModalProps = {
  onClose?: () => void
  children: JSX.Element
}
export const BaseModal = ({ onClose, children }: BaseModalProps) => {
  return (
    <>
      <Transition appear show as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          onClose={() => {
            onClose && onClose()
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full dark:bg-dark-950 items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                {children}
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
