import { BN } from '@apps-orangefi/lib'
import { Button } from '@apps-orangefi/ui/atoms'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'

type Props = {
  onSubmit: () => void
  onConnect: () => void
  onSwitchChainModal: () => void
  isConnected: boolean
  isActiveChainSupported: boolean
  isEnabled: boolean
}

export const MigrateForm = ({
  onSubmit,
  onConnect,
  onSwitchChainModal,
  isConnected,
  isActiveChainSupported,
  isEnabled,
}: Props) => {
  const { t } = useTranslation()
  const [mounted, setMounted] = useState(false)
  const [editable, setEditable] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    setEditable(isConnected && isActiveChainSupported)
  }, [isConnected, isActiveChainSupported])

  const enabled = isEnabled

  return (
    <div className="flex flex-col justify-between rounded-2xl dark:bg-gray-850 py-4 px-5 mb-4">
      <div className="type-lg-semibold dark:text-white">{t('MODEL.MIGRATION.TITLE')}</div>
      <div className="w-1/3 self-end">
        {mounted && isConnected && isActiveChainSupported && (
          <Button
            className="self-center w-full mt-2 px-6 py-3 md:py-4 rounded-xl md:rounded-2xl"
            label={t('WIDGET.ACTION.MIGRATE')}
            disabled={!enabled}
            onSubmit={onSubmit}
          />
        )}
        {mounted && isConnected && !isActiveChainSupported && (
          <Button
            className="self-center w-full mt-2 px-6 py-3 md:py-4 rounded-xl md:rounded-2xl"
            label={t('WIDGET.ACTION.SWITCH_NETWORK')}
            onSubmit={onSwitchChainModal}
          />
        )}
        {mounted && !isConnected && (
          <Button
            onSubmit={onConnect}
            className="self-center w-full mt-2 px-6 py-3 md:py-4 rounded-xl md:rounded-2xl"
            label={t('WIDGET.ACTION.CONNECT')}
          />
        )}
      </div>
    </div>
  )
}
