import { shortAddress } from '@apps-orangefi/lib/utils'
import { BaseModal } from '@apps-orangefi/ui/organisms/modals'
import { Dialog } from '@headlessui/react'
import { LogOut, ClipboardCopy, ExternalLink, Link } from 'lucide-react'
import useTranslation from 'next-translate/useTranslation'
import { useAccount } from 'wagmi'

export type AccountModalProps = {
  handleClose: () => void
  handleDisconnect: () => void
  handleCopyAccount: () => void
  handleExplore: () => void
}

export const AccountModal = ({
  handleClose,
  handleDisconnect,
  handleCopyAccount,
  handleExplore,
}: AccountModalProps) => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { chain } = useAccount()

  return (
    <BaseModal onClose={() => handleClose()}>
      <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl dark:bg-gray-900 p-6 pb-8 text-left align-middle shadow-xl transition-all">
        <Dialog.Title as="h3" className="type-xl-semibold dark:text-gray-400 text-start mb-10">
          {t('MODAL.ACCOUNT.TITLE')}
        </Dialog.Title>
        <div className="type-base-medium dark:text-gray-200">
          <div className="flex items-center justify-between">
            <div>
              <div className="flex items-center">
                <Link size={20} />
                <span className="ml-1">{chain?.name}</span>
              </div>
            </div>
            <button
              onClick={() => handleDisconnect()}
              className="rounded-full text-xs py-2 px-5 bg-brightGray dark:text-white hover:brightness-90"
            >
              <h4 className="flex items-center">
                <LogOut size={20} />
                <span className="ml-1">{t('MODAL.ACCOUNT.DISCONNECT')}</span>
              </h4>
            </button>
          </div>
          <div className="mt-10">
            <div className="dark:bg-gray-800 rounded-2xl py-4 px-6 col-span-2 type-base-semibold font-mono">
              {shortAddress(account, 10)}
            </div>
            <div className="flex mt-2 mx-2 mb-8 text-weak">
              <button
                className="flex items-center justify-center truncate whitespace-nowrap hover:opacity-90"
                onClick={() => handleCopyAccount()}
              >
                <span className="ml-1 flex items-center">
                  <ClipboardCopy size={20} />
                  <span className="ml-1 flex">{t('MODAL.ACCOUNT.COPY_ADDRESS')}</span>
                </span>
              </button>
              <button
                className="flex items-center justify-center ml-8 truncate whitespace-nowrap hover:opacity-90"
                onClick={() => handleExplore()}
              >
                <span className="flex items-center">
                  <ExternalLink size={20} />
                  <span className="ml-1">{t('MODAL.ACCOUNT.VIEW_EXPLORER')}</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </Dialog.Panel>
    </BaseModal>
  )
}
