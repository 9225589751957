type Props = {
  type?: 'lock' | 'unlock' | 'extend'
}

export const Notice = ({ type }: Props) => {
  return (
    <ul className="flex flex-col type-sm-caption dark:text-gray-400 mt-5 list-disc ml-4 sm:ml-6">
      {(type === 'lock' || type === 'extend') && (
        <li>ARB rewards end on Sep 2nd (PacankeSwap) and Sep 11th (Stryke/Camelot).</li>
      )}
      <li>Keep earning points unless you unlock, and your boost stays the same.</li>
      <li>If Chapter 2 ends during your lock, you&apos;ll earn Chapter 3 points.</li>
    </ul>
  )
}
