import { useClaimNFTForm } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { fetchDuneAstronaut, fetchAstronautProof } from '@apps-orangefi/lib/api'
import { StepState, stepState, UserClaimEligiblity } from '@apps-orangefi/lib/types'
import { useNFTStatus } from '@apps-orangefi/wagmi/hooks/astronaut'
import { useQuery } from '@tanstack/react-query'
import { isEqual, isEmpty, set, cloneDeep } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

const DUNE_ASTRONAUT_QUERY_ID = '3844037'
// const DUNE_ASTRONAUT_QUERY_ID = '3664311' // test
const CAMPAIGN_LOCK_DAYS = 28
const ELIGIBLE_ETH_AMOUNT = new BN(1)
const ELIGIBLE_USD_AMOUNT = new BN(3500)

type Steps = {
  step: number
  label: string
  state: StepState
}[]

const stepsDefaultVaues = [
  { step: 1, label: 'Connect wallet', state: stepState.Pending },
  { step: 2, label: 'Deposit at least 1 WETH / 3,500 USD', state: stepState.Pending },
  { step: 3, label: 'Hold LP tokens for 28 days', state: stepState.Pending },
  { step: 4, label: 'Claim NFT', state: stepState.Pending },
]

export const useAstronautData = (
  duneApiKey: string | undefined,
  account: AddressType | undefined,
  chainId: number,
  nftAddress: AddressType | undefined,
  astronautProxyAddress: AddressType | undefined
) => {
  const [userClaimEligiblity, setUserClaimEligiblity] = useState<UserClaimEligiblity | undefined>(
    undefined
  )
  const [steps, setSteps] = useState<Steps>(stepsDefaultVaues)

  const { data, isFetching } = useQuery({
    queryKey: ['astronaut', { queryId: DUNE_ASTRONAUT_QUERY_ID, duneApiKey, account }],
    queryFn: fetchDuneAstronaut,
    enabled: !!duneApiKey && !!account,
    refetchOnWindowFocus: false,
  })

  const { data: proofData } = useQuery({
    queryKey: ['fetchAstronautProof', { account }],
    queryFn: fetchAstronautProof,
    enabled: !!account,
  })

  const { nftStatus } = useNFTStatus(nftAddress, astronautProxyAddress, account)

  const isClaimable = useMemo(() => {
    if (!nftStatus) return false
    if (!proofData || isEmpty(proofData.proof)) return false
    return !nftStatus.isClaimed && steps[3].state === stepState.Active
  }, [proofData, nftStatus, steps])

  useEffect(() => {
    if (data) {
      const matchedItem = data.find(row => row.user.toLowerCase() === account?.toLowerCase())
      if (isEmpty(matchedItem)) {
        setUserClaimEligiblity(undefined)
        return
      }

      const _remainingDays = CAMPAIGN_LOCK_DAYS - matchedItem.maxDaysInSequence
      const _userClaimEligiblity = {
        remainingDays: _remainingDays < 0 ? 0 : _remainingDays,
        latestCommulativeEthAmount: matchedItem.latestCumulativeEthAmount,
        latestCommulativeUsdAmount: matchedItem.latestCumulativeUsdAmount,
      }
      if (!isEqual(_userClaimEligiblity, userClaimEligiblity)) {
        setUserClaimEligiblity(_userClaimEligiblity)
      }
    }
  }, [data, account])

  useEffect(() => {
    if (isFetching) return
    if (!account) {
      const resetSteps = cloneDeep(stepsDefaultVaues)
      set(resetSteps, '0.state', stepState.Active)
      setSteps(resetSteps)
      return
    }

    const newSteps = cloneDeep(steps)
    if (!!account) {
      set(newSteps, '0.state', stepState.Completed)
      set(newSteps, '1.state', stepState.Active)
    }
    if (
      !!userClaimEligiblity &&
      (userClaimEligiblity.latestCommulativeEthAmount.gte(ELIGIBLE_ETH_AMOUNT) ||
        userClaimEligiblity.latestCommulativeUsdAmount.gte(ELIGIBLE_USD_AMOUNT))
    ) {
      set(newSteps, '1.state', stepState.Completed)
      set(newSteps, '2.state', stepState.Active)
    }
    if (!!userClaimEligiblity && userClaimEligiblity.remainingDays === 0) {
      set(newSteps, '1.state', stepState.Completed)
      set(newSteps, '2.state', stepState.Completed)
      set(newSteps, '3.state', stepState.Active)
    }
    if (!!userClaimEligiblity && nftStatus?.isClaimed) {
      // set(newSteps, '1.state', stepState.Completed)
      // set(newSteps, '2.state', stepState.Completed)
      set(newSteps, '3.state', stepState.Completed)
    }

    if (!isEqual(newSteps, steps)) {
      setSteps(newSteps)
    }
  }, [userClaimEligiblity, data, account, isFetching, nftStatus])

  const { onClaimNFT } = useClaimNFTForm(
    astronautProxyAddress,
    account,
    proofData?.proof ?? [],
    isClaimable
  )

  return { steps, userClaimEligiblity, onClaimNFT, isClaimable, nftStatus }
}
