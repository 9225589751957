import { Price, Token } from '@uniswap/sdk-core'
import { tickToPrice } from '@uniswap/v3-sdk'

export const convertTicksToPriceRange = (
  tickLower: number,
  tickUpper: number,
  baseToken: Token,
  quoteToken: Token
) => {
  const priceLower = tickToPrice(baseToken, quoteToken, tickLower)
  const priceUpper = tickToPrice(baseToken, quoteToken, tickUpper)

  return { priceLower, priceUpper }
}

export const getToken = (chainId: number, address: AddressType, decimals: number) => {
  return new Token(chainId, address, decimals)
}
