import { useTx, useModal } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { Tx, txListAtom, txStatus } from '@apps-orangefi/lib/store'
import { ClaimStatus } from '@apps-orangefi/lib/types'
import { MODAL_TYPES } from '@apps-orangefi/ui/organisms/modals'
import { useClaim } from '@apps-orangefi/wagmi/hooks'
import { atom, useAtom } from 'jotai'
import { useState, useEffect, useCallback } from 'react'
import { useAccount } from 'wagmi'

const txClaimDefault: Tx = {
  title: 'Claim',
  hash: undefined,
  status: txStatus.Wait,
}

const txClaimAtom = atom<Tx>(txClaimDefault)

export const useClaimForm = (
  distributorAddress: AddressType | undefined,
  account: AddressType | undefined,
  myClaim:
    | {
        index: BN
        amount: BN
        proof: AddressType[]
      }
    | undefined,
  claimStatus: ClaimStatus
) => {
  const [txList, setTxList] = useAtom(txListAtom)
  const { chain } = useAccount()

  const {
    tx: txClaim,
    setTx: setTxClaim,
    moveToPending: txClaimPending,
    moveToError: txClaimError,
    moveToSuccess: txClaimSuccess,
  } = useTx(txClaimAtom)

  const resetTx = () => {
    if (txList.length !== 0) return
    setTxClaim(txClaimDefault)
  }

  const initTxList = () => {
    if (txList.length === 0) {
      setTxList([txClaimAtom])
    }
  }

  const claim = useClaim(
    distributorAddress,
    account,
    myClaim?.index,
    myClaim?.amount,
    myClaim?.proof ?? [],
    !claimStatus.isClaimed && !claimStatus.isExpired,
    {
      success: txClaimSuccess,
      fail: txClaimError,
    }
  )

  useEffect(() => {
    if (!claim.hash || !!txClaim.hash) return
    setTxClaim(prev => {
      return { ...prev, hash: claim.hash }
    })
  }, [claim.hash])

  useEffect(() => {
    if (txList.length === 0) return
    if (!claim.isClaimReady) return
    // if (withdrawableLpAmount.lte(0)) return
    if (txClaim.status === txStatus.Wait) {
      txClaimPending()
      claim.onClaim()
    }
  }, [txList, txClaim, claim.isClaimReady, claim.onClaim])

  const { showModal, hideModal } = useModal()

  const onClaim = useCallback(() => {
    resetTx()
    initTxList()
    showModal({
      modalType: MODAL_TYPES.TxModal,
      modalProps: {
        title: 'Claim transaction',
        chain,
        handleClose: () => {
          hideModal()
        },
        isDefaultClosable: true,
      },
    })
  }, [])

  return {
    resetTx,
    initTxList,
    onClaim,
  }
}
