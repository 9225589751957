export const MAX_LOCK_DAYS = 183

export const getTimeBoost = (lockDuration: number) => {
  return 1 + 0.5 * (lockDuration / MAX_LOCK_DAYS)
}

export const getPeriodParams = (
  iSOPeriod: string | undefined
): { start: string; end: string } | undefined => {
  if (!iSOPeriod) return
  const periods = iSOPeriod.split('/')
  return {
    start: periods[0],
    end: periods[1],
  }
}
