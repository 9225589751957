import { User, Score } from '@apps-orangefi/lib/types/point'
import axios, { AxiosError } from 'axios'

export class NoReferralCodeError extends Error {}

type FetchUserQueryKey = {
  queryKey: [string, { account: AddressType | undefined }]
}

export type XUserResult = {
  xUserId: string
  xUsername: string
}

export type PostData = {
  wallet: AddressType | undefined
  xUserId: string
  parentCode?: string
  signature?: string
  message: string
}

type CreateUserQueryKey = {
  queryKey: [string, { postData: PostData | undefined }]
}

type GetRankingsQueryKey = {
  queryKey: [string, { startTime: string; endTime: string }]
}

type CreateUserResult = User

type AddInviteCodeToUserQueryKey = {
  queryKey: [string, { account: AddressType | undefined; code: string | undefined }]
}
type AddInviteCodeToUserResult = {
  message: string
}

export interface LeaderboardUser {
  rank: number
  wallet: AddressType
  username: string
  score: number
  invitedByUsername: string
}

type FetchScoreQueryKey = {
  queryKey: [string, { account: AddressType | undefined; period?: { start: string; end: string } }]
}
type FetchFarmingStatsQueryKey = FetchUserQueryKey

type FarmingStatsResult = {
  dailyEarnings: number
  tvlBoost: {
    tvlUsd: number
    boost: number
    chapter1Boost: number
  }
  membershipBoost: number
}

const url = process.env.NEXT_PUBLIC_ORCHARD_API_URL

export const fetchUser = async ({ queryKey }: FetchUserQueryKey) => {
  const [_, { account }] = queryKey
  if (account === undefined) {
    return null
  }
  const endpoint = `${url}/users/${account}`
  try {
    const res = await axios.get<User>(endpoint)
    return res.data
  } catch (e) {
    return null
  }
}

export const fetchXUser = async ({ queryKey }: FetchUserQueryKey) => {
  const [_, { account }] = queryKey
  if (account === undefined) {
    return null
  }
  const endpoint = `${url}/x/connections/${account}`
  try {
    const res = await axios.get<XUserResult>(endpoint)
    return res.data
  } catch (e) {
    return null
  }
}

export const createUser = async ({ queryKey }: CreateUserQueryKey) => {
  const [_, { postData }] = queryKey
  if (!postData || !postData.wallet || !postData.signature) {
    return null
  }
  const endpoint = `${url}/users`
  try {
    const res = await axios.post<CreateUserResult>(endpoint, { ...postData })
    return res.data
  } catch (e) {
    return null
  }
}

export const addInvideCodeToUser = async ({ queryKey }: AddInviteCodeToUserQueryKey) => {
  const [_, { account, code }] = queryKey
  if (!account || !code) {
    return null
  }
  const endpoint = `${url}/users/${account}/code`
  try {
    const res = await axios.post<AddInviteCodeToUserResult>(endpoint, { code })
    return res.data
  } catch (error) {
    throw error
  }
}

export const fetchScore = async ({ queryKey }: FetchScoreQueryKey) => {
  const [_, { account, period }] = queryKey
  if (account === undefined) {
    return null
  }
  const params = period ?? {}
  const endpoint = `${url}/users/${account}/score/referral`
  try {
    const res = await axios.get<Score>(endpoint, { params })
    return res.data
  } catch (e) {
    throw e
  }
}

export const fetchPoints = async ({ queryKey }: FetchScoreQueryKey) => {
  const [_, { account, period }] = queryKey
  if (account === undefined) {
    return null
  }
  const params = period ?? {}
  // const endpoint = `${url}/users/${account.toLowerCase()}/score/event?start=2004-08-06T00:00:00Z&end=2034-08-15T23:59:59Z`
  const endpoint = `${url}/users/${account.toLowerCase()}/score/event`
  try {
    const res = await axios.get<{ total: number }>(endpoint, { params })
    return res.data.total
  } catch (e) {
    throw e
    return null
  }
}

export const fetchFarmingStats = async ({ queryKey }: FetchFarmingStatsQueryKey) => {
  const [_, { account }] = queryKey
  if (account === undefined) {
    return null
  }

  const endpoint = `${url}/users/${account}/farming-stats`
  try {
    const res = await axios.get<FarmingStatsResult>(endpoint)
    return res.data
  } catch (e) {
    return null
  }
}

export const fetchScores = async ({ queryKey }: GetRankingsQueryKey) => {
  const [_, { startTime, endTime }] = queryKey
  const endpoint = `${url}/scores?start=${startTime}&end=${endTime}`
  try {
    const res = await axios.get<{ scores: LeaderboardUser[] }>(endpoint)
    return res.data.scores
  } catch (e) {
    throw e
  }
}
