import { useDebounce } from '@apps-orangefi/hooks'
import { useInvalidateQueries } from '@apps-orangefi/hooks'
import { VaultCapacity } from '@apps-orangefi/lib/types'
import { bigintToBN } from '@apps-orangefi/lib/utils'
import { StrykeLPAutomatorV2ABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractsWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { useState, useEffect } from 'react'

import '@apps-orangefi/lib/extensions'

type Result = Omit<VaultCapacity, 'tokenSymbol'>

export const useLPDfiVaultCapacity = (
  vaultAddress: AddressType | undefined,
  vaultDecimals: number | null,
  watch: boolean = false
) => {
  const [vaultCapacity, setVaultCapacity] = useState<Result | undefined>(undefined)
  const enabled = useDebounce(!!vaultAddress, 300)

  const vaultContract = {
    address: vaultAddress!,
    abi: StrykeLPAutomatorV2ABI,
  }

  const { data, isFetching, isError, queryKey } = useReadContractsWithErrorHandling({
    contracts: [
      {
        ...vaultContract,
        functionName: 'totalAssets',
      },
      {
        ...vaultContract,
        functionName: 'depositCap',
      },
    ],
    query: {
      enabled,
    },
  })

  useInvalidateQueries({ queryKey })

  useEffect(() => {
    if (!!data && !!vaultDecimals) {
      const result = {
        totalDeposit: bigintToBN(data[0].result as bigint | undefined).pow10ofMinus(vaultDecimals),
        maxCapacity: bigintToBN(data[1].result as bigint | undefined).pow10ofMinus(vaultDecimals),
      }
      setVaultCapacity(result)
    }
  }, [JSON.stringify(data), vaultDecimals])

  return { vaultCapacity, isFetching, isError }
}
