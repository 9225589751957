import { LockPosition, Position } from '@apps-orangefi/lib/types/point'
import { Button } from '@apps-orangefi/ui/atoms/buttons'
import {
  DailyPointEarningsEstimation,
  Notice,
  type DailyEarnings,
} from '@apps-orangefi/ui/atoms/point'
import {
  EstimateItem,
  LockDurationField,
  VaultAttributes,
} from '@apps-orangefi/ui/molecules/point/chapter2'
import { zodResolver } from '@hookform/resolvers/zod'
import { Dayjs } from 'dayjs'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { z } from 'zod'

export type ExtendProps = {
  position: Position
  lockPosition: LockPosition
  lock: {
    lockDays: number
    onChangeLockDuration: (lockDays: number) => void
    onExtendLock: () => void
    onCancel: () => void
  }
  estimate: {
    unlockDate: Dayjs | null
    timeBoost: number
    dailyEarnings: DailyEarnings
  }
}

export const ExtendForm = ({
  position,
  lockPosition,
  lock: { lockDays, onChangeLockDuration, onExtendLock, onCancel },
  estimate: { unlockDate, timeBoost, dailyEarnings },
}: ExtendProps) => {
  const { t: tPoints } = useTranslation('points')
  const [isFormTouched, setIsFormTouched] = useState(false)

  const schema = z.object({
    lockDuration: z.coerce
      .number()
      .gt(lockPosition.lockDuration, {
        message: `Must be at least ${lockPosition.lockDuration} longer than the current duration.`,
      })
      .max(183, { message: 'Maximum duration is 183 days' }),
  })

  const {
    control,
    setValue,
    trigger,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      lockDuration: lockDays,
    },
    resolver: zodResolver(schema),
  })

  useEffect(() => {
    if (lockDays >= 0) {
      setValue('lockDuration', lockDays)
    }
  }, [lockDays, setValue])

  const handleChangeDuration = useCallback(
    (newDuration: number) => {
      onChangeLockDuration(newDuration)
      setValue('lockDuration', newDuration, { shouldValidate: false })
      setIsFormTouched(true)
      trigger('lockDuration') // Manually trigger validation
    },
    [onChangeLockDuration, setValue, trigger]
  )

  return (
    <form className="flex flex-col" onSubmit={handleSubmit(onExtendLock)}>
      <VaultAttributes
        tokenPair={position.tokenPair}
        platform={position.platform}
        className="mt-4"
      />
      <div className="flex flex-col mt-6 gap-3">
        <div className="type-base-semibold dark:text-white">Setting</div>
        <Controller
          name="lockDuration"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <LockDurationField
              lockDays={value}
              onChangeDuration={newDuration => {
                onChange(newDuration)
                handleChangeDuration(newDuration)
              }}
              error={isFormTouched ? error : undefined}
            />
          )}
        />
      </div>
      <div className="flex flex-col mt-6">
        <div className="type-base-semibold dark:text-white">Estimate</div>
        <div className="flex flex-col mt-3">
          <EstimateItem
            label="Unlock date"
            value={unlockDate ? unlockDate.format('MM.DD.YYYY') : '-'}
            tooltipText={tPoints('MODAL.HINTS.UNLOCK_DATE')}
          />
          <EstimateItem
            label="Lock Time boost"
            value={`${timeBoost.toFixed(2)}x`}
            tooltipText={tPoints('MODAL.HINTS.LOCK_TIME_BOOST')}
          />
        </div>
      </div>

      <DailyPointEarningsEstimation dailyEarnings={dailyEarnings} className="mt-4" />
      <div className="flex flex-row gap-3 w-full mt-6">
        <Button
          label="Cancel"
          onSubmit={onCancel}
          className="w-full dark:bg-gray-850 rounded-2xl type-base-bold dark:text-white"
        />
        <Button
          label={tPoints(`WIDGET.ACTION.EXTEND`)}
          disabled={!isValid}
          className={`w-full rounded-2xl type-base-bold dark:text-white ${
            !isValid ? 'dark:bg-gray-700' : ''
          }`}
        />
      </div>
      <Notice type="extend" />
    </form>
  )
}
