import CheckVerifiedIcon from '@apps-orangefi/assets/images/point/check-verified.svg'
import { User } from '@apps-orangefi/lib/types/point'
import { OctagonContainer, Button } from '@apps-orangefi/ui/atoms/point'
import { isEmpty } from 'lodash'
import Image from 'next/image'
import { useRouter } from 'next/router'

const tailwindConfig = require('@apps-orangefi/tailwindcss')

type Props = {
  user: User | undefined
}

export const AccountStatus = ({ user }: Props) => {
  const router = useRouter()

  return (
    <div className="flex flex-col sm:flex-row w-full">
      {!user ? (
        <Button label="SIGN UP" onSubmit={() => router.push('/')} className="w-fit" />
      ) : (
        <OctagonContainer
          size={3}
          borderColor={tailwindConfig.theme.extend.colors.emerald[400]}
          className="px-5 py-4 w-fit min-h-[54px]"
        >
          <div className="flex flex-row items-center">
            <div className="type-base-bold dark:text-emerald-400">YOU&apos;RE JOINED</div>
            <Image
              src={CheckVerifiedIcon}
              width={20}
              height={20}
              alt="Check Verified"
              className="ml-4"
            />
          </div>
        </OctagonContainer>
      )}
      {!!user && (!user.parentCode || isEmpty(user.parentCode)) && (
        <Button
          label="ADD REF CODE"
          onSubmit={() => router.push('/')}
          className="w-fit mt-2 sm:mt-0 sm:ml-3 min-h-[54px]"
        />
      )}
    </div>
  )
}
