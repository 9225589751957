import { StrykeLPAutomatorV2ABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractsWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'

import '@apps-orangefi/lib/extensions'

const useGetPairAssets = (vaultAddress: AddressType | undefined) => {
  const enabled = !!vaultAddress
  const vaultContract = {
    address: vaultAddress!,
    abi: StrykeLPAutomatorV2ABI,
  }

  const { data, isFetching, queryKey } = useReadContractsWithErrorHandling({
    contracts: [
      {
        ...vaultContract,
        functionName: 'asset',
      },
      {
        ...vaultContract,
        functionName: 'counterAsset',
      },
    ],
    query: {
      enabled,
    },
  })

  return {
    assetAddress: data ? (data[0].result as AddressType) : undefined,
    counterAssetAddress: data ? (data[1].result as AddressType) : undefined,
    isFetching,
  }
}

export default useGetPairAssets
