import { getTokenIcon } from '@apps-orangefi/lib/utils'
import { TokenPair } from '@apps-orangefi/ui/atoms'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'

type Props = {
  baseTokenSymbol: string
  quoteTokenSymbol: string
  size?: number
  className?: string
}

export const TokenPairBySymbol = ({
  baseTokenSymbol,
  quoteTokenSymbol,
  size = 40,
  className = '',
}: Props) => {
  const baseTokenIcon = getTokenIcon(baseTokenSymbol)
  const quoteTokenIcon = getTokenIcon(quoteTokenSymbol)

  return (
    <div className={twMerge(className)}>
      {baseTokenIcon && quoteTokenIcon && (
        <TokenPair token0IconUrl={baseTokenIcon} token1IconUrl={quoteTokenIcon} size={size} />
      )}
    </div>
  )
}
