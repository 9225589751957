import { useModal, useReserveLPForm } from '@apps-orangefi/hooks'
import { MODAL_TYPES } from '@apps-orangefi/ui/organisms/modals'
import { WithdrawnLP } from '@apps-orangefi/wagmi/hooks'
import { useCallback, useEffect } from 'react'
import { useAccount } from 'wagmi'
import { Chain } from 'wagmi/chains'

export const useReserveStrykeLp = ({
  reserveProxyAddress,
  handlerAddress,
  chain,
  lps,
  isApproved,
}: {
  reserveProxyAddress: AddressType | undefined
  handlerAddress: AddressType | undefined
  chain: Chain | undefined
  lps: WithdrawnLP[]
  isApproved: boolean
}) => {
  const { address: account, isConnected } = useAccount()

  const {
    onReserveLP,
    isReserveReady,
    isReservedLiquidity,
    hasReserveLP,
    hasNotReservedLiquidity,
    isSubmitted: isReserveLPSubmitted,
    isWaiting: isReserveLPWaiting,
    txReserveLiquidityAtom,
    resetTx: resetReserveTx,
    initTxList,
  } = useReserveLPForm({
    lastWithdrawnLPs: lps,
    isEnabledReserve: isConnected && isApproved,
    reserveProxyAddress,
    handlerAddress,
  })

  // useEffect(() => {
  //   if (isConnected && isApproved) {
  //     resetReserveTx()
  //     setTxList([txReserveLiquidityAtom])
  //   }

  //   return () => {
  //     resetTxList()
  //   }
  // }, [isConnected, isApproved])

  // const { showModal, hideModal } = useModal()

  const onReserve = useCallback(() => {
    resetReserveTx()
    initTxList()
    // showModal({
    //   modalType: MODAL_TYPES.TxModal,
    //   modalProps: {
    //     title: 'Reserve Liquidity',
    //     chain,
    //     handleClose: () => {
    //       hideModal()
    //     },
    //   },
    // })
    onReserveLP()
  }, [onReserveLP])

  // useEffect(() => {
  //   if (isReservedLiquidity || !hasReserveLP) {
  //     handleCallback(true)
  //   }
  // }, [isReservedLiquidity, hasReserveLP])

  // useEffect(() => {
  //   if (!isReservedLiquidity && hasReserveLP && !isReserveLPSubmitted) {
  //     onReserveLP()
  //   }
  // }, [
  //   onReserveLP,,
  //   isReservedLiquidity,
  //   hasReserveLP,
  //   isReserveLPSubmitted,
  // ])

  return {
    onReserve,
    isReserveLPWaiting,
    isReservedLiquidity,
    isReserveLPSubmitted,
    isReserveReady,
  }
}
