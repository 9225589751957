import ArbitrumLogo from '@apps-orangefi/assets/images/chains/arbitrum.svg'
import OptimismLogo from '@apps-orangefi/assets/images/chains/optimism.svg'
import PolygonLogo from '@apps-orangefi/assets/images/chains/polygon.svg'
import SepoliaLogo from '@apps-orangefi/assets/images/chains/sepolia.png'
import { arbitrum, polygon, optimism, sepolia } from 'viem/chains'

export * from './forms'
export * from './buttons'
export * as icons from './icons'
export * from './loaders'
export * from './ProgressBar'
export * from './Card'
export * from './Position'
export * from './GoogleTagManager'
export * from './Tag'
export * from './Stats'
export * from './ContractAddress'
export * from './Rank'
export * from './ArbReward'
export * from './TokenPair'
export * from './Platform'
export * from './SourceRevenue'
export * as point from '../atoms/point'
export * from './ClipPathOctagon'

export const chainLogos: { [key: number]: any } = {
  [arbitrum.id]: ArbitrumLogo,
  [polygon.id]: PolygonLogo,
  [optimism.id]: OptimismLogo,
  [sepolia.id]: SepoliaLogo,
}
