import Logo from '@apps-orangefi/assets/images/logo.svg'
import OrangeLogo from '@apps-orangefi/assets/images/orange-logo.svg'
import { shortAddress } from '@apps-orangefi/lib/utils'
import { MenuButton } from '@apps-orangefi/ui/atoms'
import { OctagonContainer } from '@apps-orangefi/ui/atoms/point/OctagonContainer'
import { ChainSelector } from '@apps-orangefi/ui/molecules'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { Chain } from 'wagmi/chains'

const ConnectWalletButton = dynamic(
  () => import('@apps-orangefi/ui/molecules/buttons/WalletButton'),
  { ssr: false }
)

const menuColorSet = {
  default: {
    bg: 'bg-main',
    text: 'dark:text-gray-400',
  },
  active: {
    bg: 'bg-main',
    text: 'dark:text-white',
  },
}

type Props = {
  networkStatus: {
    isConnected: boolean
    isActiveChainSupported: boolean
    isActiveChainIsSelected: boolean
  }
  account: AddressType | undefined
  openConnectWalletModal: () => void
  openAccountModal: () => void
  openSwitchChainModal: () => void
  chainSelectorProps: {
    selectedChain: Chain | undefined
    supportedChains: { [key: string]: Chain }
    onSelectChain: (chain: Chain) => void
  }
  currentPath: string
  appUrlBase: string
}

export const PointPageHeader = ({
  networkStatus,
  account,
  openConnectWalletModal,
  openAccountModal,
  openSwitchChainModal,
  chainSelectorProps,
  currentPath,
  appUrlBase,
}: Props) => {
  const { t } = useTranslation()
  const shortenAddress = shortAddress(account)

  return (
    <div className="bg-main px-1 p-1 sm:px-5 sm:pt-5 fixed top-0 right-0 left-0 z-30">
      <OctagonContainer size={12} direction="top" className="h-fit ">
        <div className="flex flex-row justify-between items-center px-5 py-4 sm:px-10 sm:py-6">
          <div className="flex flex-row type-base-semibold items-center justify-start">
            <a href={`${appUrlBase}/arbitrum`}>
              <Image
                src={Logo}
                alt="Orange Finance"
                width={184}
                height={40}
                className="hidden md:block"
              />
              <Image
                src={OrangeLogo}
                alt="Orange Finance"
                width={40}
                height={38.5}
                className="md:hidden"
              />
            </a>
            <div className="flex-row hidden md:flex ml-8">
              <MenuButton
                label={t('MENU.LIQUIDITY_VAULTS')}
                href={`${appUrlBase}/arbitrum`}
                isTargetBlank={false}
                className="ml-2"
                colorSet={menuColorSet}
              />
              <MenuButton
                label={t('MENU.POINTS')}
                href="/"
                isActive={true}
                className="ml-2"
                colorSet={menuColorSet}
              />
              <MenuButton
                label={t('MENU.ASTRONAUT')}
                href={`${appUrlBase}/astronaut`}
                isTargetBlank={false}
                className="ml-2"
                colorSet={menuColorSet}
              />
              {/* <MenuButton
                label={t('MENU.POINTS.ARB_REWARDS')}
                href=""
                // href="https://orange-finance.gitbook.io/orange-finance/"
                className="ml-2 hover:cursor-default"
                colorSet={menuColorSet}
              /> */}
            </div>
          </div>
          <div className="flex flex-row items-center">
            {networkStatus.isConnected && chainSelectorProps.selectedChain && (
              <ChainSelector {...chainSelectorProps} className="mr-3" />
            )}
            <ConnectWalletButton
              networkStatus={networkStatus}
              shortenAddress={shortenAddress}
              openConnectWalletModal={openConnectWalletModal}
              openAccountModal={openAccountModal}
              openSwitchChainModal={openSwitchChainModal}
              // className="dark:bg-orange-500 dark:text-white font-bold font-heading"
            />
          </div>
        </div>
      </OctagonContainer>
    </div>
  )
}
