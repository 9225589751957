import { StrykeUniV3HandlerV2ABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractsWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { useEffect, useMemo, useState } from 'react'

import type { Strike, ReservedPosition } from '@apps-orangefi/lib/types'

type GetTokenIdDataResult = {
  reservedLiquidity: bigint
  liquidityUsed: bigint
  totalLiquidity: bigint
  token0: `0x${string}`
  token1: `0x${string}`
}

type TokenIdData = Omit<Strike, 'token0' | 'token1'> & {
  token0Address: AddressType
  token1Address: AddressType
}

type CollectTokenIdDataResult = {
  tokenIdDataList: TokenIdData[]
  isFetching: boolean
  refetch: () => void
}

export const useCollectTokenIdData = (
  handlerAddress: AddressType | undefined,
  reservedPositions: ReservedPosition[]
): CollectTokenIdDataResult => {
  const [tokenIdDataList, setTokenIdDataList] = useState<TokenIdData[]>([])

  const handlerContract = {
    address: handlerAddress!,
    abi: StrykeUniV3HandlerV2ABI,
  }
  const contracts = useMemo(() => {
    return reservedPositions.map(rsvdPos => {
      return {
        ...handlerContract,
        functionName: 'getTokenIdData',
        args: [rsvdPos.tokenId],
      }
    })
  }, [reservedPositions, handlerContract])
  const enabled = useMemo(
    () => !!handlerAddress && reservedPositions.length > 0,
    [handlerAddress, reservedPositions]
  )

  const { data, isFetching, refetch } = useReadContractsWithErrorHandling({
    contracts,
    query: {
      enabled,
    },
  })

  useEffect(() => {
    if (data && reservedPositions.length > 0) {
      const result = data.map((item, i) => {
        const tokenData = item.result as unknown as GetTokenIdDataResult

        return {
          id: reservedPositions[i].tokenId,
          handler: handlerAddress?.toLowerCase(),
          pool: reservedPositions[i].pool.toLowerCase(),
          reservedLiquidity: tokenData.reservedLiquidity.toString(),
          usedLiquidity: tokenData.liquidityUsed.toString(),
          totalLiquidity: tokenData.totalLiquidity.toString(),
          tickLower: reservedPositions[i].tickLower,
          tickUpper: reservedPositions[i].tickUpper,
          token0Address: tokenData.token0,
          token1Address: tokenData.token1,
        } as TokenIdData
      })
      setTokenIdDataList(result)
    }
  }, [data, reservedPositions])

  return { tokenIdDataList, isFetching, refetch }
}
