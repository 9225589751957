import { useResponsiveType, responsiveType } from '@apps-orangefi/hooks'
import { DepositToken } from '@apps-orangefi/lib/types'
import { SwitchButton } from '@apps-orangefi/ui/atoms'

type Props = {
  tokenList: { symbol: DepositToken; logoUrl: string }[]
  selectedToken: DepositToken | undefined
  onSelectToken: (token: DepositToken | undefined) => void
}

export const TokenSelector = ({ tokenList, selectedToken, onSelectToken }: Props) => {
  const rType = useResponsiveType()
  const hideLabel = rType === responsiveType.SmallerThanMd

  return (
    <div className="flex flex-col md:flex-row md:items-center">
      <div className="mr-4">Vaults by tokens</div>
      <div className="flex flex-row items-center mt-3 md:mt-0">
        <SwitchButton
          key="all-token"
          label="All"
          isActive={selectedToken === undefined}
          onClick={() => onSelectToken(undefined)}
          className="mr-4"
        />
        {tokenList.map((token, i) => (
          <SwitchButton
            key={i}
            label={token.symbol}
            logoUrl={token.logoUrl}
            isActive={selectedToken === token.symbol}
            onClick={() => onSelectToken(token.symbol)}
            hideLabel={hideLabel}
            className="mr-4"
          />
        ))}
      </div>
    </div>
  )
}
