import { AstronautProxyABI } from '@apps-orangefi/wagmi/abis'
import {
  useSimulateContractWithErrorHandling,
  useWriteContractWithErrorHandling,
  useWaitForTransactionReceiptWithErrorHandling,
} from '@apps-orangefi/wagmi/hooks/common'
import { isEmpty } from 'lodash'
import { useMemo, useEffect, useState, useCallback } from 'react'
import { zeroAddress } from 'viem'
import { BaseError } from 'wagmi'

export const useClaimNFT = (
  astronautProxyAddress: AddressType | undefined,
  account: AddressType | undefined,
  merklProof: AddressType[],
  isClaimable: boolean,
  callback?: { success?: () => void; fail?: (cause: BaseError | string) => void }
) => {
  const [isClaimReady, setIsClaimReady] = useState(false)

  const args = useMemo(() => {
    return [account ?? zeroAddress, merklProof] as const
  }, [account, merklProof])

  const enabled = useMemo(() => {
    return isClaimable && !!astronautProxyAddress && !!account && !isEmpty(merklProof)
  }, [isClaimable, astronautProxyAddress, account, merklProof])

  const { data } = useSimulateContractWithErrorHandling({
    address: astronautProxyAddress!,
    abi: AstronautProxyABI,
    functionName: 'safeMint',
    args,
    query: {
      enabled,
    },
  })

  const { data: hash, writeContract } = useWriteContractWithErrorHandling({
    mutation: {
      onError(error) {
        if (callback && callback.fail) {
          callback.fail(error.cause as BaseError)
        }
      },
    },
  })

  const {
    isLoading: waitLoading,
    isSuccess,
    isError,
    error,
  } = useWaitForTransactionReceiptWithErrorHandling({ hash }, callback)

  useEffect(() => {
    setIsClaimReady(!!writeContract && !!data?.request)
  }, [writeContract, data?.request])

  return {
    isClaimReady,
    hash,
    isSuccess,
    onClaimNFT: useCallback(() => {
      if (!isClaimReady) {
        return
      }
      writeContract(data!.request)
    }, [isClaimReady, data, writeContract]),
  }
}
