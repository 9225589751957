import { fetchPoints } from '@apps-orangefi/lib/api/point/user'
import { getPeriodParams } from '@apps-orangefi/lib/utils/point'
import { useQuery } from '@tanstack/react-query'

export const useChapterPoints = (
  account: AddressType | undefined,
  iSOPeriod: string | undefined
) => {
  const period = getPeriodParams(iSOPeriod)

  const { data, error, status, isFetching } = useQuery({
    queryKey: ['getPoints', { account, period }],
    queryFn: fetchPoints,
    enabled: !!account,
    refetchOnWindowFocus: false,
  })

  return {
    score: data ?? undefined,
    isFetching,
  }
}
