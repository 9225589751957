import { useReserveLPForm, useWithdrawLPForm } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { MIN_FACTOR } from '@apps-orangefi/lib/constants'
import { txListAtom } from '@apps-orangefi/lib/store'
import { SimulateWithdrawProps } from '@apps-orangefi/lib/types'
import { TxModal } from '@apps-orangefi/ui/organisms/modals'
import { useSetAtom } from 'jotai'
import { useResetAtom } from 'jotai/utils'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'

export type WithdrawAndReserveModalContainerProps = {
  vaultAddress: AddressType | undefined
  reserveProxyAddress: AddressType | undefined
  handlerAddress: AddressType | undefined
  withdrawableLpAmount: BN
  simulateProps: SimulateWithdrawProps
  handleClose: () => void
  handleCallback: (isEnd: boolean) => void
}

export const WithdrawAndReserveModalContainer = ({
  vaultAddress,
  reserveProxyAddress,
  handlerAddress,
  withdrawableLpAmount,
  simulateProps,
  handleClose,
  handleCallback,
}: WithdrawAndReserveModalContainerProps) => {
  const { t } = useTranslation()
  const { address: account, chain } = useAccount()
  const [isWithdrawStarted, setIsWithdrawStarted] = useState(false)

  const setTxList = useSetAtom(txListAtom)
  const resetTxList = useResetAtom(txListAtom)

  const {
    lastWithdrawnLPs,
    onWithdrawLP,
    isWithdrawTransactionEnd,
    isWithdrawReady,
    txWithdrawAtom,
    resetTx: resetWithdrawTx,
  } = useWithdrawLPForm(
    vaultAddress,
    account,
    withdrawableLpAmount,
    simulateProps.resultWithdrawSimulation?.withdrawnAssets.times(MIN_FACTOR),
    handlerAddress,
    simulateProps.hasUtilizedLP
  )

  // hasReserveLP: similar to simulateProps.hasUtilizedLP
  const {
    onReserveLP,
    isReservedLiquidity,
    hasReserveLP,
    hasNotReservedLiquidity,
    isSubmitted: isReserveLPSubmitted,
    isWaiting: isReserveLPWaiting,
    txReserveLiquidityAtom,
    resetTx: resetReserveTx,
  } = useReserveLPForm({
    lastWithdrawnLPs,
    isEnabledReserve: isWithdrawTransactionEnd,
    reserveProxyAddress,
    handlerAddress,
  })

  useEffect(() => {
    resetWithdrawTx()
    resetReserveTx()

    let _txList = [txWithdrawAtom]
    if (simulateProps.hasUtilizedLP) {
      _txList.push(txReserveLiquidityAtom)
    }
    setTxList(_txList)

    return () => {
      resetTxList()
    }
  }, [])

  useEffect(() => {
    if (isReservedLiquidity && !!simulateProps.resultWithdrawSimulation) {
      simulateProps.resetResultWithdrawSimulation()
    }
  }, [isReservedLiquidity, simulateProps.resetResultWithdrawSimulation])

  useEffect(() => {
    if (isWithdrawReady && !isWithdrawTransactionEnd && !isWithdrawStarted) {
      onWithdrawLP()
      setIsWithdrawStarted(true)
    }
  }, [isWithdrawReady, isWithdrawTransactionEnd])

  useEffect(() => {
    if (isWithdrawTransactionEnd && (isReservedLiquidity || !hasReserveLP)) {
      handleCallback(true)
    }
  }, [isWithdrawTransactionEnd, isReservedLiquidity, hasReserveLP])

  useEffect(() => {
    if (isWithdrawTransactionEnd && !isReservedLiquidity && hasReserveLP && !isReserveLPSubmitted) {
      onReserveLP()
    }
  }, [
    onReserveLP,
    isWithdrawTransactionEnd,
    isReservedLiquidity,
    hasReserveLP,
    isReserveLPSubmitted,
  ])

  return (
    <>
      <TxModal
        title={'Withdraw & Reserve transaction'}
        chain={chain}
        handleClose={handleClose}
        messages={[]}
      >
        <div className="mt-3">
          {hasNotReservedLiquidity && isReservedLiquidity && (
            <div className="type-base-medium p-2">
              <Trans
                i18nKey="MODAL.TX.NOT_RESERVED_POSITIONS_EXIST"
                components={{
                  link: (
                    <a
                      href="https://www.stryke.xyz/en/dashboard"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="underline"
                    />
                  ),
                }}
              />
            </div>
          )}
        </div>
      </TxModal>
    </>
  )
}
