import React, { CSSProperties } from 'react'
import { twMerge } from 'tailwind-merge'

import styles from './OctagonContainer.module.css'

const tailwindConfig = require('@apps-orangefi/tailwindcss')

type Props = {
  size: number
  borderColor?: string
  bgColor?: string
  direction?: 'both' | 'top' | 'bottom'
  children?: React.ReactNode
  className?: string
}

export const OctagonContainer = ({
  size,
  borderColor,
  bgColor,
  direction = 'both',
  children,
  className,
}: Props) => {
  // if use 'transparent' on background-color, it will be filled border-color
  const styleVars = {
    '--background-color': bgColor ?? tailwindConfig.theme.extend.colors.gray[900],
    '--border-color': borderColor ?? tailwindConfig.theme.extend.colors.gray[800],
    '--corner-size': `${size * 2}px`,
  } as CSSProperties

  const style =
    direction === 'both'
      ? styles.octBox
      : direction === 'top'
      ? styles.octBoxTop
      : styles.octBoxBottom

  return (
    // `Single Child Constraint`
    // This component expects exactly one child component.
    <div className={twMerge('w-full h-full', style, className)} style={styleVars}>
      {children && (
        <>
          {React.Children.map(children, child =>
            React.isValidElement<{ className?: string }>(child)
              ? React.cloneElement(child, {
                  className: `${
                    child.props.className ? child.props.className + ' ' : ''
                  }z-10 relative`,
                })
              : child
          )}
        </>
      )}
      {/* {children && (
        <div className='z-10'>{children}</div>
      )} */}
    </div>
  )
}
