import { useTx, useModal } from '@apps-orangefi/hooks'
import {
  Tx,
  txListAtom,
  txStatus,
  vaultDecimalsAtom,
  isAllowedCloseModalAtom,
  needReloadAtom,
} from '@apps-orangefi/lib/store'
import { MODAL_TYPES } from '@apps-orangefi/ui/organisms/modals'
import { useBatchWithdrawReserveLiquidity } from '@apps-orangefi/wagmi/hooks'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useState, useEffect, useCallback } from 'react'
import { useAccount } from 'wagmi'

const txWithdrawReservedLPDefault: Tx = {
  title: 'Withdraw Reserved Liquidity',
  hash: undefined,
  status: txStatus.Wait,
}

const txWithdrawReservedLPAtom = atom<Tx>(txWithdrawReservedLPDefault)

export const useWithdrawReservedLPForm = (
  reserveProxyAddress: AddressType | undefined,
  handlerAddress: AddressType | undefined,
  tokenIds: string[],
  refetch: () => void
) => {
  const [txList, setTxList] = useAtom(txListAtom)
  const vaultDecimals = useAtomValue(vaultDecimalsAtom)
  const setIsAllowedCloseModal = useSetAtom(isAllowedCloseModalAtom)
  const setNeedReload = useSetAtom(needReloadAtom)

  const { chain } = useAccount()

  const {
    tx: txWithdrawReservedLP,
    setTx: setTxWithdrawReservedLP,
    moveToPending: txWithdrawPending,
    moveToError: txWithdrawReservedLPError,
    moveToSuccess: txWithdrawReservedLPSuccess,
  } = useTx(txWithdrawReservedLPAtom)

  const resetTx = () => {
    if (txList.length !== 0) return
    setTxWithdrawReservedLP(txWithdrawReservedLPDefault)
  }

  const initTxList = () => {
    if (txList.length === 0) {
      setTxList([txWithdrawReservedLPAtom])
    }
  }

  const withdrawReservedLP = useBatchWithdrawReserveLiquidity(
    reserveProxyAddress,
    handlerAddress,
    tokenIds,
    {
      success: () => {
        txWithdrawReservedLPSuccess()
        setTimeout(() => {
          refetch()
        }, 5000)
      },
      fail: txWithdrawReservedLPError,
    }
  )

  useEffect(() => {
    if (!withdrawReservedLP.hash || !!txWithdrawReservedLP.hash) return
    setTxWithdrawReservedLP(prev => {
      return { ...prev, hash: withdrawReservedLP.hash }
    })
  }, [withdrawReservedLP.hash])

  useEffect(() => {
    if (txList.length === 0) return
    if (!withdrawReservedLP.isWriteReady) return
    if (txWithdrawReservedLP.status === txStatus.Wait) {
      txWithdrawPending()
      withdrawReservedLP.onWithdraw()
    }
  }, [txList, txWithdrawReservedLP, withdrawReservedLP.isWriteReady, withdrawReservedLP.onWithdraw])

  useEffect(() => {
    setIsAllowedCloseModal(true)
  }, [])

  const { showModal, hideModal } = useModal()

  const onWithdrawReservedLP = useCallback(() => {
    resetTx()
    initTxList()
    showModal({
      modalType: MODAL_TYPES.TxModal,
      modalProps: {
        title: 'Withdraw reserved liquidity transaction',
        chain,
        handleClose: () => {
          setNeedReload(true)
          hideModal()
        },
      },
    })
  }, [])

  return {
    resetTx,
    initTxList,
    onWithdrawReservedLP,
  }
}
