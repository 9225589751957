import { useExtendForm } from '@apps-orangefi/hooks'
import { LockPosition, Position } from '@apps-orangefi/lib/types/point'
import { TxModal } from '@apps-orangefi/ui/organisms/modals'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useEffect, useState } from 'react'
import { useAccount } from 'wagmi'

import { ExtendModal } from './ExtendModal'

export type ExtendModalContainerProps = {
  position: Position
  lockPosition: LockPosition
  dailyEarnings: number
  handleClose: () => void
}

export const ExtendModalContainer = ({
  position,
  lockPosition,
  dailyEarnings,
  handleClose,
}: ExtendModalContainerProps) => {
  const { t: tPoints } = useTranslation('points')
  const [showTxModal, setShowTxModal] = useState(false)
  const { address: account, chain } = useAccount()

  const onOpenTxModal = useCallback(() => {
    setShowTxModal(true)
  }, [])

  const { lock, estimate } = useExtendForm({
    lockPosition,
    dailyEarnings,
    onOpenTxModal,
  })

  useEffect(() => {
    // Reset showTxModal when item changes
    setShowTxModal(false)
  }, [position])

  return (
    <>
      {!showTxModal && (
        <ExtendModal
          position={position}
          lockPosition={lockPosition}
          lock={lock}
          estimate={estimate}
          handleClose={handleClose}
        />
      )}
      {showTxModal && (
        <TxModal
          title={tPoints('MODAL.EXTEND.TX')}
          chain={chain}
          handleClose={handleClose}
          messages={[]}
        />
      )}
    </>
  )
}
