import { BN } from '@apps-orangefi/lib'
import { fetchBlockNumber } from '@apps-orangefi/lib/api'
import { TokenValueWithDate } from '@apps-orangefi/lib/types'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { last } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useAccount } from 'wagmi'

const APR_BASE_DAYS = 30

export type VaultPerformanceHook = (
  vaultAddress: AddressType | undefined,
  yieldStart: number | undefined,
  daysBeforeRecentEvent?: number
) => {
  vaultPerformance: BN | undefined
}

type EventHookParams = {
  vaultAddress: AddressType | undefined
  enabled: boolean
  startBlock?: number | undefined
  yieldStart?: number | undefined
  daysBeforeRecentEvent?: number
}
export type EventHook = (param: EventHookParams) => {
  tokenValuesWithDate: TokenValueWithDate[]
  vaultPerformance: BN | undefined
  isFetching: boolean
}

export const usePerformance = (
  vaultAddress: AddressType | undefined,
  yieldStart: number | undefined,
  useEventHook: EventHook,
  showAPR: boolean,
  aprBaseDays = APR_BASE_DAYS
) => {
  const { chainId } = useAccount()
  const timestamp = useMemo(() => (yieldStart ? yieldStart / 1000 : undefined), [yieldStart])
  const { data: dataFindBlock } = useQuery({
    queryKey: ['findBlock', { chainId, timestamp }],
    queryFn: fetchBlockNumber,
    enabled: !!timestamp,
  })

  const { tokenValuesWithDate, vaultPerformance, isFetching } = useEventHook({
    vaultAddress,
    startBlock: dataFindBlock?.number,
    enabled: showAPR,
  })

  const apr = useMemo(() => {
    const lastSnapshot = last(tokenValuesWithDate)
    const lastSnapshotTime = lastSnapshot?.timestamp ?? yieldStart
    const yearDays = dayjs.duration(1, 'years').asDays()

    if (aprBaseDays !== 0 && tokenValuesWithDate.length >= aprBaseDays) {
      const baseSnapshot = tokenValuesWithDate[tokenValuesWithDate.length - aprBaseDays]
      const gainValue = lastSnapshot?.tokenValue?.minus(baseSnapshot.tokenValue!)
      return (
        gainValue
          ?.div(baseSnapshot.tokenValue!)
          .times(100)
          .times(yearDays / aprBaseDays) ?? new BN(0)
      )
    } else {
      const period = dayjs.duration(lastSnapshotTime! - yieldStart!, 'milliseconds').asDays()
      return lastSnapshot?.tokenValue?.minus(new BN(100)).times(yearDays / period) ?? new BN(0)
    }
  }, [tokenValuesWithDate])

  return useMemo(
    () => ({
      performance: {
        chartData: tokenValuesWithDate,
        apr: showAPR ? apr : null,
        recentPerformance: vaultPerformance,
        isFetching,
      },
    }),
    [tokenValuesWithDate, showAPR, apr, vaultPerformance, isFetching]
  )
}
