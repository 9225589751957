import { useTimer } from 'react-timer-hook'

type Props = {
  expiryTimestamp: Date
}

const zeroPad = (num: number, places: number = 2) => String(num).padStart(places, '0')

const Countdown = ({ expiryTimestamp }: Props) => {
  const { seconds, minutes, hours, days, isRunning } = useTimer({
    expiryTimestamp,
    autoStart: true,
  })

  return (
    <>
      {isRunning ? (
        <div className="type-4xl-bold dark:text-white">
          <span>{days}</span>D. <span>{zeroPad(hours)}</span>:<span>{zeroPad(minutes)}</span>:
          <span>{zeroPad(seconds)}</span>
        </div>
      ) : (
        <div className="type-4xl-bold text-spaceship-countdown">Campaign Finished</div>
      )}
    </>
  )
}

export default Countdown
