import Script from 'next/script'

type Props = {
  googleTagManagerId: string
  googleTagManagerEnvParam: string
}

export const GoogleTagManager = ({ googleTagManagerId, googleTagManagerEnvParam }: Props) => {
  return (
    <>
      {/* Google Tag Manager - Global base code */}
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '${googleTagManagerEnvParam}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${googleTagManagerId}');
          `,
        }}
        id="google-tag-manager"
      />
    </>
  )
}
