import { atom } from 'jotai'

export const vaultDecimalsAtom = atom<number | null>(null)

type Token0 = {
  decimals: number
  symbol: string
}

export const tokenAtom = atom<Token0 | null>(null)

export const usdceAddressAtom = atom<AddressType | null>(null)
export const needReloadAtom = atom<boolean>(false)
