import InfoIcon from '@apps-orangefi/assets/images/info-icon.svg'
import Image from 'next/image'
import { Tooltip, Position } from 'react-tippy'
import 'react-tippy/dist/tippy.css'

type Props = {
  iconUrl?: string
  tooltipText?: string
  size?: number
  place?: Position
  arrow?: boolean
  className?: string
}

export const IconInfo = ({
  iconUrl,
  tooltipText,
  size,
  place = 'top',
  arrow = true,
  className,
}: Props) => {
  const icon = iconUrl ?? InfoIcon

  return (
    <div className={className}>
      {/* <Tooltip
        title={tooltipText}
        position={place}
        arrow={arrow}
      > */}
      <Tooltip html={<div className="p-2">{tooltipText}</div>} position={place} arrow={arrow}>
        <Image src={icon} width={size} height={size} alt="Info" />
      </Tooltip>
    </div>
  )
}
