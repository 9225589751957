import { useModal } from '@apps-orangefi/hooks'
import { addressLink } from '@apps-orangefi/lib/utils'
import { MODAL_TYPES, AccountModalTypes } from '@apps-orangefi/ui/organisms/modals'
import { useCallback } from 'react'
import { useDisconnect } from 'wagmi'
import { Chain } from 'wagmi/chains'

export const useAccountModal = (
  account: AddressType | undefined,
  chain: Chain | undefined,
  modalType: AccountModalTypes = MODAL_TYPES.AccountModal
) => {
  const { disconnect } = useDisconnect()
  const { showModal, hideModal } = useModal()

  const openAccountModal = useCallback(() => {
    showModal({
      modalType,
      modalProps: {
        handleClose: hideModal,
        handleDisconnect: () => {
          disconnect()
          setTimeout(() => hideModal(), 150)
        },
        handleCopyAccount: () => {
          if (!!account) {
            navigator.clipboard.writeText(account)
          }
        },
        handleExplore: () => {
          if (!!account) {
            const url = addressLink(chain, account)
            window.open(url, '_blank')
          }
        },
      },
    })
  }, [account, JSON.stringify(chain)])

  return {
    openAccountModal,
  }
}
