import { VaultInfoList, productKey } from '@apps-orangefi/lib/types'
import { zeroAddress } from 'viem'
import { arbitrum } from 'wagmi/chains'

export const CONTRACT_ADDRESSES: VaultInfoList = {
  [arbitrum.id]: [
    {
      key: productKey.CamelotUsdcDaiVault,
      VAULT_ADDRESS: '0xd6ecEb3978bf2b76958b96E8A207246b98C7d639',
    },
    {
      key: productKey.CamelotUsdcUsdceVault,
      VAULT_ADDRESS: '0x59671Aa8F4E5adCcb26f4c88F0d6047B0ae7620b',
    },
    {
      key: productKey.CamelotUsdceUsdtVault,
      VAULT_ADDRESS: '0x0B48540e214bc196e1D0954834876691fE19068D',
    },
    {
      key: productKey.CamelotWstethWethVault,
      VAULT_ADDRESS: '0x738d72b8ed405de32b9bc74065d119534018c6d7',
    },
    {
      key: productKey.CamelotWeethWethVault,
      VAULT_ADDRESS: '0xCF3cC7EF67C24cB2B8536533A0376f1f0F62aF5F',
    },
    {
      key: productKey.CamelotEzethWethVault,
      VAULT_ADDRESS: '0x5F5953f974F47f3cbCb60A34AD713eB8786861E2',
    },
    {
      key: productKey.StrykeWethUsdcVault,
      VAULT_ADDRESS: '0x77826C01303Bed9DE74d275dEA1179ed36D7455F',
    },
    {
      key: productKey.StrykeWbtcUsdcVault,
      VAULT_ADDRESS: '0x853506E93F24105820F353215793ca84993e9d2A',
    },
    {
      key: productKey.StrykeArbUsdcVault,
      VAULT_ADDRESS: '0x677285525032F9F484F742d35bC51B62b0370284',
    },
    {
      key: productKey.StrykeBoopWethVault,
      VAULT_ADDRESS: '0x7cDAC80d07c135c87634a79E78042f8dAAbb0DC8',
    },
    {
      key: productKey.NoswapStrykeWethUsdcVault,
      VAULT_ADDRESS: '0x2215EdeF1D5bD770909f049A8B8C7DD4D93708d1',
    },
    {
      key: productKey.StrykePancakeWethUsdcVault,
      VAULT_ADDRESS: '0x4FF5Ce3456Ed2160B22afD9c4aeaF4Ce3C46A5F7',
    },
    {
      key: productKey.StrykePancakeWbtcUsdcVault,
      VAULT_ADDRESS: '0x311c79F1e1BB2Da477a312e9137e86fDe82a905C',
    },
    {
      key: productKey.StrykePancakeArbUsdcVault,
      VAULT_ADDRESS: '0x2e44e5c3C26705cbce48aE998AE8361Bbb354214',
    },
    {
      key: productKey.StrykeSushiWethUsdcVault,
      VAULT_ADDRESS: '0xee39236D16721094bCf3DC934C3F59aB5754e344',
    },
    {
      key: productKey.StrykeSushiWbtcUsdcVault,
      VAULT_ADDRESS: '0x7D1f14d4cB116e698290B5E9520377A59a7A030B',
    },
    {
      key: productKey.StrykeSushiArbUsdcVault,
      VAULT_ADDRESS: '0x3561a6e1891dFf6B1d8a46F20B5605228839257b',
    },
  ],
}

export const STRYKE_VAULT_INSPECTOR_ADDRESS: AddressType =
  '0x658eacB79721ba941CB7D87E306d4F751c5cC47E'

export const RESERVE_PROXY_ADDRESS: AddressType = '0xb1ac04Ad57bDC6FA5649295682590E0922bD0b4a'

export const SPACESHIP_DISTRIBUTOR_ADDRESS: AddressType | undefined = undefined

export const ASTRONAUT_NFT_ADDRESS: AddressType = zeroAddress
export const ASTRONAUT_PROXY_ADDRESS: AddressType = zeroAddress

export const STRYKE_DISTRIBUTOR_ADDRESS: AddressType = '0x9451A017a8532C2966248FaecFC2ab9449B41AD6'
