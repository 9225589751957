import axios from 'axios'

type FetchDuneQueryKey = {
  queryKey: [string, { chainId: number | undefined; timestamp: number | undefined }] // timestamp is seconds
}
type FindBlockResult = {
  hash: string
  number: number
  timestamp: number
  parentHash: string
}

export const fetchBlockNumber = async ({ queryKey }: FetchDuneQueryKey) => {
  const [_, { chainId, timestamp }] = queryKey
  if (chainId === undefined || timestamp === undefined) {
    return undefined
  }
  const url = `https://api.findblock.xyz/v1/chain/${chainId}/block/after/${timestamp}`
  const res = await axios.get<FindBlockResult>(url)

  return res.data
}
