import SykIcon from '@apps-orangefi/assets/images/tokens/syk.svg'
import XSykIcon from '@apps-orangefi/assets/images/tokens/xsyk.svg'
import { BN } from '@apps-orangefi/lib'
import { TokenPair } from '@apps-orangefi/ui/atoms'
import { twMerge } from 'tailwind-merge'

type Props = {
  rewardAPR: BN
  className?: string
}

export const StrykeAPR = ({ rewardAPR, className = '' }: Props) => {
  return (
    <div className={twMerge('flex flex-row dark:bg-gray-750 rounded p-1', className)}>
      <TokenPair token0IconUrl={SykIcon} token1IconUrl={XSykIcon} size={16} />
      <span className="type-sm-semibold dark:text-white font-mono ml-5">
        {rewardAPR.toFixed(2)}%
      </span>
    </div>
  )
}
