import { StepState, stepState } from '@apps-orangefi/lib/types'

type StepProps = {
  step: number
  label: string
  state: StepState
}

const Step = ({ step, label, state }: StepProps) => {
  const [stepStyle, labelStyle] =
    state === stepState.Pending
      ? ['dark:text-gray-500 dark:bg-gray-750', 'dark:text-gray-400']
      : state === stepState.Active
      ? ['dark:text-white dark:bg-orange-500', 'dark:text-white']
      : ['dark:text-gray-1000 bg-emerald-400', 'dark:text-white']

  return (
    <div className="flex flex-row type-base-semibold items-center">
      <div
        className={`flex flex-col justify-center w-10 h-10 rounded-full text-center ${stepStyle}`}
      >
        {step}
      </div>
      <div className={`ml-4 ${labelStyle}`}>{label}</div>
    </div>
  )
}

type Props = {
  steps: StepProps[]
}
export const UnlockSteps = ({ steps }: Props) => {
  return (
    <div className="flex flex-col md:pr-8 lg:flex-row lg:flex-wrap xl:[&>*:nth-child(even)]:ml-6 gap-y-4 xl:gap-y-6">
      {steps.map((step, index) => (
        <Step key={index} {...step} />
      ))}
    </div>
  )
}
