import ChevronRight from '@apps-orangefi/assets/images/point/chevron-right-orange.svg'
import { OctagonContainer } from '@apps-orangefi/ui/atoms/point/OctagonContainer'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'

const tailwindConfig = require('@apps-orangefi/tailwindcss')

type Props = {
  label?: string | JSX.Element
  disabled?: boolean
  onSubmit?: (e?: Event) => void
  type?: 'lock' | 'unlock' | 'extend'
  className?: string
}

export const LockButton = ({
  label,
  disabled = false,
  onSubmit,
  type = 'lock',
  className,
}: Props) => {
  const [borderColor, bgColor] =
    type === 'lock'
      ? ['white', undefined]
      : type === 'unlock'
      ? [tailwindConfig.theme.extend.colors.gray[800], tailwindConfig.theme.extend.colors.gray[900]]
      : ['transparent', 'transparent']

  const shadow = type === 'lock' ? 'link-shadow' : ''

  return (
    <OctagonContainer
      size={3}
      borderColor={borderColor}
      bgColor={bgColor}
      className={twMerge(
        'flex flex-col items-center justify-center h-fit w-fit type-base-bold hover:cursor-pointer',
        className
      )}
    >
      <button
        onClick={() => {
          onSubmit && onSubmit()
        }}
        disabled={disabled}
        className={twMerge(
          'flex flex-row w-full h-full px-7 py-4 justify-center items-center',
          shadow
        )}
      >
        {label}
      </button>
    </OctagonContainer>
  )
}
