import { Chain } from 'wagmi/chains'

export const getBlockExplorer = (chain: Chain | undefined) => {
  if (!chain?.blockExplorers) return undefined
  return chain.blockExplorers['etherscan'] ?? chain.blockExplorers.default
}

export const txLink = (chain: Chain | undefined, tx: AddressType): string => {
  const blockExplorer = getBlockExplorer(chain)
  if (!blockExplorer) return ''
  return `${blockExplorer.url}/tx/${tx}`
}

export const addressLink = (chain: Chain | undefined, address: AddressType): string => {
  const blockExplorer = getBlockExplorer(chain)
  if (!blockExplorer) return ''
  return `${blockExplorer.url}/address/${address}`
}
