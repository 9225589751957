import { BN } from '@apps-orangefi/lib'
import axios from 'axios'

type PairResult = {
  pair: {
    chainId: string
    dexId: string
    url: string
    pairAddress: string
    baseToken: {
      address: string
      name: string
      symbol: string
    }
    quoteToken: {
      address: string
      name: string
      symbol: string
    }
    priceNative: BN
    priceUsd: BN
  }
}

export const fetchSYKPair = async () => {
  const url = `https://api.dexscreener.com/latest/dex/pairs/arbitrum/0xe83b6714f7b8d94187d8457592ce6fcf82453cf4`
  const res = await axios.get<PairResult>(url, {})

  return {
    ...res.data.pair,
    priceNative: new BN(res.data.pair.priceNative),
    priceUsd: new BN(res.data.pair.priceUsd),
  }
}
