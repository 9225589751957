import ArrorUpRight from '@apps-orangefi/assets/images/arrow-up-right.svg'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'

type Props = {
  label: string
  href: string
  className?: string
}

export const LinkButton = ({ label, href, className }: Props) => {
  return (
    <>
      <a href={href} target="_blank" rel="noreferrer" className={twMerge(className)}>
        <div className="flex flex-row justify-between dark:bg-gray-800 rounded-lg py-2 px-4 w-fit">
          <span>{label}</span>
          <Image src={ArrorUpRight} alt={label} width={16} height={16} className="ml-2" />
        </div>
      </a>
    </>
  )
}
