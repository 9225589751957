import { DefaultOptions, MutationCache, QueryCache } from '@tanstack/react-query'
import axios from 'axios'
import { StatusCodes } from 'http-status-codes'
const RETRY_COUNT = 3

export const queryConfig: DefaultOptions = {
  queries: {
    // do not retry ic 4xx error. Retry 3 times in 5xx error
    retry: (failureCount, error) => {
      if (failureCount === RETRY_COUNT) return false
      if (
        axios.isAxiosError(error) &&
        error.response?.status &&
        error.response?.status < StatusCodes.INTERNAL_SERVER_ERROR
      ) {
        return false
      }
      return true
    },
  },
}

export const queryCache = new QueryCache({
  onError: error => {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response?.status === StatusCodes.NOT_FOUND) {
        // Handle 404 error
        console.error('Resource not found')
      } else if (error.response?.status >= StatusCodes.INTERNAL_SERVER_ERROR) {
        // Handle 500+ errors
        console.error('Server error')
      }
    } else {
      console.error('An unexpected error occurred')
    }
  },
})

export const mutationCache = new MutationCache({
  onError: error => {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response?.status === StatusCodes.NOT_FOUND) {
        console.error('Resource not found')
      } else if (error.response?.status >= StatusCodes.INTERNAL_SERVER_ERROR) {
        console.error('Server error')
      }
    } else {
      console.error('An unexpected error occurred')
    }
  },
})
