import { useDebounce } from '@apps-orangefi/hooks'
import { useInvalidateQueries, useGetInvalidateQueries } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { bigintToBN } from '@apps-orangefi/lib/utils'
import { useReadContractsWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { isNil } from 'lodash'
import { useState, useEffect } from 'react'
import { erc20Abi } from 'viem'

import '@apps-orangefi/lib/extensions'

const BLOCK_INTERVAL = 150

type Erc20TokenBalance = {
  balance: BN
  decimals: number
  symbol: string
}

type Props = {
  account: AddressType | undefined
  tokenAddress: AddressType | undefined
  watch?: boolean
}

const useTokenBalance = ({ account, tokenAddress, watch = false }: Props) => {
  const [tokenBalance, setTokenBalance] = useState<Erc20TokenBalance | undefined>(undefined)

  const enabled = useDebounce(!!account && !!tokenAddress, 300)

  const vaultContract = {
    address: tokenAddress!,
    abi: erc20Abi,
  }

  const { data, isFetching, isError, queryKey } = useReadContractsWithErrorHandling({
    contracts: [
      {
        ...vaultContract,
        functionName: 'balanceOf',
        args: [account!],
      },
      {
        ...vaultContract,
        functionName: 'decimals',
      },
      {
        ...vaultContract,
        functionName: 'symbol',
      },
    ],
    query: {
      enabled,
    },
  })
  useInvalidateQueries({ queryKey, blockInterval: BLOCK_INTERVAL })
  const { refresh } = useGetInvalidateQueries({ queryKey })

  useEffect(() => {
    if (data) {
      const decimals = data[1].result as number
      const balance = bigintToBN(data[0].result as bigint | undefined).pow10ofMinus(decimals)
      const symbol = data[2].result as string

      if (isNil(decimals) || isNil(balance)) {
        return
      }
      setTokenBalance({
        balance,
        decimals,
        symbol,
      })
    }
  }, [data])

  return { data: tokenBalance, isFetching, isError, refresh }
}

export default useTokenBalance
