import { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'

export const useIsSafeWallet = () => {
  const [isSafeWallet, setIsSafeWallet] = useState<boolean | null>(null)
  const { connector } = useAccount()

  useEffect(() => {
    if (connector?.id === 'safe') {
      setIsSafeWallet(true)
    } else {
      setIsSafeWallet(false)
    }
  }, [connector])

  return { isSafeWallet }
}
