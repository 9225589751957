import { UnlockForm, type UnlockProps } from '@apps-orangefi/ui/molecules/point/forms'

import { LockModalWrapper } from './ModalWrapper'

export type UnlockModalProps = UnlockProps & {
  handleClose: () => void
}

export const UnlockModal = (props: UnlockModalProps) => {
  return (
    <LockModalWrapper mode="unlock" handleClose={props.handleClose}>
      <UnlockForm {...props} />
    </LockModalWrapper>
  )
}
