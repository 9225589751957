import { chainLogos } from '@apps-orangefi/ui/atoms'
import { Transition, Menu } from '@headlessui/react'
import { map } from 'lodash'
import { ChevronDown } from 'lucide-react'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'
import { Chain } from 'wagmi/chains'

type Props = {
  supportedChains: { [key: string]: Chain }
  selectedChain: Chain | undefined
  onSelectChain: (chain: Chain) => void
  className?: string
}

export const ChainSelector = ({
  supportedChains,
  selectedChain,
  onSelectChain,
  className,
}: Props) => {
  if (!selectedChain) {
    return <></>
  }

  return (
    <Menu as="div" className={twMerge('relative', className)}>
      <Menu.Button className="flex flex-row items-center dark:bg-gray-850 py-3 px-4 rounded-2xl">
        <Image src={chainLogos[selectedChain.id]} alt={selectedChain.name} className="w-6 h-6" />
        <ChevronDown size={16} className="ml-2" />
      </Menu.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="flex flex-col absolute py-3 px-4 right-0 mt-2 origin-top-right dark:bg-gray-850 rounded-md shadow-lg ring-1 ring-black/5 focus:outline-none">
          {map(supportedChains, (chain, network) => (
            <Menu.Item
              as="div"
              className="my-1"
              onClick={() => {
                if (!!onSelectChain) {
                  onSelectChain(chain)
                }
              }}
              key={chain.id}
            >
              <Image src={chainLogos[chain.id]} alt={chain.name} className="w-6 h-6" />
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
