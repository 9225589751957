import { blockNumberAtom } from '@apps-orangefi/lib/store/block'
import { useSetAtom } from 'jotai'
import { useWatchBlockNumber } from 'wagmi'

export const useUpdateBlockNumber = (chainId: number, enabled: boolean) => {
  const setBlockNumber = useSetAtom(blockNumberAtom)
  useWatchBlockNumber({
    chainId,
    pollingInterval: 15000,
    enabled,
    onBlockNumber: blockNumber => {
      setBlockNumber(blockNumber)
    },
  })
}
