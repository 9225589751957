import { BN } from '@apps-orangefi/lib'
import { ClaimStatus, MyRank } from '@apps-orangefi/lib/types'
import { numberWithShortScale } from '@apps-orangefi/lib/utils'
import { Button, ArbReward } from '@apps-orangefi/ui/atoms'
import { IconInfo } from '@apps-orangefi/ui/molecules/icons'
import dayjs, { Dayjs } from 'dayjs'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'

type Props = {
  myRank: MyRank | undefined
  claimStatus: ClaimStatus
  milestones: { value: BN; reward: number }[]
  mileStone1AvgReward: number
  campaignEnd: Dayjs
  onSubmit: () => void
  onConnect: () => void
  isConnected: boolean
}

export const ClaimReward = ({
  myRank,
  claimStatus,
  milestones,
  mileStone1AvgReward,
  campaignEnd,
  onSubmit,
  onConnect,
  isConnected,
}: Props) => {
  const { t } = useTranslation()
  const [mounted, setMounted] = useState(false)

  const isCampaignEnd = dayjs().isAfter(campaignEnd)
  const milestone1Reward = myRank && myRank.isAchievedMilestone1 ? mileStone1AvgReward : 0

  const milestone2Reward =
    myRank && myRank.isAchievedMilestone2
      ? myRank.isAchievedMilestone1
        ? myRank.totalReward.minus(mileStone1AvgReward).toNumber()
        : myRank.totalReward.toNumber()
      : 0

  const claimEnd = claimStatus.endTime
    ? dayjs(claimStatus.endTime).utc().format('MMM D, YYYY HH:mm')
    : null
  const isShowReward = myRank && myRank.isRewardDistributed && isCampaignEnd

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <div className="flex flex-col">
      <div className="type-2xl-semibold dark:text-white">{t('MODEL.CLAIM.TITLE')}</div>
      <div className="flex flex-col p-4 mt-4 dark:bg-gray-850 rounded-2xl">
        <div className="flex flex-row justify-between pb-3">
          <div className="type-base-medium dark:text-white flex flex-row items-center">
            {t('MODEL.CLAIM.RANK')}
            <IconInfo
              size={16}
              tooltipText={t('MODEL.CLAIM.RANKING_NOTICE')}
              place="bottom"
              className="ml-1"
            />
          </div>
          <div className="type-base-semibold dark:text-white">
            {myRank ? `#${myRank.rank}` : '-'}
          </div>
        </div>
        <div className="flex flex-row justify-between items-center py-3 border-t border-gray-800">
          <div className="type-base-medium dark:text-white w-1/2 md:w-fit">
            {t('MODEL.CLAIM.MILESTONE1', {
              target: numberWithShortScale(milestones[0].value, 0),
              reward: numberWithShortScale(new BN(milestones[0].reward), 0),
            })}
          </div>
          <div className="type-base-semibold dark:text-white">
            {myRank && myRank.isAchievedMilestone1 ? (
              <>{isShowReward ? <ArbReward reward={milestone1Reward} /> : 'Qualified'}</>
            ) : (
              'Unqualified'
            )}
          </div>
        </div>
        <div className="flex flex-row justify-between items-center py-3 border-t border-gray-800">
          <div className="type-base-medium dark:text-white w-1/2 md:w-fit">
            {t('MODEL.CLAIM.MILESTONE2', {
              target: numberWithShortScale(milestones[1].value, 0),
              reward: numberWithShortScale(new BN(milestones[1].reward - milestones[0].reward), 0),
            })}
          </div>
          <div className="type-base-semibold dark:text-white">
            {myRank && myRank.isAchievedMilestone2 ? (
              <>{isShowReward ? <ArbReward reward={milestone2Reward} /> : 'Qualified'}</>
            ) : (
              'Unqualified'
            )}
          </div>
        </div>
        {isShowReward && (
          <div className="flex flex-row justify-between py-3 border-t border-gray-800">
            <div className="type-base-medium dark:text-white">{t('MODEL.CLAIM.TOTAL')}</div>
            <div className="type-base-semibold dark:text-white">
              {myRank.isAchievedMilestone1 || myRank.isAchievedMilestone2 ? (
                <ArbReward reward={milestone1Reward + milestone2Reward} />
              ) : (
                '-'
              )}
            </div>
          </div>
        )}
        {mounted && isConnected && isShowReward && !claimStatus.isExpired && (
          <Button
            label={claimStatus.isClaimed ? t('WIDGET.ACTION.CLAIMED') : t('WIDGET.ACTION.CLAIM')}
            onSubmit={onSubmit}
            disabled={!isCampaignEnd || claimStatus.isExpired || claimStatus.isClaimed}
            className="w-full"
          />
        )}
        {mounted && !isConnected && (
          <Button
            onSubmit={onConnect}
            className="self-center w-full mt-2 px-6 py-3 md:py-4 rounded-xl md:rounded-2xl"
            label={t('WIDGET.ACTION.CONNECT')}
          />
        )}
        {isCampaignEnd && claimEnd && (
          <div className="flex flex-col items-center">
            <div className="flex flex-row justify-center type-base-medium dark:text-gray-300 mt-4">
              <IconInfo />
              <span className="ml-2">4-week claiming period</span>
            </div>
            <div>Claim end: {claimEnd}</div>
          </div>
        )}
      </div>
    </div>
  )
}
