import Image from 'next/image'
import { twMerge } from 'tailwind-merge'

type Props = {
  label: string
  logoUrl?: any
  isActive: boolean
  onClick: () => void
  hideLabel?: boolean
  className?: string
}

export const SwitchButton = ({
  label,
  logoUrl,
  isActive,
  onClick,
  hideLabel = false,
  className,
}: Props) => {
  const classStr = isActive ? 'border border-orange-500' : 'dark:bg-dark-800'

  return (
    <button
      onClick={() => onClick()}
      className={twMerge(
        `type-base-medium dark:text-white rounded-2xl mr-4 px-4 py-3 outline-none ${classStr} ${className}`
      )}
    >
      <div className="flex flex-row justify-center">
        {logoUrl && <Image src={logoUrl} alt={label} className="w-6 h-6 rounded-full" />}
        {!hideLabel && <span className={!!logoUrl ? 'ml-2' : ''}>{label}</span>}
      </div>
    </button>
  )
}
