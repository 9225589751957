/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query getDopexStrikeList($tokenIds: [ID!]!) {\n    strikes(where: { id_in: $tokenIds }, orderBy: tickLower, orderDirection: desc, first: 1000) {\n      id\n      handler\n      pool\n      hook\n      tickLower\n      tickUpper\n      token0 {\n        id\n        symbol\n        decimals\n      }\n      token1 {\n        id\n        symbol\n        decimals\n      }\n    }\n  }\n": types.GetDopexStrikeListDocument,
    "\n  query getDopexLpPositionsList($userIds: [String!]!) {\n    lppositions(where: { user_in: $userIds, shares_gt: \"1\" }, first: 1000) {\n      id\n      pool\n      hook\n      shares\n      initialLiquidity\n      user\n      handler\n      strike {\n        id\n        pool\n        tickLower\n        tickUpper\n        totalLiquidity\n        usedLiquidity\n        totalShares\n      }\n    }\n  }\n": types.GetDopexLpPositionsListDocument,
    "\n  query getDopexDailyStrikeEarningsList(\n    $tokenIds: [ID!]!\n    $tokenIdsCount: Int!\n    $startTime: BigInt!\n  ) {\n    dailyDonations(\n      where: { strike_: { id_in: $tokenIds }, donation_gt: \"0\", start_gte: $startTime }\n      orderBy: start\n      orderDirection: desc\n      first: $tokenIdsCount\n    ) {\n      id\n      donation\n      sqrtPriceX96\n      start\n      strike {\n        id\n      }\n    }\n    dailyFeeCompounds(\n      where: { strike_: { id_in: $tokenIds }, compound_gt: \"0\", start_gte: $startTime }\n      orderBy: start\n      orderDirection: desc\n      first: $tokenIdsCount\n    ) {\n      id\n      compound\n      sqrtPriceX96\n      start\n      strike {\n        id\n      }\n    }\n  }\n": types.GetDopexDailyStrikeEarningsListDocument,
    "\n  query getStrykeLiquidityList($tokenIds: [ID!]!, $skip: Int) {\n    strikes(\n      where: { id_in: $tokenIds }\n      orderBy: tickLower\n      orderDirection: desc\n      first: 1000\n      skip: $skip\n    ) {\n      id\n      handler\n      pool\n      hook\n      reservedLiquidity\n      usedLiquidity\n      totalLiquidity\n      tickLower\n      tickUpper\n      token0 {\n        id\n        symbol\n        decimals\n      }\n      token1 {\n        id\n        symbol\n        decimals\n      }\n    }\n  }\n": types.GetStrykeLiquidityListDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getDopexStrikeList($tokenIds: [ID!]!) {\n    strikes(where: { id_in: $tokenIds }, orderBy: tickLower, orderDirection: desc, first: 1000) {\n      id\n      handler\n      pool\n      hook\n      tickLower\n      tickUpper\n      token0 {\n        id\n        symbol\n        decimals\n      }\n      token1 {\n        id\n        symbol\n        decimals\n      }\n    }\n  }\n"): (typeof documents)["\n  query getDopexStrikeList($tokenIds: [ID!]!) {\n    strikes(where: { id_in: $tokenIds }, orderBy: tickLower, orderDirection: desc, first: 1000) {\n      id\n      handler\n      pool\n      hook\n      tickLower\n      tickUpper\n      token0 {\n        id\n        symbol\n        decimals\n      }\n      token1 {\n        id\n        symbol\n        decimals\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getDopexLpPositionsList($userIds: [String!]!) {\n    lppositions(where: { user_in: $userIds, shares_gt: \"1\" }, first: 1000) {\n      id\n      pool\n      hook\n      shares\n      initialLiquidity\n      user\n      handler\n      strike {\n        id\n        pool\n        tickLower\n        tickUpper\n        totalLiquidity\n        usedLiquidity\n        totalShares\n      }\n    }\n  }\n"): (typeof documents)["\n  query getDopexLpPositionsList($userIds: [String!]!) {\n    lppositions(where: { user_in: $userIds, shares_gt: \"1\" }, first: 1000) {\n      id\n      pool\n      hook\n      shares\n      initialLiquidity\n      user\n      handler\n      strike {\n        id\n        pool\n        tickLower\n        tickUpper\n        totalLiquidity\n        usedLiquidity\n        totalShares\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getDopexDailyStrikeEarningsList(\n    $tokenIds: [ID!]!\n    $tokenIdsCount: Int!\n    $startTime: BigInt!\n  ) {\n    dailyDonations(\n      where: { strike_: { id_in: $tokenIds }, donation_gt: \"0\", start_gte: $startTime }\n      orderBy: start\n      orderDirection: desc\n      first: $tokenIdsCount\n    ) {\n      id\n      donation\n      sqrtPriceX96\n      start\n      strike {\n        id\n      }\n    }\n    dailyFeeCompounds(\n      where: { strike_: { id_in: $tokenIds }, compound_gt: \"0\", start_gte: $startTime }\n      orderBy: start\n      orderDirection: desc\n      first: $tokenIdsCount\n    ) {\n      id\n      compound\n      sqrtPriceX96\n      start\n      strike {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query getDopexDailyStrikeEarningsList(\n    $tokenIds: [ID!]!\n    $tokenIdsCount: Int!\n    $startTime: BigInt!\n  ) {\n    dailyDonations(\n      where: { strike_: { id_in: $tokenIds }, donation_gt: \"0\", start_gte: $startTime }\n      orderBy: start\n      orderDirection: desc\n      first: $tokenIdsCount\n    ) {\n      id\n      donation\n      sqrtPriceX96\n      start\n      strike {\n        id\n      }\n    }\n    dailyFeeCompounds(\n      where: { strike_: { id_in: $tokenIds }, compound_gt: \"0\", start_gte: $startTime }\n      orderBy: start\n      orderDirection: desc\n      first: $tokenIdsCount\n    ) {\n      id\n      compound\n      sqrtPriceX96\n      start\n      strike {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getStrykeLiquidityList($tokenIds: [ID!]!, $skip: Int) {\n    strikes(\n      where: { id_in: $tokenIds }\n      orderBy: tickLower\n      orderDirection: desc\n      first: 1000\n      skip: $skip\n    ) {\n      id\n      handler\n      pool\n      hook\n      reservedLiquidity\n      usedLiquidity\n      totalLiquidity\n      tickLower\n      tickUpper\n      token0 {\n        id\n        symbol\n        decimals\n      }\n      token1 {\n        id\n        symbol\n        decimals\n      }\n    }\n  }\n"): (typeof documents)["\n  query getStrykeLiquidityList($tokenIds: [ID!]!, $skip: Int) {\n    strikes(\n      where: { id_in: $tokenIds }\n      orderBy: tickLower\n      orderDirection: desc\n      first: 1000\n      skip: $skip\n    ) {\n      id\n      handler\n      pool\n      hook\n      reservedLiquidity\n      usedLiquidity\n      totalLiquidity\n      tickLower\n      tickUpper\n      token0 {\n        id\n        symbol\n        decimals\n      }\n      token1 {\n        id\n        symbol\n        decimals\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;