import { useTx, useModal } from '@apps-orangefi/hooks'
import { Tx, txListAtom, txStatus } from '@apps-orangefi/lib/store'
import { stakingAddressAtom, needRefetchAtom } from '@apps-orangefi/lib/store/point'
import { LockPosition } from '@apps-orangefi/lib/types/point'
import { getTimeBoost } from '@apps-orangefi/lib/utils/point'
import { useExtendLock } from '@apps-orangefi/wagmi/hooks/point'
import dayjs, { Dayjs } from 'dayjs'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useCallback, useEffect, useState } from 'react'

const txExtendLockDefault: Tx = {
  title: 'Extend Lock',
  hash: undefined,
  status: txStatus.Wait,
}
const txExtendLockAtom = atom<Tx>(txExtendLockDefault)

export const useExtendForm = ({
  lockPosition,
  dailyEarnings,
  onOpenTxModal,
}: {
  lockPosition: LockPosition
  dailyEarnings: number
  onOpenTxModal: () => void
}) => {
  const [txList, setTxList] = useAtom(txListAtom)
  const stakingAddress = useAtomValue(stakingAddressAtom)

  const [lockDays, setLockDays] = useState(lockPosition.lockDuration)
  const [unlockDate, setUnlockDate] = useState<Dayjs>(lockPosition.unlockAt)
  const [estimateEarnings, setEstimateEarnings] = useState<number>(0)
  const setNeedRefetch = useSetAtom(needRefetchAtom)

  const {
    tx: txExtendLock,
    setTx: setTxExtendLock,
    moveToPending: txExtendLockPending,
    moveToError: txExtendLockError,
    moveToSuccess: txExtendLockSuccess,
  } = useTx(txExtendLockAtom)

  const resetTx = () => {
    if (txList.length !== 0) return
    setTxExtendLock(txExtendLockDefault)
  }

  const initTxList = () => {
    if (txList.length === 0) {
      setTxList([txExtendLockAtom])
    }
  }

  const extendLock = useExtendLock(stakingAddress, lockPosition.id, lockDays * 24 * 60 * 60, {
    success: () => {
      txExtendLockSuccess()
      setNeedRefetch(true)
    },
    fail: txExtendLockError,
  })

  // tx status
  useEffect(() => {
    if (!extendLock.hash || !!txExtendLock.hash) return
    setTxExtendLock(prev => {
      return { ...prev, hash: extendLock.hash }
    })
  }, [extendLock.hash])

  useEffect(() => {
    if (txList.length === 0) return
    if (!extendLock.isReady) return
    if (txExtendLock.status === txStatus.Wait) {
      txExtendLockPending()
      extendLock.onExtendLock()
    }
  }, [txList, txExtendLock, extendLock.isReady, extendLock.onExtendLock])

  // -----

  const onChangeLockDuration = useCallback((lockDays: number) => {
    setLockDays(lockDays)
  }, [])

  const { hideModal } = useModal()
  const onExtendLock = useCallback(() => {
    resetTx()
    initTxList()
    onOpenTxModal()
  }, [onOpenTxModal])

  useEffect(() => {
    const currentDate = dayjs().tz('GMT')
    const _unlockDate = currentDate.add(lockDays, 'day')
    if (!_unlockDate.isSame(unlockDate) && _unlockDate.isAfter(lockPosition.unlockAt)) {
      setUnlockDate(_unlockDate)
    }
  }, [lockDays])

  useEffect(() => {
    const basePoint = lockPosition.lockAmountUSD.multipliedBy(10)
    const newDailyEarnings =
      dailyEarnings +
      basePoint.toNumber() * (getTimeBoost(lockDays) - getTimeBoost(lockPosition.lockDuration))
    if (newDailyEarnings !== estimateEarnings) {
      setEstimateEarnings(newDailyEarnings)
    }
  }, [dailyEarnings, lockPosition.lockAmountUSD, lockPosition.lockDuration, lockDays])

  return {
    lock: {
      lockDays,
      onChangeLockDuration,
      onExtendLock,
      onCancel: hideModal,
    },
    estimate: {
      unlockDate,
      timeBoost: getTimeBoost(lockDays),
      dailyEarnings: {
        current: dailyEarnings,
        estimate: estimateEarnings,
      },
    },
  }
}
