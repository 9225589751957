import { LinkButton } from '@apps-orangefi/ui/atoms/buttons'
import { Disclosure } from '@headlessui/react'
import Lottie, { LottieOptions } from 'lottie-react'
import { Plus, Minus } from 'lucide-react'
import useTranslation from 'next-translate/useTranslation'
import Image, { StaticImageData } from 'next/image'
import { memo } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  title: string
  description: string
  figureSrc?: StaticImageData
  lottieOptions?: LottieOptions
  readMoreLink?: string
  className?: string
}

export const Feature = memo(function Feature({
  title,
  description,
  figureSrc,
  lottieOptions,
  readMoreLink,
  className,
}: Props) {
  const { t } = useTranslation()

  return (
    <div className={twMerge('w-full rounded-2xl', className)}>
      <Disclosure>
        {({ open }) => (
          <>
            <div className="flex flex-col w-full rounded-2xl dark:bg-gray-850 p-5">
              <div className="flex flex-row w-full justify-between">
                <span>{title}</span>
                <Disclosure.Button>
                  {open ? (
                    <Minus className="h-6 w-6 dark:text-white" />
                  ) : (
                    <Plus className="h-6 w-6 dark:text-white" />
                  )}
                </Disclosure.Button>
              </div>
              {open && (
                <>
                  <div className="mt-4 type-base-medium">{description}</div>
                  {!!readMoreLink && (
                    <LinkButton
                      label={t('DOCS.ACTION.READ_MORE')}
                      href={readMoreLink}
                      className="mt-5"
                    />
                  )}
                </>
              )}
            </div>
            <Disclosure.Panel className="pt-1 text-sm dark:text-gray-500">
              {!!figureSrc ? (
                <Image src={figureSrc} alt={title} />
              ) : (
                <>
                  {!!lottieOptions && (
                    <div className="w-full dark:bg-gray-850 rounded-2xl">
                      <Lottie {...lottieOptions} />
                    </div>
                  )}
                </>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
})
