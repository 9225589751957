import CopyIcon from '@apps-orangefi/assets/images/point/copy.svg'
import { OctagonContainer } from '@apps-orangefi/ui/atoms/point'
import Image from 'next/image'
import { useCallback, useState } from 'react'

const tailwindConfig = require('@apps-orangefi/tailwindcss')

type Props = {
  linkUrl: string | undefined
}

export const ReferralLink = ({ linkUrl }: Props) => {
  const [showCopied, setShowCopied] = useState(false)
  const borderColor = `linear-gradient(to right, ${tailwindConfig.theme.extend.colors.point.orange} 5%, rgba(95, 99, 111, 0.1) 60%)`

  const onClipboardCopy = useCallback(() => {
    if (!linkUrl) return
    navigator.clipboard.writeText(linkUrl)

    setShowCopied(true)
    setTimeout(() => {
      setShowCopied(false)
    }, 2000)
  }, [linkUrl])

  return (
    <OctagonContainer
      size={4}
      borderColor={borderColor}
      className="h-[54px] flex flex-col justify-center"
    >
      <div className="referral-box h-[50px] w-full flex flex-row justify-between relative">
        {linkUrl ? (
          <div className="type-base-semibold sm:type-xl-semibold dark:text-white pl-8 py-3 whitespace-nowrap">
            {linkUrl}
          </div>
        ) : (
          <div className="type-lg-medium pl-8 py-3">
            Your referral code is not generated. Please sign-up.
          </div>
        )}
        <button onClick={onClipboardCopy}>
          <Image src={CopyIcon} alt="copy" width={24} height={24} className="mr-8" />
        </button>
        {showCopied && (
          <div className="type-base-semibold dark:text-white p-4 bg-component absolute -top-12 right-0 rounded-md">
            Copied!
          </div>
        )}
      </div>
    </OctagonContainer>
  )
}
