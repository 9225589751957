import { ExtendForm, type ExtendProps } from '@apps-orangefi/ui/molecules/point/forms'

import { LockModalWrapper } from './ModalWrapper'

export type ExtendModalProps = ExtendProps & {
  handleClose: () => void
}

export const ExtendModal = (props: ExtendModalProps) => {
  return (
    <LockModalWrapper mode="extend" handleClose={props.handleClose}>
      <ExtendForm {...props} />
    </LockModalWrapper>
  )
}
