export const ClipPathOctagon = ({
  children,
  className = '',
  size = 10,
  style = {},
}: {
  children?: React.ReactNode
  className: string
  size?: number
  style?: any
}) => {
  const clipPath = `polygon(
    0px ${size}px, 
    ${size}px 0px, 
    calc(100% - ${size}px) 0px, 
    100% ${size}px, 
    100% calc(100% - ${size}px),
    calc(100% - ${size}px) 100%,
    ${size}px 100%, 
    0px calc(100% - ${size}px)
  )`

  return (
    <div
      className={`${className}`}
      style={{
        clipPath,
        ...style,
      }}
    >
      {children}
    </div>
  )
}
