import { useUnlockForm } from '@apps-orangefi/hooks'
import { LockPosition, Position } from '@apps-orangefi/lib/types/point'
import { TxModal } from '@apps-orangefi/ui/organisms/modals'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useEffect, useState } from 'react'
import { useAccount } from 'wagmi'

import { UnlockModal } from './UnlockModal'

export type UnlockModalContainerProps = {
  position: Position
  lockPosition: LockPosition
  dailyEarnings: number
  handleClose: () => void
}

export const UnlockModalContainer = ({
  position,
  lockPosition,
  dailyEarnings,
  handleClose,
}: UnlockModalContainerProps) => {
  const { t: tPoints } = useTranslation('points')
  const [showTxModal, setShowTxModal] = useState(false)
  const { address: account, chain } = useAccount()

  const onOpenTxModal = useCallback(() => {
    setShowTxModal(true)
  }, [])

  const { lock, estimate } = useUnlockForm({ lockPosition, dailyEarnings, onOpenTxModal })

  useEffect(() => {
    // Reset showTxModal when item changes
    setShowTxModal(false)
  }, [position])

  return (
    <>
      {!showTxModal && (
        <UnlockModal
          position={position}
          lockPosition={lockPosition}
          lock={lock}
          estimate={estimate}
          handleClose={handleClose}
        />
      )}
      {showTxModal && (
        <TxModal
          title={tPoints('MODAL.LOCK.TX')}
          chain={chain}
          handleClose={handleClose}
          messages={[]}
        />
      )}
    </>
  )
}
