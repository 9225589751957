import { ProductType } from '@apps-orangefi/lib/types'
import { useCallback, useState } from 'react'

export const useProductTypeSelector = () => {
  const [selectedProductType, setSelectedProductType] = useState<ProductType | null>(null)

  const onSelectProductType = useCallback((type: ProductType | null) => {
    setSelectedProductType(type)
  }, [])

  return {
    selectedProductType,
    onSelectProductType,
  }
}
