import { BN } from '@apps-orangefi/lib'
import { numberWithShortScale } from '@apps-orangefi/lib/utils'
import { TokenPairBySymbol } from '@apps-orangefi/ui/molecules'
import { twMerge } from 'tailwind-merge'

type Props = {
  tvls: { label: string; value: BN; baseTokenSymbol: string; quoteTokenSymbol: string }[]
  className?: string
}

type StatsProps = {
  label: string
  value: BN
  baseTokenSymbol: string
  quoteTokenSymbol: string
}

const Stats = ({ label, value, baseTokenSymbol, quoteTokenSymbol }: StatsProps) => {
  return (
    <div className="flex flex-row items-start dark:bg-gray-850 rounded-2xl px-4 py-3 md:px-5 md:py-4 w-full max-w-[400px] sm:max-w-[300px] md:max-w-[320px] lg:max-w-[350px]">
      <TokenPairBySymbol
        baseTokenSymbol={baseTokenSymbol}
        quoteTokenSymbol={quoteTokenSymbol}
        size={40}
      />
      <div className="flex flex-col ml-10">
        <div className="type-base-medium">{label}</div>
        <div className="type-xl-semibold dark:text-white font-mono">
          ${numberWithShortScale(value, 2)}
        </div>
      </div>
    </div>
  )
}

export const TVLStats = ({ tvls, className }: Props) => {
  return (
    <div className={twMerge('flex flex-row gap-5 justify-start w-full', className)}>
      {tvls.map((tvl, index) => (
        <Stats
          label={tvl.label}
          value={tvl.value}
          baseTokenSymbol={tvl.baseTokenSymbol}
          quoteTokenSymbol={tvl.quoteTokenSymbol}
          key={`tvl-${index}`}
        />
      ))}
    </div>
  )
}
