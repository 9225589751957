import { txStatus, Tx } from '@apps-orangefi/lib/store'
import { txListAtom } from '@apps-orangefi/lib/store'
import { txLink } from '@apps-orangefi/lib/utils'
import { StatusIcon } from '@apps-orangefi/ui/molecules/icons'
import { BaseModal, TxModalProps } from '@apps-orangefi/ui/organisms/modals'
import { Dialog } from '@headlessui/react'
import { useAtomValue } from 'jotai'
import { useResetAtom } from 'jotai/utils'
import useTranslation from 'next-translate/useTranslation'
import { useCallback } from 'react'
import { Chain } from 'wagmi/chains'

import type { PrimitiveAtom } from 'jotai'

type TxRowItemProps = {
  txAtom: PrimitiveAtom<Tx>
  chain: Chain | undefined
}

const TxRowItem = ({ txAtom, chain }: TxRowItemProps) => {
  const { t } = useTranslation()

  const tx = useAtomValue(txAtom)
  const { title, status, hash, error } = tx

  return (
    <div className="relative w-full">
      <div className="flex flex-row flex-1 items-center justify-between text-body3-md px-4 py-4">
        <div className="text-lg text-brightGray">{title}</div>
        <div className="w-8 h-8 text-center text-lightPrimary">
          <StatusIcon status={status} />
        </div>
      </div>

      <div className="absolute bottom-0.5 right-0">
        {!!hash ? (
          <p className="text-weak text-sm text-right">
            <a href={txLink(chain, hash)} target="_blank" rel="noreferrer noopener">
              {t('MODAL.TX.VIEW_EXPLORER')} &#8599;
            </a>
          </p>
        ) : null}
      </div>
      {status === txStatus.Error && !!error ? (
        <p className="absolute bottom-0.5 left-0 text-rose-300 text-sm text-right text-ellipsis whitespace-nowrap overflow-hidden">
          {error}
        </p>
      ) : null}
    </div>
  )
}

export const TxAlphaModal = ({ title, chain, handleClose, messages }: TxModalProps) => {
  const txList = useAtomValue(txListAtom)
  const resetTxList = useResetAtom(txListAtom)

  const onClose = useCallback(() => {
    resetTxList()
    handleClose()
  }, [resetTxList, handleClose])
  return (
    <BaseModal onClose={() => onClose()}>
      <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-3xl bg-white px-10 py-14 text-left align-middle shadow-xl transition-all">
        <Dialog.Title as="h3" className="text-style-body1-sb text-center">
          {title}
        </Dialog.Title>
        <div className="mt-10 text-style-info">
          <ol className="border-t border-frenchGray alpha-list-number">
            {txList.map((txAtom, i) => (
              <li
                key={`modal-action-${i}`}
                className="border-b border-frenchGray flex items-center"
              >
                <TxRowItem chain={chain} txAtom={txAtom} />
              </li>
            ))}
          </ol>
          {messages && (
            <div className="mt-5 flex flex-col text-style-info">
              {messages.map((message, i) => (
                <div key={`tx-modal-msg-${i}`} className="mt-1">
                  {message}
                </div>
              ))}
            </div>
          )}
        </div>
      </Dialog.Panel>
    </BaseModal>
  )
}
