import { useDebounce } from '@apps-orangefi/hooks'
import { useInvalidateQueries } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { bigintToBN } from '@apps-orangefi/lib/utils'
import { ReadVaultABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractsWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { isNil } from 'lodash'
import { useState, useEffect } from 'react'

import '@apps-orangefi/lib/extensions'

const BLOCK_INTERVAL = 450

const useTokenValue = (vaultAddress: AddressType | undefined, watch: boolean = false) => {
  const [tokenValue, setTokenValue] = useState<BN | undefined>(undefined)
  const [decimals, setDecimals] = useState<number | null>(null)

  const enabled = useDebounce(!!vaultAddress, 300)

  const vaultContract = {
    address: vaultAddress!,
    abi: ReadVaultABI,
  }

  const { data, isFetching, isError, queryKey } = useReadContractsWithErrorHandling({
    contracts: [
      {
        ...vaultContract,
        functionName: 'decimals',
      },
      {
        ...vaultContract,
        functionName: 'totalAssets',
      },
      {
        ...vaultContract,
        functionName: 'totalSupply',
      },
    ],
    query: {
      enabled,
    },
  })

  useInvalidateQueries({ queryKey, blockInterval: BLOCK_INTERVAL })

  useEffect(() => {
    if (data) {
      const _decimals = data[0].result as number
      const totalAssets = bigintToBN(data[1].result as bigint | undefined).pow10ofMinus(_decimals)
      const totalSupply = bigintToBN(data[2].result as bigint | undefined).pow10ofMinus(_decimals)

      if (isNil(_decimals) || isNil(totalAssets) || isNil(totalSupply)) {
        return
      }
      setTokenValue(totalAssets.div(totalSupply))
      setDecimals(_decimals)
    }
  }, [data])

  return { tokenValue, decimals, isFetching, isError }
}

export default useTokenValue
