import { BN } from '@apps-orangefi/lib'
import { StrategyVaultInfo, UserPosition, vaultVersion } from '@apps-orangefi/lib/types'
import { numberWithShortScale } from '@apps-orangefi/lib/utils'
import { Position } from '@apps-orangefi/ui/atoms'
import {
  DepositForm,
  WithdrawForm,
  DepositProps,
  WithdrawProps,
} from '@apps-orangefi/ui/molecules/strategy/forms'
import { Tab } from '@headlessui/react'
import useTranslation from 'next-translate/useTranslation'
import { useMemo } from 'react'

export type WalletProps = {
  onConnect: () => void
  onSwitchChainModal: () => void
  onShowInvalidAddressModal: (e?: Event) => void
  isConnected: boolean
  isActiveChainSupported: boolean
}

export type ContractWidgetProps = {
  deposit: Omit<DepositProps, 'protcolFee' | 'tokenSymbol'>
  withdraw: Omit<WithdrawProps, 'isClosed' | 'tokenSymbol'>
  wallet: WalletProps
  isFetching: boolean
  userPosition: UserPosition | undefined
  isClosed: boolean
  isDeprecatedVault?: boolean
  tokenSymbol: string | undefined
  baseTokenPriceUSD: BN
}

type Props = {
  contractWidgetProps: ContractWidgetProps
  productInfo: StrategyVaultInfo
}

export const ContractWidget = ({
  contractWidgetProps: {
    deposit,
    withdraw,
    wallet,
    isFetching,
    userPosition,
    isClosed,
    isDeprecatedVault,
    tokenSymbol,
    baseTokenPriceUSD,
  },
  productInfo: { info },
}: Props) => {
  const { t } = useTranslation()

  const yourPosition = numberWithShortScale(userPosition?.myPosition.position ?? new BN(0), 4)
  const yourPositionUSD = numberWithShortScale(
    userPosition?.myPosition.position.multipliedBy(baseTokenPriceUSD) ?? new BN(0)
  )

  const tabs = useMemo(() => {
    if (info.version === vaultVersion.LPDfi) {
      return [t('WIDGET.TABS.DEPOSIT'), t('WIDGET.TABS.RESERVE'), t('WIDGET.TABS.WITHDRAW')]
    } else {
      return [t('WIDGET.TABS.DEPOSIT'), t('WIDGET.TABS.WITHDRAW')]
    }
  }, [t, info])

  const protcolFee = useMemo(() => {
    if (info.version === vaultVersion.LPDfi) {
      return { deposit: 0.1, performance: 0 }
    } else {
      return { deposit: 0, performance: 10 }
    }
  }, [info])

  return (
    <div className="flex flex-col gap-0.5 w-full h-fit">
      <div className="flex flex-row justify-between items-center rounded-t-2xl rounded-b dark:bg-gray-850 py-4 px-5">
        <div className="flex flex-col w-full">
          <div className="flex flex-row justify-between w-full items-center">
            <div className="type-lg-semibold dark:text-white">{t('MODEL.USER.YOUR_POSITION')}</div>
            <div className="flex flex-row justify-between items-center">
              <div className="type-lg-semibold dark:text-white font-mono">${yourPositionUSD}</div>
              <div className="type-sm-medium dark:text-gray-400 font-mono ml-3">
                {yourPosition}
                {tokenSymbol && <span className="ml-1.5">{tokenSymbol}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tab.Group defaultIndex={isClosed ? 1 : 0}>
        <Tab.List className="flex flex-row gap-0.5 w-full flex justify-evenly">
          {tabs.map((tab, i) => (
            <Tab
              disabled={(isClosed || isDeprecatedVault) && i === 0}
              className={({ selected }) => {
                const bgClass = selected
                  ? 'dark:bg-gray-800 dark:text-white'
                  : 'dark:bg-gray-850 dark:text-gray-300'
                return `${bgClass} rounded type-base-semibold w-full py-4 focus:outline-none`
              }}
              key={i}
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="w-full dark:bg-gray-850 rounded-t rounded-b-2xl">
          <Tab.Panel>
            <DepositForm
              deposit={{
                ...deposit,
                protcolFee,
                tokenSymbol,
              }}
              wallet={wallet}
            />
          </Tab.Panel>
          <Tab.Panel>
            <WithdrawForm
              withdraw={{
                ...withdraw,
                isClosed,
                tokenSymbol,
                statusKey: 'WITHDRAW',
              }}
              wallet={wallet}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
