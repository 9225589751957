import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

type SignMessageResult = {
  signature: `0x${string}`
  message: string
}

// TODO: wallet 切り替わったらクリア
// キーをwallet addressと紐づけたほうがいいかも
export const signMessageAtom = atomWithStorage<SignMessageResult | null>('signMessageResult', null)

export const stakingAddressAtom = atom<AddressType | undefined>(undefined)
export const needRefetchAtom = atom<boolean>(false)
