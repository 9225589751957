import { useTx, useModal } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { Tx, txListAtom, txStatus } from '@apps-orangefi/lib/store'
import { stakingAddressAtom, needRefetchAtom } from '@apps-orangefi/lib/store/point'
import { getTimeBoost } from '@apps-orangefi/lib/utils/point'
import {
  useTokenBalance,
  useConvertToAssets,
  useAllowance,
  useApprove,
} from '@apps-orangefi/wagmi/hooks'
import { useCreateLock } from '@apps-orangefi/wagmi/hooks/point'
import dayjs, { Dayjs } from 'dayjs'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useEffect, useState } from 'react'

const txApproveDefault: Tx = {
  title: 'Approve',
  hash: undefined,
  status: txStatus.Wait,
}

const txCreateLockDefault: Tx = {
  title: 'Create Lock',
  hash: undefined,
  status: txStatus.Wait,
}

const txApproveAtom = atom<Tx>(txApproveDefault)
const txCreateLockAtom = atom<Tx>(txCreateLockDefault)

export const useLockForm = ({
  vaultAddress,
  account,
  lastSharePrice,
  dailyEarnings,
  onOpenTxModal,
}: {
  vaultAddress: AddressType | undefined
  account: AddressType | undefined
  lastSharePrice: BN
  dailyEarnings: number
  onOpenTxModal: () => void
}) => {
  const { t } = useTranslation()
  const [txList, setTxList] = useAtom(txListAtom)
  const stakingAddress = useAtomValue(stakingAddressAtom)
  const setNeedRefetch = useSetAtom(needRefetchAtom)

  const [amount, setAmount] = useState<BN>(new BN(0))
  const [lockDays, setLockDays] = useState(0)
  const [lockValue, setLockValue] = useState<BN>(new BN(0))
  const [unlockDate, setUnlockDate] = useState<Dayjs | null>(null)
  const [estimateEarnings, setEstimateEarnings] = useState<number>(0)

  const {
    tx: txApprove,
    setTx: setTxApprove,
    moveToPending: txApprovePending,
    moveToError: txApproveError,
    moveToSuccess: txApproveSuccess,
  } = useTx(txApproveAtom)

  const {
    tx: txCreateLock,
    setTx: setTxCreateLock,
    moveToPending: txCreateLockPending,
    moveToError: txCreateLockError,
    moveToSuccess: txCreateLockSuccess,
  } = useTx(txCreateLockAtom)

  const resetTx = () => {
    if (txList.length !== 0) return
    setTxApprove(txApproveDefault)
    setTxCreateLock(txCreateLockDefault)
  }

  const initTxList = () => {
    if (txList.length === 0) {
      setTxList([txApproveAtom, txCreateLockAtom])
    }
  }
  const { data: dataTokenBalance, isFetching: isTokenBalanceFetching } = useTokenBalance({
    account,
    tokenAddress: vaultAddress,
  })

  const allowance = useAllowance(vaultAddress, account, stakingAddress, dataTokenBalance?.decimals)
  const approve = useApprove(
    vaultAddress,
    account,
    stakingAddress,
    amount,
    dataTokenBalance?.decimals,
    {
      success: allowance.refetch,
      fail: txApproveError,
    }
  )

  const createLock = useCreateLock(
    vaultAddress,
    stakingAddress,
    amount,
    lockDays * 24 * 60 * 60,
    allowance.data,
    dataTokenBalance?.decimals,
    {
      success: () => {
        txCreateLockSuccess()
        setNeedRefetch(true)
      },
      fail: txCreateLockError,
    }
  )

  // tx status
  useEffect(() => {
    if (!approve.hash || !!txApprove.hash) return
    setTxApprove(prev => {
      return { ...prev, hash: approve.hash }
    })
  }, [approve.hash])

  useEffect(() => {
    if (!createLock.hash || !!txCreateLock.hash) return
    setTxCreateLock(prev => {
      return { ...prev, hash: createLock.hash }
    })
  }, [createLock.hash])

  useEffect(() => {
    if (txList.length === 0) return
    if (txApprove.status === txStatus.Wait) {
      if (amount.lte(0)) return
      if (approve.waitLoading) return
      if (allowance.data.lt(amount) && !!approve.writeContract && !!approve.data?.request) {
        txApprovePending()
        approve.onApprove()
      }
    }

    if (txApprove.status === txStatus.Wait || txApprove.status === txStatus.Pending) {
      if (amount.gt(0) && allowance.data.gte(amount)) {
        txApproveSuccess()
      }
    }
  }, [
    txList,
    txApprove,
    amount.toFixed(),
    allowance.data.toFixed(),
    approve.isSuccess,
    approve.writeContract,
    approve.data?.request,
  ])

  useEffect(() => {
    if (!approve.isSuccess) return
    if (allowance.isRefetching || !allowance.isLoaded) return
    if (txApprove.status !== txStatus.Pending) return
    if (allowance.data.lt(amount)) {
      txApproveError(t('WIDGET.ERROR.ALLOWANCE_NOT_ENOUGH'))
    }
  }, [JSON.stringify(allowance.data), allowance.isLoaded, amount.toFixed()])

  useEffect(() => {
    if (txList.length === 0) return
    if (!createLock.isReady) return
    if (txApprove.status === txStatus.Success && txCreateLock.status === txStatus.Wait) {
      txCreateLockPending()
      createLock.onCreateLock()
    }
  }, [txList, txApprove, txCreateLock, createLock.isReady, createLock.onCreateLock])
  // ----

  const { hideModal } = useModal()
  const onCreateLock = useCallback(
    (data: { amount: string }) => {
      resetTx()
      initTxList()
      onOpenTxModal()
    },
    [onOpenTxModal]
  )
  const onSetMaxBalance = useCallback(() => {
    if (!!dataTokenBalance?.balance) {
      setAmount(dataTokenBalance?.balance)
    }
  }, [dataTokenBalance?.balance])

  const onChangeLockDuration = useCallback((lockDays: number) => {
    setLockDays(lockDays)
  }, [])

  useEffect(() => {
    const _lockValue = amount?.multipliedBy(lastSharePrice) ?? new BN(0)
    if (!_lockValue?.eq(lockValue)) {
      setLockValue(_lockValue)
    }
  }, [amount, lastSharePrice])

  useEffect(() => {
    const currentDate = dayjs().tz('GMT')
    const _unlockDate = currentDate.add(lockDays, 'day')
    if (!_unlockDate.isSame(unlockDate)) {
      setUnlockDate(_unlockDate)
    }
  }, [lockDays])

  useEffect(() => {
    const basePoint = lockValue.multipliedBy(10)
    const newDailyEarnings = dailyEarnings + basePoint.toNumber() * getTimeBoost(lockDays)
    if (newDailyEarnings !== estimateEarnings) {
      setEstimateEarnings(newDailyEarnings)
    }
  }, [dailyEarnings, lockValue, lockDays])

  return {
    lock: {
      amount,
      onSetAmount: setAmount,
      onSetMaxBalance,
      lockDays,
      onChangeLockDuration,
      onCreateLock,
      onCancel: hideModal,
    },
    estimate: {
      lockValue,
      unlockDate,
      timeBoost: getTimeBoost(lockDays),
      dailyEarnings: {
        current: dailyEarnings,
        estimate: estimateEarnings,
      },
    },
    tokenBalance: dataTokenBalance?.balance,
  }
}
