import { graphql } from '../types/points'

export const getUserPositionsQuery = graphql(/* GraphQL */ `
  query getUserPositions($account: ID!) {
    user(id: $account) {
      id
      stakingPositions(where: { withdrawn: false }) {
        id
        amount
        amountUSD
        startLockTime
        lockDuration
        unlockTime
        withdrawn
        vault {
          id
        }
      }
    }
    vaults {
      id
      token
      lastSharePrice
    }
  }
`)
