import { twMerge } from 'tailwind-merge'

type Props = {
  label: string
  disabled: boolean
  onClick: () => void
  className?: string
}

export const TagButton = ({ label, disabled, onClick, className }: Props) => {
  return (
    <button
      className={twMerge(`dark:bg-gray-700 type-sm-semibold py-3 px-4 rounded-6xl ${className}`)}
      type="button"
      onClick={() => onClick()}
      disabled={disabled}
    >
      {label}
    </button>
  )
}
