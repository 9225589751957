import axios from 'axios'

type AuthXQueryKey = {
  queryKey: [string, { account: AddressType | undefined; callbackUrl: string }]
}

const url = process.env.NEXT_PUBLIC_ORCHARD_API_URL

export const authX = async ({ queryKey }: AuthXQueryKey) => {
  const [_, { account, callbackUrl }] = queryKey
  if (account === undefined || callbackUrl === undefined) {
    return null
  }
  const endpoint = `${url}/x/auth`
  const params = {
    wallet: account,
    callback: callbackUrl,
  }
  try {
    const res = await axios.get(endpoint, { params })
    return res.data
  } catch (e) {
    return null
  }
}
