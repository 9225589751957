import { useDebounce } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { MIN_FACTOR } from '@apps-orangefi/lib/constants'
import { V1VaultABI } from '@apps-orangefi/wagmi/abis'
import { useConvertToAssets, ResultUseWithdraw, WithdrawHook } from '@apps-orangefi/wagmi/hooks'
import {
  useSimulateContractWithErrorHandling,
  useWriteContractWithErrorHandling,
  useWaitForTransactionReceiptWithErrorHandling,
} from '@apps-orangefi/wagmi/hooks/common'
import { useMemo, useEffect, useState } from 'react'
import { BaseError } from 'wagmi'

import '@apps-orangefi/lib/extensions'

export const useWithdrawV1: WithdrawHook = ({
  fromWithdrawAddress,
  vaultAddress,
  account,
  shares,
  vaultDecimals,
  callback,
}): ResultUseWithdraw => {
  const [isWithdrawReady, setIsWithdrawReady] = useState(false)

  const { assets } = useConvertToAssets(vaultAddress, shares, vaultDecimals)
  const _shares = shares.pow10(vaultDecimals ?? 0).convertBigint()

  const minAssets = assets
    ?.times(MIN_FACTOR)
    .pow10(vaultDecimals ?? 0)
    .convertBigint()
  const args = useMemo(() => {
    return [_shares, minAssets!] as const
  }, [_shares, minAssets])

  // NOTE: debounce occurrs contract error
  // const enabled = useDebounce(!!fromWithdrawAddress && !!account && shares.gt(0), 300)
  const enabled = !!fromWithdrawAddress && !!account && !shares.isZero() && !!assets

  const { data } = useSimulateContractWithErrorHandling({
    address: fromWithdrawAddress!,
    abi: V1VaultABI,
    functionName: 'redeem',
    args,
    query: {
      enabled,
    },
  })

  const { data: hash, writeContract } = useWriteContractWithErrorHandling({
    mutation: {
      onError(error: Error) {
        if (callback && callback.fail) {
          callback.fail(error.cause as BaseError)
        }
      },
    },
  })

  const {
    isLoading: waitLoading,
    isSuccess,
    isError,
    error,
  } = useWaitForTransactionReceiptWithErrorHandling({ hash }, callback)

  useEffect(() => {
    setIsWithdrawReady(!!writeContract && !!data?.request)
  }, [writeContract, data?.request])

  return {
    isWithdrawReady,
    hash,
    isSuccess,
    onWithdraw: () => {
      if (!isWithdrawReady) {
        return
      }
      writeContract(data!.request)
    },
  }
}
