import { OctagonContainer } from '@apps-orangefi/ui/atoms/point'
import React, { CSSProperties } from 'react'
import { twMerge } from 'tailwind-merge'

const tailwindConfig = require('@apps-orangefi/tailwindcss')

export type StepContentProps = {
  active?: boolean
  isCompleted?: boolean
  onSubmit?: (e?: Event) => void
}

type Props = {
  step: string
  labels: { default: string; active: string; completed: string }
  active?: boolean
  isCompleted?: boolean
  className?: string
  children: React.ReactElement<StepContentProps>
}
const width = 92

export const AccountStep = ({
  step,
  labels,
  active = false,
  isCompleted = false,
  children,
  className,
}: Props) => {
  const [borderColor, textColor] = isCompleted
    ? [tailwindConfig.theme.extend.colors.emerald[400], 'dark:text-emerald-400']
    : active
    ? [tailwindConfig.theme.extend.colors.gray[800], 'dark:text-orange-500']
    : [tailwindConfig.theme.extend.colors.gray[850], 'dark:text-white']

  const label = isCompleted ? labels.completed : active ? labels.active : labels.default
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { active, isCompleted })
    }
    return child
  })

  // TODO: component
  const styleVars = {
    width: `${width}px`,
    position: 'absolute',
    '--spotlight-width': `${width * 1.66}px`,
    '--spotlight-height': `${width * 0.6}px`,
    '--spotlight-left': `-${(width * 0.66) / 2}px`,
  } as CSSProperties

  return (
    <div className={twMerge('flex flex-row', className)}>
      <OctagonContainer
        size={6}
        borderColor={borderColor}
        className="flex flex-row items-center px-3 py-6 md:p-8"
      >
        {active && (
          <div
            className="border-t border-orange-500 top-0 left-8 active-box"
            style={styleVars}
          ></div>
        )}
        <div className="flex flex-row justify-between items-center flex-wrap w-full">
          <div className="flex flex-row justify-start items-baseline type-2xl-semibold">
            <span className={`${textColor}`}>{step}:</span>
            <span className={`ml-2 ${isCompleted ? textColor : ''}`}>{label}</span>
          </div>
          <>{childrenWithProps}</>
        </div>
      </OctagonContainer>
    </div>
  )
}
