import MinusIcon from '@apps-orangefi/assets/images/icons/minus.svg'
import PlusIcon from '@apps-orangefi/assets/images/icons/plus.svg'
import { isNaN } from 'lodash'
import Image from 'next/image'
import { FieldError } from 'react-hook-form'

type Props = {
  lockDays: number
  onChangeDuration: (lockDays: number) => void
  error?: FieldError
}

export const LockDurationField = ({ lockDays, onChangeDuration, error }: Props) => {
  const months = Math.floor(lockDays / 30)
  const days = lockDays % 30

  const onMonthsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMonths = Number(e.target.value)
    if (isNaN(newMonths) || newMonths < 0) return
    onChangeDuration(newMonths * 30 + days)
  }

  const onDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDays = Number(e.target.value)
    if (isNaN(newDays) || newDays < 0) return
    onChangeDuration(months * 30 + newDays)
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => e.target.select()

  const onClickMinus = () => {
    if (lockDays <= 0) return
    onChangeDuration(lockDays - 1)
  }

  const onClickPlus = () => {
    onChangeDuration(lockDays + 1)
  }

  return (
    <div className="flex flex-col">
      <div className={`type-sm-medium ${error ? 'dark:text-red-400' : 'dark:text-gray-400'}`}>
        Lock duration
      </div>
      <div
        className={`flex flex-row rounded-2xl border ${
          error ? 'border-red-400' : 'border-gray-800'
        } p-2 justify-between mt-1.5`}
      >
        <div onClick={onClickMinus} className="flex items-center dark:bg-gray-850 p-2 rounded-lg">
          <Image src={MinusIcon} width={14} height={24} alt="minus" className="w-6 h-6 px-1" />
        </div>
        <div className="flex flex-row">
          <div className="flex flex-row items-center">
            <span className="type-sm-medium dark:text-gray-400">Months: </span>
            <input
              type="text"
              value={months}
              onChange={onMonthsChange}
              onFocus={handleFocus}
              maxLength={2}
              className={`w-5 h-6 p-0 text-center bg-main border-0 border-b border-white ml-1 type-base-semibold ${
                error ? 'dark:text-red-400' : 'dark:text-white'
              } focus:border-white`}
            />
          </div>
          <div className="flex flex-row items-center ml-3">
            <span className="type-sm-medium dark:text-gray-400">Days: </span>
            <input
              type="text"
              value={days}
              onChange={onDaysChange}
              onFocus={handleFocus}
              maxLength={2}
              className={`w-5 h-6 p-0 text-center bg-main border-0 border-b border-white ml-1 type-base-semibold ${
                error ? 'dark:text-red-400' : 'dark:text-white'
              } focus:border-white`}
            />
          </div>
        </div>
        <div onClick={onClickPlus} className="flex items-center dark:bg-gray-850 p-2 rounded-lg">
          <Image src={PlusIcon} width={14} height={24} alt="plus" className="w-6 h-6 px-1" />
        </div>
      </div>
      {error ? (
        <div className="dark:text-red-400 mt-1">{error.message}</div>
      ) : (
        <div className="type-xs-medium dark:text-gray-600 mt-1">Set max bonus (1 ~ 183 days)</div>
      )}
    </div>
  )
}
