import keccak256 from 'keccak256'
import { MerkleTree } from 'merkletreejs'
import { keccak256 as solidityKeccak256 } from 'viem'

export type DistributorList = {
  address: AddressType
}[]

export const getDepositProof = async (
  targetList: DistributorList,
  account: AddressType | undefined
) => {
  let leaf: string = ''
  const hashedList = targetList.map(({ address }) => {
    const hashed = solidityKeccak256(address)
    if (address.toLowerCase() === account?.toLowerCase()) {
      leaf = hashed
    }
    return hashed
  })
  return await getMerkleProof(hashedList, leaf)
}

const getMerkleProof = async (leaves: string[], leaf: string): Promise<AddressType[]> => {
  const tree = await new MerkleTree(leaves, keccak256, { sort: true })
  const proof = (await tree.getHexProof(leaf)) as AddressType[]
  return proof
}

export type ClaimsList = {
  [key: AddressType]: {
    index: number
    amount: string
    proof: AddressType[]
  }
}

export type SpaceshipDistributorList = {
  tokenTotal: string
  claims: ClaimsList
}

export type LTIPPDistributorList = SpaceshipDistributorList
