import { useStrykeQuery } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { getDopexLpPositionsListQuery } from '@apps-orangefi/lib/subgraph/queries'
import { type GetDopexLpPositionsListQuery } from '@apps-orangefi/lib/subgraph/types/dopex/graphql'
import { type Pool } from '@apps-orangefi/lib/types'
import {
  convertAmountToETH,
  convertTicksToPriceRange,
  getAmountsForLiquidity,
  getToken,
} from '@apps-orangefi/lib/utils'
import { bigintToBN } from '@apps-orangefi/lib/utils/bn'
import { TickMath, tickToPrice } from '@uniswap/v3-sdk'
import JSBI from 'jsbi'
import { chain } from 'lodash'
import { useMemo } from 'react'

type StrykeLPsByPool = {
  pool: AddressType
  handler: AddressType
  lps: {
    tokenId: string
    share: bigint
    totalLiquidity: bigint
    totalShares: bigint
    tickLower: number
    tickUpper: number
  }[]
}

export const useGetStrykeLP = (
  account: AddressType | undefined,
  poolList: Pool[],
  chainId: number,
  ethPriceUSD: BN
) => {
  const [resultStryke, reexecuteQuery] = useStrykeQuery<GetDopexLpPositionsListQuery>({
    query: getDopexLpPositionsListQuery,
    variables: {
      userIds: [account?.toLowerCase() ?? ''],
    },
    pause: !account,
  })
  const { data: dataStryke, fetching, error } = useMemo(() => resultStryke, [resultStryke])

  const _strykeLPsByPool = useMemo(() => {
    return (dataStryke?.lppositions ?? []).reduce((acc, lp) => {
      const lpItem = {
        tokenId: lp.strike.id,
        share: BigInt(lp.shares),
        totalLiquidity: BigInt(lp.strike.totalLiquidity),
        totalShares: BigInt(lp.strike.totalShares),
        tickLower: lp.strike.tickLower,
        tickUpper: lp.strike.tickUpper,
      }
      const result = acc.find(i => i.pool === lp.pool)
      if (result) {
        result.lps.push(lpItem)
      } else {
        acc.push({
          pool: lp.pool as AddressType,
          handler: lp.handler as AddressType,
          lps: [lpItem],
        })
      }
      return acc
    }, [] as StrykeLPsByPool[])
  }, [dataStryke])

  const userStrykeLiquiditiesByPool = useMemo(() => {
    return chain(_strykeLPsByPool)
      .map(strykeLp => {
        const pool = poolList.find(pool => pool.id === strykeLp.pool)
        if (!pool) return

        const poolBaseToken = getToken(
          chainId,
          pool.token0.id as AddressType,
          Number(pool.token0.decimals)
        )
        const poolQuoteToken = getToken(
          chainId,
          pool.token1.id as AddressType,
          Number(pool.token1.decimals)
        )
        const currentPrice = tickToPrice(poolBaseToken, poolQuoteToken, Number(pool.tick))
        const sqrtPriceX96 = JSBI.BigInt(pool.sqrtPrice)

        const userStrykeLiquidities = strykeLp.lps.map(lp => {
          const totalLiquidity = bigintToBN(lp.totalLiquidity)
          const totalShares = bigintToBN(lp.totalShares)
          const share = bigintToBN(lp.share)
          const liquidity = JSBI.BigInt(
            totalLiquidity.multipliedBy(share.div(totalShares)).toFixed(0)
          )
          const sqrtRatioAX96 = TickMath.getSqrtRatioAtTick(lp.tickLower)
          const sqrtRatioBX96 = TickMath.getSqrtRatioAtTick(lp.tickUpper)

          const { amount0, amount1 } = getAmountsForLiquidity(
            sqrtPriceX96,
            sqrtRatioAX96,
            sqrtRatioBX96,
            liquidity
          )
          const { priceLower, priceUpper } = convertTicksToPriceRange(
            lp.tickLower,
            lp.tickUpper,
            poolBaseToken,
            poolQuoteToken
          )

          const [lpAmount0, lpAmount1] = [new BN(amount0.toString()), new BN(amount1.toString())]

          if (currentPrice.greaterThan(priceLower) && currentPrice.lessThan(priceUpper)) {
            const deltaLower = currentPrice.subtract(priceLower)
            const deltaUpper = priceUpper.subtract(currentPrice)
            const strikePrice = deltaLower.lessThan(deltaUpper) ? priceLower : priceUpper

            // size: amount
            // amount: for calculating total amount USD (not decimalized size)
            return {
              id: lp.tokenId,
              strikePrice: new BN(strikePrice.toSignificant()),
              token0: {
                size: lpAmount0.pow10ofMinus(pool.token0.decimals),
                symbol: pool.token0.symbol,
                amount: lpAmount0,
              },
              token1: {
                size: lpAmount1.pow10ofMinus(pool.token1.decimals),
                symbol: pool.token1.symbol,
                amount: lpAmount1,
              },
            }
          } else if (currentPrice.lessThan(priceLower)) {
            return {
              id: lp.tokenId,
              strikePrice: new BN(priceUpper.toSignificant()),
              token0: {
                size: lpAmount0.pow10ofMinus(pool.token0.decimals),
                symbol: pool.token0.symbol,
                amount: lpAmount0,
              },
              token1: {
                size: new BN(0),
                symbol: pool.token1.symbol,
                amount: new BN(0),
              },
            }
          } else {
            return {
              id: lp.tokenId,
              strikePrice: new BN(priceLower.toSignificant()),
              token0: {
                size: new BN(0),
                symbol: pool.token0.symbol,
                amount: new BN(0),
              },
              token1: {
                size: lpAmount1.pow10ofMinus(pool.token1.decimals),
                symbol: pool.token1.symbol,
                amount: lpAmount1,
              },
            }
          }
        })

        const tokenTotalLiquidity = userStrykeLiquidities.reduce((acc, cur) => {
          if (cur.token0) {
            acc.token0 = {
              size: acc.token0?.size.plus(cur.token0.size) ?? cur.token0.size,
              symbol: cur.token0.symbol,
              amount: acc.token0?.amount.plus(cur.token0.amount) ?? cur.token0.amount,
              decimals: pool.token0.decimals,
            }
          }
          if (cur.token1) {
            acc.token1 = {
              size: acc.token1?.size.plus(cur.token1.size) ?? cur.token1.size,
              symbol: cur.token1.symbol,
              amount: acc.token1?.amount.plus(cur.token1.amount) ?? cur.token1.amount,
              decimals: pool.token1.decimals,
            }
          }
          return acc
        }, {} as { token0: { size: BN; symbol: string; amount: BN; decimals: number }; token1: { size: BN; symbol: string; amount: BN; decimals: number } })

        const token0TotalAmount = JSBI.BigInt(
          (tokenTotalLiquidity.token0?.amount ?? new BN(0)).toString()
        )
        const token1TotalAmount = JSBI.BigInt(
          (tokenTotalLiquidity.token1?.amount ?? new BN(0)).toString()
        )

        const { totalAmountUSD } = convertAmountToETH(
          token0TotalAmount,
          token1TotalAmount,
          pool.token0,
          pool.token1,
          ethPriceUSD
        )

        return {
          ...strykeLp,
          userStrykeLiquidities,
          tokenTotalLiquidity,
          totalAmountUSD,
        }
      })
      .compact()
      .value()
  }, [_strykeLPsByPool, poolList, chainId, ethPriceUSD])

  return { userStrykeLiquiditiesByPool, fetching, error, reexecuteQuery }
}
