const abi = [
  {
    inputs: [{ internalType: 'address', name: 'user_', type: 'address' }],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    inputs: [{ internalType: 'address', name: 'handler', type: 'address' }],
    name: 'IncorrectHandler',
    type: 'error',
  },
  { inputs: [], name: 'OnlyProxy', type: 'error' },
  {
    inputs: [],
    name: 'getReservedPositions',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'pool', type: 'address' },
          { internalType: 'address', name: 'hook', type: 'address' },
          { internalType: 'int24', name: 'tickLower', type: 'int24' },
          { internalType: 'int24', name: 'tickUpper', type: 'int24' },
          { internalType: 'uint128', name: 'liquidity', type: 'uint128' },
        ],
        internalType: 'struct IStrykeHandlerV2.ReserveLiquidity[]',
        name: 'positions',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getReservedTokenIds',
    outputs: [{ internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'proxy',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IStrykeHandlerV2', name: 'handler', type: 'address' },
      {
        components: [
          { internalType: 'address', name: 'pool', type: 'address' },
          { internalType: 'address', name: 'hook', type: 'address' },
          { internalType: 'int24', name: 'tickLower', type: 'int24' },
          { internalType: 'int24', name: 'tickUpper', type: 'int24' },
          { internalType: 'uint128', name: 'shares', type: 'uint128' },
        ],
        internalType: 'struct IStrykeHandlerV2.ReserveShare',
        name: 'reserveInShare',
        type: 'tuple',
      },
    ],
    name: 'reserveLiquidity',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'pool', type: 'address' },
          { internalType: 'address', name: 'hook', type: 'address' },
          { internalType: 'int24', name: 'tickLower', type: 'int24' },
          { internalType: 'int24', name: 'tickUpper', type: 'int24' },
          { internalType: 'uint128', name: 'liquidity', type: 'uint128' },
        ],
        internalType: 'struct IStrykeHandlerV2.ReserveLiquidity',
        name: 'liquidityReserved',
        type: 'tuple',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'user',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
    name: 'userReservedPositions',
    outputs: [
      { internalType: 'address', name: 'pool', type: 'address' },
      { internalType: 'address', name: 'hook', type: 'address' },
      { internalType: 'int24', name: 'tickLower', type: 'int24' },
      { internalType: 'int24', name: 'tickUpper', type: 'int24' },
      { internalType: 'uint128', name: 'liquidity', type: 'uint128' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IStrykeHandlerV2', name: 'handler', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
    ],
    name: 'withdrawReserveLiquidity',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'pool', type: 'address' },
          { internalType: 'address', name: 'hook', type: 'address' },
          { internalType: 'int24', name: 'tickLower', type: 'int24' },
          { internalType: 'int24', name: 'tickUpper', type: 'int24' },
          { internalType: 'uint128', name: 'liquidity', type: 'uint128' },
        ],
        internalType: 'struct IStrykeHandlerV2.ReserveLiquidity',
        name: 'positionWithdrawn',
        type: 'tuple',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const

export default abi
