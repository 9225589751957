import CloseIcon from '@apps-orangefi/assets/images/icons/close.svg'
import { BaseModal } from '@apps-orangefi/ui/organisms/modals'
// import { LockContainer, type LockContainerProps } from '@apps-orangefi/ui/organisms/point'
import { Dialog } from '@headlessui/react'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

type WrapperProps = {
  mode: 'lock' | 'extend' | 'unlock'
  handleClose: () => void
  children: JSX.Element
}

export const LockModalWrapper = ({ mode, handleClose, children }: WrapperProps) => {
  const translateKey = mode.toUpperCase()
  const { t: tPoints } = useTranslation('points')

  return (
    <BaseModal>
      <Dialog.Panel className="w-full max-w-[520px] transform overflow-hidden rounded-2xl bg-main px-6 pt-6 pb-8 text-left align-middle shadow-xl transition-all flex flex-col">
        <Dialog.Title as="div" className="flex flex-col">
          <div className="flex justify-between items-center">
            <span className="type-xl-semibold dark:text-white">
              {tPoints(`MODAL.${translateKey}.TITLE`)}
            </span>
            <Image
              src={CloseIcon}
              width={12}
              height={12}
              alt="close"
              onClick={handleClose}
              className="hover:cursor-pointer"
            />
          </div>
          <div className="type-sm-medium dark:text-gray-400 mt-2">
            {tPoints(`MODAL.${translateKey}.DESC`)}
          </div>
        </Dialog.Title>
        {children}
      </Dialog.Panel>
    </BaseModal>
  )
}
