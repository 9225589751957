import { OrangeStakingABI } from '@apps-orangefi/wagmi/abis'
import {
  useSimulateContractWithErrorHandling,
  useWriteContractWithErrorHandling,
  useWaitForTransactionReceiptWithErrorHandling,
} from '@apps-orangefi/wagmi/hooks/common'
import { useMemo, useEffect, useState } from 'react'
import { BaseError } from 'wagmi'

export const useWithdrawLock = (
  stakingAddress: AddressType | undefined,
  positionId: string | undefined,
  callback?: { success?: () => void; fail?: (cause: BaseError | string) => void }
) => {
  const [isReady, setIsReady] = useState(false)

  const args = useMemo(() => {
    return [BigInt(positionId!)] as const
  }, [positionId])

  const enabled = useMemo(() => {
    return !!positionId && !!stakingAddress
  }, [positionId, stakingAddress])

  const { data } = useSimulateContractWithErrorHandling({
    address: stakingAddress!,
    abi: OrangeStakingABI,
    functionName: 'withdraw',
    args,
    query: {
      enabled,
    },
  })

  const { data: hash, writeContract } = useWriteContractWithErrorHandling({
    mutation: {
      onError(error) {
        if (callback && callback.fail) {
          callback.fail(error.cause as BaseError)
        }
      },
    },
  })

  const {
    isLoading: waitLoading,
    isSuccess,
    isError,
    error,
  } = useWaitForTransactionReceiptWithErrorHandling({ hash }, callback)

  useEffect(() => {
    setIsReady(!!writeContract && !!data?.request)
  }, [writeContract, data?.request])

  return {
    isReady,
    hash,
    isSuccess,
    onWithdrawLock: () => {
      if (!isReady) {
        return
      }
      writeContract(data!.request)
    },
  }
}
