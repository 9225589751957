import SykIcon from '@apps-orangefi/assets/images/tokens/syk.svg'
import XSykIcon from '@apps-orangefi/assets/images/tokens/xsyk.svg'
import { BN } from '@apps-orangefi/lib'
import { fetchSYKPair } from '@apps-orangefi/lib/api'
import { useCurrentEpoch, useEpochRewards } from '@apps-orangefi/wagmi/hooks'
import { useQuery } from '@tanstack/react-query'
import { isNil } from 'lodash'
import { useMemo } from 'react'

const YEAR_IN_SECONDS = 31536000
const SYK_TOKEN_ADDRESS = '0xACC51FFDeF63fB0c014c882267C3A17261A5eD50' as AddressType
const XSYK_TOKEN_ADDRESS = '0x50E04E222Fc1be96E94E86AcF1136cB0E97E1d40' as AddressType

export const useStrykeRewardAPR = (
  distributorAddress: AddressType | undefined,
  vaultList: { vaultAddress: AddressType; strykeGaugeId: AddressType | undefined; tvl: BN }[]
) => {
  const {
    currentEpoch,
    epochLength,
    controllerAddress,
    isFetching: isFetchingCurrentEpoch,
  } = useCurrentEpoch(distributorAddress)

  const { data: dataSykPair, isFetching: isFetchingDexScreener } = useQuery({
    queryKey: ['fetchSYKPair'],
    queryFn: fetchSYKPair,
    refetchOnWindowFocus: false,
  })

  const rewardTargets = useMemo(() => {
    if (!currentEpoch) {
      return []
    }
    return vaultList
      .filter(vault => !!vault.strykeGaugeId)
      .map(vault => ({
        vaultAddress: vault.vaultAddress,
        strykeGaugeId: vault.strykeGaugeId!,
        epoch: currentEpoch - 1,
      }))
  }, [vaultList, currentEpoch])

  const { rewards, isFetching: isFetchingRewards } = useEpochRewards(
    controllerAddress,
    rewardTargets
  )

  const strykeRewardsApr = useMemo(() => {
    if (!rewards) {
      return []
    }
    return rewards.map(reward => {
      const vault = vaultList.find(vault => vault.vaultAddress === reward.vaultAddress)
      const tvl = vault?.tvl
      if (!!tvl && tvl.gt(0) && !isNil(epochLength) && epochLength > 0) {
        const rewardUsd = reward.reward.multipliedBy(dataSykPair?.priceUsd ?? 0)
        const totalRewardApr = rewardUsd
          .multipliedBy(YEAR_IN_SECONDS / epochLength)
          .div(tvl)
          .multipliedBy(100)

        return {
          ...vault,
          epoch: reward.epoch,
          reward: reward.reward,
          totalRewardApr,
          aprList: [
            {
              iconUrl: SykIcon,
              symbol: 'SYK',
              tokenAddress: SYK_TOKEN_ADDRESS,
              apr: totalRewardApr.div(2),
            },
            {
              iconUrl: XSykIcon,
              symbol: 'xSYK',
              tokenAddress: XSYK_TOKEN_ADDRESS,
              apr: totalRewardApr.div(2),
            },
          ],
        }
      }
    })
  }, [
    JSON.stringify(vaultList),
    JSON.stringify(rewards),
    epochLength,
    dataSykPair?.priceUsd.toFixed(),
  ])

  return {
    strykeRewardsApr,
    isFetching: isFetchingCurrentEpoch || isFetchingRewards || isFetchingDexScreener,
  }
}
