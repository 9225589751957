import { useModal } from '@apps-orangefi/hooks'
import { selectedChainAtom } from '@apps-orangefi/lib/store'
import { MODAL_TYPES, SwitchChainModalTypes } from '@apps-orangefi/ui/organisms/modals'
import { useAtomValue } from 'jotai'
import { useMemo, useCallback } from 'react'
import { useAccount, useSwitchChain } from 'wagmi'
import { Chain } from 'wagmi/chains'

export const useSwitchChainModal = (
  supportedChainIds: number[],
  defaultChainId: number | undefined,
  onSuccessSwitchChain: (chainId: number) => void,
  modalType: SwitchChainModalTypes = MODAL_TYPES.SwitchChainModal
) => {
  const { chain: activeChain, chainId } = useAccount()
  const selectedChain = useAtomValue(selectedChainAtom)

  const isActiveChainSupported = useMemo(() => {
    return !!activeChain && supportedChainIds.includes(activeChain.id)
  }, [activeChain, supportedChainIds])

  const { isPending, switchChainAsync } = useSwitchChain({
    mutation: {
      onSuccess: chain => {
        onSuccessSwitchChain(chain.id)
      },
    },
  })

  // const defaultChain = chains.find(c => c.id === defaultChainId)

  const { showModal, hideModal } = useModal()

  const openSwitchChainModal = useCallback(() => {
    showModal({
      modalType,
      modalProps: {
        handleClose: hideModal,
        handleSwitchChain: () => {
          if (switchChainAsync && selectedChain) {
            switchChainAsync({ chainId: selectedChain.id })
            setTimeout(() => hideModal(), 150)
          }
        },
        chainInfo: {
          name: selectedChain?.name,
          id: selectedChain?.id,
        },
      },
    })
  }, [switchChainAsync, selectedChain])

  const onSelectChain = useCallback(
    (chain: Chain) => {
      if (switchChainAsync) {
        switchChainAsync({ chainId: chain.id })
      }
    },
    [switchChainAsync]
  )

  return {
    isActiveChainSupported,
    isLoading: isPending,
    openSwitchChainModal,
    onSelectChain,
  }
}
