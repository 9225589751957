import { useSimulateRedeem, useStrykeQuery } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { getDopexStrikeListQuery } from '@apps-orangefi/lib/subgraph/queries'
import { GetDopexStrikeListQuery } from '@apps-orangefi/lib/subgraph/types/dopex/graphql'
import { ResultWithdrawSimulation, Token } from '@apps-orangefi/lib/types'
import {
  convertTicksToPriceRange,
  getToken,
  bigintToBN,
  convertAmountToETH,
} from '@apps-orangefi/lib/utils'
import { StrykeUniV3HandlerV2ABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractsWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { Price, Token as UniToken } from '@uniswap/sdk-core'
import { tickToPrice } from '@uniswap/v3-sdk'
import JSBI from 'jsbi'
import { omit, chain, zipWith, zipObject, isNil, isEqual } from 'lodash'
import { useMemo, useState, useEffect, useCallback } from 'react'
import { zeroAddress, encodeAbiParameters, parseAbiParameters } from 'viem'

type DopexLpShare = {
  tokenId: string
  share: BN
}

type DopexLpShareWithPrice = DopexLpShare & {
  handler: string
  pool: string
  priceLower: Price<UniToken, UniToken>
  priceUpper: Price<UniToken, UniToken>
  tickLower: number
  tickUpper: number
  token0: {
    id: string
    symbol: string
    decimals: number
  }
  token1: {
    id: string
    symbol: string
    decimals: number
  }
}

type DopexLpAsset = DopexLpShareWithPrice & { asset: BN }

export const useSimulateWithdrawLPDfi = (
  vaultAddress: AddressType | undefined,
  account: AddressType | undefined,
  shares: BN,
  vaultDecimals: number | null,
  chainId: number,
  pool: {
    token0: Token | undefined
    token1: Token | undefined
    tick: number | undefined
  },
  vaultBaseToken: Token | undefined,
  ethPriceUSD: BN | undefined
) => {
  const [resultWithdrawSimulation, setResultWithdrawSimulation] = useState<
    ResultWithdrawSimulation | undefined
  >(undefined)
  const [dopexLpShareWithPriceList, setDopexLpShareWithPriceList] = useState<
    DopexLpShareWithPrice[]
  >([])
  const [dopexLpAssetList, setDopexLpAssetList] = useState<DopexLpAsset[]>([])
  const [isLpPositionFetching, setIsLpPositionFetching] = useState<boolean>(false)

  const poolBaseToken = useMemo(() => {
    if (!pool.token0) return
    return getToken(chainId, pool.token0.id as AddressType, Number(pool.token0.decimals))
  }, [pool.token0])

  const poolQuoteToken = useMemo(() => {
    if (!pool.token1) return
    return getToken(chainId, pool.token1.id as AddressType, Number(pool.token1.decimals))
  }, [pool.token1])

  // 1. simulate redeem LP token from vault.
  //    receive estimated withdrable assets and stryke LP shares
  const { simulateWithdrawLPDfi, resultSimulation, hasUtilizedLP, isSimulating } =
    useSimulateRedeem(vaultAddress, account, shares, vaultDecimals)

  // 2. Fetch strike list from stryke subgraph
  //    to get tickLower, tickUpper to calculate price range
  //    Merge strike and price range with dopexLpShares
  const [resultDopex, _] = useStrykeQuery<GetDopexStrikeListQuery>({
    query: getDopexStrikeListQuery,
    variables: {
      tokenIds: resultSimulation.dopexLpShares.map(dopexLpShare => dopexLpShare.tokenId),
    },
    pause: resultSimulation.dopexLpShares.length === 0,
  })
  const {
    data: dataDopex,
    fetching: fetchingDopexSubgraph,
    error,
  } = useMemo(() => resultDopex, [resultDopex])

  useEffect(() => {
    const _shareList = chain(dataDopex?.strikes)
      .map(strike => {
        const dopexLpShare = resultSimulation.dopexLpShares.find(
          dopexShare => dopexShare.tokenId === strike.id
        )
        //
        if (dopexLpShare) {
          if (!!poolBaseToken && !!poolQuoteToken) {
            const { priceLower, priceUpper } = convertTicksToPriceRange(
              strike.tickLower,
              strike.tickUpper,
              poolBaseToken,
              poolQuoteToken
            )

            return {
              ...dopexLpShare,
              ...omit(strike, ['id', '__typename']),
              priceLower,
              priceUpper,
            }
          }
        }
      })
      .compact()
      .value()

    if (!isEqual(_shareList, dopexLpShareWithPriceList)) {
      setDopexLpShareWithPriceList(_shareList)
    }
  }, [dataDopex, resultSimulation.dopexLpShares, poolBaseToken, poolQuoteToken])

  // 3. Convert LP shares to assets by using stryke handler contract
  const dopexConvertAssetsContracts = useMemo(() => {
    return dopexLpShareWithPriceList.map(dopexShare => {
      const args = [dopexShare.share.convertBigint(), new BN(dopexShare.tokenId).convertBigint()]
      return {
        address: dopexShare.handler as AddressType,
        abi: StrykeUniV3HandlerV2ABI,
        functionName: 'convertToAssets',
        args,
      }
    })
  }, [dopexLpShareWithPriceList])

  const { data: dataDopexAssetList, isFetching: isAssetFetching } =
    useReadContractsWithErrorHandling({
      contracts: dopexConvertAssetsContracts,
      query: {
        enabled: dopexLpShareWithPriceList.length > 0,
      },
    })

  useEffect(() => {
    const _dataDopexAssetList = dataDopexAssetList?.map(item => item.result as bigint) ?? []
    const _list = zipWith(dopexLpShareWithPriceList, _dataDopexAssetList, (dopexShare, asset) => {
      return {
        ...dopexShare,
        asset: bigintToBN(asset),
      }
    })
    setDopexLpAssetList(_list)
  }, [JSON.stringify(dopexLpShareWithPriceList), JSON.stringify(dataDopexAssetList)])

  // 4. Fetch token pair amounts from stryke handler contract
  const dopexContracts = useMemo(() => {
    return chain(dopexLpAssetList)
      .map(dopexShare => {
        return {
          ...dopexShare,
          args: encodeAbiParameters(parseAbiParameters('address, address, int24, int24, uint128'), [
            dopexShare.pool as AddressType,
            zeroAddress,
            dopexShare.tickLower,
            dopexShare.tickUpper,
            dopexShare.asset.convertBigint(),
          ]),
        }
      })
      .map(dopexShare => {
        return {
          address: dopexShare.handler as AddressType,
          abi: StrykeUniV3HandlerV2ABI,
          functionName: 'tokensToPullForMint',
          args: [dopexShare.args as AddressType],
        }
      })
      .value()
  }, [dopexLpAssetList])

  const {
    data: lpAmountData,
    isFetching: isLpAmountFetching,
    isError,
  } = useReadContractsWithErrorHandling({
    contracts: dopexContracts,
    query: {
      enabled: dopexLpAssetList.length > 0,
    },
  })

  // 5. Calculate withdrawable token pair amounts
  useEffect(() => {
    // if (isNil(isLpPositionFetching) || isLpPositionFetching) return
    const tokenPairAmountList = chain(lpAmountData ?? [])
      .map(item => item.result as unknown as [[AddressType, AddressType], [bigint, bigint]])
      .compact()
      .value()

    const amountPerToken = chain(tokenPairAmountList)
      .map(tokenPairAmount =>
        zipObject(
          tokenPairAmount[0].map(token => token.toLowerCase()),
          tokenPairAmount[1].map(amount => bigintToBN(amount))
        )
      )
      .value()

    const strikeTokenAmountList = zipWith(
      dopexLpAssetList,
      amountPerToken,
      (dopexShare, tokenPair) => {
        const token0Amount = tokenPair ? tokenPair[dopexShare.token0.id] : new BN(0)
        const token1Amount = tokenPair ? tokenPair[dopexShare.token1.id] : new BN(0)

        return {
          ...dopexShare,
          token0Amount,
          token1Amount,
        }
      }
    )

    if (
      poolBaseToken &&
      poolQuoteToken &&
      !isNil(pool.tick) &&
      !!resultSimulation.withdrawnAssets
    ) {
      const currentPrice = tickToPrice(poolBaseToken, poolQuoteToken, pool.tick)
      const withdrawnAssets = resultSimulation.withdrawnAssets ?? new BN(0)

      // NOTE: calculate total utilized LP
      //       priceLower, priceUpper, token0Amount, token1Amount, token0, token1
      const withdrawnLP = strikeTokenAmountList.map(strike => {
        const [priceLower, priceUpper] = [strike.priceLower, strike.priceUpper]

        if (currentPrice.greaterThan(priceLower) && currentPrice.lessThan(priceUpper)) {
          const deltaLower = currentPrice.subtract(priceLower)
          const deltaUpper = priceUpper.subtract(currentPrice)
          const strikePrice = deltaLower.lessThan(deltaUpper) ? priceLower : priceUpper
          return {
            strikePrice: new BN(strikePrice.toSignificant()),
            token0: {
              size: new BN(strike.token0Amount).pow10ofMinus(strike.token0.decimals),
              amount: strike.token0Amount,
              symbol: strike.token0.symbol,
            },
            token1: {
              size: new BN(strike.token1Amount).pow10ofMinus(strike.token1.decimals),
              amount: strike.token1Amount,
              symbol: strike.token1.symbol,
            },
          }
        } else if (currentPrice.lessThan(priceLower)) {
          return {
            strikePrice: new BN(priceUpper.toSignificant()),
            token0: {
              size: new BN(strike.token0Amount).pow10ofMinus(strike.token0.decimals),
              amount: strike.token0Amount,
              symbol: strike.token0.symbol,
            },
          }
        } else {
          return {
            strikePrice: new BN(priceLower.toSignificant()),
            token1: {
              size: new BN(strike.token1Amount).pow10ofMinus(strike.token1.decimals),
              amount: strike.token1Amount,
              symbol: strike.token1.symbol,
            },
          }
        }
      })
      const totalUtilizedLP = withdrawnLP.reduce((acc, lp) => {
        if (lp.token0) {
          acc.token0 = {
            size: acc.token0?.size.plus(lp.token0.size) ?? lp.token0.size,
            amount: acc.token0?.amount.plus(lp.token0.amount) ?? lp.token0.amount,
            symbol: lp.token0.symbol,
          }
        }
        if (lp.token1) {
          acc.token1 = {
            size: acc.token1?.size.plus(lp.token1.size) ?? lp.token1.size,
            amount: acc.token1?.amount.plus(lp.token1.amount) ?? lp.token1.amount,
            symbol: lp.token1.symbol,
          }
        }
        return acc
      }, {} as { token0?: { size: BN; amount: BN; symbol: string }; token1?: { size: BN; amount: BN; symbol: string } })

      const totalUtilizedAmount =
        !!pool.token0 && !!pool.token1 && !!ethPriceUSD
          ? convertAmountToETH(
              JSBI.BigInt(totalUtilizedLP.token0?.amount.toString() ?? 0),
              JSBI.BigInt(totalUtilizedLP.token1?.amount.toString() ?? 0),
              pool.token0,
              pool.token1,
              ethPriceUSD
            )
          : undefined

      const totalUtilizedBaseAsset = (totalUtilizedAmount?.totalAmountETH ?? new BN(0)).div(
        vaultBaseToken?.derivedETH ?? 1
      )

      const totalValue = withdrawnAssets.plus(totalUtilizedBaseAsset)

      setResultWithdrawSimulation({
        withdrawnAssets,
        withdrawnLP,
        totalUtilizedLP,
        totalValue,
      })
    }
  }, [
    resultSimulation,
    lpAmountData,
    dopexLpAssetList,
    JSON.stringify(poolBaseToken),
    JSON.stringify(poolQuoteToken),
    pool.tick,
    ethPriceUSD,
    pool.token0,
    pool.token1,
    // isLpPositionFetching,
  ])

  useEffect(() => {
    const _isLpPositionFetching = fetchingDopexSubgraph || isAssetFetching || isLpAmountFetching
    if (_isLpPositionFetching !== isLpPositionFetching) {
      setIsLpPositionFetching(_isLpPositionFetching)
    }
  }, [fetchingDopexSubgraph, isAssetFetching, isLpAmountFetching])

  const resetResultWithdrawSimulation = useCallback(() => {
    setResultWithdrawSimulation(undefined)
  }, [])

  return {
    simulateWithdrawLPDfi,
    resultWithdrawSimulation,
    hasUtilizedLP,
    isSimulating,
    isLpPositionFetching,
    resetResultWithdrawSimulation,
  }
}
