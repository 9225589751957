import { useMemo } from 'react'
import { useQuery, UseQueryResponse, RequestPolicy } from 'urql'

interface BaseQueryOptions {
  query: any // GraphQLクエリを渡す型
  variables?: Record<string, any>
  pause?: boolean
  requestPolicy?: RequestPolicy
}

export const useOrangeQuery = <TData = any>(options: BaseQueryOptions): UseQueryResponse<TData> => {
  return useQuery<TData>({
    ...options,
  })
}

export const useUniV3Query = <TData = any>(options: BaseQueryOptions): UseQueryResponse<TData> => {
  const context = useMemo(() => ({ url: process.env.NEXT_PUBLIC_UNISWAP_SUBGRAPH_URL }), [])

  return useQuery<TData>({
    ...options,
    context,
  })
}

export const useStrykeQuery = <TData = any>(options: BaseQueryOptions): UseQueryResponse<TData> => {
  const context = useMemo(() => ({ url: process.env.NEXT_PUBLIC_DOPEX_SUBGRAPH_URL }), [])

  return useQuery<TData>({
    ...options,
    context,
  })
}

export const usePointsQuery = <TData = any>(options: BaseQueryOptions): UseQueryResponse<TData> => {
  const context = useMemo(() => ({ url: process.env.NEXT_PUBLIC_POINTS_SUBGRAPH_URL }), [])

  return useQuery<TData>({
    ...options,
    context,
  })
}
