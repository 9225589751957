module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  loadLocaleFrom: async (lang, ns) => {
    const locales = require(`.//locales/${lang}/${ns}.json`)
    const defaultLocales = require(`.//locales/en/${ns}.json`)
    return { ...defaultLocales, ...locales }
  },
  defaultNS: 'common',
  pages: {
    '*': ['common']
  }
}
