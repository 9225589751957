import axios from 'axios'

type FetchAstronautProofQueryKey = {
  queryKey: [string, { account: AddressType | undefined }]
}
type AstronautProofResult = {
  proof: AddressType[]
}

export const fetchAstronautProof = async ({ queryKey }: FetchAstronautProofQueryKey) => {
  const [_, { account }] = queryKey
  if (account === undefined) {
    return undefined
  }
  const url = `https://astronaut-eight.vercel.app/api/get-proof?user=${account}`
  const res = await axios.get<AstronautProofResult>(url)

  return res.data
}
