
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '@apps-orangefi/lib/scripts/wdyr'
import { queryConfig, queryCache, mutationCache } from '@apps-orangefi/lib/api/react-query'
import { targetChainAtom, urlBaseAtom } from '@apps-orangefi/lib/store'
import { createInitialValues } from '@apps-orangefi/lib/utils'
import { GoogleTagManager } from '@apps-orangefi/ui/atoms'
import { GlobalModal } from '@apps-orangefi/ui/organisms/modals'
import { SafeAutoConnectProvider } from '@apps-orangefi/wagmi/hooks/useSafeWagmiConfig'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { Provider as JotaiProvider } from 'jotai'
import { useAtomsDebugValue } from 'jotai/devtools'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'
import { ThemeProvider } from 'next-themes'
import useTranslation from 'next-translate/useTranslation'
import { useEffect } from 'react'
import { createClient, Provider as UrqlProvider, cacheExchange, fetchExchange } from 'urql'

import {
  APP_PUBLIC_URL,
  SUPPORTED_CHAIN_IDS,
  DEFAULT_CHAIN_ID,
  SUPPORTED_CHAINS,
  DEFAULT_SUBGRAPH_URL,
} from '../config'

import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import type { ReactElement, ReactNode } from 'react'

import '@apps-orangefi/assets'
import '../styles/index.css'
import '@apps-orangefi/lib/extensions'

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout & { theme: string }
}

const DebugAtoms = () => {
  useAtomsDebugValue()
  return null
}

const pageview = (url: string) => {
  ;(window as any).dataLayer?.push({
    event: 'pageview',
    page: url,
  })
}

const subgraphUrl = process.env.NEXT_PUBLIC_ORANGE_SUBGRAPH_URL ?? DEFAULT_SUBGRAPH_URL
const urqlClient = createClient({
  url: subgraphUrl,
  exchanges: [cacheExchange, fetchExchange],
})

const queryClient = new QueryClient({
  defaultOptions: queryConfig,
  queryCache,
  mutationCache,
})

const [appUrlBase, pointsUrlBase] =
  process.env.NODE_ENV === 'development'
    ? ['http://localhost:3000', 'http://localhost:3001']
    : process.env.NEXT_PUBLIC_STAGE === 'app'
    ? ['https://app.orangefinance.io', 'https://points.orangefinance.io']
    : process.env.NEXT_PUBLIC_STAGE === 'qa'
    ? ['https://qa.orangefinance.io', 'https://points-qa.orangefinance.io']
    : ['https://dev.orangefinance.io', 'https://points-dev.orangefinance.io']

const __Page_Next_Translate__ = function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? (page => page)
  const { t } = useTranslation()

  const router = useRouter()
  useEffect(() => {
    router.events.on('routeChangeComplete', pageview)
    return () => {
      router.events.off('routeChangeComplete', pageview)
    }
  }, [router.events])

  const initialValues = createInitialValues()
  initialValues.set(targetChainAtom, {
    supportedChainIds: SUPPORTED_CHAIN_IDS,
    defaultChainId: DEFAULT_CHAIN_ID,
    supportedChains: SUPPORTED_CHAINS,
  })
  initialValues.set(urlBaseAtom, { app: appUrlBase, points: pointsUrlBase })

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      return
    }
    const script = document.createElement('script')
    script.src = 'https://attribution.metacrm.inc/tracking-1-1-1.js'
    script.setAttribute('data-entity-id', '65b756982ff70eacfbdb6ad2')
    script.id = 'metacrm-tracking'
    script.integrity = 'sha384-KVT0cdwbRt/fjdhbbEcHAVvFdtrAkBuvoip0q/+NDjPC73iAPNEkAXLkGOre5AUP'
    script.setAttribute('crossorigin', 'anonymous')
    script.setAttribute('apiKey', '2odujdre977')
    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [])

  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/orange-logo.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
      </Head>
      <DefaultSeo
        titleTemplate={`%s | ${t('SITE.TITLE')}`}
        defaultTitle={t('SITE.TITLE')}
        description={t('SITE.DESCRIPTION')}
        openGraph={{
          type: 'website',
          url: 'https://alpha.orangefinance.io/',
          title: t('SITE.TITLE'),
          description: t('SITE.DESCRIPTION'),
          images: [
            {
              url: `${APP_PUBLIC_URL}/ogp.png`,
              width: 1200,
              height: 630,
              alt: 'Orange Finance',
            },
          ],
        }}
      />
      {process.env.NODE_ENV !== 'development' &&
      process.env.NEXT_PUBLIC_GTM_ID &&
      process.env.NEXT_PUBLIC_GTM_ENV_PARAM ? (
        <GoogleTagManager
          googleTagManagerId={process.env.NEXT_PUBLIC_GTM_ID}
          googleTagManagerEnvParam={process.env.NEXT_PUBLIC_GTM_ENV_PARAM}
        />
      ) : null}
      <ThemeProvider attribute="class" defaultTheme="dark">
        <JotaiProvider initialValues={initialValues.get()}>
          {process.env.NODE_ENV === 'development' && <DebugAtoms />}
          <UrqlProvider value={urqlClient}>
            <QueryClientProvider client={queryClient}>
              <SafeAutoConnectProvider>
                {/*
                // @ts-ignore */}
                {getLayout(<Component {...pageProps} />)}
                <GlobalModal />
              </SafeAutoConnectProvider>
            </QueryClientProvider>
          </UrqlProvider>
        </JotaiProvider>
      </ThemeProvider>
    </>
  )
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  