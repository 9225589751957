import { AlphaVaultABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { useState, useEffect } from 'react'

const useAlphaTicks = (vaultAddress: AddressType | undefined) => {
  const [lowerTick, setLowerTick] = useState<number | undefined>(undefined)
  const [upperTick, setUpperTick] = useState<number | undefined>(undefined)

  const { data: upperTickData, isFetching: isFetchingUpperTick } = useReadContractWithErrorHandling(
    {
      address: vaultAddress!,
      abi: AlphaVaultABI,
      functionName: 'upperTick',
    }
  )

  const { data: lowerTickData, isFetching: isFetchingLowerTick } = useReadContractWithErrorHandling(
    {
      address: vaultAddress!,
      abi: AlphaVaultABI,
      functionName: 'lowerTick',
    }
  )

  useEffect(() => {
    setLowerTick(lowerTickData)
  }, [lowerTickData])

  useEffect(() => {
    setUpperTick(upperTickData)
  }, [upperTickData])

  return {
    upperTick,
    lowerTick,
    isFetching: isFetchingLowerTick || isFetchingUpperTick,
  }
}

export default useAlphaTicks
