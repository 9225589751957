import { EventHook, useOrangeQuery } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { getRebalanceEventsListQuery } from '@apps-orangefi/lib/subgraph/queries'
import { TokenValueWithDate } from '@apps-orangefi/lib/types'
import dayjs from 'dayjs'
import { chain, isEqual, last } from 'lodash'
import { useState, useEffect, useMemo } from 'react'

export const useDopexEvent: EventHook = ({
  vaultAddress,
  yieldStart,
  enabled,
  daysBeforeRecentEvent = 1,
}) => {
  const [tokenValuesWithDate, setTokenValueWithDate] = useState<TokenValueWithDate[]>([])
  const [vaultPerformance, setVaultPerformance] = useState<BN | undefined>(undefined)

  const [result, reexecuteQuery] = useOrangeQuery({
    query: getRebalanceEventsListQuery,
    variables: {
      vaultAddress: vaultAddress?.toLowerCase() ?? '',
      yieldStart: ((yieldStart ?? 0) / 1000).toString(),
    },
    pause: !vaultAddress,
  })
  const { data, fetching, error } = useMemo(() => result, [result])

  useEffect(() => {
    const events = chain(data?.rebalances)
      .groupBy(rebalance => {
        return dayjs.unix(Number(rebalance.blockTimestamp)).format('YYYY-MM-DD')
      })
      .values()
      .flatMap(rebalances => {
        return last(rebalances)
      })
      .compact()
      .map(rebalance => {
        const blockTime = dayjs.unix(Number(rebalance.blockTimestamp))

        return {
          date: dayjs(blockTime.format('YYYY-MM-DD HH:mm')).valueOf(),
          timestamp: blockTime.valueOf(),
          tokenValue: new BN(rebalance.rate).times(100),
        } as TokenValueWithDate
      })
      .value()

    const firstEvent = events[0]
    const result = events.map(event => {
      return {
        ...event,
        gainValueSinceYieldSatrt: event.tokenValue.minus(firstEvent.tokenValue),
      }
    })
    if (isEqual(result, tokenValuesWithDate)) return
    setTokenValueWithDate(result)
  }, [data])

  useEffect(() => {
    const events = chain(data?.rebalances)
      .groupBy(rebalance => {
        return dayjs.unix(Number(rebalance.blockTimestamp)).format('YYYY-MM-DD')
      })
      .values()
      .flatMap(rebalances => {
        return last(rebalances)
      })
      .compact()
      .reverse()
      .value()

    if (events.length >= daysBeforeRecentEvent + 1) {
      const tokenValues = [events[0], events[daysBeforeRecentEvent]].map(e => {
        return new BN(e.rate).minus(1).times(100)
      })

      const performance = tokenValues[0].minus(tokenValues[1])
      if (isEqual(performance, vaultPerformance)) return
      setVaultPerformance(performance)
    }
  }, [data])

  return {
    tokenValuesWithDate,
    vaultPerformance,
    isFetching: fetching,
  }
}
