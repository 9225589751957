import { useResponsiveType, responsiveType } from '@apps-orangefi/hooks'
import { LeaderboardRow } from '@apps-orangefi/lib/types'
import { Rank, RankSm } from '@apps-orangefi/ui/atoms'
import useTranslation from 'next-translate/useTranslation'

type Props = {
  leaderboards: LeaderboardRow[]
}

export const Leaderboard = ({ leaderboards }: Props) => {
  const { t } = useTranslation()
  const rType = useResponsiveType()
  const isMdOrLarger = rType === responsiveType.MdOrLarger

  return (
    <>
      {isMdOrLarger ? (
        <div className="flex flex-col">
          <div className="type-2xl-semibold dark:text-white">{t('MODEL.LEADERBOARD.TITLE')}</div>
          <div className="flex flex-col dark:bg-gray-850 rounded-2xl px-1 pb-1 mt-4">
            <div className="flex flex-row type-sm-medium dark:text-gray-400 py-3 px-4">
              <div className="w-1/12">Rank</div>
              <div className="w-6/12">Wallet address</div>
              <div className="w-4/12">Total share</div>
              <div className="w-1/12">Score</div>
            </div>
            <div className="max-h-[880px] overflow-y-scroll type-base-semibold dark:text-white">
              {leaderboards.map((data, i) => (
                <Rank key={i} {...data} className="mb-1" />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="max-h-[1600px] overflow-y-scroll">
          {leaderboards.map((data, i) => (
            <RankSm key={i} {...data} className="mb-1" />
          ))}
        </div>
      )}
    </>
  )
}
