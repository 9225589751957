import { useGetInvalidateQueries } from '@apps-orangefi/hooks'
import { ReservedPosition } from '@apps-orangefi/lib/types'
import { genTokenId } from '@apps-orangefi/lib/utils'
import { ReserveHelperABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { chain, isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

export const useReservedPositions = (
  helperAddress: AddressType | undefined | null,
  poolAddress: AddressType | undefined,
  handlerAddress: AddressType | undefined
) => {
  const [reservedPositions, setReservedPositions] = useState<ReservedPosition[]>([])
  const enabled = useMemo(() => !!helperAddress, [helperAddress])

  const { data, isFetching, isFetched, queryKey } = useReadContractWithErrorHandling({
    address: helperAddress!,
    abi: ReserveHelperABI,
    functionName: 'getReservedPositions',
    query: {
      enabled,
    },
  })

  const { refresh } = useGetInvalidateQueries({ queryKey })

  useEffect(() => {
    if (data && !!poolAddress && !!handlerAddress) {
      const result = chain(data)
        .filter(item => item.pool.toLowerCase() === poolAddress.toLowerCase())
        .map(item => {
          return {
            ...item,
            tokenId: genTokenId(
              handlerAddress,
              item.pool,
              item.hook,
              item.tickLower,
              item.tickUpper
            ),
          }
        })
        .value()
      if (!isEqual(reservedPositions, result)) {
        setReservedPositions(result)
      }
    }
  }, [data, poolAddress, handlerAddress])

  return {
    reservedPositions,
    isFetching,
    isFetched,
    refresh,
  }
}
