import { Amm, Derivative } from '@apps-orangefi/lib/types'
import { getSourceRevenue } from '@apps-orangefi/lib/utils'
import Image from 'next/image'

type Props = {
  amm: Amm | undefined
  derivative?: Derivative | undefined
  size?: number
  className?: string
}

export const SourceRevenue = ({ amm, derivative, size = 20, className = '' }: Props) => {
  const revenues = [getSourceRevenue(amm), getSourceRevenue(derivative)].filter(r => !!r)

  return (
    <div className="flex flex-row flex-wrap justify-start items-center relative">
      {revenues.map((revenue, index) => (
        <div key={index} className="flex">
          {revenue && (
            <div className="flex flex-row">
              <Image
                src={revenue[1]}
                alt="revenue"
                width={size}
                height={size}
                className="rounded-full"
              />
              <div className="ml-2">{revenue[0]?.toUpperCase()}</div>
            </div>
          )}
          {revenues.length > 1 && index < revenues.length - 1 && (
            <span className="type-sm-caption dark:text-gray-650 mx-3">/</span>
          )}
        </div>
      ))}
    </div>
  )
}
