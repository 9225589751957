import { BN } from '@apps-orangefi/lib'
import { bigintToBN } from '@apps-orangefi/lib/utils'
import { StrykeLPAutomatorV2ABI, StrykeUniV3HandlerV2ABI } from '@apps-orangefi/wagmi/abis'
import { ResultUseWithdraw, WithdrawHookParams } from '@apps-orangefi/wagmi/hooks'
import {
  useSimulateContractWithErrorHandling,
  useWriteContractWithErrorHandling,
  useWaitForTransactionReceiptWithErrorHandling,
} from '@apps-orangefi/wagmi/hooks/common'
import { useMemo, useEffect, useState } from 'react'
import { Log, parseEventLogs } from 'viem'
import { BaseError } from 'wagmi'

import '@apps-orangefi/lib/extensions'

export type WithdrawnLP = {
  tokenId: string
  share: bigint
}

export const useWithdrawLPDfi = ({
  fromWithdrawAddress,
  account,
  handlerAddress,
  shares,
  minAssets,
  vaultDecimals,
  callback,
}: Omit<WithdrawHookParams, 'vaultAddress' | 'inspectorAddress'> & {
  minAssets: BN | undefined
  handlerAddress: AddressType | undefined
}) => {
  const [isWithdrawReady, setIsWithdrawReady] = useState(false)
  const [withdrawnLPs, setWithdrawnLPs] = useState<WithdrawnLP[]>([])

  const vaultAddress = fromWithdrawAddress
  const _shares = shares.pow10(vaultDecimals ?? 0).convertBigint()

  const _minAssets = useMemo(() => {
    return minAssets?.pow10(vaultDecimals ?? 0).convertBigint()
  }, [minAssets, vaultDecimals])

  const args = useMemo(() => {
    return [_shares, _minAssets!] as const
  }, [_shares, _minAssets])

  // NOTE: debounce occurrs contract error
  // const enabled = useDebounce(!!fromWithdrawAddress && !!account && shares.gt(0), 300)
  const enabled = !!fromWithdrawAddress && !!account && !shares.isZero() && !!minAssets

  const { data } = useSimulateContractWithErrorHandling({
    address: fromWithdrawAddress!,
    abi: StrykeLPAutomatorV2ABI,
    functionName: 'redeem',
    args,
    query: {
      enabled,
    },
  })

  const { data: hash, writeContract } = useWriteContractWithErrorHandling({
    mutation: {
      onError(error) {
        if (callback && callback.fail) {
          callback.fail(error.cause as BaseError)
        }
      },
    },
  })

  const [isTransactionEnd, setIsTransactionEnd] = useState(false)
  const {
    data: receipt,
    isLoading: waitLoading,
    isSuccess,
    isError,
    error,
  } = useWaitForTransactionReceiptWithErrorHandling({ hash })

  useEffect(() => {
    if (isTransactionEnd) return
    if (isSuccess) {
      if (callback && callback.success) {
        callback.success()
        setIsTransactionEnd(true)
      }
    }
    if (isError && error) {
      if (callback && callback.fail) {
        callback.fail(error.message)
        setIsTransactionEnd(true)
      }
    }
  }, [isSuccess, isError, error, callback])

  useEffect(() => {
    setIsWithdrawReady(!!writeContract && !!data?.request)
  }, [writeContract, data?.request])

  useEffect(() => {
    if (isSuccess && receipt) {
      const _logs = receipt.logs.filter(
        (log: Log) => log.address.toLowerCase() === handlerAddress?.toLowerCase()
      )
      const transferEvents = parseEventLogs({
        abi: StrykeUniV3HandlerV2ABI,
        eventName: 'Transfer',
        logs: _logs,
      })

      const relevantTransferEvents = transferEvents
        .filter(event => {
          return (
            event.args.from.toLowerCase() === vaultAddress?.toLowerCase() &&
            event.args.to.toLowerCase() === account?.toLowerCase()
          )
        })
        .map(event => {
          return {
            tokenId: event.args.id.toString(),
            share: event.args.amount,
          }
        })

      setWithdrawnLPs(relevantTransferEvents)
    }
  }, [isSuccess, receipt, handlerAddress, vaultAddress, account])

  return {
    isWithdrawReady,
    isTransactionEnd,
    hash,
    isSuccess,
    onWithdraw: () => {
      if (!isWithdrawReady) {
        return
      }
      writeContract(data!.request)
    },
    withdrawnLPs,
  }
}
