import { Feature } from '@apps-orangefi/ui/molecules/strategy'
import useTranslation from 'next-translate/useTranslation'
import { memo } from 'react'

export const FAQ = memo(function FAQ() {
  const { t } = useTranslation()

  return (
    <section className="pt-10 flex flex-col">
      <h2 className="type-2xl-semibold dark:text-white">{t('DOCS.FAQ.TITLE')}</h2>
      <div className="flex flex-col gap-3 mt-6">
        <Feature title={t('DOCS.FAQ.1.TITLE')} description={t('DOCS.FAQ.1.DESC')} />
        <Feature title={t('DOCS.FAQ.2.TITLE')} description={t('DOCS.FAQ.2.DESC')} />
        <Feature title={t('DOCS.FAQ.3.TITLE')} description={t('DOCS.FAQ.3.DESC')} />
      </div>
    </section>
  )
})
