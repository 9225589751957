import ExternalLink from '@apps-orangefi/assets/images/external-link.svg'
import { shortAddress } from '@apps-orangefi/lib/utils'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'

type Props = {
  symbol: string
  address: AddressType
  url: string
  className?: string
}

export const ContractLink = ({ symbol, address, url, className = '' }: Props) => {
  return (
    <a
      href={url}
      target="_blank"
      className={twMerge(
        `flex flex-row justify-between items-center py-4 px-5 dark:bg-gray-850 ${className}`
      )}
    >
      <div className="type-base-medium dark:text-white">{symbol}</div>
      <div className="flex flex-row">
        <div className="type-base-semibold font-mono dark:text-white">{shortAddress(address)}</div>
        <Image src={ExternalLink} alt={symbol} width={16} height={16} className="ml-3" />
      </div>
    </a>
  )
}
