// import { QueryFunctionContext } from '@tanstack/react-query'
import axios from 'axios'

type FetchStatsQueryKey = {
  queryKey: [string, {}]
}

type StatsResult = {
  [address: string]: {
    name: string
    rewardAPR: number
  }
}

export const fetchFallbackStats = async ({ queryKey }: FetchStatsQueryKey) => {
  let url = process.env.NEXT_PUBLIC_FALLBACK_STATS_URL
  if (!url) {
    return {}
  }

  const res = await axios.get<StatsResult>(url)

  return res.data
}
