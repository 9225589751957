/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query getVaultList($account: ID!) {\n    vaults(orderBy: tvl, orderDirection: desc) {\n      id\n      totalAssets\n      totalSupply\n      feeAPR\n      totalFeeUSD\n      tvl\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool {\n        id\n        token0 {\n          id\n          name\n          symbol\n          decimals\n          derivedETH\n        }\n        token1 {\n          id\n          name\n          symbol\n          decimals\n          derivedETH\n        }\n      }\n    }\n    user(id: $account) {\n      id\n      positions(where: { share_gt: 0 }) {\n        id\n        vault\n        share\n      }\n    }\n    globalState(id: \"current\") {\n      ethPriceUSD\n    }\n  }\n": types.GetVaultListDocument,
    "\n  query getVault($account: ID!, $vaultAddress: ID!) {\n    vault(id: $vaultAddress) {\n      id\n      totalAssets\n      totalSupply\n      feeAPR\n      totalFeeUSD\n      tvl\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      parameter\n      pool {\n        id\n        tick\n        sqrtPrice\n        token0 {\n          id\n          symbol\n          decimals\n          derivedETH\n        }\n        token1 {\n          id\n          symbol\n          decimals\n          derivedETH\n        }\n      }\n      tokenComposition {\n        vaultToken1\n        vaultToken0\n        poolToken1\n        poolToken0\n      }\n    }\n    user(id: $account) {\n      id\n      positions(where: { share_gt: 0 }) {\n        id\n        vault\n        share\n      }\n    }\n    globalState(id: \"current\") {\n      ethPriceUSD\n    }\n  }\n": types.GetVaultDocument,
    "\n  query getDopexVaultList($account: ID!) {\n    dopexVaults(orderBy: totalSupply, orderDirection: desc) {\n      id\n      totalAssets\n      totalSupply\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool\n    }\n    user(id: $account) {\n      id\n      positions(where: { share_gt: 0 }) {\n        id\n        vault\n        share\n      }\n    }\n  }\n": types.GetDopexVaultListDocument,
    "\n  query getDopexVault($account: ID!, $vaultAddress: ID!) {\n    dopexVault(id: $vaultAddress) {\n      id\n      totalAssets\n      totalSupply\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool\n    }\n    user(id: $account) {\n      id\n      positions(where: { share_gt: 0 }) {\n        id\n        vault\n        share\n      }\n    }\n  }\n": types.GetDopexVaultDocument,
    "\n  query getSpaceshipVaultList($account: ID!) {\n    dopexVaults(orderBy: totalSupply, orderDirection: desc) {\n      id\n      totalAssets\n      totalSupply\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool\n    }\n    vaults(orderBy: totalSupply, orderDirection: desc) {\n      id\n      totalAssets\n      totalSupply\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool {\n        id\n      }\n    }\n  }\n": types.GetSpaceshipVaultListDocument,
    "\n  query getSpaceshipVaultListWithBlockNumber($blockNumber: Int) {\n    dopexVaults(block: { number: $blockNumber }, orderBy: totalSupply, orderDirection: desc) {\n      id\n      totalAssets\n      totalSupply\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool\n    }\n    vaults(block: { number: $blockNumber }, orderBy: totalSupply, orderDirection: desc) {\n      id\n      totalAssets\n      totalSupply\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool {\n        id\n      }\n    }\n  }\n": types.GetSpaceshipVaultListWithBlockNumberDocument,
    "\n  query getDopexRebalanceEventsList($vaultAddress: String!, $yieldStart: BigInt!) {\n    rebalances(\n      where: { vault: $vaultAddress, blockTimestamp_gte: $yieldStart }\n      orderBy: blockTimestamp\n      orderDirection: asc\n    ) {\n      id\n      vault\n      totalAssets\n      totalSupply\n      rate\n      blockNumber\n      blockTimestamp\n    }\n  }\n": types.GetDopexRebalanceEventsListDocument,
    "\n  query getMultiPoolReservedLiquidityList(\n    $account: String!\n    $poolIds: [String!]!\n    $handlerIds: [String!]!\n    $skip: Int\n  ) {\n    reservedLiquidities(\n      where: { user: $account, handler_in: $handlerIds, pool_in: $poolIds, liquidity_gt: 0 }\n      first: 1000\n      skip: $skip\n    ) {\n      id\n      tokenId\n      handler\n      helper\n      user\n      pool\n      tickLower\n      tickUpper\n      liquidity\n      blockNumber\n      blockTimestamp\n    }\n  }\n": types.GetMultiPoolReservedLiquidityListDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getVaultList($account: ID!) {\n    vaults(orderBy: tvl, orderDirection: desc) {\n      id\n      totalAssets\n      totalSupply\n      feeAPR\n      totalFeeUSD\n      tvl\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool {\n        id\n        token0 {\n          id\n          name\n          symbol\n          decimals\n          derivedETH\n        }\n        token1 {\n          id\n          name\n          symbol\n          decimals\n          derivedETH\n        }\n      }\n    }\n    user(id: $account) {\n      id\n      positions(where: { share_gt: 0 }) {\n        id\n        vault\n        share\n      }\n    }\n    globalState(id: \"current\") {\n      ethPriceUSD\n    }\n  }\n"): (typeof documents)["\n  query getVaultList($account: ID!) {\n    vaults(orderBy: tvl, orderDirection: desc) {\n      id\n      totalAssets\n      totalSupply\n      feeAPR\n      totalFeeUSD\n      tvl\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool {\n        id\n        token0 {\n          id\n          name\n          symbol\n          decimals\n          derivedETH\n        }\n        token1 {\n          id\n          name\n          symbol\n          decimals\n          derivedETH\n        }\n      }\n    }\n    user(id: $account) {\n      id\n      positions(where: { share_gt: 0 }) {\n        id\n        vault\n        share\n      }\n    }\n    globalState(id: \"current\") {\n      ethPriceUSD\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getVault($account: ID!, $vaultAddress: ID!) {\n    vault(id: $vaultAddress) {\n      id\n      totalAssets\n      totalSupply\n      feeAPR\n      totalFeeUSD\n      tvl\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      parameter\n      pool {\n        id\n        tick\n        sqrtPrice\n        token0 {\n          id\n          symbol\n          decimals\n          derivedETH\n        }\n        token1 {\n          id\n          symbol\n          decimals\n          derivedETH\n        }\n      }\n      tokenComposition {\n        vaultToken1\n        vaultToken0\n        poolToken1\n        poolToken0\n      }\n    }\n    user(id: $account) {\n      id\n      positions(where: { share_gt: 0 }) {\n        id\n        vault\n        share\n      }\n    }\n    globalState(id: \"current\") {\n      ethPriceUSD\n    }\n  }\n"): (typeof documents)["\n  query getVault($account: ID!, $vaultAddress: ID!) {\n    vault(id: $vaultAddress) {\n      id\n      totalAssets\n      totalSupply\n      feeAPR\n      totalFeeUSD\n      tvl\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      parameter\n      pool {\n        id\n        tick\n        sqrtPrice\n        token0 {\n          id\n          symbol\n          decimals\n          derivedETH\n        }\n        token1 {\n          id\n          symbol\n          decimals\n          derivedETH\n        }\n      }\n      tokenComposition {\n        vaultToken1\n        vaultToken0\n        poolToken1\n        poolToken0\n      }\n    }\n    user(id: $account) {\n      id\n      positions(where: { share_gt: 0 }) {\n        id\n        vault\n        share\n      }\n    }\n    globalState(id: \"current\") {\n      ethPriceUSD\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getDopexVaultList($account: ID!) {\n    dopexVaults(orderBy: totalSupply, orderDirection: desc) {\n      id\n      totalAssets\n      totalSupply\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool\n    }\n    user(id: $account) {\n      id\n      positions(where: { share_gt: 0 }) {\n        id\n        vault\n        share\n      }\n    }\n  }\n"): (typeof documents)["\n  query getDopexVaultList($account: ID!) {\n    dopexVaults(orderBy: totalSupply, orderDirection: desc) {\n      id\n      totalAssets\n      totalSupply\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool\n    }\n    user(id: $account) {\n      id\n      positions(where: { share_gt: 0 }) {\n        id\n        vault\n        share\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getDopexVault($account: ID!, $vaultAddress: ID!) {\n    dopexVault(id: $vaultAddress) {\n      id\n      totalAssets\n      totalSupply\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool\n    }\n    user(id: $account) {\n      id\n      positions(where: { share_gt: 0 }) {\n        id\n        vault\n        share\n      }\n    }\n  }\n"): (typeof documents)["\n  query getDopexVault($account: ID!, $vaultAddress: ID!) {\n    dopexVault(id: $vaultAddress) {\n      id\n      totalAssets\n      totalSupply\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool\n    }\n    user(id: $account) {\n      id\n      positions(where: { share_gt: 0 }) {\n        id\n        vault\n        share\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getSpaceshipVaultList($account: ID!) {\n    dopexVaults(orderBy: totalSupply, orderDirection: desc) {\n      id\n      totalAssets\n      totalSupply\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool\n    }\n    vaults(orderBy: totalSupply, orderDirection: desc) {\n      id\n      totalAssets\n      totalSupply\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query getSpaceshipVaultList($account: ID!) {\n    dopexVaults(orderBy: totalSupply, orderDirection: desc) {\n      id\n      totalAssets\n      totalSupply\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool\n    }\n    vaults(orderBy: totalSupply, orderDirection: desc) {\n      id\n      totalAssets\n      totalSupply\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getSpaceshipVaultListWithBlockNumber($blockNumber: Int) {\n    dopexVaults(block: { number: $blockNumber }, orderBy: totalSupply, orderDirection: desc) {\n      id\n      totalAssets\n      totalSupply\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool\n    }\n    vaults(block: { number: $blockNumber }, orderBy: totalSupply, orderDirection: desc) {\n      id\n      totalAssets\n      totalSupply\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query getSpaceshipVaultListWithBlockNumber($blockNumber: Int) {\n    dopexVaults(block: { number: $blockNumber }, orderBy: totalSupply, orderDirection: desc) {\n      id\n      totalAssets\n      totalSupply\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool\n    }\n    vaults(block: { number: $blockNumber }, orderBy: totalSupply, orderDirection: desc) {\n      id\n      totalAssets\n      totalSupply\n      decimals\n      yieldStart\n      isTokenPairReversed\n      depositCap\n      pool {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getDopexRebalanceEventsList($vaultAddress: String!, $yieldStart: BigInt!) {\n    rebalances(\n      where: { vault: $vaultAddress, blockTimestamp_gte: $yieldStart }\n      orderBy: blockTimestamp\n      orderDirection: asc\n    ) {\n      id\n      vault\n      totalAssets\n      totalSupply\n      rate\n      blockNumber\n      blockTimestamp\n    }\n  }\n"): (typeof documents)["\n  query getDopexRebalanceEventsList($vaultAddress: String!, $yieldStart: BigInt!) {\n    rebalances(\n      where: { vault: $vaultAddress, blockTimestamp_gte: $yieldStart }\n      orderBy: blockTimestamp\n      orderDirection: asc\n    ) {\n      id\n      vault\n      totalAssets\n      totalSupply\n      rate\n      blockNumber\n      blockTimestamp\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getMultiPoolReservedLiquidityList(\n    $account: String!\n    $poolIds: [String!]!\n    $handlerIds: [String!]!\n    $skip: Int\n  ) {\n    reservedLiquidities(\n      where: { user: $account, handler_in: $handlerIds, pool_in: $poolIds, liquidity_gt: 0 }\n      first: 1000\n      skip: $skip\n    ) {\n      id\n      tokenId\n      handler\n      helper\n      user\n      pool\n      tickLower\n      tickUpper\n      liquidity\n      blockNumber\n      blockTimestamp\n    }\n  }\n"): (typeof documents)["\n  query getMultiPoolReservedLiquidityList(\n    $account: String!\n    $poolIds: [String!]!\n    $handlerIds: [String!]!\n    $skip: Int\n  ) {\n    reservedLiquidities(\n      where: { user: $account, handler_in: $handlerIds, pool_in: $poolIds, liquidity_gt: 0 }\n      first: 1000\n      skip: $skip\n    ) {\n      id\n      tokenId\n      handler\n      helper\n      user\n      pool\n      tickLower\n      tickUpper\n      liquidity\n      blockNumber\n      blockTimestamp\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;