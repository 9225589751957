import { ProductType } from '@apps-orangefi/lib/types'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  items: {
    label: string
    value: ProductType | null
  }[]
  onSelect: (type: ProductType | null) => void
  className?: string
}

export const ProductTypeSelector = ({ items, onSelect, className }: Props) => {
  const [selectedProductType, setSelectedProtoType] = useState<ProductType | null>(null)

  const ProductType = ({
    label,
    value,
    isSelected,
    onClick,
  }: {
    label: string
    value: ProductType | null
    isSelected?: boolean
    onClick: (type: ProductType | null) => void
  }) => {
    return (
      <div
        onClick={() => onClick(value)}
        className={`flex flex-row items-center justify-between box-border rounded-2xl px-3 py-3 md:px-5 md:py-4 w-full cursor-pointer ${
          isSelected ? 'dark:bg-gray-800 cursor-default' : 'dark:bg-gray-850 hover:opacity-70'
        }`}
      >
        <span className="leading-4 type-sm-medium sm:type-base-medium">{label}</span>
        {isSelected && (
          <div className="w-2 h-2 rounded-full dark:bg-orange-500 drop-shadow-[0_2px_6px_rgba(251,91,13,0.8)]"></div>
        )}
      </div>
    )
  }

  return (
    <div
      className={twMerge('flex flex-row sm:flex-col gap-2 sm:w-content min-w-[200px]', className)}
    >
      {items.map((item, i) => (
        <ProductType
          label={item.label}
          value={item.value}
          isSelected={selectedProductType === item.value}
          onClick={(type: ProductType | null) => {
            if (type !== selectedProductType) {
              setSelectedProtoType(type)
              onSelect(type)
            }
          }}
          key={i}
        />
      ))}
    </div>
  )
}
