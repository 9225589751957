import PancakeswapIcon from '@apps-orangefi/assets/images/amm/pancakeswap.svg'
import SushiIcon from '@apps-orangefi/assets/images/amm/sushi.svg'
import UniswapIcon from '@apps-orangefi/assets/images/amm/uniswap.svg'
import CamelotIcon from '@apps-orangefi/assets/images/products/camelot/dex.svg'
import StrykeIcon from '@apps-orangefi/assets/images/products/stryke/concept.svg'
import OptionPremiumIcon from '@apps-orangefi/assets/images/revenues/option-premium.svg'
import SwapFeeIcon from '@apps-orangefi/assets/images/revenues/swap-fee.svg'
import { Amm, amm, Derivative, derivative } from '@apps-orangefi/lib/types'

export const getAmmIcon = (ammKey: Amm | undefined) => {
  switch (ammKey) {
    case amm.UNISWAP:
      return UniswapIcon
    case amm.PANCAKESWAP:
      return PancakeswapIcon
    case amm.SUSHI:
      return SushiIcon
    case amm.CAMELOT:
      return CamelotIcon
    default:
      return null
  }
}

export const getDerivativeIcon = (derivativeKey: Derivative | undefined) => {
  switch (derivativeKey) {
    case derivative.STRYKE:
      return StrykeIcon
    default:
      return null
  }
}

export const getSourceRevenue = (key: Amm | Derivative | undefined): [string, any] | null => {
  switch (key) {
    case derivative.STRYKE:
      return ['Option Premium', OptionPremiumIcon]
    case amm.UNISWAP:
    case amm.PANCAKESWAP:
    case amm.SUSHI:
    case amm.CAMELOT:
      return ['Swap Fee', SwapFeeIcon]
    default:
      return null
  }
}
