import { getConfig } from '@apps-orangefi/wagmi'
import { SafeAppProvider } from '@safe-global/safe-apps-provider'
import SafeAppsSDK, { SafeInfo } from '@safe-global/safe-apps-sdk'
import { useEffect, useState } from 'react'
import { Transport, EIP1193RequestFn, custom, CustomSource, createWalletClient } from 'viem'
import { createConfig, Config, http, useAccount, useConnect } from 'wagmi'
import { WagmiProvider } from 'wagmi'
import { arbitrum } from 'wagmi/chains'
import { safe } from 'wagmi/connectors'

function createSafeTransport(provider: SafeAppProvider): Transport {
  return ({ chain }) => ({
    config: {
      key: 'safe',
      name: 'Safe App Provider',
      type: 'safe' as const,
      request: provider.request.bind(provider) as EIP1193RequestFn,
    },
    request: provider.request.bind(provider) as EIP1193RequestFn,
    value: {
      chainId: provider.chainId,
    },
  })
}

export function useSafeWagmiConfig() {
  const [safeInfo, setSafeInfo] = useState<SafeInfo | null>(null)
  const [config, setConfig] = useState<Config | null>(null)
  const [isSafeApp, setIsSafeApp] = useState<boolean | null>(null)

  useEffect(() => {
    const initConfig = async () => {
      // Safe環境の検出
      const detectSafeEnvironment = async (): Promise<boolean> => {
        return new Promise(resolve => {
          const timeout = setTimeout(() => {
            console.log('Safe environment detection timed out')
            resolve(false)
          }, 1000)

          const sdk = new SafeAppsSDK()
          sdk.safe
            .getInfo()
            .then(() => {
              clearTimeout(timeout)
              resolve(true)
            })
            .catch(() => {
              clearTimeout(timeout)
              resolve(false)
            })
        })
      }

      const isSafeEnv = await detectSafeEnvironment()
      setIsSafeApp(isSafeEnv)

      if (isSafeEnv) {
        console.log('Running in Safe environment')
        const sdk = new SafeAppsSDK()
        try {
          const safeInfo = await sdk.safe.getInfo()
          console.log('Safe Info:', safeInfo)
          setSafeInfo(safeInfo)

          const safeProvider = new SafeAppProvider(safeInfo, sdk)

          const wagmiConfig = createConfig({
            chains: [arbitrum],
            connectors: [safe()],
            transports: {
              [arbitrum.id]: createSafeTransport(safeProvider),
            },
          })

          setConfig(wagmiConfig)
        } catch (error) {
          console.error('Failed to get Safe info:', error)
          fallbackToRegularConfig()
        }
      } else {
        console.log('Not running in Safe environment')
        fallbackToRegularConfig()
      }
    }

    const fallbackToRegularConfig = () => {
      const wagmiConfig = getConfig()
      setConfig(wagmiConfig)
    }

    initConfig()
  }, [])

  return { config, safeInfo, isSafeApp }
}

const SafeAutoConnectProvider = ({ children }: { children: React.ReactNode }) => {
  const { config, safeInfo } = useSafeWagmiConfig()

  if (!config) {
    return <main className="flex-1 bg-main w-full h-full"></main>
  }
  return (
    <WagmiProvider config={config}>
      <SafeAutoConnectContent safeInfo={safeInfo}>{children}</SafeAutoConnectContent>
    </WagmiProvider>
  )
}

function SafeAutoConnectContent({
  children,
  safeInfo,
}: {
  children: React.ReactNode
  safeInfo: SafeInfo | null
}) {
  const { isConnected } = useAccount()
  const { connect, connectors } = useConnect()

  useEffect(() => {
    if (safeInfo && !isConnected) {
      const safeConnector = connectors.find(c => c.name === 'Safe')
      if (safeConnector) {
        connect({ connector: safeConnector })
      }
    }
  }, [safeInfo, isConnected, connect, connectors])

  return (
    <>
      {/* {isConnected ? (
        <div>
          <p>Connected address: {address}</p>
          {safeInfo && <p>Safe address: {safeInfo.safeAddress}</p>}
        </div>
      ) : (
        <p>Not connected to Safe environment</p>
      )} */}
      {children}
    </>
  )
}

export { SafeAutoConnectProvider }
