import { useState, useEffect } from 'react'

export const responsiveType = {
  SmallerThanMd: 'smallerThanMd',
  MdOrLarger: 'mdOrLarger',
}
export type ResponsiveType = typeof responsiveType[keyof typeof responsiveType]

// ウィンドウ幅に基づいてtypeを返すカスタムフック
export const useResponsiveType = () => {
  const [type, setType] = useState<ResponsiveType>(responsiveType.SmallerThanMd)

  useEffect(() => {
    // Tailwindのブレークポイントに合わせてピクセル値を設定
    const breakpointMd = 768 // 通常のmdブレークポイント

    function handleResize() {
      if (window.innerWidth < breakpointMd) {
        setType(responsiveType.SmallerThanMd)
      } else {
        setType(responsiveType.MdOrLarger)
      }
    }

    // イベントリスナーを設定
    window.addEventListener('resize', handleResize)

    // 初期値を設定
    handleResize()

    // クリーンアップ関数
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return type
}
