import { useDebounce } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { bigintToBN } from '@apps-orangefi/lib/utils'
import { AlphaVaultABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { useMemo, useState, useEffect } from 'react'

// import '@apps-orangefi/lib/extensions'

const useConvertToShares = (
  vaultAddress: AddressType | undefined,
  amount: BN,
  vaultDecimals: number | null
) => {
  const [shares, setShares] = useState<BN | undefined>(undefined)

  const _amount = amount.pow10(vaultDecimals ?? 0).convertBigint()

  const args = useMemo(() => {
    return [_amount] as const
  }, [_amount])

  const enabled = useDebounce(!!vaultAddress && !!amount.gt(0), 300)

  const { data, isFetching } = useReadContractWithErrorHandling({
    address: vaultAddress!,
    abi: AlphaVaultABI,
    functionName: 'convertToShares',
    args,
    query: {
      enabled,
    },
  })

  useEffect(() => {
    if (!!data && !!vaultDecimals) {
      setShares(bigintToBN(data).pow10ofMinus(vaultDecimals))
    }
  }, [data, vaultDecimals])

  return {
    shares,
    isFetching,
  }
}

export default useConvertToShares
