import css from './index.module.css'

type Props = {
  type?: 'dots' | 'line' | 'triangle'
  className?: string
}

const LineLoader = ({ type = 'dots', className }: Props) => {
  return (
    <span className={className}>
      <span className={[css.spinner, css[`${type}`]].join(' ')} />
    </span>
  )
}
export default LineLoader
