import { IconInfo } from '@apps-orangefi/ui/molecules/icons'

type Props = {
  label: string
  value: string
  tooltipText: string
}

export const EstimateItem = ({ label, value, tooltipText }: Props) => {
  return (
    <div className="flex flex-row justify-between">
      <div className="flex">
        <span className="type-sm-medium dark:text-gray-400">{label}</span>
        <IconInfo size={16} tooltipText={tooltipText} className="ml-2" />
      </div>
      <span className="type-sm-semibold dark:text-white">{value}</span>
    </div>
  )
}
