import WireHatch from '@apps-orangefi/assets/images/point/wireframe-hatch.svg'
import { User } from '@apps-orangefi/lib/types/point'
import { OctagonContainer } from '@apps-orangefi/ui/atoms/point'
import { AccountStatus } from '@apps-orangefi/ui/molecules/point'
import Image from 'next/image'
import { CSSProperties } from 'react'

type Props = {
  user: User | undefined
}

const width = 130
const styleVars = {
  width: `${width}px`,
  position: 'absolute',
  '--spotlight-width': `188px`,
  '--spotlight-height': `77px`,
  '--spotlight-left': `-30px`,
} as CSSProperties

export const Hatch = ({ user }: Props) => {
  return (
    <div className="flex flex-col sm:flex-row gap-6 sm:gap-3 py-0 sm:py-8 sm:py-0 items-start">
      <div className="flex flex-col gap-3 h-full w-full md:w-[640px] justify-start">
        <OctagonContainer size={6} className="flex flex-col items-center px-3 py-6 sm:p-8">
          <div
            className="border-t border-orange-500 top-0 left-6 active-box"
            style={styleVars}
          ></div>
          <div className="flex flex-col justify-between items-start flex-wrap w-full h-full">
            <div className="flex flex-col">
              <div className="flex flex-row justify-start items-baseline type-2xl-semibold">
                <span className="dark:text-orange-500">Chapter 2.</span>
                <span className="dark:text-white">&ldquo;The Hatch&rdquo;</span>
              </div>
              <div className="type-base-medium dark:text-white mt-4 mb-8">
                Lock Orange LP and earn points.
                <br />
                The more you lock and the longer the lock, the greater the point boost.
              </div>
            </div>
            <AccountStatus user={user} />
          </div>
        </OctagonContainer>
      </div>
      <Image
        src={WireHatch}
        width={508}
        height={400}
        alt="Wireframe Hole"
        className="sm:ml-3 top-0 right-0"
      />
    </div>
  )
}
