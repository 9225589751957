import { useDebounce, useGetInvalidateQueries } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { bigintToBN } from '@apps-orangefi/lib/utils'
import { AlphaVaultABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'
import { isNil } from 'lodash'
import { useMemo, useState, useEffect } from 'react'

const useConvertToAssets = (
  vaultAddress: AddressType | undefined,
  lpAmount: BN | undefined,
  vaultDecimals: number | null | undefined
) => {
  const [assets, setAssets] = useState<BN | undefined>(undefined)

  const _lpAmount = lpAmount?.pow10(vaultDecimals ?? 0).convertBigint()

  const args = useMemo(() => {
    return [_lpAmount!] as const
  }, [_lpAmount])

  const enabled = useMemo(() => {
    return !!vaultAddress && !isNil(lpAmount)
  }, [vaultAddress, lpAmount])

  const { data, isFetching, queryKey } = useReadContractWithErrorHandling({
    address: vaultAddress!,
    abi: AlphaVaultABI,
    functionName: 'convertToAssets',
    args,
    query: {
      enabled,
    },
  })

  const { refresh } = useGetInvalidateQueries({ queryKey })

  useEffect(() => {
    if (!isNil(data) && !!vaultDecimals) {
      setAssets(bigintToBN(data).pow10ofMinus(vaultDecimals))
    }
  }, [data, vaultDecimals])

  return {
    assets,
    isFetching,
    refresh,
  }
}

export default useConvertToAssets
