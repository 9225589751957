import { StepState, stepState, UserClaimEligiblity } from '@apps-orangefi/lib/types'
import { Button } from '@apps-orangefi/ui/atoms'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'

export type ClaimNFTProps = {
  userClaimEligiblity: UserClaimEligiblity | undefined
  steps: { step: number; label: string; state: StepState }[]
  nftStatus: { isClaimed: boolean; mintedNumbers: number }
  onSubmit: () => void
  onConnect: () => void
  isConnected: boolean
  isClaimable: boolean
}

export const ClaimNFT = ({
  userClaimEligiblity,
  steps,
  nftStatus,
  onSubmit,
  onConnect,
  isConnected,
  isClaimable,
}: ClaimNFTProps) => {
  const { t } = useTranslation()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  const [claimButtonLabel, claimButtonStyle] =
    steps[1].state === stepState.Active
      ? [t('WIDGET.CAMPAIGN.PROMOTE_DEPOSIT'), 'dark:bg-gray-700']
      : steps[2].state === stepState.Active
      ? [t('WIDGET.CAMPAIGN.CLAIM_NFT'), 'dark:bg-gray-700']
      : steps[3].state === stepState.Active
      ? isClaimable
        ? [t('WIDGET.CAMPAIGN.CLAIM_NFT'), '']
        : ['The NFT mint event will be on 15th May at 1 PM (UTC)', '']
      : steps[3].state === stepState.Completed
      ? [
          t('WIDGET.CAMPAIGN.CLAIMED'),
          'bg-emerald-400 dark:text-gray-950 opacity-100 hover:opacity-100',
        ]
      : ['', '']

  return (
    <div className="flex flex-col p-6 dark:bg-gray-850 rounded-2xl">
      <div className="flex flex-col md:flex-row justify-between">
        <div className="flex flex-row md:flex-col items-center justify-between w-full md:w-1/3">
          <div className="type-base-medium dark:text-gray-300">Deposit amount</div>
          <div className="type-base-semibold dark:text-white">
            {isConnected ? (
              <>
                <span>
                  {userClaimEligiblity
                    ? userClaimEligiblity.latestCommulativeEthAmount.toFixed(2)
                    : 0}{' '}
                  WETH
                </span>
                <span className="mx-1">/</span>
                <span>
                  {userClaimEligiblity
                    ? Number(
                        userClaimEligiblity.latestCommulativeUsdAmount.toFixed(0)
                      ).toLocaleString()
                    : 0}{' '}
                  USD
                </span>
              </>
            ) : (
              <span>Unavailable</span>
            )}
          </div>
        </div>
        <div className="flex flex-row md:flex-col items-center justify-between w-full md:w-1/3 mt-2.5 md:mt-0">
          <div className="type-base-medium dark:text-gray-300">Days remaining</div>
          <div className="type-base-semibold dark:text-white">
            {isConnected ? (
              <>{userClaimEligiblity ? userClaimEligiblity.remainingDays : '-'}</>
            ) : (
              <span>Unavailable</span>
            )}
          </div>
        </div>
        <div className="flex flex-row md:flex-col items-center justify-between w-full md:w-1/3 mt-2.5 md:mt-0">
          <div className="type-base-medium dark:text-gray-300">NFT minted</div>
          <div className="type-base-semibold dark:text-white">{`${nftStatus.mintedNumbers}/100`}</div>
        </div>
      </div>
      <div className="mt-6">
        {mounted && isConnected && (
          <Button
            label={claimButtonLabel}
            onSubmit={onSubmit}
            disabled={!isClaimable}
            className={`w-full rounded-xl md:rounded-2xl h-14 type-base-bold dark:text-white ${claimButtonStyle}`}
          />
        )}
        {mounted && !isConnected && (
          <Button
            onSubmit={onConnect}
            className="self-center w-full mt-2 px-6 py-3 md:py-4 rounded-xl md:rounded-2xl h-14"
            label={t('WIDGET.ACTION.CONNECT')}
          />
        )}
      </div>
    </div>
  )
}
