import { useTx, useStrykeQuery } from '@apps-orangefi/hooks'
import { Tx, txStatus, isAllowedCloseModalAtom, txListAtom } from '@apps-orangefi/lib/store'
import { getDopexStrikeListQuery } from '@apps-orangefi/lib/subgraph/queries'
import { GetDopexStrikeListQuery } from '@apps-orangefi/lib/subgraph/types/dopex/graphql'
import {
  useBatchReserveLiquidity,
  useGetPoolPositions,
  type ReserveLiquidityParam,
  type WithdrawnLP,
} from '@apps-orangefi/wagmi/hooks'
import { atom, useAtom } from 'jotai'
import { chain as _chain, isEmpty, every, some, find, isEqual, drop } from 'lodash'
import { useState, useEffect, useCallback, useMemo } from 'react'

const txReserveLiquidityDefault: Tx = {
  title: 'Reserve Liquidity',
  hash: undefined,
  status: txStatus.Wait,
}

const txReserveLiquidityAtom = atom<Tx>(txReserveLiquidityDefault)

export const useReserveLPForm = ({
  lastWithdrawnLPs,
  isEnabledReserve,
  reserveProxyAddress,
  handlerAddress,
}: {
  lastWithdrawnLPs: WithdrawnLP[]
  isEnabledReserve: boolean
  reserveProxyAddress?: AddressType
  handlerAddress?: AddressType
}) => {
  const [isReservedLiquidity, setIsReservedLiquidity] = useState(false)
  const [hasNotReservedLiquidity, setHasNotReservedLiquidity] = useState(false)
  const [reserveParamsWithPoolLiquidity, setReserveParamsWithPoolLiquidity] = useState<
    ReserveLiquidityParam[]
  >([])

  const [isAllowedCloseModal, setIsAllowedCloseModal] = useAtom(isAllowedCloseModalAtom)

  const {
    tx: txReserveLiquidity,
    setTx: setTxReserveLiquidity,
    moveToPending: txReserveLiquidityPending,
    moveToError: txReserveLiquidityError,
    moveToSuccess: txReserveLiquiditySuccess,
  } = useTx(txReserveLiquidityAtom)
  const [txList, setTxList] = useAtom(txListAtom)

  const resetTx = () => {
    setTxReserveLiquidity(txReserveLiquidityDefault)
  }
  const initTxList = () => {
    if (txList.length === 0) {
      setTxList([txReserveLiquidityAtom])
    }
  }

  const [resultStryke, _] = useStrykeQuery<GetDopexStrikeListQuery>({
    query: getDopexStrikeListQuery,
    variables: {
      tokenIds: lastWithdrawnLPs.map(lpshare => lpshare.tokenId) ?? [],
    },
    pause: !isEnabledReserve || lastWithdrawnLPs.length === 0,
  })

  const {
    data: dataStryke,
    fetching: fetchingStryke,
    error: errorStryke,
  } = useMemo(() => resultStryke, [resultStryke])

  const reserveParams = useMemo(() => {
    if (!dataStryke) return []
    return _chain(lastWithdrawnLPs)
      .map(lpshare => {
        const strike = dataStryke.strikes.find(strike => strike.id === lpshare.tokenId)
        if (!strike) return
        return {
          pool: strike.pool as AddressType,
          hook: strike.hook as AddressType,
          tickLower: strike.tickLower,
          tickUpper: strike.tickUpper,
          shares: lpshare.share,
        }
      })
      .compact()
      .value()
  }, [dataStryke, lastWithdrawnLPs])

  const poolAddress = reserveParams[0]?.pool
  const tickList = reserveParams.map(param => ({
    tickLower: param.tickLower,
    tickUpper: param.tickUpper,
  }))
  const { poolPositions } = useGetPoolPositions(poolAddress, handlerAddress, tickList)

  useEffect(() => {
    const _reserveParamsWithPoolLiquidity = reserveParams.filter(param => {
      const poolPosition = find(poolPositions, {
        tickLower: param.tickLower,
        tickUpper: param.tickUpper,
      })
      return poolPosition ? poolPosition.liquidity.gt(0) : false
    })
    if (!isEqual(reserveParamsWithPoolLiquidity, _reserveParamsWithPoolLiquidity)) {
      setReserveParamsWithPoolLiquidity(_reserveParamsWithPoolLiquidity)
    }

    if (reserveParams.length > _reserveParamsWithPoolLiquidity.length) {
      setHasNotReservedLiquidity(true)
    }

    return () => {
      setReserveParamsWithPoolLiquidity([])
      setHasNotReservedLiquidity(false)
    }
  }, [poolPositions, reserveParams])

  const reserveLiquidity = useBatchReserveLiquidity(
    reserveProxyAddress,
    handlerAddress,
    reserveParamsWithPoolLiquidity,
    isEnabledReserve && !isReservedLiquidity,
    {
      success: () => {
        txReserveLiquiditySuccess()
        setIsReservedLiquidity(true)
      },
    }
  )

  useEffect(() => {
    if (!reserveLiquidity.hash || txReserveLiquidity.hash) return
    setTxReserveLiquidity(prev => {
      return { ...prev, hash: reserveLiquidity.hash }
    })
  }, [reserveLiquidity.hash])

  useEffect(() => {
    const isTransactionEnd =
      every([txReserveLiquidity], ['status', txStatus.Success]) ||
      some([txReserveLiquidity], ['status', txStatus.Error])
    if (isTransactionEnd !== isAllowedCloseModal) {
      setIsAllowedCloseModal(isTransactionEnd)
    }
    return () => {
      setIsAllowedCloseModal(false)
    }
  }, [txReserveLiquidity, setIsAllowedCloseModal])

  const onReserveLP = useCallback(() => {
    setIsReservedLiquidity(false)
    txReserveLiquidityPending()
    reserveLiquidity.onReserve()
  }, [txReserveLiquidityPending, reserveLiquidity.onReserve, reserveLiquidity.isWriteReady])

  return {
    onReserveLP,
    isReservedLiquidity,
    hasReserveLP: !isEmpty(reserveParamsWithPoolLiquidity),
    hasNotReservedLiquidity,
    isReserveReady: reserveLiquidity.isWriteReady,
    isSubmitted: reserveLiquidity.isSubmitted,
    isWaiting: reserveLiquidity.waitLoading,
    txReserveLiquidityAtom,
    resetTx,
    initTxList,
  }
}
