import { BN } from '@apps-orangefi/lib'
import { chain as _chain } from 'lodash'
import { useState, useEffect, useCallback } from 'react'

export const useWithdrawableAmount = (position: BN | undefined, lpBalance: BN | undefined) => {
  const [withdrawRate, setWithdrawRate] = useState(new BN(0))
  const [withdrawableAmount, setWithdrawableAmount] = useState(new BN(0))
  const [withdrawableLpAmount, setWithdrawableLPAmount] = useState(new BN(0))

  useEffect(() => {
    if (!position) return
    const amount = position.times(withdrawRate.div(100))

    if (amount.isEqualTo(withdrawableAmount)) return
    setWithdrawableAmount(amount)
  }, [position?.toFixed(), withdrawRate.toFixed()])

  useEffect(() => {
    if (!lpBalance) return
    const lpAmount = lpBalance.times(withdrawRate.div(100))
    if (lpAmount.isEqualTo(withdrawableLpAmount)) return
    setWithdrawableLPAmount(lpAmount)
  }, [lpBalance?.toFixed(), withdrawRate.toFixed()])

  const onChangeRateLP = useCallback((rate: BN) => {
    setWithdrawRate(rate)
  }, [])

  return {
    setWithdrawRate,
    withdrawableAmount,
    withdrawableLpAmount,
    onChangeRateLP,
  }
}
