import arbTokenImageUrl from '@apps-orangefi/assets/images/tokens/arb.svg'
import boopTokenImageUrl from '@apps-orangefi/assets/images/tokens/boop.svg'
import daiTokenImageUrl from '@apps-orangefi/assets/images/tokens/dai.svg'
import ezEthTokenImageUrl from '@apps-orangefi/assets/images/tokens/ezETH.svg'
import usdcTokenImageUrl from '@apps-orangefi/assets/images/tokens/usdc.svg'
import usdtTokenImageUrl from '@apps-orangefi/assets/images/tokens/usdt.svg'
import wbtcTokenImageUrl from '@apps-orangefi/assets/images/tokens/wbtc.svg'
import weEthTokenImageUrl from '@apps-orangefi/assets/images/tokens/weETH.svg'
import wethTokenImageUrl from '@apps-orangefi/assets/images/tokens/weth.svg'
import wstEthTokenImageUrl from '@apps-orangefi/assets/images/tokens/wstETH.svg'
import { AssetsToken, DepositToken, depositToken } from '@apps-orangefi/lib/types'

export const MIN_FACTOR = 0.99
export const EVENT_BATCH_BLOCK_SIZE = 50000

export const depositTokenList: { symbol: DepositToken; logoUrl: string }[] = [
  { symbol: depositToken.Usdce, logoUrl: usdcTokenImageUrl },
  { symbol: depositToken.Usdc, logoUrl: usdcTokenImageUrl },
  { symbol: depositToken.Weth, logoUrl: wethTokenImageUrl },
  { symbol: depositToken.Boop, logoUrl: boopTokenImageUrl },
  { symbol: depositToken.WstEth, logoUrl: wstEthTokenImageUrl },
  { symbol: depositToken.WeEth, logoUrl: weEthTokenImageUrl },
  { symbol: depositToken.EzEth, logoUrl: ezEthTokenImageUrl },
]

export const assetTokenList: { symbol: AssetsToken; logoUrl: string }[] = [
  ...depositTokenList,
  { symbol: 'WBTC', logoUrl: wbtcTokenImageUrl },
  { symbol: 'BTC', logoUrl: wbtcTokenImageUrl },
  { symbol: 'ARB', logoUrl: arbTokenImageUrl },
  { symbol: 'DAI', logoUrl: daiTokenImageUrl },
  { symbol: 'USDT', logoUrl: usdtTokenImageUrl },
]

export * as helpers from './helpers'
