import { Atom } from 'jotai'
import { keccak256, encodePacked, toBytes } from 'viem'

export const createInitialValues = () => {
  const initialValues: (readonly [Atom<unknown>, unknown])[] = []
  const get = () => initialValues
  const set = <Value>(anAtom: Atom<Value>, value: Value) => {
    initialValues.push([anAtom, value])
  }
  return { get, set }
}

export const convertUSDCSymbol = (
  tokenAddress: AddressType | undefined,
  usdceAddress: AddressType | null
): string | undefined => {
  return tokenAddress?.toLowerCase() === usdceAddress?.toLowerCase() ? 'USDC.e' : undefined
}

export const addHex = (hex: string, value: number): string => {
  const bigintValue = BigInt(hex)
  const result = bigintValue + BigInt(value)
  const resultHex = result.toString(16)
  return '0x' + resultHex.padStart(hex.length - 2, '0')
}

export const hashPosition = (owner: AddressType, tickLower: number, tickUpper: number): string => {
  // ABI エンコード (Solidity の abi.encodePacked 相当)
  const encoded = encodePacked(['address', 'int24', 'int24'], [owner, tickLower, tickUpper])

  // keccak256 ハッシュ値を計算
  const hash = keccak256(toBytes(encoded))

  return hash
}
