import { BN } from '@apps-orangefi/lib'
import { Amm, Derivative, StrykeLP } from '@apps-orangefi/lib/types'
import { numberWithShortScale } from '@apps-orangefi/lib/utils'
import { Platform } from '@apps-orangefi/ui/atoms'
import { Button } from '@apps-orangefi/ui/atoms/buttons'
import { TokenPairBySymbol } from '@apps-orangefi/ui/molecules'
import { twMerge } from 'tailwind-merge'

type Props = Pick<
  StrykeLP,
  | 'title'
  | 'baseTokenSymbol'
  | 'quoteTokenSymbol'
  | 'amm'
  | 'derivative'
  | 'totalAmountUSD'
  | 'tokenTotalLiquidity'
> & {
  buttonLabel: string
  onSubmit: () => void
  className?: string
}

export const ReserveCard = ({
  title,
  baseTokenSymbol,
  quoteTokenSymbol,
  amm,
  derivative,
  totalAmountUSD,
  tokenTotalLiquidity,
  buttonLabel,
  onSubmit,
  className,
}: Props) => {
  const { token0, token1 } = tokenTotalLiquidity
    ? tokenTotalLiquidity
    : {
        token0: { size: new BN(0), symbol: baseTokenSymbol, decimals: 6 },
        token1: { size: new BN(0), symbol: quoteTokenSymbol, decimals: 6 },
      }

  return (
    <div
      className={twMerge(
        `flex flex-col dark:bg-gray-800 rounded-2xl`,
        'sm:w-[300px] p-5',
        'w-full',
        className
      )}
    >
      <div className="flex flex-row">
        <TokenPairBySymbol
          baseTokenSymbol={baseTokenSymbol}
          quoteTokenSymbol={quoteTokenSymbol}
          size={20}
          className="mr-6"
        />
        <span>{title}</span>
      </div>
      <Platform
        amm={amm}
        derivative={derivative}
        size={20}
        noText={true}
        noDelimiter={true}
        className="w-fit self-end mr-5"
      />
      <span className="type-base-caption mt-6">Your Liquidity on Stryke</span>
      <div className="flex flex-row justify-end w-full mt-1 dark:text-white">
        <div>
          {numberWithShortScale(token0.size, Number(token0.decimals) === 6 ? 2 : 4)}
          <span className="ml-1">{token0.symbol}</span>
        </div>
        <span className="mx-1">/</span>
        <div>
          {numberWithShortScale(token1.size, Number(token1.decimals) === 6 ? 2 : 4)}
          <span className="ml-1">{token1.symbol}</span>
        </div>
      </div>
      <div className="flex flex-row justify-end type-base-caption w-full mt-1">
        <span className="dark:text-white">${numberWithShortScale(totalAmountUSD, 4)}</span>
      </div>
      <Button
        label={buttonLabel}
        disabled={totalAmountUSD.eq(0)}
        onSubmit={onSubmit}
        className="mt-3 py-2 px-4 rounded-xl w-full"
      />
    </div>
  )
}
