import { BN } from '@apps-orangefi/lib'

export const shortAddress = (
  address: AddressType | undefined,
  pre: number = 5,
  post: number = 4
): string => {
  return !!address
    ? `${address.substring(0, pre)}...${address.substring(address.length - post, address.length)}`
    : ''
}

export const numberWithShortScale = (val: BN | undefined, dp: number = 2): string => {
  if (!val || val.isNaN()) return ''

  if (val.gte(1_000) && val.lt(1_000_000)) {
    // 1k <= x < 1m
    return new BN(val).div(1_000).toFormat(dp) + 'K'
  } else if (val.gte(1_000_000) && val.lt(1_000_000_000)) {
    // 1m <= x < 1b
    return new BN(val).div(1_000_000).toFormat(dp) + 'M'
  } else if (val.gte(1_000_000_000)) {
    // 1b <= x
    return new BN(val).div(1_000_000_000).toFormat(dp) + 'B'
  } else {
    return val.toFormat(dp)
  }
}
