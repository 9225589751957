import { BN } from '@apps-orangefi/lib'
import { vaultDecimalsAtom, tokenAtom, usdceAddressAtom } from '@apps-orangefi/lib/store'
import { UserPosition, V1ContractAddresses } from '@apps-orangefi/lib/types'
import { convertUSDCSymbol, bigintToBN } from '@apps-orangefi/lib/utils'
import {
  useV1VaultCapacity,
  useTokenValue,
  useMyPosition,
  // useLastDepositedV1TokenValue,
  useV1Token0,
  useTokenBalance,
} from '@apps-orangefi/wagmi/hooks'
import { useAtom, useSetAtom, useAtomValue } from 'jotai'
import { isNil } from 'lodash'
import { useState, useEffect } from 'react'

export const useReadV1Vault = (
  contracts: V1ContractAddresses,
  account: AddressType | undefined
) => {
  const [vaultDecimals, setVaultDecimals] = useAtom(vaultDecimalsAtom)
  const setToken = useSetAtom(tokenAtom)
  const [tokenBalance, setTokenBalance] = useState<BN | undefined>(undefined)
  const [userPosition, setUserPosition] = useState<UserPosition | undefined>(undefined)

  const usdceAddress = useAtomValue(usdceAddressAtom)
  const { tokenAddress } = useV1Token0(contracts.vaultAddress)

  const { data: dataTokenBalance } = useTokenBalance({
    account,
    tokenAddress,
    watch: true,
  })

  useEffect(() => {
    const tokenDecimals = dataTokenBalance?.decimals ?? 0
    const tokenSymbol =
      convertUSDCSymbol(tokenAddress, usdceAddress) ?? dataTokenBalance?.symbol ?? ''

    setTokenBalance(dataTokenBalance?.balance)
    setToken({
      decimals: tokenDecimals,
      symbol: tokenSymbol,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataTokenBalance)])

  const {
    tokenValue: currentTokenValue,
    decimals,
    isFetching: tokenValueIsFetching,
  } = useTokenValue(contracts.vaultAddress, true)

  useEffect(() => {
    setVaultDecimals(decimals)
  }, [decimals])

  const { myPosition, isFetching: positionIsFetching } = useMyPosition(
    contracts.vaultAddress,
    account,
    vaultDecimals,
    true
  )
  // const { tokenValue: lastDepositedTokenValue, isFetching: lastDepositedTokenValueIsFetching } =
  //   useLastDepositedV1TokenValue(contracts.vaultAddress, account)

  const { vaultCapacity: vCapacity, isFetching: capacityIsFetching } = useV1VaultCapacity(
    contracts.vaultAddress,
    contracts.parametersAddress,
    vaultDecimals,
    true
  )

  const vaultCapacity = {
    totalDeposit: vCapacity?.totalDeposit ?? new BN(0),
    maxCapacity: vCapacity?.maxCapacity ?? new BN(0),
  }

  const isFetching =
    tokenValueIsFetching &&
    positionIsFetching &&
    // lastDepositedTokenValueIsFetching &&
    capacityIsFetching

  useEffect(() => {
    // FIXME: cant get lastDepositionTokenValue if time passed since last deposit
    if (!isNil(myPosition) && !isFetching) {
      // const premium =
      //   !isNil(lastDepositedTokenValue) && !isNil(currentTokenValue) && !isNil(myPosition.lpBalance)
      //     ? currentTokenValue?.minus(lastDepositedTokenValue).times(myPosition.lpBalance)
      //     : new BN(0)
      const premium = new BN(0)

      setUserPosition({
        myPosition: myPosition,
        premium,
      })
    }
  }, [
    JSON.stringify(myPosition),
    currentTokenValue?.toFixed(),
    // lastDepositedTokenValue?.toFixed(),
    isFetching,
  ])
  return { tokenAddress, tokenBalance, userPosition, vaultCapacity, currentTokenValue, isFetching }
}
