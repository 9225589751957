import { useTx, useModal } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { Tx, txListAtom, txStatus, vaultDecimalsAtom } from '@apps-orangefi/lib/store'
import { MODAL_TYPES, TxModalTypes } from '@apps-orangefi/ui/organisms/modals'
import { WithdrawHook } from '@apps-orangefi/wagmi/hooks'
import { atom, useAtom, useAtomValue } from 'jotai'
import { setWith } from 'lodash'
import { useState, useEffect, useCallback } from 'react'
import { useAccount } from 'wagmi'

const txWithdrawDefault: Tx = {
  title: 'Withdraw',
  hash: undefined,
  status: txStatus.Wait,
}

const txWithdrawAtom = atom<Tx>(txWithdrawDefault)

export const useWithdrawForm = (
  vaultAddress: AddressType | undefined,
  fromWithdrawAddress: AddressType | undefined,
  account: AddressType | undefined,
  position: BN | undefined,
  lpBalance: BN | undefined,
  useWithdraw: WithdrawHook,
  modalType: TxModalTypes,
  inspectorAddress?: AddressType,
  reservProxyAddress?: AddressType
) => {
  const [withdrawRate, setWithdrawRate] = useState(new BN(0))
  const [withdrawableAmount, setWithdrawableAmount] = useState(new BN(0))
  const [withdrawableLpAmount, setWithdrawableLPAmount] = useState(new BN(0))

  const [txList, setTxList] = useAtom(txListAtom)
  const vaultDecimals = useAtomValue(vaultDecimalsAtom)
  const { chain } = useAccount()

  const {
    tx: txWithdraw,
    setTx: setTxWithdraw,
    moveToPending: txWithdrawPending,
    moveToError: txWithdrawError,
    moveToSuccess: txWithdrawSuccess,
  } = useTx(txWithdrawAtom)

  const resetTx = () => {
    if (txList.length !== 0) return
    setTxWithdraw(txWithdrawDefault)
  }

  const initTxList = () => {
    if (txList.length === 0) {
      setTxList([txWithdrawAtom])
    }
  }

  const withdraw = useWithdraw({
    fromWithdrawAddress,
    vaultAddress,
    account,
    shares: withdrawableLpAmount,
    vaultDecimals,
    callback: {
      success: () => {
        txWithdrawSuccess()
        // setWithdrawRate(new BN(0)) // trigger internal loop
      },
      fail: txWithdrawError,
    },
    inspectorAddress,
    reservProxyAddress,
  })

  useEffect(() => {
    if (!withdraw.hash || !!txWithdraw.hash) return
    setTxWithdraw(prev => {
      return { ...prev, hash: withdraw.hash }
    })
  }, [withdraw.hash])

  useEffect(() => {
    if (txList.length === 0) return
    if (!withdraw.isWithdrawReady) return
    if (withdrawableLpAmount.lte(0)) return
    if (txWithdraw.status === txStatus.Wait) {
      txWithdrawPending()
      withdraw.onWithdraw()
    }
  }, [
    txList,
    txWithdraw,
    withdraw.isWithdrawReady,
    withdraw.onWithdraw,
    withdrawableLpAmount.toFixed(),
  ])

  useEffect(() => {
    if (!position) return
    const amount = position.times(withdrawRate.div(100))

    if (amount.isEqualTo(withdrawableAmount)) return
    setWithdrawableAmount(amount)
  }, [position?.toFixed(), withdrawRate.toFixed()])

  useEffect(() => {
    if (!lpBalance) return
    const lpAmount = lpBalance.times(withdrawRate.div(100))
    if (lpAmount.isEqualTo(withdrawableLpAmount)) return
    setWithdrawableLPAmount(lpAmount)
  }, [lpBalance?.toFixed(), withdrawRate.toFixed()])

  const { showModal, hideModal } = useModal()

  const onWithdraw = useCallback(() => {
    resetTx()
    initTxList()
    showModal({
      modalType,
      modalProps: {
        title: 'Withdraw transaction',
        chain,
        handleClose: () => {
          hideModal()
        },
        isDefaultClosable: true,
      },
    })
  }, [])

  const onChangeRate = useCallback((rate: BN) => {
    setWithdrawRate(rate)
  }, [])

  return {
    setWithdrawRate,
    withdrawableAmount,
    withdrawableLpAmount,
    resetTx,
    initTxList,
    onWithdraw,
    onChangeRate,
  }
}
