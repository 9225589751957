export const productKey = {
  CamelotWethUsdtVault: 'camelot-weth-usdt-vault',
  CamelotUsdcArbVault: 'camelot-usdc-arb-vault',
  CamelotWethArbVault: 'camelot-weth-arb-vault',
  CamelotUsdcDaiVault: 'camelot-usdc-dai-vault',
  CamelotWethWbtcVault: 'camelot-weth-wbtc-vault',
  CamelotUsdcUsdceVault: 'camelot-usdc-usdce-vault',
  CamelotUsdceUsdtVault: 'camelot-usdce-usdt-vault',
  CamelotWethUsdcVault: 'camelot-weth-usdc-vault',
  CamelotUsdcWethVault: 'camelot-usdc-weth-vault',
  CamelotWstethWethVault: 'camelot-wsteth-weth-vault',
  CamelotWeethWethVault: 'camelot-weeth-weth-vault',
  CamelotEzethWethVault: 'camelot-ezeth-weth-vault',
  DeprecatedStrykeWethUsdcVault: 'deprecated-stryke-weth-usdc-vault',
  NoswapStrykeWethUsdcVault: 'noswap-stryke-weth-usdc-vault',
  StrykeWethUsdcVault: 'stryke-weth-usdc-vault',
  StrykeWbtcUsdcVault: 'stryke-wbtc-usdc-vault',
  StrykeArbUsdcVault: 'stryke-arb-usdc-vault',
  StrykeBoopWethVault: 'stryke-boop-weth-vault',
  StrykePancakeWethUsdcVault: 'stryke-pancake-weth-usdc-vault',
  StrykePancakeWbtcUsdcVault: 'stryke-pancake-wbtc-usdc-vault',
  StrykePancakeArbUsdcVault: 'stryke-pancake-arb-usdc-vault',
  StrykeSushiWethUsdcVault: 'stryke-sushi-weth-usdc-vault',
  StrykeSushiWbtcUsdcVault: 'stryke-sushi-wbtc-usdc-vault',
  StrykeSushiArbUsdcVault: 'stryke-sushi-arb-usdc-vault',
} as const

export type ProductKey = typeof productKey[keyof typeof productKey]
