import ARBIcon from '@apps-orangefi/assets/images/tokens/arb.svg'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'

type Props = {
  reward: number
  className?: string
}

export const ArbReward = ({ reward, className }: Props) => {
  return (
    <div className={twMerge('flex flex-row items-center', className)}>
      <span className="type-base-semibold leading-5 dark:text-white mr-1.5">
        {reward.toLocaleString()} ARB
      </span>
      <Image src={ARBIcon} alt="arb" className="w-6 h-6" />
    </div>
  )
}
