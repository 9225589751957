import { useTx, useModal } from '@apps-orangefi/hooks'
import { Tx, txListAtom, txStatus } from '@apps-orangefi/lib/store'
import { stakingAddressAtom, needRefetchAtom } from '@apps-orangefi/lib/store/point'
import { LockPosition } from '@apps-orangefi/lib/types/point'
import { getTimeBoost } from '@apps-orangefi/lib/utils/point'
import { useWithdrawLock } from '@apps-orangefi/wagmi/hooks/point'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useCallback, useEffect, useState } from 'react'

const txUnlockDefault: Tx = {
  title: 'Unlock',
  hash: undefined,
  status: txStatus.Wait,
}
const txUnlockAtom = atom<Tx>(txUnlockDefault)

export const useUnlockForm = ({
  lockPosition,
  dailyEarnings,
  onOpenTxModal,
}: {
  lockPosition: LockPosition
  dailyEarnings: number
  onOpenTxModal: () => void
}) => {
  const [txList, setTxList] = useAtom(txListAtom)
  const stakingAddress = useAtomValue(stakingAddressAtom)
  const setNeedRefetch = useSetAtom(needRefetchAtom)

  const [estimateEarnings, setEstimateEarnings] = useState<number>(0)

  const {
    tx: txUnlock,
    setTx: setTxUnlock,
    moveToPending: txUnlockPending,
    moveToError: txUnlockError,
    moveToSuccess: txUnlockSuccess,
  } = useTx(txUnlockAtom)

  const resetTx = () => {
    if (txList.length !== 0) return
    setTxUnlock(txUnlockDefault)
  }

  const initTxList = () => {
    if (txList.length === 0) {
      setTxList([txUnlockAtom])
    }
  }

  const unlock = useWithdrawLock(stakingAddress, lockPosition.id, {
    success: () => {
      txUnlockSuccess()
      setNeedRefetch(true)
    },
    fail: txUnlockError,
  })

  // tx status
  useEffect(() => {
    if (!unlock.hash || !!txUnlock.hash) return
    setTxUnlock(prev => {
      return { ...prev, hash: unlock.hash }
    })
  }, [unlock.hash])

  useEffect(() => {
    if (txList.length === 0) return
    if (!unlock.isReady) return
    if (txUnlock.status === txStatus.Wait) {
      txUnlockPending()
      unlock.onWithdrawLock()
    }
  }, [txList, txUnlock, unlock.isReady, unlock.onWithdrawLock])
  // -----

  const { hideModal } = useModal()
  const onUnlock = useCallback(() => {
    resetTx()
    initTxList()
    onOpenTxModal()
  }, [onOpenTxModal])

  useEffect(() => {
    const basePoint = lockPosition.lockAmountUSD.multipliedBy(10)
    const newDailyEarnings =
      dailyEarnings - basePoint.toNumber() * getTimeBoost(lockPosition.lockDuration)
    if (newDailyEarnings !== estimateEarnings) {
      setEstimateEarnings(newDailyEarnings > 0 ? newDailyEarnings : 0)
    }
  }, [dailyEarnings, lockPosition.lockAmountUSD, lockPosition.lockDuration])

  return {
    lock: {
      onUnlock,
      onCancel: hideModal,
    },
    estimate: {
      dailyEarnings: {
        current: dailyEarnings,
        estimate: estimateEarnings,
      },
    },
  }
}
