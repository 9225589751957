import { OrangeDistributorABI, StrykeGaugeControllerABI } from '@apps-orangefi/wagmi/abis'
import {
  useReadContractWithErrorHandling,
  useReadContractsWithErrorHandling,
} from '@apps-orangefi/wagmi/hooks/common'
import { useMemo } from 'react'

import '@apps-orangefi/lib/extensions'

export const useCurrentEpoch = (distributorAddress: AddressType | undefined) => {
  const enabled = !!distributorAddress

  const { data, isFetching, queryKey } = useReadContractWithErrorHandling({
    address: distributorAddress!,
    abi: OrangeDistributorABI,
    functionName: 'controller',
    query: {
      enabled,
    },
  })

  const controllerAddress = useMemo(() => {
    return data as AddressType | undefined
  }, [data])

  const contracts = useMemo(() => {
    return [
      {
        address: controllerAddress!,
        abi: StrykeGaugeControllerABI,
        functionName: 'EPOCH_LENGTH',
      },
      {
        address: controllerAddress!,
        abi: StrykeGaugeControllerABI,
        functionName: 'epoch',
      },
    ]
  }, [controllerAddress])

  const { data: dataController, isFetching: isFetchingController } =
    useReadContractsWithErrorHandling({
      contracts,
      query: {
        enabled: !!controllerAddress,
      },
    })

  return {
    controllerAddress,
    epochLength:
      dataController && dataController[0].result ? Number(dataController[0].result) : undefined,
    currentEpoch:
      dataController && dataController[1].result ? Number(dataController[1].result) : undefined,
    isFetching: isFetching || isFetchingController,
  }
}
