import { useDebounce } from '@apps-orangefi/hooks'
import { ReserveProxyABI } from '@apps-orangefi/wagmi/abis'
import {
  useSimulateContractWithErrorHandling,
  useReadContractWithErrorHandling,
  useWriteContractWithErrorHandling,
  useWaitForTransactionReceiptWithErrorHandling,
} from '@apps-orangefi/wagmi/hooks/common'
import { isEmpty } from 'lodash'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { encodeFunctionData, zeroAddress } from 'viem'
import { BaseError } from 'wagmi'
import '@apps-orangefi/lib/extensions'

type UseReserveHelperType = (
  reserveProxyAddress: AddressType | undefined,
  account: AddressType | undefined
) => {
  reserveHelperAddress: AddressType | null
  isFetching: boolean
  isError: boolean
  isFetched: boolean
  refetch: () => void
}

export const useReserveHelpers: UseReserveHelperType = (
  reserveProxyAddress: AddressType | undefined,
  account: AddressType | undefined
) => {
  const [reserveHelperAddress, setReserveHelperAddress] = useState<AddressType | null>(null)
  const enabled = useDebounce(!!reserveProxyAddress, 300)

  const args = [account!] as const

  const { data, isFetching, isError, queryKey, refetch, isFetched } =
    useReadContractWithErrorHandling({
      address: reserveProxyAddress!,
      abi: ReserveProxyABI,
      functionName: 'reserveHelpers',
      args,
      query: {
        enabled,
      },
    })

  useEffect(() => {
    if (data && data !== zeroAddress) {
      setReserveHelperAddress(data)
    } else {
      setReserveHelperAddress(null)
    }
  }, [data])

  return { reserveHelperAddress, isFetching, isError, refetch, isFetched }
}

export const useCreateReserveHelper = (
  reserveProxyAddress: AddressType | undefined,
  callback?: { success?: () => void; fail?: (cause: BaseError | string) => void }
) => {
  const [isWriteReady, setIsWriteReady] = useState(false)
  const enabled = useDebounce(!!reserveProxyAddress, 300)

  const { data } = useSimulateContractWithErrorHandling({
    address: reserveProxyAddress!,
    abi: ReserveProxyABI,
    functionName: 'createMyReserveHelper',
    query: {
      enabled,
    },
  })

  const { data: hash, writeContract } = useWriteContractWithErrorHandling({
    mutation: {
      onError(error) {
        if (callback && callback.fail) {
          callback.fail(error.cause as BaseError)
        }
      },
    },
  })

  const [isTransactionEnd, setIsTransactionEnd] = useState(false)
  const {
    isLoading: waitLoading,
    isSuccess,
    isError,
    error,
  } = useWaitForTransactionReceiptWithErrorHandling({ hash })

  useEffect(() => {
    if (isTransactionEnd) return
    if (isSuccess) {
      if (callback && callback.success) {
        callback.success()
        setIsTransactionEnd(true)
      }
    }
    if (isError && error) {
      if (callback && callback.fail) {
        callback.fail(error.message)
        setIsTransactionEnd(true)
      }
    }
  }, [isSuccess, isError, error, callback])

  useEffect(() => {
    setIsWriteReady(!!writeContract && !!data?.request)
  }, [writeContract, data?.request])

  return {
    isWriteReady,
    hash,
    isSuccess,
    onCreateReserveHelper: () => {
      if (!isWriteReady) {
        return
      }
      writeContract(data!.request)
    },
  }
}

export type ReserveLiquidityParam = {
  pool: AddressType
  hook: AddressType
  tickLower: number
  tickUpper: number
  shares: bigint
}

export const useBatchReserveLiquidity = (
  reserveProxyAddress: AddressType | undefined,
  handlerAddress: AddressType | undefined,
  reserveLiquidityParams: ReserveLiquidityParam[],
  isEnabled: boolean,
  callback?: { success?: () => void; fail?: (cause: BaseError | string) => void }
) => {
  const [isWriteReady, setIsWriteReady] = useState(false)
  const args = useMemo(() => {
    return [handlerAddress!, reserveLiquidityParams] as const
  }, [handlerAddress, reserveLiquidityParams])

  const enabled = useMemo(
    () =>
      !!reserveProxyAddress && !!handlerAddress && !isEmpty(reserveLiquidityParams) && isEnabled,
    [reserveProxyAddress, handlerAddress, reserveLiquidityParams, isEnabled]
  )

  const {
    data,
    failureReason,
    isError: simuIsError,
  } = useSimulateContractWithErrorHandling({
    address: reserveProxyAddress!,
    abi: ReserveProxyABI,
    functionName: 'batchReserveLiquidity',
    args,
    query: {
      enabled,
    },
  })

  const {
    data: hash,
    writeContract,
    submittedAt,
  } = useWriteContractWithErrorHandling({
    mutation: {
      onError(error) {
        console.debug('batch reserve liquidity error occured:', error, `args: ${args}`)
        if (callback && callback.fail) {
          callback.fail(error.cause as BaseError)
        }
      },
    },
  })

  const [isTransactionEnd, setIsTransactionEnd] = useState(false)
  const {
    isLoading: waitLoading,
    isSuccess,
    isError,
    error,
  } = useWaitForTransactionReceiptWithErrorHandling({ hash })

  useEffect(() => {
    if (!!failureReason) {
      console.log('batch reserve liquidity failure reason: ', failureReason)
    }
  }, [failureReason])

  useEffect(() => {
    if (isTransactionEnd) return
    if (isSuccess) {
      if (callback && callback.success) {
        callback.success()
        setIsTransactionEnd(true)
      }
    }
    if (isError && error) {
      if (callback && callback.fail) {
        callback.fail(error.message)
        setIsTransactionEnd(true)
      }
    }
  }, [isSuccess, isError, error, callback])

  useEffect(() => {
    setIsWriteReady(!!writeContract && !!data?.request)
  }, [writeContract, data?.request])

  const onReserve = useCallback(() => {
    if (!isWriteReady) {
      return
    }
    writeContract(data!.request)
  }, [writeContract, isWriteReady, data?.request])

  return {
    isWriteReady,
    hash,
    waitLoading,
    isSuccess,
    onReserve,
    isSubmitted: submittedAt > 0,
  }
}

export const useBatchWithdrawReserveLiquidity = (
  reserveProxyAddress: AddressType | undefined,
  handlerAddress: AddressType | undefined,
  tokenIds: string[],
  callback?: { success?: () => void; fail?: (cause: BaseError | string) => void }
) => {
  const [isWriteReady, setIsWriteReady] = useState(false)
  const args = useMemo(() => {
    const _tokenIds = tokenIds.map(id => BigInt(id))
    return [handlerAddress!, _tokenIds] as const
  }, [handlerAddress, tokenIds])

  const enabled = useDebounce(!!reserveProxyAddress && !!handlerAddress && !isEmpty(tokenIds), 300)

  const { data } = useSimulateContractWithErrorHandling({
    address: reserveProxyAddress!,
    abi: ReserveProxyABI,
    functionName: 'batchWithdrawReserveLiquidity',
    args,
    query: {
      enabled,
    },
  })

  const { data: hash, writeContract } = useWriteContractWithErrorHandling({
    mutation: {
      onError(error) {
        if (callback && callback.fail) {
          callback.fail(error.cause as BaseError)
        }
      },
    },
  })

  const [isTransactionEnd, setIsTransactionEnd] = useState(false)
  const {
    isLoading: waitLoading,
    isSuccess,
    isError,
    error,
  } = useWaitForTransactionReceiptWithErrorHandling({ hash })

  useEffect(() => {
    if (isTransactionEnd) return
    if (isSuccess) {
      if (callback && callback.success) {
        callback.success()
        setIsTransactionEnd(true)
      }
    }
    if (isError && error) {
      if (callback && callback.fail) {
        callback.fail(error.message)
        setIsTransactionEnd(true)
      }
    }
  }, [isSuccess, isError, error, callback])

  useEffect(() => {
    setIsWriteReady(!!writeContract && !!data?.request)
  }, [writeContract, data?.request])

  return {
    isWriteReady,
    hash,
    isSuccess,
    onWithdraw: () => {
      if (!isWriteReady) {
        return
      }
      writeContract(data!.request)
    },
  }
}
