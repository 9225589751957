import { useLockForm } from '@apps-orangefi/hooks'
import { Position } from '@apps-orangefi/lib/types/point'
import { TxModal } from '@apps-orangefi/ui/organisms/modals'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useEffect, useState } from 'react'
import { useAccount } from 'wagmi'

import { LockModal } from './LockModal'

export type LockModalContainerProps = {
  dailyEarnings: number
  position: Position
  handleClose: () => void
}

export const LockModalContainer = ({
  dailyEarnings,
  position,
  handleClose,
}: LockModalContainerProps) => {
  const { t: tPoints } = useTranslation('points')
  const [showTxModal, setShowTxModal] = useState(false)
  const { address: account, chain } = useAccount()

  const onOpenTxModal = useCallback(() => {
    setShowTxModal(true)
  }, [])

  const { lock, tokenBalance, estimate } = useLockForm({
    vaultAddress: position.vaultAddress,
    account,
    lastSharePrice: position.lastSharePrice,
    dailyEarnings,
    onOpenTxModal,
  })

  useEffect(() => {
    // Reset showTxModal when item changes
    setShowTxModal(false)
  }, [position])

  return (
    <>
      {!showTxModal && (
        <LockModal
          position={position}
          tokenBalance={tokenBalance}
          lock={lock}
          estimate={estimate}
          handleClose={handleClose}
        />
      )}
      {showTxModal && (
        <TxModal
          title={tPoints('MODAL.LOCK.TX')}
          chain={chain}
          handleClose={handleClose}
          // handleClose={() => setShowTxModal(false)}
          messages={[]}
        />
      )}
    </>
  )
}
