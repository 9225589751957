const { resolve } = require('path')

const defaultTheme = require('tailwindcss/defaultTheme')

// const colors = require(resolve(__dirname, 'src/styles/colors.js'))
// const fontSize = require(resolve(__dirname, 'src/styles/fontSize.js'))
const colors = require('./src/styles/colors')
const fontSize = require('./src/styles/fontSize')
/** @type {import("@types/tailwindcss/tailwind-config").TailwindConfig } */

module.exports = {
  darkMode: 'class',
  plugins: [require('@tailwindcss/forms'), require('@tailwindcss/typography')],
  content: [
    './apps/**/*.{js,ts,jsx,tsx}',
    '../../packages/ui/{,!(node_modules)/**/}*.{js,ts,jsx,tsx}',
  ],
  theme: {
    extend: {
      screens: {
        sm: '640px',
        md: '768px',
        md2: '960px',
        lg: '1024px',
        xl: '1200px',
        '2xl': '1440px',
      },
      colors,
      maxWidth: {
        container: '80rem',
      },
      // fontFamily: {
      //   clash: ['ClashGrotesk-Semibold', ...defaultTheme.fontFamily.sans],
      // },
      fontSize,
      borderRadius: {
        '3xl': '20px',
        '4xl': '24px',
        '5xl': '32px',
        '6xl': '40px',
        '7xl': '56px',
        '12xl': '180px',
      },
    },
  },
}
