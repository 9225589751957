import { useOrangeQuery, useUniV3Query, useStrykeQuery } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { usdceAddressAtom } from '@apps-orangefi/lib/store'
import {
  getDopexVaultQuery,
  getUniV3PoolQuery,
  getDopexLpPositionsListQuery,
  getDopexDailyStrikeEarningsListQuery,
} from '@apps-orangefi/lib/subgraph/queries'
import {
  type GetDopexLpPositionsListQuery,
  type GetDopexDailyStrikeEarningsListQuery,
} from '@apps-orangefi/lib/subgraph/types/dopex/graphql'
import { type GetDopexVaultQuery } from '@apps-orangefi/lib/subgraph/types/orange/graphql'
import { type GetPoolQueryQuery } from '@apps-orangefi/lib/subgraph/types/uniswap/graphql'
import {
  VaultInfo,
  ContractProp,
  StrategyVaultInfo,
  Token,
  Vault,
  Pool,
} from '@apps-orangefi/lib/types'
import { convertUSDCSymbol, calculateAPR, addHex } from '@apps-orangefi/lib/utils'
import { useAtomValue } from 'jotai'
import { chain as _chain, isEqual, uniqBy } from 'lodash'
import { useEffect, useState, useMemo } from 'react'

export const useDopexVault = (account: AddressType | undefined, vaultInfo: VaultInfo) => {
  const usdceAddress = useAtomValue(usdceAddressAtom)
  const [productContract, setProductContract] = useState<StrategyVaultInfo | undefined>(undefined)
  const [contractProps, setContractProps] = useState<ContractProp[]>([])
  const [baseToken, setBaseToken] = useState<Token | undefined>(undefined)
  const [quoteToken, setQuoteToken] = useState<Token | undefined>(undefined)
  const [vaultData, setVaultData] = useState<Vault | undefined>(undefined)

  const [result, reexecuteQuery] = useOrangeQuery<GetDopexVaultQuery>({
    query: getDopexVaultQuery,
    variables: {
      account: account?.toLowerCase() ?? '',
      vaultAddress: vaultInfo.VAULT_ADDRESS.toLowerCase(),
    },
  })
  const { data: dataOrange, fetching: fetchingOrange, error } = useMemo(() => result, [result])

  const myVault = useMemo(() => {
    return dataOrange?.user?.positions.find(position => position.vault as AddressType) ?? []
  }, [dataOrange?.user?.positions])

  const [resultUniV3] = useUniV3Query<GetPoolQueryQuery>({
    query: getUniV3PoolQuery,
    variables: {
      poolId: dataOrange?.dopexVault!.pool.toLowerCase() ?? '',
    },
    pause: !dataOrange || !dataOrange?.dopexVault?.pool,
  })
  const {
    data: dataUniV3,
    fetching: fetchingUniV3,
    error: errorUniV3,
  } = useMemo(() => resultUniV3, [resultUniV3])

  const [resultDopex1st] = useStrykeQuery<GetDopexLpPositionsListQuery>({
    query: getDopexLpPositionsListQuery,
    variables: {
      userIds: [vaultInfo.VAULT_ADDRESS.toLowerCase()],
    },
  })
  const {
    data: dataDopexFirst,
    fetching: fetchingDopexFirst,
    error: errorDopexFirst,
  } = useMemo(() => resultDopex1st, [resultDopex1st])

  const tokenIds = useMemo(
    () => dataDopexFirst?.lppositions.map(lpPosition => lpPosition.strike.id) ?? [],
    [dataDopexFirst?.lppositions]
  )

  const startTime = useMemo(() => {
    return Math.floor(new Date().getTime() / 1000) - 60 * 60 * 24
  }, [])

  const [resultDopex2nd] = useStrykeQuery<GetDopexDailyStrikeEarningsListQuery>({
    query: getDopexDailyStrikeEarningsListQuery,
    variables: {
      tokenIds,
      tokenIdsCount: tokenIds.length,
      startTime: startTime.toString(),
    },
  })
  const {
    data: dataDopexSecond,
    fetching: fetchingDopexSecond,
    error: errorDopexSecond,
  } = useMemo(() => resultDopex2nd, [resultDopex2nd])

  const dopexStrikeEarnings = useMemo(() => {
    const lpPositions = dataDopexFirst?.lppositions ?? []
    const dailyDonations = uniqBy(dataDopexSecond?.dailyDonations, 'strike.id')
    const dailyFeeCompounds = uniqBy(dataDopexSecond?.dailyFeeCompounds, 'strike.id')
    return _chain(lpPositions)
      .map(lpPosition => {
        const donation = dailyDonations.find(d => d.strike.id === lpPosition.strike.id)
        const compound = dailyFeeCompounds.find(c => c.strike.id === lpPosition.strike.id)
        if (!donation && !compound) {
          return null
        }

        return {
          sqrtPriceX96: donation?.sqrtPriceX96 ?? compound?.sqrtPriceX96,
          donation: donation?.donation ?? '0',
          compound: compound?.compound ?? '0',
          strike: lpPosition?.strike,
          pool: lpPosition.pool,
          shares: lpPosition.shares,
          user: lpPosition.user,
          handler: lpPosition.handler,
        }
      })
      .compact()
      .value()
  }, [dataDopexFirst, dataDopexSecond])

  const stats = useMemo(() => {
    return dataOrange?.dopexVault && dataUniV3?.pool && dataUniV3?.bundle
      ? calculateAPR(
          dopexStrikeEarnings,
          dataOrange.dopexVault,
          dataUniV3.pool as Pool,
          new BN(dataUniV3.bundle.ethPriceUSD)
        )
      : undefined
  }, [dataOrange?.dopexVault, dataUniV3?.pool, dataUniV3?.bundle, dopexStrikeEarnings])

  useEffect(() => {
    if (dataOrange) {
      if (!vaultInfo || !dataOrange.dopexVault) {
        return
      }
      const vault = dataOrange.dopexVault
      // FIXME: globalState.ethPriceUSDはcamelot
      const currentEthPrice = new BN(dataUniV3?.bundle?.ethPriceUSD ?? 0)

      const _myPosition = dataOrange.user?.positions.find(
        position => position.vault === vaultInfo?.VAULT_ADDRESS
      )
      // FIXME: exist similar variable name
      const [_baseToken, _quoteToken] = (
        vault.isTokenPairReversed
          ? [dataUniV3?.pool?.token1, dataUniV3?.pool?.token0]
          : [dataUniV3?.pool?.token0, dataUniV3?.pool?.token1]
      ) as [Token, Token]
      if (!!_baseToken && !isEqual(baseToken, _baseToken)) {
        setBaseToken(
          Object.assign({}, _baseToken, { id: _baseToken.id.toLowerCase() as AddressType }) as Token
        )
      }
      if (!!_quoteToken && !isEqual(quoteToken, _quoteToken)) {
        setQuoteToken(
          Object.assign({}, _quoteToken, {
            id: _quoteToken.id.toLowerCase() as AddressType,
          }) as Token
        )
      }

      const symbol =
        convertUSDCSymbol(baseToken?.id as AddressType, usdceAddress) ?? baseToken?.symbol ?? ''

      const share = new BN(_myPosition?.share ?? 0)
      const [totalAssets, totalSupply] = [new BN(vault.totalAssets), new BN(vault.totalSupply)]
      const myPosition = totalSupply.isZero()
        ? new BN(0)
        : share.times(totalAssets).div(totalSupply).pow10ofMinus(Number(baseToken?.decimals))

      const _productContract = {
        ...vaultInfo,
        YIELD_START: Number(vault.yieldStart) * 1000,
        poolAddress: vault.pool.toLowerCase() as AddressType,
        totalDeposit: new BN(vault.totalAssets).pow10ofMinus(Number(vault.decimals)),
        maxCapacity: new BN(vault.depositCap).pow10ofMinus(Number(vault.decimals)),
        apr: null,
        feeApr: stats?.dopexApr.multipliedBy(100) ?? new BN(0),
        myPosition,
        myPositionUSD: myPosition
          .multipliedBy(new BN(baseToken?.derivedETH ?? 0))
          .multipliedBy(currentEthPrice),
        baseTokenPriceUSD: new BN(baseToken?.derivedETH ?? 0).multipliedBy(currentEthPrice),
        symbol,
        baseToken: _baseToken,
        quoteToken: _quoteToken,
        allocation: {
          amm: vaultInfo.info?.platform?.amm,
          derivative: vaultInfo.info?.platform?.derivative,
        },
      }
      if (!isEqual(productContract, _productContract)) {
        setProductContract(_productContract)
      }
    }
  }, [dataOrange, dataUniV3, usdceAddress, vaultInfo, baseToken, stats])

  useEffect(() => {
    if (dataOrange) {
      if (!vaultInfo || !dataOrange.dopexVault) {
        return
      }
      if (!baseToken?.id || !quoteToken?.id) {
        return
      }
      const vault = dataOrange.dopexVault

      const _contractProps: ContractProp[] = [
        {
          symbol: convertUSDCSymbol(baseToken.id as AddressType, usdceAddress) ?? baseToken.symbol,
          address: baseToken?.id.toLowerCase() as AddressType,
          url: `https://arbiscan.io/address/${baseToken.id}`,
        },
        {
          symbol:
            convertUSDCSymbol(quoteToken?.id as AddressType, usdceAddress) ?? quoteToken.symbol,
          address: quoteToken?.id.toLowerCase() as AddressType,
          url: `https://arbiscan.io/address/${quoteToken.id}`,
        },
        {
          symbol: 'Vault',
          address: vault.id.toLowerCase() as AddressType,
          url: `https://arbiscan.io/address/${vault.id}`,
        },
        {
          symbol: 'Pool',
          address: vault.pool.toLowerCase() as AddressType,
          url: `https://arbiscan.io/address/${vault.pool}`,
        },
      ]
      if (!isEqual(contractProps, _contractProps)) {
        setContractProps(_contractProps)
      }

      if (!!vault && !!dataUniV3?.pool) {
        setVaultData({
          ...vault,
          pool: dataUniV3.pool as Pool,
          baseToken,
          quoteToken,
        })
      }
    }
  }, [dataOrange, dataUniV3, vaultInfo, baseToken, quoteToken])

  const almAddressList = useMemo(() => {
    if (!vaultData) {
      return []
    }
    const strykePoolId = addHex(vaultData.pool.id as AddressType, 9)
    return [
      {
        vaultAddress: vaultData.id as AddressType,
        poolAddress: strykePoolId as AddressType,
      },
    ]
  }, [vaultData])

  const ethPriceUSD = useMemo(() => {
    return dataUniV3?.bundle?.ethPriceUSD ? new BN(dataUniV3?.bundle?.ethPriceUSD) : undefined
  }, [dataUniV3?.bundle?.ethPriceUSD])

  return {
    productContract,
    myVault,
    contractProps,
    vaultData,
    almAddressList,
    fetching: useMemo(() => {
      return {
        subgraph1: fetchingOrange || fetchingUniV3,
        subgraph2: fetchingDopexFirst || fetchingDopexSecond,
        subgraphAll: fetchingOrange || fetchingUniV3 || fetchingDopexFirst || fetchingDopexSecond,
      }
    }, [fetchingOrange, fetchingUniV3, fetchingDopexFirst, fetchingDopexSecond]),
    ethPriceUSD,
    dataUniV3,
    baseToken,
    dopexData: dataDopexFirst,
    // ethPriceUSD: dataUniV3?.bundle?.ethPriceUSD
    //   ? new BN(dataUniV3?.bundle?.ethPriceUSD)
    //   : undefined,
    error,
    reexecuteQuery,
  }
}
