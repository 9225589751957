import { blockNumberAtom } from '@apps-orangefi/lib/store'
import { useQueryClient, QueryKey } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { useState, useEffect, useCallback } from 'react'

type Props = {
  queryKey: QueryKey
  blockInterval?: number
}

export const useGetInvalidateQueries = ({ queryKey }: Props) => {
  const queryClient = useQueryClient()

  const refresh = useCallback(() => {
    queryClient.invalidateQueries({ queryKey })
  }, [queryKey, queryClient])

  return { refresh }
}

export const useInvalidateQueries = ({ queryKey, blockInterval = 500 }: Props) => {
  const { refresh } = useGetInvalidateQueries({ queryKey })

  useRefreshQuery({ refresh, blockInterval })
}

type RefreshQueryProps = {
  refresh: () => void
  blockInterval?: number
}

export const useRefreshQuery = ({ refresh, blockInterval = 500 }: RefreshQueryProps) => {
  const [currentBlockNumber, setCurrentBlockNumber] = useState<bigint | undefined>(undefined)
  const blockNumber = useAtomValue(blockNumberAtom)

  useEffect(() => {
    if (blockNumber) {
      if (!currentBlockNumber || blockNumber - currentBlockNumber > BigInt(blockInterval)) {
        setCurrentBlockNumber(blockNumber)
      }
    }
  }, [blockNumber])

  useEffect(() => {
    if (currentBlockNumber) {
      refresh()
    }
  }, [currentBlockNumber, JSON.stringify(refresh)])
}
