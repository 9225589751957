import { atom } from 'jotai'

export * from './tx'
export * from './vault'
export * from './chains'
export * from './block'

export const urlBaseAtom = atom({
  app: 'https://app.orangefinance.io',
  points: 'https://points.orangefinance.io',
})
