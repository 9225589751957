import { useResponsiveType, responsiveType } from '@apps-orangefi/hooks'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { twMerge } from 'tailwind-merge'

type Props = {
  type?: 'vertical' | 'horizontal'
}

export const StatsSkeleton = ({ type = 'vertical' }: Props) => {
  const [componentClass, labelClass, valueClass] =
    type === 'vertical'
      ? ['flex-col', '', 'mt-1 md:mt-3']
      : ['flex-row justify-between items-center', '', '']

  return (
    <div
      className={twMerge(
        'flex dark:bg-gray-850 rounded-2xl px-4 py-3 md:px-5 md:py-4 w-full whitespace-pre',
        componentClass
      )}
    >
      <div className={twMerge('type-base-medium', labelClass)}>
        <div className="flex flex-row items-center">
          <span className="leading-4">
            <Skeleton width={90} />
          </span>
        </div>
      </div>
      <div className={twMerge('type-xl-semibold dark:text-white font-mono', valueClass)}>
        <Skeleton width={120} />
      </div>
    </div>
  )
}
