import { useTx, useModal } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { Tx, txListAtom, txStatus } from '@apps-orangefi/lib/store'
import { MODAL_TYPES } from '@apps-orangefi/ui/organisms/modals'
import { useClaimNFT } from '@apps-orangefi/wagmi/hooks/astronaut'
import { atom, useAtom } from 'jotai'
import { useState, useEffect, useCallback } from 'react'
import { useAccount } from 'wagmi'

const txClaimNFTDefault: Tx = {
  title: 'Claim',
  hash: undefined,
  status: txStatus.Wait,
}

const txClaimNFTAtom = atom<Tx>(txClaimNFTDefault)

export const useClaimNFTForm = (
  astronautProxyAddress: AddressType | undefined,
  account: AddressType | undefined,
  merkleProof: AddressType[],
  isClaimable: boolean
) => {
  const [txList, setTxList] = useAtom(txListAtom)
  const { chain } = useAccount()

  const {
    tx: txClaimNFT,
    setTx: setTxClaimNFT,
    moveToPending: txClaimNFTPending,
    moveToError: txClaimNFTError,
    moveToSuccess: txClaimNFTSuccess,
  } = useTx(txClaimNFTAtom)

  const resetTx = () => {
    if (txList.length !== 0) return
    setTxClaimNFT(txClaimNFTDefault)
  }

  const initTxList = () => {
    if (txList.length === 0) {
      setTxList([txClaimNFTAtom])
    }
  }

  const claimNFT = useClaimNFT(astronautProxyAddress, account, merkleProof, isClaimable, {
    success: txClaimNFTSuccess,
    fail: txClaimNFTError,
  })

  useEffect(() => {
    if (!claimNFT.hash || !!txClaimNFT.hash) return
    setTxClaimNFT(prev => {
      return { ...prev, hash: claimNFT.hash }
    })
  }, [claimNFT.hash])

  useEffect(() => {
    if (txList.length === 0) return
    if (!claimNFT.isClaimReady) return
    if (txClaimNFT.status === txStatus.Wait) {
      txClaimNFTPending()
      claimNFT.onClaimNFT()
    }
  }, [txList, txClaimNFT, claimNFT.isClaimReady, claimNFT.onClaimNFT])

  const { showModal, hideModal } = useModal()

  const onClaimNFT = useCallback(() => {
    resetTx()
    initTxList()
    showModal({
      modalType: MODAL_TYPES.TxModal,
      modalProps: {
        title: 'Claim transaction',
        chain,
        handleClose: () => {
          hideModal()
        },
        isDefaultClosable: true,
      },
    })
  }, [])

  return {
    resetTx,
    initTxList,
    onClaimNFT,
  }
}
