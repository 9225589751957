import { useUniV3Query } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { getUniV3PoolQuery } from '@apps-orangefi/lib/subgraph/queries'
import { type GetPoolQueryQuery } from '@apps-orangefi/lib/subgraph/types/uniswap/graphql'
import { type Strike, type Pool, type Token } from '@apps-orangefi/lib/types'
import { getAmountsForLiquidity, getToken } from '@apps-orangefi/lib/utils'
import {
  calcReservedLpValue,
  ReservedAmount,
  ReservedLpValue,
} from '@apps-orangefi/lib/utils/reserved'
import {
  useCollectTokenIdData,
  useGetReserveCooldown,
  useGetReservedLiquidities,
  useReservedPositions,
  useReserveHelpers,
} from '@apps-orangefi/wagmi/hooks'
import { TickMath } from '@uniswap/v3-sdk'
import dayjs from 'dayjs'
import JSBI from 'jsbi'
import { chain, isEmpty, isNil } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

// 1. fetch reserved liquidities from ReserveHelper contract
// 2. fetch current tick, sqrtPrice from uniswap subgraph
// 3. fetch reserve cooldown & reserved liquidities from contractss
//    reserved liquidity has last reserve timestamp
// 4. calculate reserved token pair amounts with reserved liquidities and current tick and sqrtPrice, tickLower, tickUpper
// 5. fetch a whole reserved liquidity and total liquidity, used liquidity from Stryke Handler contract
// 6. calculate tick withdrawable liquidity with a whole reserved liquidity and total liquidity, used liquidity
// 7. calculate withdrawable token pair amounts with tick withdrawable liquidity and current tick, sqrtPrice, tickLower, tickUpper

const BLOCK_INTERVAL = 150

export const useReservedLPStatus = (
  account: AddressType | undefined,
  pool: AddressType | undefined,
  handlerAddress: AddressType | undefined,
  reserveProxyAddress: AddressType | undefined,
  chainId: number | undefined
) => {
  const [reservedLpValue, setReservedLpValue] = useState<ReservedLpValue>({
    withdrawableReserveLiquidities: [],
    totalWithdrawableReserveLiquidity: {},
    totalAmountUSD: new BN(0),
  })
  const [reservedAmounts, setReservedAmounts] = useState<ReservedAmount[]>([])

  // useRefreshQuery({ refresh: reexecuteQuery, blockInterval: BLOCK_INTERVAL })

  // 1. fetch reserved liquidities from ReserveHelper contract
  const { reserveHelperAddress, refetch: refetchReserveHelper } = useReserveHelpers(
    reserveProxyAddress,
    account
  )
  const {
    reservedPositions,
    isFetching: isFetchingReservedPositions,
    refresh: refreshReservedPositions,
  } = useReservedPositions(reserveHelperAddress, pool, handlerAddress)

  // 2. fetch current tick, sqrtPrice from uniswap subgraph
  const [resultUniV3] = useUniV3Query<GetPoolQueryQuery>({
    query: getUniV3PoolQuery,
    variables: {
      poolId: pool?.toLowerCase() ?? '',
    },
    pause: !pool,
    requestPolicy: 'network-only',
  })
  const {
    data: dataUniV3,
    fetching: fetchingUniV3,
    error: errorUniV3,
  } = useMemo(() => resultUniV3, [resultUniV3])

  // 3. fetch reserve cooldown & reserved liquidities from contractss
  //      reserved liquidity has last reserve timestamp
  const { reserveCooldwon } = useGetReserveCooldown(handlerAddress)
  const reservedLiquidityTokenIds = useMemo(() => {
    return reservedPositions.map(rsvdPos => rsvdPos.tokenId) ?? []
  }, [reservedPositions])

  const { reservedLiquidities, isFetching: isFetchingReservedLiquidities } =
    useGetReservedLiquidities(
      handlerAddress,
      reserveHelperAddress ?? undefined,
      reservedLiquidityTokenIds
    )

  // 4. calculate reserved token pair amounts with reserved liquidities and current tick and sqrtPrice, tickLower, tickUpper
  useEffect(() => {
    if (
      reservedPositions.length === 0 ||
      !dataUniV3 ||
      !dataUniV3.pool ||
      isEmpty(reservedLiquidities) ||
      isNil(reserveCooldwon)
    )
      return
    const now = dayjs().unix()

    // TODO: wrap function JSBI.BigInt
    const sqrtPriceX96 = JSBI.BigInt(dataUniV3.pool.sqrtPrice)
    const _reservedAmounts = reservedPositions.map(rsvdPos => {
      const sqrtRatioAX96 = TickMath.getSqrtRatioAtTick(Number(rsvdPos.tickLower))
      const sqrtRatioBX96 = TickMath.getSqrtRatioAtTick(Number(rsvdPos.tickUpper))
      const liquidity = JSBI.BigInt(rsvdPos.liquidity.toString())

      const { amount0, amount1 } = getAmountsForLiquidity(
        sqrtPriceX96,
        sqrtRatioAX96,
        sqrtRatioBX96,
        liquidity
      )
      const reserved = reservedLiquidities.find(liq => liq.tokenId === rsvdPos.tokenId)
      const [withdrawableAmount0, withdrawableAmount1] =
        now - (reserved?.lastReserve ?? 0) > reserveCooldwon
          ? [new BN(amount0.toString()), new BN(amount1.toString())]
          : [new BN(0), new BN(0)]

      return {
        tokenId: rsvdPos.tokenId,
        amount0: new BN(amount0.toString()),
        amount1: new BN(amount1.toString()),
        withdrawableAmount0,
        withdrawableAmount1,
        liquidity: new BN(liquidity.toString()),
      }
    })
    setReservedAmounts(_reservedAmounts)
  }, [reservedPositions, dataUniV3, reservedLiquidities])

  // 5. fetch a whole reserved liquidity and total liquidity, used liquidity from Stryke Handler contract
  const { tokenIdDataList, isFetching: isFetchingTokenIdData } = useCollectTokenIdData(
    handlerAddress,
    reservedPositions
  )

  const strikes: Strike[] = useMemo(() => {
    if (!dataUniV3 || !dataUniV3.pool) return []
    const pool = dataUniV3.pool

    return chain(tokenIdDataList)
      .map(tokenIdData => {
        const [token0, token1] =
          pool.token0.id.toLowerCase() === tokenIdData.token0Address.toLowerCase()
            ? [pool.token0, pool.token1]
            : [pool.token1, pool.token0]
        return {
          ...tokenIdData,
          token0: {
            ...token0,
            decimals: Number(token0.decimals),
          } as Token,
          token1: {
            ...token1,
            decimals: Number(token1.decimals),
          } as Token,
        }
      })
      .sortBy(strike => strike.tickLower)
      .value()
  }, [tokenIdDataList, dataUniV3?.pool])

  const poolBaseToken = useMemo(() => {
    if (!dataUniV3 || !dataUniV3.pool?.token0 || !chainId) return
    return getToken(
      chainId,
      dataUniV3.pool.token0.id as AddressType,
      Number(dataUniV3.pool.token0.decimals)
    )
  }, [dataUniV3, chainId])

  const poolQuoteToken = useMemo(() => {
    if (!dataUniV3 || !dataUniV3.pool?.token1 || !chainId) return
    return getToken(
      chainId,
      dataUniV3.pool.token1.id as AddressType,
      Number(dataUniV3.pool.token1.decimals)
    )
  }, [dataUniV3, chainId])

  // 6. calculate tick withdrawable liquidity with a whole reserved liquidity and total liquidity, used liquidity
  // 7. calculate withdrawable token pair amounts with tick withdrawable liquidity and current tick, sqrtPrice, tickLower, tickUpper

  useEffect(() => {
    if (!dataUniV3 || !dataUniV3.pool) return
    if (!poolBaseToken || !poolQuoteToken) return
    const _reservedLpValue = calcReservedLpValue(
      strikes,
      dataUniV3.pool as Pool,
      reservedAmounts,
      poolBaseToken,
      poolQuoteToken,
      new BN(dataUniV3.bundle?.ethPriceUSD ?? 0)
    )

    setReservedLpValue(_reservedLpValue)
  }, [reservedAmounts, strikes, dataUniV3, poolBaseToken, poolQuoteToken])

  const withdrawableTokenIds = useMemo(() => {
    return reservedLpValue.withdrawableReserveLiquidities
      .filter(lp => {
        return lp.token0?.withdrawable.gt(new BN(0)) || lp.token1?.withdrawable.gt(new BN(0))
      })
      .map(lp => lp.id)
  }, [reservedLpValue.withdrawableReserveLiquidities])

  return {
    ...reservedLpValue,
    tokenIds: withdrawableTokenIds,
    fetching:
      fetchingUniV3 ||
      isFetchingReservedPositions ||
      isFetchingReservedLiquidities ||
      isFetchingTokenIdData,
    refetch: () => {
      refetchReserveHelper()
      refreshReservedPositions()
    },
  }
}
