import { Tx, txStatus } from '@apps-orangefi/lib/store'
import { useAtom } from 'jotai'
import { BaseError } from 'wagmi'

import type { PrimitiveAtom } from 'jotai'

export const useTx = (txAtom: PrimitiveAtom<Tx>) => {
  const [tx, setTx] = useAtom(txAtom)

  const moveToPending = () => {
    setTx(prev => {
      if (!prev || prev.status !== txStatus.Wait) return prev
      return { ...prev, status: txStatus.Pending }
    })
  }

  const moveToSuccess = () => {
    setTx(prev => {
      if (!prev || (prev.status !== txStatus.Pending && prev.status !== txStatus.Wait)) return prev
      return { ...prev, status: txStatus.Success }
    })
  }

  const moveToError = (cause?: BaseError | string) => {
    const message = (() => {
      if (typeof cause === 'string') return cause
      switch (cause?.name) {
        case 'UserRejectedRequestError':
          return 'User rejected the request'
        default:
          return 'Unexpected error'
      }
    })()
    setTx(prev => {
      if (!prev || prev.status !== txStatus.Pending) return prev
      return { ...prev, status: txStatus.Error, error: message }
    })
  }

  return {
    tx,
    setTx,
    moveToPending,
    moveToSuccess,
    moveToError,
  }
}
