import ImgCoinbase from '@apps-orangefi/assets/images/wallets/coinbase-wallet.png'
import ImgMetamask from '@apps-orangefi/assets/images/wallets/metamask-fox.svg'
import ImgRabby from '@apps-orangefi/assets/images/wallets/rabby.svg'
import ImgWalletconnext from '@apps-orangefi/assets/images/wallets/walletconnect-circle-white.svg'
import { LineLoader } from '@apps-orangefi/ui/atoms'
import { BaseModal } from '@apps-orangefi/ui/organisms/modals'
import { Dialog } from '@headlessui/react'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { useConnect, Connector } from 'wagmi'

export type ConnectAlphaModalProps = {
  handleClose: () => void
  handleConnect: (conn: Connector) => void
}

export const ConnectAlphaModal = ({ handleClose, handleConnect }: ConnectAlphaModalProps) => {
  const { t } = useTranslation()
  const { connectors, isPending } = useConnect()

  const WalletIcon: any = {
    metaMask: <Image src={ImgMetamask} height={22} width={22} alt="Metamask" />,
    rabby: <Image src={ImgRabby} alt="Rabby" />,
    coinbaseWallet: <Image src={ImgCoinbase} height={22} width={22} alt="CoinbaseWallet" />,
    walletConnect: <Image src={ImgWalletconnext} height={22} width={22} alt="WalletConnect" />,
  }

  return (
    <BaseModal onClose={() => handleClose()}>
      <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-3xl bg-white py-14 px-10 text-left align-middle shadow-xl transition-all">
        <Dialog.Title as="h3" className="text-style-body1-sb text-center mb-10">
          {t('WALLET.CONNECT_WALLET')}
        </Dialog.Title>
        <div className="">
          {connectors.map(conn => (
            <button
              // disabled={isMounted ? !x.ready : false}
              key={conn.id}
              onClick={() => handleConnect(conn)}
              className="flex w-full items-center rounded-3xl py-2 px-5 bg-athensGray text-black hover:brightness-95 mt-5 disabled:cursor-not-allowed disabled:opacity-70"
            >
              <div className="mr-3 shrink-0 relative">
                <div className="w-12 h-12 flex flex-row justify-center items-center ">
                  {WalletIcon[conn.id]}
                </div>
              </div>
              {conn.name}
              {isPending && <LineLoader type="line" className="ml-4" />}
            </button>
          ))}
        </div>
      </Dialog.Panel>
    </BaseModal>
  )
}
