import Spaceship from '@apps-orangefi/assets/images/spaceship.svg'
import { BN } from '@apps-orangefi/lib'
import { Token } from '@apps-orangefi/lib/types'
import { numberWithShortScale } from '@apps-orangefi/lib/utils'
import { TVLBar, TVLStats } from '@apps-orangefi/ui/molecules/spaceship'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Countdown = dynamic(() => import('@apps-orangefi/ui/molecules/spaceship/Countdown'), {
  ssr: false,
})

type Props = {
  tvlList: {
    vaultAddress: AddressType
    productName: string
    baseToken: Token
    quoteToken: Token
    tvl: BN
  }[]
  totalTvl: BN
  milestones: { value: BN; reward: number }[]
  expiryTimestamp: Date
  fetching: boolean
}

export const SpaceshipSummary = ({ totalTvl, tvlList, milestones, expiryTimestamp }: Props) => {
  // const [isVideoEnded, setIsVideoEnded] = useState(false)
  // useEffect(() => {
  //   const video = document.querySelector("video");

  //   video?.addEventListener("ended", (event) => {
  //     setIsVideoEnded(true)
  //   });
  // }, [])

  const tvls = tvlList.map(tvl => {
    return {
      label: tvl.productName,
      value: tvl.tvl,
      baseTokenSymbol: tvl.baseToken.symbol,
      quoteTokenSymbol: tvl.quoteToken.symbol,
    }
  })

  const { value: milestone1Target, reward: milestone1Reward } = milestones[0]
  const { value: milestone2Target, reward: milestone2Reward } = milestones[1]

  return (
    // <div className={`bg-main w-screen mx-[calc(50%-50vw)]`}>
    <div className={`bg-main `}>
      <div className="container flex flex-col md:flex-col items-center py-4 pb-8 md:py-6 ">
        <div className="flex flex-row w-full justify-between items-center">
          <div className="flex flex-col md:flex-row w-full">
            <div className="flex flex-col flex-1">
              <h1 className="dark:text-white text-3xl md:text-7xl font-bold leading-9 md:leading-[4.5rem]">
                The Spaceship Returns
              </h1>
              <div className="type-base-medium mt-3 md:mt-5 w-full md:w-3/4">
                Orange&apos;s All Vault users can receive up to {milestone2Reward.toLocaleString()}{' '}
                ARB based on the vaults&apos; total TVL. The top 50 depositors will split{' '}
                {milestone1Reward.toLocaleString()} ARB if TVL hits $
                {numberWithShortScale(milestone1Target, 0)}, and all depositors share{' '}
                {(milestone2Reward - milestone1Reward).toLocaleString()} ARB if TVL reaches $
                {numberWithShortScale(milestone2Target, 0)}.
              </div>
              <div className="mt-12">
                <Countdown expiryTimestamp={expiryTimestamp} />
              </div>
            </div>
            <div className="w-full max-w-[400px]">
              <video src={require('../../../assets/videos/rocketSq.mp4')} autoPlay muted loop />
              {/* {isVideoEnded ? (
                <Image src={Spaceship} alt="spaceship" />
              ) : (
                <video src={require('../../../assets/videos/rocketSq.mp4')} autoPlay muted />
              )} */}
            </div>
          </div>
        </div>
        <div className="mt-12 w-full">
          <TVLBar tvl={totalTvl} milestone={milestones[0]} max={milestones[1]} />
        </div>
        <TVLStats tvls={tvls} className="mt-12 overflow-x-scroll hidden-scrollbar flex-wrap" />
      </div>
    </div>
  )
}
