import { BN } from '@apps-orangefi/lib'
import { Position } from '@apps-orangefi/lib/types/point'
import { Button } from '@apps-orangefi/ui/atoms/buttons'
import {
  DailyPointEarningsEstimation,
  Notice,
  type DailyEarnings,
} from '@apps-orangefi/ui/atoms/point'
import {
  EstimateItem,
  LockAmountField,
  LockDurationField,
  VaultAttributes,
} from '@apps-orangefi/ui/molecules/point/chapter2'
import { zodResolver } from '@hookform/resolvers/zod'
import { Dayjs } from 'dayjs'
import { isEmpty } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { z } from 'zod'

export type LockProps = {
  position: Position
  tokenBalance: BN | undefined
  lock: {
    amount: BN
    onSetAmount: (amount: BN) => void
    onSetMaxBalance: () => void
    lockDays: number
    onChangeLockDuration: (lockDays: number) => void
    onCreateLock: (data: { amount: string }) => void
    onCancel: () => void
  }
  estimate: {
    lockValue: BN
    unlockDate: Dayjs | null
    timeBoost: number
    dailyEarnings: DailyEarnings
  }
}

export const LockForm = ({
  position,
  lock: {
    amount,
    onSetAmount,
    onSetMaxBalance,
    lockDays,
    onChangeLockDuration,
    onCreateLock,
    onCancel,
  },
  estimate: { lockValue, unlockDate, timeBoost, dailyEarnings },
  tokenBalance,
}: LockProps) => {
  const { t: tPoints } = useTranslation('points')
  const [isFormTouched, setIsFormTouched] = useState(false)

  const schema = z.object({
    amount: z.string().superRefine((value, ctx) => {
      const amountValue = new BN(value)

      if (!isEmpty(value) && amountValue.isNaN()) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Amount must be a valid Value',
        })
      } else if (amountValue.isNegative()) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Amount must be positive',
        })
      } else if (amountValue.gt(tokenBalance ?? new BN(0))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Balance is insufficient',
        })
      }
    }),
    lockDuration: z.coerce
      .number()
      .min(1, {
        message: 'Must be at least 1 days',
      })
      .max(183, { message: 'Must be less than 183 days' }),
  })

  const {
    control,
    setValue,
    trigger,
    watch,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    defaultValues: {
      amount: '0',
      lockDuration: 0,
    },
    resolver: zodResolver(schema),
  })
  const watchAmount = watch('amount')

  useEffect(() => {
    if (amount.gt(0)) {
      setValue('amount', amount.toFixed())
    }
  }, [amount.toFixed()])

  useEffect(() => {
    if (lockDays >= 0) {
      setValue('lockDuration', lockDays)
    }
  }, [lockDays])

  const handleChangeDuration = useCallback(
    (newDuration: number) => {
      onChangeLockDuration(newDuration)
      setValue('lockDuration', newDuration, { shouldValidate: false })
      setIsFormTouched(true)
      trigger('lockDuration') // Manually trigger validation
    },
    [onChangeLockDuration, setValue, trigger]
  )

  useEffect(() => {
    const newAmount = new BN(watchAmount)
    if (!newAmount.isNaN() && !newAmount.isEqualTo(amount)) {
      onSetAmount(new BN(watchAmount))
      trigger('amount')
    }
  }, [watchAmount])

  return (
    <form className="flex flex-col" onSubmit={handleSubmit(onCreateLock)}>
      <VaultAttributes
        tokenPair={position.tokenPair}
        platform={position.platform}
        className="mt-4"
      />
      <div className="flex flex-col mt-6 gap-3">
        <div className="type-base-semibold dark:text-white">Setting</div>
        <Controller
          name="amount"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <LockAmountField
              value={value}
              balance={tokenBalance}
              editable={true}
              onChange={onChange}
              onClickMax={onSetMaxBalance}
              error={error}
            />
          )}
        />
        <Controller
          name="lockDuration"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <LockDurationField
              lockDays={value}
              onChangeDuration={newDuration => {
                onChange(newDuration)
                handleChangeDuration(newDuration)
              }}
              error={isFormTouched ? error : undefined}
            />
          )}
        />
      </div>
      <div className="flex flex-col mt-6">
        <div className="type-base-semibold dark:text-white">Estimate</div>
        <div className="flex flex-col mt-3">
          <EstimateItem
            label="Lock Value"
            value={`$ ${lockValue.toFixed(2)}`}
            tooltipText={tPoints('MODAL.HINTS.LOCK_VALUE')}
          />
          <EstimateItem
            label="Unlock date"
            value={unlockDate ? unlockDate.format('MM.DD.YYYY') : '-'}
            tooltipText={tPoints('MODAL.HINTS.UNLOCK_DATE')}
          />
          <EstimateItem
            label="Lock Time boost"
            value={`${timeBoost.toFixed(2)}x`}
            tooltipText={tPoints('MODAL.HINTS.LOCK_TIME_BOOST')}
          />
        </div>
      </div>

      <DailyPointEarningsEstimation dailyEarnings={dailyEarnings} className="mt-4" />
      <div className="flex flex-row gap-3 w-full mt-6">
        <Button
          label="Cancel"
          onSubmit={onCancel}
          className="w-full dark:bg-gray-850 rounded-2xl type-base-bold dark:text-white"
        />
        <Button
          label={tPoints(`WIDGET.ACTION.LOCK`)}
          disabled={!isValid}
          className={`w-full rounded-2xl type-base-bold dark:text-white ${
            !isValid ? 'dark:bg-gray-700' : ''
          }`}
        />
      </div>
      <Notice type="lock" />
    </form>
  )
}
