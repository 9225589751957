import { useState, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  value: number
  max: number
  className?: string
}

export const ProgressBar = ({ value, max, className }: Props) => {
  const rate = (value / max) * 100

  return (
    <div className={className ? twMerge(`${className}`) : ''}>
      <div className="w-full bg-capacity rounded-7xl h-full relative overflow-hidden">
        <div
          className="bg-capacity-used h-full rounded-7xl absolute top-0 left-0"
          style={{ width: `${rate}%` }}
        />
      </div>
    </div>
  )
}
