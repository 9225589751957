const abi = [
  {
    inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
    name: 'ReserveHelperAlreadyInitialized',
    type: 'error',
  },
  {
    inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
    name: 'ReserveHelperUninitialized',
    type: 'error',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      {
        indexed: false,
        internalType: 'contract ReserveHelper',
        name: 'reserveHelper',
        type: 'address',
      },
    ],
    name: 'CreateReserveHelper',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      {
        indexed: false,
        internalType: 'contract IStrykeHandlerV2',
        name: 'handler',
        type: 'address',
      },
      {
        components: [
          { internalType: 'address', name: 'pool', type: 'address' },
          { internalType: 'address', name: 'hook', type: 'address' },
          { internalType: 'int24', name: 'tickLower', type: 'int24' },
          { internalType: 'int24', name: 'tickUpper', type: 'int24' },
          { internalType: 'uint128', name: 'liquidity', type: 'uint128' },
        ],
        indexed: false,
        internalType: 'struct IStrykeHandlerV2.ReserveLiquidity',
        name: 'reservedPosition',
        type: 'tuple',
      },
    ],
    name: 'ReserveLiquidity',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      {
        indexed: false,
        internalType: 'contract IStrykeHandlerV2',
        name: 'handler',
        type: 'address',
      },
      {
        components: [
          { internalType: 'address', name: 'pool', type: 'address' },
          { internalType: 'address', name: 'hook', type: 'address' },
          { internalType: 'int24', name: 'tickLower', type: 'int24' },
          { internalType: 'int24', name: 'tickUpper', type: 'int24' },
          { internalType: 'uint128', name: 'liquidity', type: 'uint128' },
        ],
        indexed: false,
        internalType: 'struct IStrykeHandlerV2.ReserveLiquidity',
        name: 'withdrawnPosition',
        type: 'tuple',
      },
    ],
    name: 'WithdrawReserveLiquidity',
    type: 'event',
  },
  {
    inputs: [
      { internalType: 'contract IStrykeHandlerV2', name: 'handler', type: 'address' },
      {
        components: [
          { internalType: 'address', name: 'pool', type: 'address' },
          { internalType: 'address', name: 'hook', type: 'address' },
          { internalType: 'int24', name: 'tickLower', type: 'int24' },
          { internalType: 'int24', name: 'tickUpper', type: 'int24' },
          { internalType: 'uint128', name: 'shares', type: 'uint128' },
        ],
        internalType: 'struct IStrykeHandlerV2.ReserveShare[]',
        name: 'reserveLiquidityParams',
        type: 'tuple[]',
      },
    ],
    name: 'batchReserveLiquidity',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'pool', type: 'address' },
          { internalType: 'address', name: 'hook', type: 'address' },
          { internalType: 'int24', name: 'tickLower', type: 'int24' },
          { internalType: 'int24', name: 'tickUpper', type: 'int24' },
          { internalType: 'uint128', name: 'liquidity', type: 'uint128' },
        ],
        internalType: 'struct IStrykeHandlerV2.ReserveLiquidity[]',
        name: 'reservedLiquidities',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IStrykeHandlerV2', name: 'handler', type: 'address' },
      { internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' },
    ],
    name: 'batchWithdrawReserveLiquidity',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'createMyReserveHelper',
    outputs: [{ internalType: 'contract ReserveHelper', name: 'reserveHelper', type: 'address' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
    name: 'reserveHelpers',
    outputs: [{ internalType: 'contract ReserveHelper', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const

export default abi
