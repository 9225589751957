import { LockPosition, Position } from '@apps-orangefi/lib/types/point'
import { numberWithShortScale } from '@apps-orangefi/lib/utils'
import { Button } from '@apps-orangefi/ui/atoms/buttons'
import {
  DailyPointEarningsEstimation,
  Notice,
  type DailyEarnings,
} from '@apps-orangefi/ui/atoms/point'
import { EstimateItem, VaultAttributes } from '@apps-orangefi/ui/molecules/point/chapter2'
import dayjs from 'dayjs'
import useTranslation from 'next-translate/useTranslation'
import { useForm } from 'react-hook-form'

export type UnlockProps = {
  position: Position
  lockPosition: LockPosition
  lock: {
    onUnlock: () => void
    onCancel: () => void
  }
  estimate: {
    dailyEarnings: DailyEarnings
  }
}

export const UnlockForm = ({ position, lockPosition, lock, estimate }: UnlockProps) => {
  const { t: tPoints } = useTranslation('points')
  const isLockExpired = lockPosition.unlockAt.isBefore(dayjs())

  const { handleSubmit } = useForm()

  return (
    <form className="flex flex-col" onSubmit={handleSubmit(lock.onUnlock)}>
      <VaultAttributes
        tokenPair={position.tokenPair}
        platform={position.platform}
        className="mt-4"
      />
      <div className="flex flex-col mt-6">
        <div className="flex flex-col mt-3">
          <EstimateItem
            label="Unlock amount"
            value={`$${numberWithShortScale(lockPosition.lockAmountUSD)}`}
            tooltipText={tPoints('MODAL.HINTS.UNLOCK_AMOUNT')}
          />
          <EstimateItem
            label="Unlock date"
            value={lockPosition.unlockAt ? lockPosition.unlockAt.format('MM.DD.YYYY') : '-'}
            tooltipText={tPoints('MODAL.HINTS.UNLOCK_DATE')}
          />
          {!isLockExpired && (
            <span className="type-sm-caption dark:text-red-400 mt-1">
              The lock is still within valid period.
            </span>
          )}
        </div>
      </div>

      <DailyPointEarningsEstimation dailyEarnings={estimate.dailyEarnings} className="mt-4" />
      <div className="flex flex-row gap-3 w-full mt-6">
        <Button
          label="Cancel"
          onSubmit={lock.onCancel}
          className="w-full dark:bg-gray-850 rounded-2xl type-base-bold dark:text-white"
        />
        <Button
          label={tPoints(`WIDGET.ACTION.UNLOCK`)}
          disabled={!isLockExpired}
          className={`w-full rounded-2xl type-base-bold dark:text-white ${
            !isLockExpired ? 'dark:bg-gray-700' : ''
          }`}
        />
      </div>
      <Notice type="lock" />
    </form>
  )
}
