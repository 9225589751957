import { BN } from '@apps-orangefi/lib'
import {
  vaultDecimalsAtom,
  tokenAtom,
  usdceAddressAtom,
  needReloadAtom,
} from '@apps-orangefi/lib/store'
import { UserPosition } from '@apps-orangefi/lib/types'
import { convertUSDCSymbol, bigintToBN } from '@apps-orangefi/lib/utils'
import {
  useLPDfiVaultCapacity,
  useTokenValue,
  useMyPosition,
  useMinDepositAssets,
  useTokenBalance,
  useHandlers,
} from '@apps-orangefi/wagmi/hooks'
import { useAtom, useSetAtom, useAtomValue } from 'jotai'
import { isNil } from 'lodash'
import { useState, useEffect, useMemo } from 'react'

export const useReadLPDfiVault = (
  vaultAddress: AddressType | undefined,
  account: AddressType | undefined,
  baseTokenAddress: AddressType | undefined
) => {
  const [vaultDecimals, setVaultDecimals] = useAtom(vaultDecimalsAtom)
  const setToken = useSetAtom(tokenAtom)
  const [tokenBalance, setTokenBalance] = useState<BN | undefined>(undefined)
  const [userPosition, setUserPosition] = useState<UserPosition | undefined>(undefined)

  const usdceAddress = useAtomValue(usdceAddressAtom)
  const [needReload, setNeedReload] = useAtom(needReloadAtom)

  const { data: dataTokenBalance, refresh: refreshTokenBalance } = useTokenBalance({
    account,
    tokenAddress: baseTokenAddress,
    watch: true,
  })

  const { minDepositAssets, isFetching: minDepositAssetsIsFetching } = useMinDepositAssets(
    vaultAddress,
    vaultDecimals
  )

  useEffect(() => {
    const tokenDecimals = dataTokenBalance?.decimals ?? 0
    const tokenSymbol =
      convertUSDCSymbol(baseTokenAddress, usdceAddress) ?? dataTokenBalance?.symbol ?? ''

    setTokenBalance(dataTokenBalance?.balance)
    setToken({
      decimals: tokenDecimals,
      symbol: tokenSymbol,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataTokenBalance)])

  const {
    tokenValue: currentTokenValue,
    decimals,
    isFetching: tokenValueIsFetching,
  } = useTokenValue(vaultAddress, true)

  useEffect(() => {
    setVaultDecimals(decimals)
  }, [decimals])

  const {
    myPosition,
    isFetching: positionIsFetching,
    refresh: refreshMyPosition,
  } = useMyPosition(vaultAddress, account, vaultDecimals, true)
  // const { tokenValue: lastDepositedTokenValue, isFetching: lastDepositedTokenValueIsFetching } =
  //   useLastDepositedV1TokenValue(vaultAddress, account)

  const { vaultCapacity: vCapacity, isFetching: capacityIsFetching } = useLPDfiVaultCapacity(
    vaultAddress,
    vaultDecimals,
    true
  )
  const { handlerAddressList, isFetching: handlerIsFetching } = useHandlers([vaultAddress])

  const vaultCapacity = useMemo(() => {
    return {
      totalDeposit: vCapacity?.totalDeposit ?? new BN(0),
      maxCapacity: vCapacity?.maxCapacity ?? new BN(0),
    }
  }, [vCapacity])

  const isFetching = useMemo(() => {
    return (
      tokenValueIsFetching ||
      positionIsFetching ||
      // lastDepositedTokenValueIsFetching &&
      capacityIsFetching ||
      minDepositAssetsIsFetching ||
      handlerIsFetching
    )
  }, [
    tokenValueIsFetching,
    positionIsFetching,
    capacityIsFetching,
    minDepositAssetsIsFetching,
    handlerIsFetching,
  ])

  useEffect(() => {
    // FIXME: cant get lastDepositionTokenValue if time passed since last deposit
    if (!isNil(myPosition) && !isFetching) {
      // const premium =
      //   !isNil(lastDepositedTokenValue) && !isNil(currentTokenValue) && !isNil(myPosition.lpBalance)
      //     ? currentTokenValue?.minus(lastDepositedTokenValue).times(myPosition.lpBalance)
      //     : new BN(0)

      setUserPosition({
        myPosition: myPosition,
        premium: new BN(999), // FIXME
      })
    }
  }, [
    JSON.stringify(myPosition),
    currentTokenValue?.toFixed(),
    // lastDepositedTokenValue?.toFixed(),
    isFetching,
  ])

  useEffect(() => {
    let id: NodeJS.Timeout | undefined
    if (needReload) {
      id = setTimeout(() => {
        refreshTokenBalance()
        refreshMyPosition()
        setNeedReload(false)
      }, 1000)
    }

    return () => {
      if (id) {
        clearTimeout(id)
      }
    }
  }, [needReload, refreshTokenBalance, refreshMyPosition])

  return {
    tokenBalance,
    userPosition,
    vaultCapacity,
    currentTokenValue,
    minDepositAssets,
    handlerAddress: handlerAddressList[0],
    isFetching,
  }
}
