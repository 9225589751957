import Image from 'next/image'
import { twMerge } from 'tailwind-merge'

import { OctagonContainer } from './OctagonContainer'

const tailwindConfig = require('@apps-orangefi/tailwindcss')

type Props = {
  label: string
  value: string
  iconUrl: any
  className?: string
}

export const Stats = ({ label, value, iconUrl, className }: Props) => {
  return (
    <div className={twMerge('flex flex-row', className)}>
      <OctagonContainer
        size={3}
        borderColor={tailwindConfig.theme.extend.colors.orange[500]}
        className="w-fit h-fit"
      >
        <div className="panel-shadow p-3 sm:p-5">
          <Image
            src={iconUrl}
            alt={label}
            width={32}
            height={32}
            className="w-6 h-6 sm:w-8 sm:h-8"
          />
        </div>
      </OctagonContainer>
      <div className="flex flex-col ml-3">
        <span className="type-base-medium dark:text-gray-400">{label}</span>
        <span className="type-xl-medium sm:type-2xl-medium dark:text-white mt-0.5 sm:mt-1">
          {value}
        </span>
      </div>
    </div>
  )
}
