import { BN } from '@apps-orangefi/lib'
import { fetchMerkl, fetchFallbackStats } from '@apps-orangefi/lib/api'
import { MerkleRewardsApr, Token, Vault } from '@apps-orangefi/lib/types'
import { getAmountsForLiquidity } from '@apps-orangefi/lib/utils'
import { useGetAutomatorPositions } from '@apps-orangefi/wagmi/hooks/lpdfi'
import { useQuery } from '@tanstack/react-query'
import { TickMath } from '@uniswap/v3-sdk'
import JSBI from 'jsbi'
import { find, isEmpty, chain, set, isEqual, mapKeys, map } from 'lodash'
import { useEffect, useState } from 'react'
import { useMemo } from 'react'

type ALMAddressPair = {
  vaultAddress: AddressType
  poolAddress: AddressType
}

type RawAPR = {
  [key: AddressType]: {
    baseToken: BN
    quoteToken: BN
  }
}
// const REWARD_TICK_RANGE = 250
const TICK_SPACING = 10

// TODO: unused now, but may be used in the future
// Change callers to pass rewardTickRange
export const useMerklRewardsDopex = (
  chainId: number,
  account: AddressType | undefined,
  vaultList: (Vault & { rewardTickRange: number })[],
  inspectorAddress: AddressType
) => {
  const [rawApr, setRawApr] = useState<RawAPR>({})
  const [merklRewardsApr, setMerklRewardsApr] = useState<MerkleRewardsApr>({})

  const res = useQuery({
    queryKey: ['merkl', { chainId }],
    queryFn: fetchMerkl,
  })
  const merklPools = useMemo(() => {
    return res && res.data ? res.data[chainId]?.pools : {}
  }, [res?.data, chainId])

  useEffect(() => {
    const poolRewards = vaultList.reduce((result, vault: Vault) => {
      const pool = find(merklPools, (_, key) => vault.pool?.id.toLowerCase() === key.toLowerCase())
      if (!pool) {
        return result
      }
      const baseTokenAprKeyPrefix = `${vault.baseToken?.symbol} APR`
      const quoteTokenAprKeyPrefix = `${vault.quoteToken?.symbol} APR`

      const baseTokenAprKey = Object.keys(pool.aprs).find(aprKey =>
        aprKey.includes(baseTokenAprKeyPrefix)
      )
      const quoteTokenAprKey = Object.keys(pool.aprs).find(aprKey =>
        aprKey.includes(quoteTokenAprKeyPrefix)
      )

      if (!baseTokenAprKey || !quoteTokenAprKey) {
        return result
      }
      const baseTokenApr: string | number | undefined = pool.aprs[baseTokenAprKey]
      const quoteTokenApr: string | number | undefined = pool.aprs[quoteTokenAprKey]

      result[vault.id as AddressType] = {
        baseToken: new BN(baseTokenApr ?? 0),
        quoteToken: new BN(quoteTokenApr ?? 0),
      }
      return result
    }, {} as RawAPR)

    setRawApr(poolRewards)
  }, [merklPools, vaultList])

  const { vaultPositions } = useGetAutomatorPositions(
    vaultList.map(vault => vault.id as AddressType),
    inspectorAddress
  )

  useEffect(() => {
    if (!isEmpty(vaultList) && !isEmpty(vaultPositions)) {
      const filteredVaultPositions = chain(vaultPositions)
        .map(vp => {
          const vault = vaultList.find(v => v.id === vp.vaultAddress)
          if (!vault) {
            return
          }
          const tickLower = Number(vault.pool.tick) - vault.rewardTickRange
          const tickUpper = Number(vault.pool.tick) + vault.rewardTickRange

          return {
            ...vp,
            ...vault,
            positions: vp.positions.filter(p => p.tick >= tickLower && p.tick <= tickUpper),
            sqrtPriceX96: JSBI.BigInt(vault.pool.sqrtPrice),
          }
        })
        .compact()
        .value()

      const rewardList = chain(filteredVaultPositions)
        .map(vp => {
          const { vaultAddress, positions, sqrtPriceX96, isTokenPairReversed } = vp
          const summary = positions.reduce(
            (result, position) => {
              const sqrtRatioAX96 = TickMath.getSqrtRatioAtTick(position.tick)
              const sqrtRatioBX96 = TickMath.getSqrtRatioAtTick(position.tick + TICK_SPACING)

              const amounts = getAmountsForLiquidity(
                sqrtPriceX96,
                sqrtRatioAX96,
                sqrtRatioBX96,
                JSBI.BigInt(position.liquidity.toString())
              )
              const decimalizedAmount0 = new BN(amounts.amount0.toString()).div(
                10 ** Number(vp.pool.token0.decimals)
              )
              const decimalizedAmount1 = new BN(amounts.amount1.toString()).div(
                10 ** Number(vp.pool.token1.decimals)
              )
              result.amount0 = result.amount0.plus(decimalizedAmount0)
              result.amount1 = result.amount1.plus(decimalizedAmount1)
              return result
            },
            { amount0: new BN(0), amount1: new BN(0) }
          )

          let totalAssets: BN
          if (isTokenPairReversed) {
            summary.amount0 = summary.amount0.times(new BN(vp.pool.token1Price))
            totalAssets = new BN(vp.totalAssets).div(10 ** Number(vp.pool.token1.decimals))
          } else {
            summary.amount1 = summary.amount1.times(new BN(vp.pool.token0Price))
            totalAssets = new BN(vp.totalAssets).div(10 ** Number(vp.pool.token0.decimals))
          }
          const apr = rawApr[vaultAddress]
          if (!apr) {
            return
          }
          const [amount0, amount1] = isTokenPairReversed
            ? [summary.amount1, summary.amount0]
            : [summary.amount0, summary.amount1]
          const [apr0, apr1] = totalAssets.isZero()
            ? [new BN(0), new BN(0)]
            : [
                apr.baseToken.times(amount0.div(totalAssets)),
                apr.quoteToken.times(amount1.div(totalAssets)),
              ]
          const totalApr = apr0.plus(apr1)

          return { ...vp, positionTotal: summary, apr0, apr1, totalApr }
        })
        .compact()
        .value()

      const _merklRewardsApr = rewardList.reduce((result, reward) => {
        result[reward.vaultAddress] = reward.totalApr
        return result
      }, {} as MerkleRewardsApr)
      setMerklRewardsApr(_merklRewardsApr)
    }
  }, [vaultPositions, vaultList, rawApr])

  return { merklRewardsApr }
}

export const useMerklRewards = (chainId: number, almAddressList: ALMAddressPair[]) => {
  const [merklRewardsApr, setMerklRewardsApr] = useState<MerkleRewardsApr>({})

  const res = useQuery({
    queryKey: ['merkl', { chainId }],
    queryFn: fetchMerkl,
  })
  const merklPools = useMemo(() => {
    return res && res.data ? res.data[chainId]?.pools : {}
  }, [res?.data, chainId])

  useEffect(() => {
    if (res.isError) return
    const poolRewards = almAddressList.reduce((result: any, alm: ALMAddressPair) => {
      const pool = find(
        merklPools,
        (_, key) => alm.poolAddress === (key.toLowerCase() as AddressType)
      )
      const aprKey = `Orange ${alm.vaultAddress}`
      const matchedKey = Object.keys(pool?.aprs ?? []).find(
        key => key.toLowerCase() === aprKey.toLowerCase()
      )
      const apr: string | number | undefined = matchedKey ? pool.aprs[matchedKey] : undefined

      result[alm.vaultAddress.toLowerCase()] = apr ? new BN(apr) : undefined
      return result
    }, {})

    setMerklRewardsApr(poolRewards)
  }, [merklPools, almAddressList, res.isError])

  return {
    merklRewardsApr,
    isLoading: res.isLoading,
  }
}

type FallbackStats = {
  [address: string]: {
    name: string
    rewardAPR: number
  }
}

export const useFallbackRewards = (chainId: number) => {
  const [fallbackRewards, setFallbackRewards] = useState<FallbackStats>({})

  const res = useQuery({
    queryKey: ['fallbackStats', {}],
    queryFn: fetchFallbackStats,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (!res.data) return

    const mapped = mapKeys(res.data, (_, key) => key.toLowerCase())
    if (!isEqual(fallbackRewards, mapped)) {
      setFallbackRewards(mapped)
    }
  }, [res.data])

  return {
    fallbackRewards,
    isLoading: res.isLoading,
  }
}

// export const useRewardsApr = (chainId: number, almAddressList: ALMAddressPair[]) => {
//   const [rewardsApr, setRewardsApr] = useState<MerkleRewardsApr>({})

//   const { merklRewardsApr, isLoading: merklLoading } = useMerklRewards(chainId, almAddressList)
//   const { fallbackRewards, isLoading: fallbackLoading } = useFallbackRewards(chainId)

//   useEffect(() => {
//     Object.keys(merklRewardsApr).forEach(key => {
//       const rewardApr = merklRewardsApr[key as AddressType]
//       if (!!rewardApr) {
//         setRewardsApr(prev => set(prev, key.toLowerCase(), rewardApr))
//       } else {
//         const fallbackApr = fallbackRewards[key]
//           ? new BN(fallbackRewards[key].rewardAPR)
//           : undefined
//         setRewardsApr(prev => set(prev, key.toLowerCase(), fallbackApr))
//       }
//     })
//   }, [merklRewardsApr, fallbackRewards])

//   const isLoading = useMemo(() => merklLoading || fallbackLoading, [merklLoading, fallbackLoading])

//   return { merklRewardsApr: rewardsApr, isLoading }
// }

export const useRewardsApr = (chainId: number, almAddressList: ALMAddressPair[]) => {
  const [rewardsApr, setRewardsApr] = useState<MerkleRewardsApr>({})

  const { fallbackRewards, isLoading: fallbackLoading } = useFallbackRewards(chainId)

  useEffect(() => {
    Object.keys(fallbackRewards).forEach(key => {
      const fallbackReward = fallbackRewards[key as AddressType]
      if (!!fallbackReward) {
        setRewardsApr(prev => set(prev, key.toLowerCase(), fallbackReward.rewardAPR))
      }
    })
  }, [fallbackRewards])

  return { merklRewardsApr: rewardsApr, isLoading: fallbackLoading }
}
