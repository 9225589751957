import WatchPug from '@apps-orangefi/assets/images/auditor/watchpug.svg'
import YAudit from '@apps-orangefi/assets/images/auditor/yaudit.svg'
import Zokyo from '@apps-orangefi/assets/images/auditor/zokyo.svg'
import { LinkButton } from '@apps-orangefi/ui/atoms/buttons'
import { Disclosure } from '@headlessui/react'
import { Plus, Minus } from 'lucide-react'
import Image from 'next/image'
import { memo } from 'react'
import { twMerge } from 'tailwind-merge'

export enum Auditor {
  WatchPug = 'WATCHPUG',
  Zokyo = 'ZOKYO',
  YAudit = 'YAUDIT',
}

type Props = {
  auditors: Auditor[]
  className?: string
}

export const Audits = memo(function Audits({ auditors, className }: Props) {
  return (
    <div className={twMerge('w-full', className)}>
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <>
            <div className="flex flex-col w-full rounded-t-2xl dark:bg-gray-850 p-5">
              <div className="flex flex-row w-full justify-between">
                <span className="type-lg-semibold dark:text-white">Audits</span>
                <Disclosure.Button>
                  {open ? (
                    <Minus className="h-6 w-6 dark:text-white" />
                  ) : (
                    <Plus className="h-6 w-6 dark:text-white" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
            <Disclosure.Panel className="pt-0.5 text-sm dark:text-gray-500 flex flex-col gap-0.5">
              {auditors.includes(Auditor.WatchPug) && (
                <div className="dark:bg-gray-850 flex flex-col py-4 px-5">
                  <div className="flex flex-row items-center">
                    <Image src={WatchPug} alt="WatchPug" width={36} height={36} />
                    <span className="ml-3 type-lg-semibold dark:text-white">WatchPug</span>
                  </div>
                  <div className="type-base-medium mt-3">
                    WATCHPUG is a security team that offers in-depth auditing for Solidity smart
                    contracts, ranked#1 at Sherlock & Code4rena.
                  </div>
                </div>
              )}
              {auditors.includes(Auditor.Zokyo) && (
                <div className="dark:bg-gray-850 flex flex-col py-4 px-5">
                  <div className="flex flex-row items-center">
                    <Image src={Zokyo} alt="Zokyo" width={36} height={36} />
                    <span className="ml-3 type-lg-semibold dark:text-white">Zokyo</span>
                  </div>
                  <div className="type-base-medium mt-3">
                    Zokyo is a cybersecurity company that excels in conducting comprehensive smart
                    contract security reviews and penetration tests designed for blockchain and Web3
                    projects.
                  </div>
                </div>
              )}
              {auditors.includes(Auditor.YAudit) && (
                <div className="dark:bg-gray-850 flex flex-col py-4 px-5">
                  <div className="flex flex-row items-center">
                    <Image src={YAudit} alt="Zokyo" width={36} height={36} />
                    <span className="ml-3 type-lg-semibold dark:text-white">yAudit</span>
                  </div>
                  <div className="type-base-medium mt-3">
                    yAudit is an auditing collective made up of the best auditors hand-selected from
                    the yAcademy training program.
                  </div>
                </div>
              )}
            </Disclosure.Panel>
            <div className="rounded-b-2xl dark:bg-gray-850 p-5 pt-4 mt-0.5">
              <LinkButton
                label="Reports"
                href="https://orange-finance.gitbook.io/orange-finance/risk-and-security/audit"
              />
            </div>
          </>
        )}
      </Disclosure>
    </div>
  )
})
