import ArrowRightIcon from '@apps-orangefi/assets/images/arrow-right.svg'
import { BN } from '@apps-orangefi/lib'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'

export type DailyEarnings = {
  current: number
  estimate: number
}

type Props = {
  dailyEarnings: DailyEarnings
  className?: string
}

export const DailyPointEarningsEstimation = ({ dailyEarnings, className }: Props) => {
  return (
    <div
      className={twMerge('flex flex-col rounded-xl px-3 py-2 border border-gray-800', className)}
    >
      <div className="type-sm-medium dark:text-gray-400">Est. Daily Point Earnings</div>
      <div className="flex flex-row gap-2 type-sm-semibold dark:text-white mt-1">
        <span>{new BN(dailyEarnings.current).toFormat(0)} points</span>
        <Image src={ArrowRightIcon} width={13} height={19} alt="right" />
        <span>{new BN(dailyEarnings.estimate).toFormat(0)} points</span>
      </div>
    </div>
  )
}
