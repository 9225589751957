import { useResponsiveType, responsiveType } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { numberWithShortScale } from '@apps-orangefi/lib/utils'
import { ProgressBar, ArbReward } from '@apps-orangefi/ui/atoms'
import { Milestone } from 'lucide-react'

type Props = {
  tvl: BN
  milestone: { value: BN; reward: number }
  max: { value: BN; reward: number }
}

export const TVLBar = ({ tvl, milestone, max }: Props) => {
  const milestoneRate = milestone.value.div(max.value).times(100).toNumber()
  const rType = useResponsiveType()
  const isMdOrLarger = rType === responsiveType.MdOrLarger

  return (
    <>
      {isMdOrLarger ? (
        <div className="flex flex-col w-full relative">
          <div className="flex flex-col justify-center">
            <div className="mb-2 type-base-medium dark:text-gray-300">Total value locked</div>
          </div>
          <div className="relative">
            <div className="absolute w-full h-full z-20">
              <div
                className="relative border-r border-dashed border-white h-full "
                style={{ width: `${milestoneRate}%` }}
              >
                <ArbReward reward={milestone.reward} className="absolute -top-8 -right-16" />
                <span className="absolute top-4 right-2">
                  ${numberWithShortScale(milestone.value)}
                </span>
              </div>
            </div>
            <div className="flex flex-row absolute right-0 -top-8 z-10">
              <span className="type-base-medium dark:text-gray-300 mr-1.5">Milestone bonus:</span>
              <ArbReward reward={max.reward} />
            </div>
            <div className="relative flex flex-col justify-center">
              <div className="absolute text-style-value ml-6 z-10 font-mono">
                ${numberWithShortScale(tvl, 2)}
              </div>
              {/* <div className="border-r border-white h-full absolute z-20" style={{ width: `${milestoneRate}%` }}></div> */}
              <ProgressBar value={tvl.toNumber()} max={max.value.toNumber()} className="h-14" />
              <div className="absolute text-style-value right-6 z-10 font-mono">
                ${numberWithShortScale(max.value, 2)}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="type-base-medium dark:text-gray-300">Total value locked</div>
            <div className="type-base-semibold dark:text-white font-mono">
              ${numberWithShortScale(tvl, 2)}
            </div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="type-base-medium dark:text-gray-300">Milestone bonus:</div>
            <ArbReward reward={max.reward} />
          </div>
          <div className="flex flex-row justify-between mt-2 mb-4">
            <div className="type-base-medium dark:text-gray-300">
              ${numberWithShortScale(milestone.value, 0)} bonus:
            </div>
            <ArbReward reward={milestone.reward} />
          </div>
          <div className="relative">
            <ProgressBar value={tvl.toNumber()} max={max.value.toNumber()} className="h-14" />
            <div
              className="absolute border-r border-dashed border-white h-full top-0"
              style={{ width: `${milestoneRate}%` }}
            ></div>
          </div>
        </div>
      )}
    </>
  )
}
