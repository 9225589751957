import { StrykeUniV3HandlerV2ABI } from '@apps-orangefi/wagmi/abis'
import { useReadContractWithErrorHandling } from '@apps-orangefi/wagmi/hooks/common'

import '@apps-orangefi/lib/extensions'

export const useGetReserveCooldown = (handlerAddress: AddressType | undefined) => {
  const enabled = !!handlerAddress

  const { data, isFetching, isFetched, queryKey } = useReadContractWithErrorHandling({
    address: handlerAddress!,
    abi: StrykeUniV3HandlerV2ABI,
    functionName: 'reserveCooldown',
    query: {
      enabled,
    },
  })

  return {
    reserveCooldwon: data ? Number(data) : undefined,
    isFetching,
    isFetched,
  }
}
